import { IcoClose } from '../icons';

export default function BtnClose({ onClick }) {
  const classIco =
    'w-6 h-6 fill-current mx-0.5 transition-all duration-300 text-blue-500 group-hover:text-blue-400 group-hover:scale-110 transition-all duration-300';

  return (
    <button className="d-flex align-items-center justify-center h-7 min-w-7 bg-white group cursor-pointer border-0">
      <IcoClose className={classIco} onClick={onClick} />
    </button>
  );
}
