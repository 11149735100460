export const buildModuleKey = poiType => `planner-${poiType}`;
// TODO: Move local storage utils to a utils out of planner
const instance = `${window._env_.REACT_APP_BRAND}`;

const dbName = 'mofi-db';
let db;

export const saveToLocalStorage = async (name, value, module) => {
  // localStorage.setItem(buildKey(module, name), JSON.stringify(value));
  if (!db) db = await openDatabase();

  const data = { key: buildKey(module, name), value };
  await addToStore(db, data);
};

export const loadFromLocalStorage = async (name, module, cb) => {
  const key = buildKey(module, name);

  // return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
  if (!db) db = await openDatabase();
  const data = await getFromStore(db, key);
  cb(data ? data.value : null);
};

function buildKey(module, name) {
  return `${instance}_${module}_${name}`;
}

// Wrapper for opening the database
async function openDatabase() {
  return new Promise((resolve, reject) => {
    const openDB = version => {
      // console.log('opening db ', version);
      const request = indexedDB.open(dbName, version);
      request.onupgradeneeded = event => {
        // console.log('onupgradeneeded', event);
        const db = event.target.result;
        db.createObjectStore(instance, { keyPath: 'key' });
      };

      request.onsuccess = event => {
        // console.log('onsuccess', event);
        const db = event.target.result;
        try {
          const transaction = db.transaction(instance, 'readwrite');
          const store = transaction.objectStore(instance);
          console.log('found', store.name);
          return resolve(event.target.result);
        } catch (e) {
          const v = db.version + 1;
          db.close();
          openDB(v);
          // return reject('invalid version');
        }
      };
      request.onerror = event => {
        // console.log('onerror', event);
        return reject(event.target.error);
      };
    };
    openDB();
  });
}

// Wrapper for adding data to the store
async function addToStore(db, data) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(instance, 'readwrite');
    const store = transaction.objectStore(instance);
    // const request = store.add(data);
    const request = store.put(data);
    request.onsuccess = () => resolve();
    request.onerror = () => reject(request.error);
  });
}

// Wrapper for getting data from the store
async function getFromStore(db, key) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(instance, 'readonly');
    const store = transaction.objectStore(instance);
    const request = store.get(key);
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
}

// (async () => {
// try {
// Open the database
// const db = await openDatabase();

// Add data to the store
// const data = { id: 1, name: "Alice" };
// await addToStore(db, data);
// console.log("Data added:", data);

// Get data from the store
// const id = 1;
// const retrievedData = await getFromStore(db, id);
// console.log("Data retrieved:", retrievedData);
// } catch (error) {
// console.error("Error:", error);
// }
// })();

const e = {
  saveToLocalStorage,
  loadFromLocalStorage,
  buildModuleKey
};

export default e;
