import React, { useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { logout } from '../../redux/ducks/auth';
import Header from '../header/Header';

import './app_layout.scss';
import SettingsNav from '../nav/SettingsNav';
import Footer from '../footer/Footer';

export const SettingsLayout = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [navMini, setNavMini] = useState(props.navMini);

  const logoutDispath = () => dispatch(logout());

  const toggleNav = e => {
    e.preventDefault();
    setNavMini(!navMini);
  };

  const hideNav = e => {
    e.stopPropagation();
    e.preventDefault();
    setNavMini(false);
  };

  return (
    <div className="app-wrapper" ref={ref}>
      {props.withNav ? (
        <>
          <SettingsNav mini={navMini} toggleNav={toggleNav} user={props.user} />
          <div className={`content-container ${navMini ? 'full' : ''}`}>
            {/* dropshadow for mobile nav triggering */}
            <div
              className="menu-dropshadow"
              style={{ display: navMini ? 'block' : 'none' }}
              onClick={hideNav}
            />
            <Header
              toggleNav={toggleNav}
              logout={logoutDispath}
              filter={null}
              user={props.user}
              organization={props.organization}
            />
            {props.children}
            <Footer />
          </div>
        </>
      ) : (
        <>
          <div className={`content-container full-no-nav`}>
            <nav className={`site-nav no-nav`}>
              <header className="nav-head m-0 p-3 w-100 align-items-center d-flex justify-content-center">
                <img
                  src={`${window._env_.REACT_APP_BASE_BRAND_URL}/${window._env_.REACT_APP_BRAND}/logo.png`}
                  alt={window._env_.REACT_APP_TITLE}
                />
              </header>
            </nav>
            {props.children}
          </div>
        </>
      )}
    </div>
  );
});

export default SettingsLayout;
