import { IcoInfo, IcoAlert } from '../icons';

export default function InfoMessage({ ico, text, icoColor, icoSize, addClass }) {
  const classIco =
    'fill-current  ' +
    (icoColor ? icoColor : 'text-slate-500') +
    ' ' +
    (icoSize ? icoSize : 'w-5.5 h-5.5');
  return (
    <div className={'d-flex align-items-center' + ' ' + (addClass && addClass)}>
      {ico &&
        {
          info: <IcoInfo className={classIco} />,
          alert: <IcoAlert className={classIco} />
        }[ico]}

      <p className="mb-0 font-open-sans text-xs text-slate-600 mt-0.5 ml-1 tracking-minimal">
        {text}
      </p>
    </div>
  );
}
