import i18n from 'i18n-js';

export default function PoisSelected({ val }) {
  return (
    <div className="font-open-sans flex align-items-center space-x-1.5">
      <div
        className={
          'd-flex align-items-center justify-center px-2.5 h-7 rounded-full ' +
          (val === 0 ? 'bg-mof-slate-500 ' : 'bg-blue-600')
        }
      >
        <p className="mb-0 font-semibold text-white leading-none">{val}</p>
      </div>
      <p className="mb-0 text-slate-700 tracking-normal">{i18n.t('search_selected_pois')}</p>
    </div>
  );
}
