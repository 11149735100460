import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { StandardEventsWeekHourChart } from './EventsWeekHourChart';
import EventsHourChart from './EventsHourChart';
import { callSyncAPIv2DataLoader } from '../../hooks/api';

export default function VisitsTrendsFDCard({ fullDemosObj, poiType }) {
  const [timeBlocks, setTimeBlocks] = useState(backwardCompatibleTimeBlocks());

  useEffect(() => {
    let isMounted = true;
    callSyncAPIv2DataLoader(
      'appConfig',
      `visit-timeblock/timeBlocks`,
      {},
      { responseHolder: 'response' },
      res => {
        if (isMounted && !res.error && res.data.value.response['visit-timeblock']) {
          setTimeBlocks(res.data.value.response['visit-timeblock'].timeBlocks);
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, []);
  const metricsWeekDayHour = [];
  for (let d = 1; d <= 7; d++) {
    for (let r = 1; r <= 4; r++) {
      metricsWeekDayHour.push({
        dayofweek: d,
        range: r,
        visits: fullDemosObj.fullDemos.reduce((accum, v) => accum + v[`dow_${d}_h${r}`], 0)
      });
    }
  }
  const metricsHour = [];
  for (let r = 1; r <= 4; r++) {
    let v = 0;
    let vW = 0;
    for (let d = 1; d <= 2; d++) {
      vW += fullDemosObj.fullDemos.reduce((accum, v) => accum + v[`dow_${d}_h${r}`], 0);
    }
    for (let d = 3; d <= 7; d++) {
      v += fullDemosObj.fullDemos.reduce((accum, v) => accum + v[`dow_${d}_h${r}`], 0);
    }
    metricsHour.push({
      range: r,
      visits: v,
      visitsWeekend: vW
    });
  }
  // This is the one filtered by demographics
  return (
    <>
      {window._env_.REACT_APP_HIDE_MODULE_REPORTS_FOUR_TIME_BLOCKS_DEMOGRAPHICS !== 'true' && (
        <Row>
          <div className="mb-4 col-sm-12 col-md-12">
            <Card>
              <CardBody>
                <CardTitle className="text-uppercase h6">
                  {i18n.t(poiType === 'ooh' ? 'mobility_per_week_ooh' : 'mobility_per_week')}
                </CardTitle>
                <div className="small mb-4 card-subtitle">
                  {i18n.t(
                    poiType === 'ooh' ? 'mobility_per_week_text_ooh' : 'mobility_per_week_text'
                  )}
                </div>
                <div style={{ width: '100%', height: '460px' }}>
                  <StandardEventsWeekHourChart
                    response={metricsWeekDayHour}
                    visits={fullDemosObj.totalVisits}
                    poiType={poiType}
                    timeBlocks={timeBlocks}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      )}
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t(
                  poiType === 'ooh' ? 'mobility_per_hour_block_ooh' : 'mobility_per_hour_block'
                )}
              </CardTitle>
              <div className="small mb-4 card-subtitle">
                {i18n.t(
                  poiType === 'ooh'
                    ? 'mobility_per_hour_block_text_ooh'
                    : 'mobility_per_hour_block_text'
                )}
              </div>
              <div style={{ width: '100%', height: '460px' }}>
                <EventsHourChart
                  response={metricsHour}
                  visits={fullDemosObj.totalVisits}
                  poiType={poiType}
                  timeBlocks={timeBlocks}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>
    </>
  );
}

function backwardCompatibleTimeBlocks() {
  return [
    {
      block: '6_10',
      from: 6,
      to: 9,
      label: {
        pt: '6:00am - 9:59am',
        en: '6:00am - 9:59am',
        es: '6:00am - 9:59am'
      }
    },
    {
      block: '10_14',
      from: 10,
      to: 13,
      label: {
        pt: '10:00am - 1:59pm',
        en: '10:00am - 1:59pm',
        es: '10:00am - 1:59pm'
      }
    },
    {
      block: '14_18',
      from: 14,
      to: 17,
      label: {
        pt: '2:00pm - 5:59pm',
        en: '2:00pm - 5:59pm',
        es: '2:00pm - 5:59pm'
      }
    },
    {
      block: '18_22',
      from: 18,
      to: 21,
      label: {
        pt: '6:00pm - 9:59pm',
        en: '6:00pm - 9:59pm',
        es: '6:00pm - 9:59pm'
      }
    }
  ];
}
