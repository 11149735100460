import { IcoLocation } from '../icons';
import InfoMessage from './infoMessage';

export default function PopupHeader({ ico, title, info }) {
  const classIco = 'text-white fill-current w-6 h-6 ';

  return (
    <div className="border-b border-slate-200 pb-1.5 mb-0.5 space-y-2.5">
      <div className="d-flex align-items-center space-x-5">
        <div className="d-flex align-items-center space-x-2">
          <div className="d-flex justify-center align-items-center rounded-full bg-blue-800 w-8 h-8">
            {ico &&
              {
                location: <IcoLocation className={classIco} />
              }[ico]}
          </div>
          <p className="mb-0 font-open-sans text-sm font-medium tracking-wide">{title}</p>
        </div>
        {info && <InfoMessage text={info} ico="info" />}
      </div>
    </div>
  );
}
