import React from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';

export default function CustomTooltip({ payload, active, type }) {
  if (active && payload && payload[0]) {
    return (
      <div className="custom-tooltip">
        <p>
          <b>{payload[0].payload.name}</b>
        </p>
        <p style={{ fontSize: 10 }}>{payload[0].payload.address}</p>
        <hr />
        <p>
          {i18n.t(type === 'ooh' ? 'ranking_visits_ooh' : 'ranking_visits')}:{' '}
          {numeral(payload[0].payload.visits).format(
            payload[0].payload.visits > 10000000 ? '0.00a' : '0,0'
          )}{' '}
          {`(${numeral(payload[0].payload.perc).format('%0.00')})`}
        </p>
      </div>
    );
  }
  return null;
}
