import React from 'react';
import i18n from 'i18n-js';
import toastr from 'toastr';

import * as Yup from 'yup';

import { Formik, Field, Form } from 'formik';

import { FormGroup, Col, FormFeedback, Button, Input, Card, CardBody, Label } from 'reactstrap';

import API2 from '../../api2/index';

export default function EditPassword({ user }) {
  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('edit_password_title')}</h1>
          <p className="mb-0">{i18n.t('edit_password')}</p>
        </header>
      </div>
      <ViewContent user={user} />
    </div>
  );
}

function ViewContent({ user }) {
  return (
    <div className="view-content view-components">
      <Card className="mb-4">
        <CardBody className="table-responsive">
          <h6 className="mb-4 text-uppercase">{i18n.t('new_password_title')}</h6>
          <Card>
            <CardBody>{user && <EditPasswordForm {...{ user }} />}</CardBody>
          </Card>
        </CardBody>
      </Card>
    </div>
  );
}

function EditPasswordForm({ user }) {
  const onSubmit = params => {
    const userObj = {
      password: params.password
    };

    API2.patch('users', `${user.id}`, userObj, (err, user) => {
      if (err) {
        toastr.error(err.reason);
      } else {
        toastr.success(i18n.t('ok_saving_profile'));
      }
    });
  };

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: ''
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(6, i18n.t('password_too_short'))
          .required(i18n.t('password_required')),
        passwordConfirmation: Yup.string()
          .min(6, i18n.t('password_too_short'))
          .oneOf([Yup.ref('password'), null], i18n.t('password_confirm_not_match'))
          .required(i18n.t('password_confirm_required'))
      })}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className="align-items-center d-flex flex-column">
          <Col msm={9}>
            <FormGroup>
              <Label for="password">{i18n.t('password_field')}</Label>
              <Input
                placeholder=""
                className="form_item text_form"
                type="password"
                name="password"
                tag={Field}
                invalid={errors.password && touched.password}
              />
              {errors.password && touched.password && (
                <FormFeedback>{errors.password}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="passwordConfirmation">{i18n.t('password_confirm_field')}</Label>
              <Input
                placeholder=""
                className="form_item text_form"
                type="password"
                name="passwordConfirmation"
                tag={Field}
                invalid={errors.passwordConfirmation && touched.passwordConfirmation}
              />
              {errors.passwordConfirmation && touched.passwordConfirmation && (
                <FormFeedback>{errors.passwordConfirmation}</FormFeedback>
              )}
            </FormGroup>
          </Col>
          <Button color="primary" type="submit" className="form_bt bt_common mt-3 w-25">
            {i18n.t('save_button')}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
