import ScrollConte from './scrollConte';
import WindowActive from './windowActive';
import LoaderFullCenter from './loaderFullCenter';

export default function ScrollWindow({ children, selected, loading, maxH }) {
  return (
    <div
      className={
        'relative grow bg-white border border-b-2 border-slate-200 rounded mt-0.5 ' +
        (maxH ? maxH : 'max-h-108')
      }
    >
      <ScrollConte>{children}</ScrollConte>

      {selected && <WindowActive />}
      {loading && (
        <div className="d-flex align-items-center justify-center absolute h-full w-win-active top-0 left-0">
          <LoaderFullCenter />
        </div>
      )}
    </div>
  );
}
