import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import i18n from 'i18n-js';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Area,
  Scatter
} from 'recharts';

import { colors } from '../../components/charts/Styles';

export default function VisitorsComparedChart({
  uniquesTitle,
  repeatTitle,
  repeatVisitsTitle,
  visitsWeekly,
  repeatCount,
  fixedPeriods
}) {
  if (!visitsWeekly || visitsWeekly.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const max = Math.round(
    visitsWeekly.reduce(
      // (accum, val) => (accum > val.unique_visitors ? accum : val.unique_visitors),
      (accum, val) => (accum > val[`repeat_visits`] ? accum : val[`repeat_visits`]),
      0
    )
  );
  const multi = '1'.padEnd(String(max).length - 1, '0');

  const d = Math.ceil(max / multi) * multi + multi * 2;

  function CustomTooltip({ active, payload, label, fixedPeriods }) {
    let v = null;
    if (fixedPeriods === 'false') v = visitsWeekly.find(r => r._id.period_quarter === label);
    else v = visitsWeekly.find(r => r._id.period_quarter_start === label);
    if (active && v !== null) {
      return (
        <div className="custom-tooltip">
          <p>
            <b>
              {moment(v._id.period_quarter_start).utc().format('MMMM DD')} -{' '}
              {moment(v._id.period_quarter_end).utc().format('MMMM DD')}
            </b>
          </p>
          <hr />
          <p style={{ color: payload[0].color }}>
            {` ${payload[0].name}`} :{' '}
            {` ${numeral(payload[0].value).format(payload[0].value > 10000000 ? '0.000a' : '0,0')}`}
          </p>
          <p style={{ color: payload[1].color }}>
            {` ${payload[1].name}`} :{' '}
            {` ${numeral(payload[1].value).format(payload[1].value > 10000000 ? '0.000a' : '0,0')}`}
          </p>
          <p style={{ color: payload[2].color }}>
            {` ${payload[2].name}`} :{' '}
            {` ${numeral(payload[2].value).format(payload[2].value > 10000000 ? '0.000a' : '0,0')}`}
          </p>
          <p style={{ color: payload[3].color }}>
            {` ${payload[3].name}`} : {` ${numeral(payload[3].value).format('0,0.00')}`}
          </p>
        </div>
      );
    }

    return null;
  }

  return (
    <ResponsiveContainer>
      <ComposedChart data={visitsWeekly} margin={{ top: 10, right: 10, left: 10, bottom: 0 }}>
        <CartesianGrid stroke="#eee" vertical={false} />
        <XAxis
          dataKey={fixedPeriods === 'false' ? '_id.period_quarter' : '_id.period_quarter_start'}
          axisLine={false}
          fontSize={10}
          tickLine={false}
          padding={{ left: 0, right: 5 }}
          tickFormatter={value =>
            fixedPeriods === 'false'
              ? `${i18n.t('week')} #${value + 1}`
              : moment(value).utc().format('DD/MM')
          }
        />
        <YAxis
          //yAxisId="visitors"
          yAxisId="visits"
          fontSize={10}
          axisLine={false}
          tickLine={false}
          type="number"
          domain={[0, d]}
          tickFormatter={value =>
            // eslint-disable-next-line no-nested-ternary
            numeral(value).format(value > 1000000 ? '0.00a' : value > 100000 ? '0a' : '0,0')
          }
        />
        {/* <YAxis
          yAxisId="visits"
          orientation="right"
          fontSize={10}
          axisLine={false}
          tickLine={false}
          label={{
            value: i18n.t(repeatVisitsTitle),
            angle: 90,
            position: 'right',
            offset: -5,
            fontSize: 9
          }}
          type="number"
          // domain={[0, d]}
          tickFormatter={value =>
            // eslint-disable-next-line no-nested-ternary
            numeral(value).format(value > 1000000 ? '0.00a' : value > 100000 ? '0a' : '0,0')
          }
        /> */}
        <YAxis
          yAxisId="frequency"
          orientation="right"
          fontSize={10}
          axisLine={false}
          tickLine={false}
          type="number"
          // domain={[0, d]}
          tickFormatter={value =>
            // eslint-disable-next-line no-nested-ternary
            numeral(value).format('0,0.00')
          }
        />
        <Tooltip content={<CustomTooltip fixedPeriods={fixedPeriods} />} />
        <Legend />
        <Area
          type="monotone"
          barSize={20}
          yAxisId="visits"
          name={i18n.t(repeatVisitsTitle)}
          dataKey={`repeat_visits_${repeatCount}`}
          stackId="3"
          strokeWidth={2}
          stroke={colors[9]}
          fill={colors[9]}
        />

        <Bar
          type="monotone"
          barSize={20}
          yAxisId="visits"
          name={i18n.t(repeatTitle)}
          dataKey={`repeat_visitors_${repeatCount}`}
          stackId="2"
          strokeWidth={2}
          stroke={colors[1]}
          fill={colors[1]}
        />

        <Bar
          type="monotone"
          barSize={20}
          yAxisId="visits"
          name={i18n.t(uniquesTitle)}
          dataKey="unique_visitors"
          stackId="1"
          strokeWidth={2}
          stroke={colors[0]}
          fill={colors[0]}
        />

        <Scatter
          type="monotone"
          yAxisId="frequency"
          name={i18n.t('frequency_title')}
          dataKey={`frequency_${repeatCount}`}
          stackId="4"
          strokeWidth={1}
          stroke={colors[7]}
          fill={colors[7]}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
