import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';

import { CardGroup, Card, CardBody, CardTitle } from 'reactstrap';

import { MdCancel, MdEdit, MdTrendingDown, MdTrendingUp } from 'react-icons/md/';

import { ResponseLoading } from '../../components/utils/Loading';
import DemographicsCard from './DemographicsCard';
import { callSyncAPIDataLoader } from '../../hooks/api';
import POISelector from '../../components/selector/POISelector';

const defaultSegment = { name: '--', count: 0 };

export default function CompareColumn({
  responseBase,
  onResponseChange,
  onFilterChange,
  name,
  defaultFilter,
  defaultResponse
}) {
  const [modal, setModal] = useState(false);
  const [segment, setSegment] = useState(
    defaultFilter.columns ? defaultFilter.columns[name] || defaultSegment : defaultSegment
  );
  const [responseVisits, setResponseVisits] = useState({ ...defaultResponse });
  const [responseDemos, setResponseDemos] = useState({ ...defaultResponse });

  // const filter = { ...defaultFilter };
  const type = defaultFilter.type;

  const isBaseline = name === '0';

  const responseVisitsBase = responseBase && responseBase.responseVisits;

  const processSegment = React.useCallback((segment, filter) => {
    if (segment.group_hash) {
      const f = {
        period: filter.period,
        startDate: filter.startDate,
        endDate: filter.endDate,
        offset: filter.offset,
        groupKeys: [segment.group_hash],
        type: filter.type
      };

      callSyncAPIDataLoader(
        'metrics.dashboardData',
        {
          filter: f,
          type: { qVisits: true }
        },
        { cache: true },
        res => {
          addCompareMetrics(res, segment.count);
          // onResponseChange(name, { responseVisits: res, responseDemos });
          const resVisits = res;
          setResponseVisits({ ...res });

          callSyncAPIDataLoader(
            'metrics.dashboardData',
            {
              filter: f,
              type: { qDemos: true }
            },
            { cache: true },
            res => {
              onResponseChange(name, { responseVisits: resVisits, responseDemos: res });
              setResponseDemos({ ...res });
            }
          );
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelect = React.useCallback(
    node => {
      setModal(false);
      setTimeout(() => {
        onSelectPOI(node, name);
      }, 200);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultFilter.columns]
  );

  // if any of defaultFilter (period/month) OR segment change, try to get data from API
  useEffect(() => {
    // console.log('defaultFilter', name, defaultFilter);
    processSegment(segment, { ...defaultFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment, defaultFilter.startDate, defaultFilter.endDate]);

  const addCompareMetrics = (response, segmentCount) => {
    if (response && response.hasValue('metrics') && segmentCount > 0) {
      response.data.value.metricsCompare = {
        unique_visitors: Math.round(response.data.value.metrics.unique_visitors / segmentCount),
        visits: Math.round(response.data.value.metrics.visits / segmentCount)
      };
    }
  };

  const onSelectPOI = node => {
    const s = { name: node.name, count: node.groupCount, group_hash: node.group_hash };
    setSegment(s);
    onFilterChange(name, s);
    // processSegment(s, { ...defaultFilter });
  };

  const requestReset = () => {
    setSegment(defaultSegment);
    setResponseVisits(defaultResponse);
    setResponseDemos(defaultResponse);
    onFilterChange(name, null);
    onResponseChange(name, { responseVisits: defaultResponse });
  };

  const variationBlock = (metric, variable, format, value) => {
    const percStyle = {
      fontSize: '12px',
      textAlign: 'center',
      marginBottom: '0px',
      marginTop: '0px'
    };

    const calculateVar =
      responseVisitsBase &&
      responseVisits.hasValue(metric) &&
      responseVisitsBase.hasValue(metric) &&
      responseVisitsBase.data.value[metric][variable] > 0;

    const variation = calculateVar ? (
      responseVisits.data.value[metric][variable] >
      (responseVisitsBase.data.value[metric][variable] || 0) ? (
        // eslint-disable-next-line react/jsx-indent
        <MdTrendingUp size="16" color="#00c853" />
      ) : (
        <MdTrendingDown size="16" color="#c80053" />
      )
    ) : (
      <span>{responseVisits.hasValue(metric) ? null : '--'}</span>
    );

    const val = responseVisits.hasValue(metric) ? (
      <span>{numeral(value || responseVisits.data.value[metric][variable]).format(format)}</span>
    ) : null;

    const perc = calculateVar
      ? [
          {
            ...percStyle,
            color:
              responseVisits.data.value[metric][variable] >
              (responseVisitsBase.data.value[metric][variable] || 0)
                ? '#18cb5d'
                : '#c80053'
          },
          numeral(
            (responseVisits.data.value[metric][variable] || 0) /
              responseVisitsBase.data.value[metric][variable] -
              1
          ).format('% 0,0.0')
        ]
      : [{ ...percStyle, color: 'white' }, '--'];

    return (
      <>
        {variation}
        {val}
        <div style={perc[0]}>{perc[1]}</div>
      </>
    );
  };

  return (
    <CardGroup className="metric-card mb-4">
      <Card style={{ backgroundColor: isBaseline ? '#e8e8e8' : 'white' }}>
        <CardBody>
          {/* <ModalFilter
            modal={modal}
            setModal={setModal}
            name={name}
            preventDefault={true}
            onSelect={onSelect}
            filter={defaultFilter}
          /> */}
          <POISelector
            modal={modal}
            setModal={setModal}
            name={name}
            preventDefault={true}
            onSelect={onSelect}
            filter={defaultFilter}
          />
          <div className="d-flex justify-content-between w-25">
            <MdEdit
              size="18"
              color="#005384"
              style={{ float: 'right', cursor: 'pointer' }}
              // disabled={!responseFilters.hasValue('pdvs')}
              onClick={() => {
                setModal(true);
              }}
            />
            <MdCancel
              size="18"
              color="#cecece"
              style={{ float: 'right', cursor: 'pointer' }}
              // disabled={!responseFilters.hasValue('pdvs')}
              onClick={requestReset}
            />
          </div>

          <div className="text-uppercase small font-weight-bold text-center mt-4">
            {isBaseline ? <span className="badge-info">baseline</span> : `#${name}`}
          </div>
        </CardBody>

        {responseVisits && (
          <>
            <ResponseLoading responses={[responseVisits]} />

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(type === 'ooh' ? 'segment_label_ooh' : 'segment_label')}
              </CardTitle>

              <div className="d-flex flex-column align-items-center">
                <h3
                  className="mr-2 ml-2 font-weight-normal small text-center"
                  style={{ minHeight: '3.5em' }}
                >
                  {segment.name}
                </h3>
                <h3 className="mr-2 ml-2 font-weight-normal small text-center">
                  {segment.count > 0 ? `(${segment.count})` : '--'}
                </h3>
              </div>
            </CardBody>

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(
                  type === 'ooh' ? 'total_unique_visitors_title_ooh' : 'total_unique_visitors_title'
                )}
              </CardTitle>

              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metrics', 'unique_visitors', '0.[00]a')}
                </h3>
              </div>
            </CardBody>

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(
                  type === 'ooh' ? 'avg_unique_visitors_title_ooh' : 'avg_unique_visitors_title'
                )}{' '}
                {i18n.t(type === 'ooh' ? 'by_ooh' : 'by_poi')}
              </CardTitle>

              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metricsCompare', 'unique_visitors', '0.[00]a')}
                </h3>
              </div>
            </CardBody>

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(type === 'ooh' ? 'total_visits_title_ooh' : 'total_visits_title')}
              </CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metrics', 'visits', '0.[00]a')}
                </h3>
              </div>
            </CardBody>

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(type === 'ooh' ? 'avg_visits_title_ooh' : 'avg_visits_title')}{' '}
                {i18n.t(type === 'ooh' ? 'by_ooh' : 'by_poi')}
              </CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metricsCompare', 'visits', '0.[00]a')}
                </h3>
              </div>
            </CardBody>

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t('avg_frequency_title')}
              </CardTitle>

              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metrics', 'frequency', '0,0.00')}
                </h3>
              </div>
            </CardBody>
            {type === 'ooh' ? null : (
              <CardBody>
                <CardTitle className="text-uppercase small font-weight-bold text-center">
                  {i18n.t('avg_dwell_time_title')}
                </CardTitle>

                <div className="d-flex align-items-center">
                  <h3 className="mr-2 font-weight-normal">
                    {variationBlock('metrics', 'dwell_time_mins', '0,0.00')}
                  </h3>
                </div>
              </CardBody>
            )}
            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(type === 'ooh' ? 'repeat_visitors_title_ooh' : 'repeat_visitors_title')}
              </CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metrics', 'repeat_visitors', '0.[00]a')}
                </h3>
              </div>
            </CardBody>
            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(type === 'ooh' ? 'avg_new_visitors_title_ooh' : 'avg_new_visitors_title')}
              </CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metrics', 'new_visitors', '0.[00]a')}
                </h3>
              </div>
            </CardBody>

            {isBaseline ? (
              <DemographicsCard response={responseDemos} />
            ) : (
              <CompareDemographicsCard response={responseDemos} responseBase={responseBase} />
            )}
          </>
        )}
      </Card>
    </CardGroup>
  );
}

function CompareDemographicsCard({ response, responseBase }) {
  // console.log(responseBase);

  const percStyle = {
    fontSize: '11px',
    textAlign: 'right',
    marginBottom: '0px',
    marginTop: '0px'
  };

  const responseDemosBase = responseBase && responseBase.responseDemos;

  const processCompareMetric = (metric, field, isLabel) => {
    if (!responseDemosBase || !responseDemosBase.hasValue(metric) || !response.hasValue(metric)) {
      return [];
    }

    const metricsBase =
      responseDemosBase && responseDemosBase.hasValue(metric)
        ? responseDemosBase.data.value[metric].map(e => ({
            name: isLabel ? i18n.t(e[field]) : e[field],
            value: e.total
          }))
        : [];

    const metrics = response.hasValue(metric)
      ? response.data.value[metric].map(e => ({
          name: isLabel ? i18n.t(e[field]) : e[field],
          value: e.total
        }))
      : [];

    const totalBase = metricsBase.reduce((accum, val) => accum + val.value, 0);
    const total = metrics.reduce((accum, val) => accum + val.value, 0);

    const compared = metrics.map(g => {
      const value = g.value / total;
      const valueBase = metricsBase.find(gb => gb.name === g.name).value / totalBase;

      const variation =
        value > valueBase ? (
          <MdTrendingUp size="16" color="#00c853" />
        ) : (
          <MdTrendingDown size="16" color="#c80053" />
        );

      const perc = [
        {
          ...percStyle,
          color: value > valueBase ? '#18cb5d' : '#c80053'
        },
        // numeral((value - valueBase) / 100).format('% 0,0.00')
        numeral(value / valueBase - 1).format('% 0,0.00')
      ];

      return {
        value: numeral(value).format('% 0,0.00'),
        valueBase: numeral(valueBase).format('% 0,0.00'),
        variation,
        name: g.name,
        perc
      };
    });

    return compared;
  };

  const displayComparedMetric = (title, metrics) => {
    return (
      <CardBody>
        <CardTitle className="text-uppercase h6">
          {i18n.t(title)}
          <ResponseLoading response={response} />
        </CardTitle>
        <div className="pieChart">
          <CardBody className="h-100 border-0">
            {metrics.map(c => {
              return (
                <div
                  key={c.name}
                  className="align-items-center d-flex justify-content-between w-100"
                >
                  <CardTitle className="text-uppercase font-weight-bold text-center mr-3">
                    {c.name}
                  </CardTitle>

                  <h3 className="mr-2 font-weight-normal small d-flex align-items-center">
                    {c.variation}
                    <div className="d-flex flex-column ml-2">
                      {c.value}

                      <div style={c.perc[0]}>{c.perc[1]}</div>
                    </div>
                  </h3>
                </div>
              );
            })}
          </CardBody>
        </div>
      </CardBody>
    );
  };

  const comparedGenders = processCompareMetric('metricsByGender', 'demographics_gender', false);
  const comparedAges = processCompareMetric('metricsByAge', 'demographics_age_range', true);
  const comparedClasses = processCompareMetric('metricsByClass', 'demographics_class', false);

  // console.log(comparedGenders, comparedAges, comparedClasses);

  return (
    <>
      {displayComparedMetric('gender', comparedGenders)}
      {displayComparedMetric('age_range', comparedAges)}
      {displayComparedMetric('socioeconomic_level', comparedClasses)}
    </>
  );
}
