import { IcoHierarchy } from '../icons';

export default function BtnGroup({ isSelected, isExpanded }) {
  return (
    <div className="flex items-center justify-center group">
      {/* <div
        className={
          'absolute w-3 h-3 bg-white ' + (isSelected && (!isExpanded ? 'hidden' : 'block'))
        }
      /> */}

      <div
        className={
          'relative flex items-center justify-center w-5.5 h-5.5 border transition-all duration-300 rounded group-hover:scale-105 ' +
          (isExpanded
            ? isSelected
              ? 'bg-blue-700 border-blue-700'
              : 'bg-blue-500 border-blue-500'
            : 'bg-white border-slate-300 group-hover:border-blue-400')
        }
      >
        <IcoHierarchy
          className={
            'relative w-4.5 h-4.5 fill-current transition-all cursor-pointer duration-300 ' +
            (isExpanded ? 'text-white' : 'text-blue-500 group-hover:text-blue-600')
          }
        />
      </div>
    </div>
  );
}
