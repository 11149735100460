import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import toastr from 'toastr';

import { Button, Card, Row } from 'reactstrap';
import {
  MdCheck,
  MdOutlineRestartAlt,
  MdOutlineDataSaverOn,
  MdDeleteOutline,
  MdOutlineWarningAmber,
  MdAutorenew
} from 'react-icons/md';
import { callSyncAPIv2DataLoader } from '../../hooks/api';
import API from '../../api';
import API2 from '../../api2';
import Accordion from '../../components/accordion/Accordion';
import { MultiSelectFilter, SingleSelectFilter } from '../../components/filter/GenericFilter';
import PeriodFilter from '../../components/filter/PeriodFilter';
import POIsFilter from '../../components/filter/POIsFilter';
import { filterPois } from '../../components/heatmap/utils';
import PopupConfirm from '../../components/utils/PopupConfirm';
import { buildModuleKey, saveToLocalStorage } from './utils';
import ReachDetails from './ReachDetails';
import SaveCampaignModal from './SaveCampaignModal';
import PlannerMapCard from './PlannerMapCard';
import './planner.css';
import moment from 'moment';

const periodFixed = window._env_.REACT_APP_TYPE_PERIOD === 'fixed';
const maxSelectablePOIsCount =
  parseInt(window._env_.REACT_APP_PLANNER_MAX_SELECTABLE_POIS_COUNT) || 1500;

let filterTask;

export default function PlannerStructure({ filter, workingData, poiType, editCampaignId }) {
  if (!poiType) throw new Error('POI Type is mandatory');

  const module = buildModuleKey(poiType);

  const defaultWorkingFilter = {
    period: periodFixed ? filter.period : { timestamp: filter.startDate, offset: filter.offset },
    poiType,
    genericFilters: {},
    reachOptions: {},
    targetPerc: 1
  };

  const defaultMapPois = {
    plannerPois: [],
    poisFiltered: [],
    audiencePois: [],
    selectedPois: [],
    radius: 0,
    heatmapOn: false,
    reachThreshold: 1
  };

  const defaultReach = {};

  const [workingFilter, setWorkingFilter] = useState(
    workingData.workingFilter || { ...defaultWorkingFilter }
  ); // triggers repaint
  const [mapPois, setMapPois] = useState(workingData.mapPois || { ...defaultMapPois });
  const [reach, setReach] = useState(workingData.reach || { ...defaultReach });

  const [plannerPois, setPlannerPois] = useState(mapPois.plannerPois || []);
  const [cart, setCart] = useState(mapPois.selectedPois || []);
  const [reachThreshold, setReachThreshold] = useState(mapPois.reachThreshold || 1);
  const [audienceTempPois, setAudienceTempPois] = useState(workingFilter.audiencePois || []);
  const [rDistanceSelected, setRDistanceSelected] = useState(workingFilter.poiDistance || 0);

  const [genericFilters, setGenericFilters] = useState([]);
  const [markerColorField, setMarkerColorField] = useState(null);
  const [genericFiltersLoading, setGenericFiltersLoading] = useState(false);
  const [genericFiltersOffer, setGenericFiltersOffer] = useState([]);
  const [reachOptionsLoading, setReachOptionsLoading] = useState(false);
  const [reachOptions, setReachOptions] = useState([]);
  const [reachSubPeriodList, setReachSubPeriodList] = useState([]);

  const [, setSavingAudience] = useState(false);
  const [isPoisLoading, setPoisLoading] = useState(false);
  const [isReachLoading, setReachLoading] = useState(false);

  const [openMenu, setOpenMenu] = useState('filters');
  const [openCampaign, setOpenCampaign] = useState(false);

  const requiredFields = {};

  const searchLoading = (
    <div className="loading_metric float-right position-relative" style={{ right: 0 }}>
      <div className="loading-dot" />
    </div>
  );

  const optionFields = [
    ['gender', 'genders'],
    ['age_range', 'ageRanges'],
    ['socioeconomic_level', 'classes'],
    ['platform', 'platforms'],
    ['make', 'makes'],
    ['model', 'models'],
    ['carrier', 'carriers'],
    ['offline_behavior', 'offlines']
  ];

  if (window._env_.REACT_APP_HIDE_APPS_BEHAVIOR !== 'true')
    optionFields.push(['apps_usage', 'genres']);

  // SAVE TO CACHE

  useEffect(() => {
    console.log(genericFiltersLoading);
  }, [genericFiltersLoading]);

  useEffect(() => {
    saveToLocalStorage('workingFilter', workingFilter, module);
    saveToLocalStorage('mapPois', mapPois, module);
    saveToLocalStorage('reach', reach, module);
  }, [workingFilter, mapPois, reach, module]);

  // CONTROL ACCORDION MENU AUTO SCROLL
  const menuRef = React.createRef();
  useEffect(() => {
    menuRef.current.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMenu]);

  useEffect(() => {
    let isMounted = true;
    loadSubPeriods(isMounted);

    return () => {
      isMounted = false;
    };
    // TODO: Research how to correctly execute only the first time but depending on other state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildSubPeriodLabel = (startDate, endDate) => {
    const formattedPeriod = `${moment(startDate).utc().format('DD/MM')} - ${moment(endDate)
      .utc()
      .format('DD/MM')}`;

    return formattedPeriod;
  };

  useEffect(() => {
    let isMounted = true;
    setReachOptionsLoading(true);
    callSyncAPIv2DataLoader(
      'planner',
      'reachOptions',
      // { filter: { period: workingFilter.period } },
      { filter: JSON.stringify(workingFilter) },
      {
        cache: true,
        responseHolder: `defaultReachOptions`,
        encode: ['filter'],
        maskWithPost: true
      },

      res => {
        if (isMounted) {
          setReachOptions(res.data.value.defaultReachOptions);
          setReachOptionsLoading(false);
        }
      }
    );
    setGenericFiltersOffer([]);
    setGenericFiltersLoading(true);
    callSyncAPIv2DataLoader(
      'planner',
      'genericFiltersOffer',
      {
        poiType: poiType,
        period: workingFilter.period,
        genericFilters: workingFilter.genericFilters
      },
      { responseHolder: 'defaultFiltersOffer' },
      res => {
        if (isMounted) {
          res.hasValue('defaultFiltersOffer') &&
            setGenericFiltersOffer(res.data.value.defaultFiltersOffer);
          setGenericFiltersLoading(false);
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, [genericFilters, setGenericFiltersOffer, poiType, workingFilter]);

  useEffect(() => {
    let isMounted = true;
    setGenericFiltersLoading(true);
    callSyncAPIv2DataLoader(
      'appConfig',
      `${module}/filters`,
      {},
      { cache: true, responseHolder: `response` },
      res => {
        if (isMounted) {
          res.hasValue('response') && setGenericFilters(res.data.value.response[module].filters);
          setGenericFiltersLoading(false);
        }
      }
    );

    callSyncAPIv2DataLoader(
      'appConfig',
      `${module}/markerColorField`,
      {},
      { cache: true, responseHolder: `response` },
      res => {
        if (isMounted) {
          res.hasValue('response') &&
            setMarkerColorField(res.data.value.response[module].markerColorField);
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, [setGenericFilters, module]);

  // RESET MAP WITH FILTERED POIS
  useEffect(() => {
    const pois = plannerPois;
    const audiencePois = buildMapPois(audienceTempPois);
    const filtered = filterPois(pois, audiencePois, rDistanceSelected);

    const heatmapOn =
      workingFilter.reachOptions && Object.keys(workingFilter.reachOptions).length > 0;

    let dirty = false;
    let newMapPois = {
      ...mapPois
    };

    // filtered pois changed
    if (
      mapPois.plannerPois !== pois ||
      JSON.stringify(mapPois.poisFiltered) !== JSON.stringify(filtered)
    ) {
      newMapPois = {
        ...newMapPois,
        plannerPois: pois,
        poisFiltered: filtered,
        radius: rDistanceSelected
      };
      dirty = true;
    }

    // audience pois changed
    if (JSON.stringify(mapPois.audiencePois) !== JSON.stringify(audiencePois)) {
      newMapPois = {
        ...newMapPois,
        audiencePois: audiencePois
      };
      dirty = true;
    }

    // heatmapOn changed
    if (mapPois.heatmapOn !== heatmapOn) {
      newMapPois = {
        ...newMapPois,
        heatmapOn
      };
      dirty = true;
    }

    if (dirty) {
      setMapPois(mp => {
        return {
          ...mp,
          ...newMapPois
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plannerPois, audienceTempPois, rDistanceSelected, workingFilter.reachOptions]);

  // SET SELECTED POIS FROM CART
  useEffect(() => {
    if (mapPois.selectedPois !== cart || mapPois.reachThreshold !== reachThreshold) {
      setMapPois(mp => {
        return {
          ...mp,
          selectedPois: cart,
          reachThreshold: reachThreshold
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, reachThreshold]); //  avoid filtering when not needed

  const setRequiredField = (field, value) => {
    requiredFields[field] = value;
  };

  const selectPOIs = (selected, params) => {
    if (params.option && params.option.type === 'POIsGroup') {
      if (canSelectPOIs(params.option.POIsCount)) {
        toastr.error(`${i18n.t('error_selecting_poi')}: ${maxSelectablePOIsCount}`);
        return;
      }

      callSyncAPIv2DataLoader(
        'planner',
        `POIsGroup/${params.option.value}`,
        {},
        { cache: true, responseHolder: `poisGroup` },
        (res, err) => {
          params.option.pois = res.data.value.poisGroup.pois;
          workingFilter.audiencePois = selected;
          setAudienceTempPois(workingFilter.audiencePois);
          setWorkingFilter({ ...workingFilter });
        }
      );
    } else {
      if (params.option && canSelectPOIs(1)) {
        // TODO: refactor. params.option is present when adding. This else is confusing since params.option is evaluated on the if
        toastr.error(`${i18n.t('error_selecting_poi')}: ${maxSelectablePOIsCount}`);
        return;
      }

      workingFilter.audiencePois = selected;
      setAudienceTempPois(workingFilter.audiencePois);
      setWorkingFilter({ ...workingFilter });
    }

    function canSelectPOIs(countToAdd) {
      let currentlySelected = 0;
      if (Array.isArray(mapPois.audiencePois)) {
        currentlySelected = mapPois.audiencePois.length;
      }

      return (
        currentlySelected + countToAdd > maxSelectablePOIsCount // TODO: This component didn't know mapPOIs until now. If the "canSelectPOIs" was a callback, that would be ok. But in general, the Structure can't answer how many POIs are selected without counting. A global method that returns that value would be fine.
      );
    }
  };

  const selectDistance = (selected, params) => {
    workingFilter.poiDistance = selected;
    setRDistanceSelected(workingFilter.poiDistance);
    setWorkingFilter({ ...workingFilter });
  };

  const selectPeriod = selected => {
    periodFixed
      ? (workingFilter.period = selected)
      : (workingFilter.period = { timestamp: selected, offset: filter.offset });

    workingFilter.genericFiltersWarning = true;

    setWorkingFilter({ ...workingFilter });
  };

  const selectReachSubPeriod = (field, selected, params) => {
    workingFilter.reachSubPeriod = selected;
    setWorkingFilter({ ...workingFilter, reachWarning: true });
  };

  const selectParam = (field, selected, params) => {
    workingFilter.genericFilters = workingFilter.genericFilters || {};

    if (selected && (!Array.isArray(selected) || selected.length > 0)) {
      workingFilter.genericFilters[field] = selected;
    } else {
      delete workingFilter.genericFilters[field];
    }

    workingFilter.genericFiltersWarning = true;
    setWorkingFilter({ ...workingFilter });
  };

  const selectReachOption = (field, selected, params) => {
    workingFilter.reachOptions = workingFilter.reachOptions || {};

    if (selected && (!Array.isArray(selected) || selected.length > 0)) {
      workingFilter.reachOptions[field] = selected;
    } else {
      delete workingFilter.reachOptions[field];
    }

    workingFilter.reachOptionsWarning = true;
    setWorkingFilter({ ...workingFilter });
  };

  const addToCart = React.useCallback(
    (pois, forceSelect = false) => {
      for (let s = 0; s < pois.length; s++) {
        const poi = pois[s];
        const i = cart.indexOf(poi._id);
        if (i > -1) {
          if (!forceSelect) {
            cart.splice(i, 1);
          }
        } else {
          cart.push(poi._id);
        }
      }

      setWorkingFilter(wf => {
        return {
          ...wf,
          reachWarning: true
        };
      });

      setCart([...cart]);
    },
    [cart]
  );

  const saveAudiencePOI = (formValues, selectedPosition) => {
    const { poiName } = formValues;

    setSavingAudience(true);
    API.call(
      'audiences.saveAudiencePOI',
      {
        name: poiName,
        position: { ...selectedPosition },
        type: 'POI'
      },
      (err, res) => {
        setSavingAudience(false);
        if (err) {
          console.log('err', err);
          toastr.error(`${i18n.t('error_saving_poi')}: ${err}`);
        } else {
          const _tempPois = [...audienceTempPois] || [];
          _tempPois.push({
            value: res._id,
            label: res.name,
            type: res.type,
            latitude: res.latitude,
            longitude: res.longitude
          });

          workingFilter.audiencePois = _tempPois;
          setAudienceTempPois(_tempPois);
          // setFiltersEnabled(true);

          toastr.success(`${i18n.t('ok_saving_poi')}`);
        }
      }
    );
  };

  const saveAudiencePOIsGroup = (poisGroupName, pois) => {
    setSavingAudience(true);
    const type = 'POIsGroup';
    API2.post('planner', '/poisGroup', { poisGroupName, pois, type }, (err, res) => {
      setSavingAudience(false);
      if (err) {
        console.log('err', err);
        toastr.error(`${i18n.t('error_saving_pois_group')}: ${err}`);
      } else {
        const _tempPois = [...audienceTempPois] || [];
        const savedPOIsGroup = {
          value: res.id,
          label: res.name,
          type: res.type,
          POIsCount: res.POIsCount,
          pois: []
        };
        _tempPois.push(savedPOIsGroup);

        selectPOIs(_tempPois, { option: savedPOIsGroup });

        toastr.success(`${i18n.t('ok_saving_pois_group')}`);
      }
    });
  };

  const applyFilters = cb => {
    setPoisLoading(true);

    // validate all required fields has values
    const r = Object.keys(requiredFields);

    for (let i = 0; i < r.length; i++) {
      const f = r[i];

      if (
        requiredFields[f].required &&
        (!workingFilter.genericFilters[f] ||
          workingFilter.genericFilters[f] === '' ||
          workingFilter.genericFilters[f] === null)
      ) {
        window.scrollTo(0, 0);
        toastr.error(`${i18n.t('error_filtering_audience')}: ${requiredFields[f].title}`);
        setPoisLoading(false);
        return;
      }
    }

    callSyncAPIv2DataLoader(
      'planner',
      'POI',
      { filter: JSON.stringify(workingFilter) },
      { cache: true, responseHolder: `poisPartialList`, encode: ['filter'], maskWithPost: true },
      res => {
        if (!res.error) {
          // TODO: reset cart ?
          // only show total count warning if there were changes in generic fitlers. If not, just update the results
          if (workingFilter.genericFiltersWarning) {
            const partialCount = parseInt(res.data.value.poisPartialList.partialCount);
            const totalCount = parseInt(res.data.value.poisPartialList.totalCount);
            if (partialCount < totalCount) {
              toastr.warning(
                `${i18n.t('planner_pois_partial_result')} ${partialCount}/${totalCount}`
              );
            }
          }
          workingFilter.genericFiltersWarning = false;
          workingFilter.targetPerc = res.data.value.poisPartialList.targetPerc;
          setPlannerPois(res.data.value.poisPartialList.partialList);
          cb && cb();
        }
        setPoisLoading(false);
      }
    );

    loadSubPeriods(true);

    // window.scrollTo(0, 0);
  };

  const updateReach = () => {
    setReachLoading(true);
    // call apply filter to update reachheatmap
    applyFilters(() => {
      const reachFilter = {
        period: workingFilter.period,
        quarters: workingFilter.reachSubPeriod,
        genericFilters: workingFilter.genericFilters, // TODO: send all or search AppConfig for populationFilterField?
        reachOptions: workingFilter.reachOptions,
        selectedPois: cart,
        targetPerc: workingFilter.targetPerc
      };

      callSyncAPIv2DataLoader(
        'planner',
        `Reach`,
        { filter: JSON.stringify(reachFilter) },
        { cache: true, responseHolder: `reach`, encode: ['filter'], maskWithPost: true },
        res => {
          if (!res.error) {
            workingFilter.reachOptionsWarning = false;
            workingFilter.reachWarning = false;
            setReach(res.data.value.reach);
            setOpenMenu('reach_metrics');
          } else {
            toastr.error(`${res.error.message}`);
            setReach(defaultReach);
          }
          setReachLoading(false);
        }
      );

      // window.scrollTo(0, 0);
    });
  };

  const resetData = () => {
    setPlannerPois([]);
    setCart([]);
    setAudienceTempPois([]);
    setRDistanceSelected(0);

    setWorkingFilter({ ...defaultWorkingFilter });
    setMapPois({ ...defaultMapPois });
    setReach({ ...defaultReach });
  };

  const requestResetData = () => {
    PopupConfirm({
      onConfirm: () => {
        resetData();
      }
    });
  };

  const onSaveCampaign = () => {
    resetData();
  };

  const filterByReach = value => {
    if (filterTask) clearTimeout(filterTask);
    filterTask = setTimeout(() => {
      setReachThreshold(value);
    }, 200);
  };

  return (
    <div className="view-content view-dashboard">
      <React.StrictMode>
        <SaveCampaignModal
          isOpen={openCampaign}
          setOpen={setOpenCampaign}
          onSave={onSaveCampaign}
          editCampaignId={editCampaignId}
          workingData={{
            campaignName: workingData.campaignName,
            workingFilter,
            mapPois,
            reach
          }}
        />
        <Row>
          <div className="col-3 col-sm-3 col-md-3 mb-8 p-0 pr-1 planner_menu" ref={menuRef}>
            <Card>
              <Accordion id="filters" title="filters_title" open={openMenu} setOpen={setOpenMenu}>
                {PeriodFilter(i18n.t('period'), workingFilter.period, selectPeriod, periodFixed)}
                {genericFilters
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map(f => {
                    if (f.type === 'single') {
                      return SingleSelectFilter(
                        f.label,
                        f.field,
                        genericFiltersOffer ? genericFiltersOffer[f.options] : null,
                        selectParam,
                        genericFiltersLoading,
                        workingFilter.genericFilters && workingFilter.genericFilters[f.field]
                          ? workingFilter.genericFilters[f.field]
                          : null,
                        f.required,
                        setRequiredField
                      );
                    }
                    if (f.type === 'multi') {
                      return MultiSelectFilter(
                        f.label,
                        f.field,
                        genericFiltersOffer ? genericFiltersOffer[f.options] : null,
                        selectParam,
                        genericFiltersLoading,
                        workingFilter.genericFilters && workingFilter.genericFilters[f.field]
                          ? workingFilter.genericFilters[f.field]
                          : null,
                        f.required,
                        setRequiredField
                      );
                    }
                    return null;
                  })}
                <POIsFilter
                  selectedPOIs={audienceTempPois}
                  selectedDistance={rDistanceSelected}
                  onPOISelect={selectPOIs}
                  onDistanceSelect={selectDistance}
                  onSavePOI={saveAudiencePOI}
                  onSavePOIsGroup={saveAudiencePOIsGroup}
                />
                <div className="p-1 mt-3 ">
                  {workingFilter.genericFiltersWarning && (
                    <div className="alert-warning">
                      <p className="p-2 mb-0 small">{i18n.t('planner_update_filters_warning')}</p>
                    </div>
                  )}
                  <Button
                    className="w-100 mb-5 d-flex justify-content-center align-items-center"
                    color="primary"
                    onClick={() => applyFilters()}
                    disabled={isPoisLoading}
                  >
                    {isPoisLoading ? (
                      <MdAutorenew size={20} className="rotate mr-2" />
                    ) : (
                      <MdCheck size={20} className="mr-2" />
                    )}
                    {i18n.t('apply_filters_button')}
                  </Button>
                </div>
              </Accordion>
              <Accordion
                id="reach_filters"
                title="planner_filters_matchers"
                open={openMenu}
                setOpen={setOpenMenu}
              >
                {optionFields.map(o => {
                  return MultiSelectFilter(
                    i18n.t(o[0]),
                    o[1],
                    reachOptions ? reachOptions[o[1]] : null,
                    selectReachOption,
                    reachOptionsLoading,
                    workingFilter.reachOptions && workingFilter.reachOptions[o[1]]
                      ? workingFilter.reachOptions[o[1]]
                      : null
                  );
                })}
                <div className="p-1 mt-3 ">
                  {workingFilter.reachOptionsWarning && (
                    <div className="alert-warning">
                      <p className="p-2 mb-0 small">{i18n.t('planner_update_filters_warning')}</p>
                    </div>
                  )}
                  <Button
                    className="w-100 mb-5 d-flex justify-content-center align-items-center"
                    color="primary"
                    onClick={updateReach}
                    disabled={isReachLoading}
                  >
                    {isReachLoading ? (
                      <MdAutorenew size={20} className="rotate mr-2" />
                    ) : (
                      <MdOutlineRestartAlt size={20} className="mr-2" />
                    )}
                    {i18n.t('planner_refresh_reach')}
                  </Button>
                </div>
              </Accordion>
              <Accordion
                id="reach_metrics"
                title="planner_metrics_title"
                open={openMenu}
                setOpen={setOpenMenu}
              >
                {MultiSelectFilter(
                  i18n.t('planner_fraction'),
                  'subPeriod',
                  reachSubPeriodList,
                  selectReachSubPeriod,
                  workingFilter.reachSubPeriod
                )}
                <div className="p-1">
                  {workingFilter.reachWarning && (
                    <div className="alert-warning">
                      <p className="p-2 small">{i18n.t('planner_update_reach_warning')}</p>
                      <Button
                        className="w-100 mt-3 mb-3 d-flex justify-content-center align-items-center"
                        color="primary"
                        onClick={updateReach}
                        disabled={isReachLoading}
                      >
                        {isReachLoading ? (
                          <MdAutorenew size={20} className="rotate" />
                        ) : (
                          <MdOutlineRestartAlt size={20} />
                        )}
                      </Button>
                    </div>
                  )}
                </div>
                <ReachDetails reach={reach} selectedPois={mapPois.selectedPois} />
              </Accordion>
              <Accordion id="actions" title="campaign_actions" open="actions" setOpen={setOpenMenu}>
                <div className="p-1">
                  {(workingFilter.genericFiltersWarning ||
                    workingFilter.reachOptionsWarning ||
                    workingFilter.reachWarning) && (
                    <div className="alert-warning">
                      <p className="p-2 small">
                        <MdOutlineWarningAmber size={20} className="mr-2" />
                        {i18n.t('planner_update_warning')}
                      </p>
                    </div>
                  )}
                </div>

                <Button
                  className="w-100 d-flex justify-content-center align-items-center"
                  color="primary"
                  disabled={!reach || !reach.uniqueReach}
                  onClick={() => setOpenCampaign(true)}
                >
                  <MdOutlineDataSaverOn size={20} className="mr-2" />
                  {i18n.t('planner_save_campaign')}
                </Button>
                <Button
                  className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3 mb-5"
                  color="secondary"
                  onClick={requestResetData}
                >
                  <MdDeleteOutline size={20} className="mr-2" />
                  {i18n.t('campaign_reset_data')}
                </Button>
              </Accordion>
            </Card>
          </div>
          <div className="col-9 col-sm-9 col-md-9 p-0">
            {(isPoisLoading || isReachLoading) && searchLoading}
            <div className="w-100 planner_map">
              <PlannerMapCard
                mapPois={mapPois}
                onSelect={addToCart}
                onChangeReachRange={filterByReach}
                markerColorField={markerColorField}
              />
            </div>
          </div>
        </Row>
      </React.StrictMode>
    </div>
  );

  function loadSubPeriods(isMounted) {
    callSyncAPIv2DataLoader(
      'planner',
      'subPeriods',
      { period: workingFilter.period },
      { responseHolder: 'subPeriods' },
      res => {
        if (isMounted && res.hasValue('subPeriods')) {
          const subPeriodsList = res.data.value.subPeriods.map(subPeriod => {
            const value = parseInt(subPeriod.id) + 1; // TODO: Let's agree on subperiod starting on 0 or 1 - https://www.notion.so/mofiler/Agree-on-subperiods-start-76b71b447e364cbba2fce6d85e90c8ff
            const label = buildSubPeriodLabel(subPeriod.startDate, subPeriod.endDate);

            return { value, label };
          });

          setReachSubPeriodList(subPeriodsList);
        }
      }
    );
  }
}

function buildMapPois(fromPOIs) {
  fromPOIs = fromPOIs || [];
  let flatPOIs = fromPOIs.filter(poi => poi.type === 'POI');

  const POIsGroups = fromPOIs.filter(poi => poi.type === 'POIsGroup');

  for (const group of POIsGroups) {
    const transformedPOIs = group.pois.map(poiFromMap => ({
      value: poiFromMap.id,
      label: poiFromMap.name,
      latitude: poiFromMap.latitude,
      longitude: poiFromMap.longitude
    }));
    flatPOIs = [...flatPOIs, ...transformedPOIs];
  }

  return flatPOIs;
}
