import i18n from 'i18n-js';
import { IcoCloseCirc } from '../icons';

export default function Categories({ children, searchString, onClearSearch }) {
  return (
    <div className="flex items-center">
      {/* Nav Categories */}
      <div className="flex space-x-2 w-full max-w-full pt-2.5 pb-1 mb-2 overflow-x-auto scroll">
        {children}
      </div>
      {searchString && (
        <div className="flex h-10 items-center space-x-1.5">
          <p className="text-slate-700 tracking-normal whitespace-nowrap pl-3">
            {i18n.t('showing_results_for')}:{' '}
            <span className="text-blue-500 font-medium">{searchString}</span>
          </p>
          <IcoCloseCirc
            className="w-5 h-5 text-slate-400 fill-current transition-color duration-500 hover:scale-100 hover:text-slate-700 cursor-pointer"
            onClick={onClearSearch}
          />
        </div>
      )}
    </div>
  );
}
