import React from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';
import { MdMotionPhotosOn } from 'react-icons/md';
import { Slider } from 'rsuite';
import POIsMatchMap from './POIsMatchMap';

const heatmapColors = ['#c7ddfc', '#2661be', '#ffeb3b', '#ffc107', '#e65f3f', '#b22439'];
const poisColors = {
  buffer: '#ca2438',
  regular: '#03a9f4',
  selected: '#2df00f'
};

export default function PlannerMapCard(props) {
  const { mapPois, onSelect, onChangeReachRange, markerColorField } = props;

  const filtered = mapPois.poisFiltered || [];
  const { heatmapOn } = mapPois;

  let maxReach = 0;
  try {
    // TODO: improve maxReach -> if maxReach > mapPois.reachThreshold it is better to reset mapPois.reachThreshold
    maxReach = Math.max(
      Math.max(mapPois.reachThreshold, Math.max(...filtered.map(f => f.metrics.uniqueReach))),
      500
    ); // 1 to 501 minimum
  } catch (e) {
    // ignore
  }

  return (
    <>
      <POIsMatchMap
        mapPois={mapPois}
        onSelect={onSelect}
        heatmapColors={heatmapColors}
        poisColors={poisColors}
        markerColorField={markerColorField}
      />

      {heatmapOn && (
        <div
          className="position-relative d-flex align-items-center small w-75"
          style={{
            top: '-9vh',
            left: '3vw',
            height: '7vh',
            zIndex: 9999
          }}
        >
          <div
            className="align-items-center justify-content-center bg-white d-flex h-100 pl-2 w-50"
            style={{ borderRadius: '0.3rem' }}
          >
            <MdMotionPhotosOn size={20} className="mr-1" /> {i18n.t('planner_metrics_reach')}
            <span className="ml-4 mr-2">{i18n.t('planner_metrics_reach_less')}</span>
            {heatmapColors.map(c => {
              return <div key={c} style={{ height: '0.5vh', backgroundColor: c, width: '2rem' }} />;
            })}
            <span className="ml-2 mr-1">{i18n.t('planner_metrics_reach_more')}</span>
          </div>
          <div
            className="bg-white d-flex flex-column h-100 justify-content-center ml-3 pl-3 pr-3 w-50"
            style={{
              borderRadius: '0.3rem'
            }}
          >
            {i18n.t('planner_filter_by_reach')}
            <Slider
              progress
              className="mt-2 mb-3"
              defaultValue={mapPois.reachThreshold}
              // value={reachCount}
              onChange={onChangeReachRange}
              min={0}
              max={maxReach}
              step={500}
            />
            {i18n.t('planner_filter_by_reach_min')} {numeral(mapPois.reachThreshold).format(0, 0)}{' '}
            {i18n.t('unique_visitors_title')}
          </div>
        </div>
      )}
    </>
  );
}
