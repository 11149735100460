import i18n from 'i18n-js';
import React from 'react';

// import toastr from 'toastr';

// import { Link } from 'react-router-dom';

import { Card, CardBody, Row } from 'reactstrap';

import Logo from './Logo';

export default function Registered(props) {
  return (
    <div className="view_cont">
      <Card className="main_window">
        <CardBody>
          <Row>
            <div className="col-md-12">
              <Logo />
            </div>
          </Row>
          <Row className="text_3_color registered_message">{i18n.t('register_thanks')}</Row>
          <Row className="text_3_color registered_message">{i18n.t('register_close_window')}</Row>
        </CardBody>
      </Card>
    </div>
  );
}
