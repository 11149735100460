import React from 'react';
import i18n from 'i18n-js';
import { Link, NavLink } from 'react-router-dom';
// import { Button } from 'reactstrap';
// icons
import { MdAttachMoney, MdFace, MdOutlinePayment, MdSecurity } from 'react-icons/md/';
import { RiOrganizationChart } from 'react-icons/ri';

import ScrollArea from '../../components/scrollbar';

import './style.scss';

function NavHead(props) {
  return (
    <header className="nav-head m-0 p-3 w-100 align-items-center d-flex justify-content-center">
      <Link to="/dashboard">
        <img
          src={`${window._env_.REACT_APP_BASE_BRAND_URL}/${window._env_.REACT_APP_BRAND}/logo.png`}
          alt={window._env_.REACT_APP_TITLE}
        />
      </Link>
    </header>
  );
}

function NavList(props) {
  return (
    <ScrollArea
      className="nav-list-container"
      horizontal={false}
      verticalScrollbarStyle={{ width: '4px', marginLeft: '10px' }}
    >
      <ul className="list-unstyled nav-list clearfix">
        <li>
          <div
            style={{
              position: 'relative'
            }}
          />
        </li>
        <li>
          <div className="nav-list-title">{i18n.t('edit_profile_title')}</div>
        </li>
        <li>
          <NavLink to="/settings/profile" activeClassName="active">
            <MdFace size="18" className="icon-dashboard" />
            <span className="name">{i18n.t('edit_profile')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/settings/password" activeClassName="active">
            <MdSecurity size="18" className="icon-dashboard" />
            <span className="name">{i18n.t('edit_password')}</span>
          </NavLink>
        </li>

        {window._env_.REACT_APP_ORGANIZATIONS_AWARE === 'true' ? (
          <>
            <li>
              <div className="nav-list-title">{i18n.t('edit_organization_title')}</div>
            </li>
            <li>
              <NavLink to="/settings/organization" activeClassName="active">
                <RiOrganizationChart size="18" className="icon-dashboard" />
                <span className="name">{i18n.t('edit_organization')}</span>
              </NavLink>
            </li>

            <li>
              <div className="nav-list-title">{i18n.t('edit_subscription_title')}</div>
            </li>
            <li>
              <NavLink to="/settings/subscription" activeClassName="active">
                <MdOutlinePayment size="18" className="icon-dashboard" />
                <span className="name">{i18n.t('manage_subscription')}</span>
              </NavLink>
            </li>

            <li>
              <div className="nav-list-title">{i18n.t('edit_billing_title')}</div>
            </li>
            <li>
              <NavLink to="/settings/billing" activeClassName="active">
                <MdAttachMoney size="18" className="icon-dashboard" />
                <span className="name">{i18n.t('manage_billing')}</span>
              </NavLink>
            </li>
          </>
        ) : null}
      </ul>

      {window._env_.REACT_APP_SUB_BRAND === 'true' ? (
        <div
          className="d-flex justify-content-center"
          style={{ position: 'fixed', bottom: 10, width: '100%' }}
        >
          <img
            src={`${window._env_.REACT_APP_BASE_BRAND_URL}/${window._env_.REACT_APP_BRAND}/sub-logo.png`}
            alt={window._env_.REACT_APP_SUB_BRAND_TITLE}
          />
        </div>
      ) : null}
    </ScrollArea>
  );
}

export default function SettingsNav(props) {
  return (
    <nav className={`site-nav ${props.mini ? 'mini' : ''}`}>
      <NavHead {...props} />
      <NavList {...props} />
    </nav>
  );
}
