import React, { useEffect, useState } from 'react';

import InputRadio from './inputRadio';

export default function PoiItem({ label, hasSelect, selected, onSelect, multiSelected }) {
  const [isSelected, setIsSelected] = useState(selected);
  const toggleSelect = () => {
    setIsSelected(!isSelected);
    onSelect(!isSelected);
  };

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <div
      onClick={() => hasSelect && toggleSelect()}
      className={
        'static font-open-sans text-slate-600 pr-1 anim-item-tree ' +
        (hasSelect && 'group cursor-pointer select-none')
      }
    >
      <div className={'static flex items-center pl-2.5'}>
        {/* BG Active  */}
        {hasSelect && (
          <div
            className={
              'absolute w-full h-7 left-0 ' +
              (isSelected
                ? 'bg-blue-500'
                : multiSelected
                ? 'transition-color duration-500 bg-blue-100/20 group-hover:bg-blue-100/80'
                : 'group-hover:bg-slate-200 transition-color duration-500')
            }
          />
        )}

        {/* RADIO Select */}
        <div className={'px-1 py-0.5 -ml-1 mr-1 ' + (!hasSelect && 'opacity-0')}>
          <InputRadio isSelected={isSelected} disabled={!hasSelect} multiSelected={multiSelected} />
        </div>

        {/* LABEL  */}
        <p
          className={
            'text-2xs font-medium relative pr-1 truncate ' +
            (!isSelected
              ? hasSelect
                ? 'group-hover:text-neutral-900'
                : 'opacity-50 cursor-default'
              : 'text-white')
          }
        >
          {label}
        </p>
      </div>
    </div>
  );
}
