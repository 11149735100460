import { ReactSVG } from 'react-svg';
// import icoLoc from './ico-location.svg';

// export const IcoLocation = props => <ReactSVG src={icoLoc} width="24" height="24" {...props} />;

export const IcoMenu = props => <IcoSVG href="ico-menu.svg" width="24" height="24" {...props} />;
export const IcoMail = props => <IcoSVG href="ico-mail.svg" width="24" height="24" {...props} />;
export const IcoTel = props => <IcoSVG href="ico-Tel.svg" width="24" height="24" {...props} />;
export const IcoSend = props => <IcoSVG href="ico-send.svg" width="24" height="24" {...props} />;
export const IcoSearch = props => (
  <IcoSVG href="ico-search.svg" width="24" height="24" {...props} />
);
export const IcoLogin = props => <IcoSVG href="ico-login.svg" width="24" height="24" {...props} />;
export const IcoAsterix = props => (
  <IcoSVG href="ico-asterix.svg" width="24" height="24" {...props} />
);
export const IcoAlert = props => <IcoSVG href="ico-alert.svg" width="24" height="24" {...props} />;
export const IcoInfo = props => <IcoSVG href="ico-info.svg" width="24" height="24" {...props} />;
export const IcoHierarchy = props => (
  <IcoSVG href="ico-hierarchy.svg" width="24" height="24" {...props} />
);
export const IcoHierarchyCirc = props => (
  <IcoSVG href="ico-hierarchy-circ.svg" width="24" height="24" {...props} />
);
export const IcoPoisList = props => (
  <IcoSVG href="ico-pois-list.svg" width="24" height="24" {...props} />
);
export const IcoPoisListCirc = props => (
  <IcoSVG href="ico-pois-list-circ.svg" width="24" height="24" {...props} />
);
export const IcoList = props => <IcoSVG href="ico-list.svg" width="24" height="24" {...props} />;
export const IcoPlus = props => <IcoSVG href="ico-plus.svg" width="24" height="24" {...props} />;
export const IcoPlusCirc = props => (
  <IcoSVG href="ico-plus-circ.svg" width="24" height="24" {...props} />
);
export const IcoMinus = props => <IcoSVG href="ico-minus.svg" width="24" height="24" {...props} />;
export const IcoMinusCirc = props => (
  <IcoSVG href="ico-minus-circ.svg" width="24" height="24" {...props} />
);
export const IcoCheck = props => <IcoSVG href="ico-Check.svg" width="24" height="24" {...props} />;
export const IcoCheckCirc = props => (
  <IcoSVG href="ico-check-circ.svg" width="24" height="24" {...props} />
);
export const IcoCheckCircLine = props => (
  <IcoSVG href="ico-check-circ-line.svg" width="24" height="24" {...props} />
);

export const IcoLocation = props => (
  <IcoSVG href="ico-location.svg" width="24" height="24" {...props} />
);

export const IcoFilter = props => (
  <IcoSVG href="ico-filter.svg" width="24" height="24" {...props} />
);

export const IcoClose = props => <IcoSVG href="ico-close.svg" width="24" height="24" {...props} />;
export const IcoCloseCirc = props => (
  <IcoSVG href="ico-close-circ.svg" width="24" height="24" {...props} />
);
export const IcoCloseCircLine = props => (
  <IcoSVG href="ico-close-circ-line.svg" width="24" height="24" {...props} />
);
export const IcoPause = props => <IcoSVG href="ico-pause.svg" width="24" height="24" {...props} />;
export const IcoPlay = props => <IcoSVG href="ico-play.svg" width="24" height="24" {...props} />;
export const IcoNext = props => <IcoSVG href="ico-next.svg" width="24" height="24" {...props} />;
export const IcoArwGo = props => <IcoSVG href="ico-arw-go.svg" width="24" height="24" {...props} />;
export const IcoArwInput = props => (
  <IcoSVG href="ico-arw-input.svg" width="24" height="24" {...props} />
);
export const IcoArwPull = props => (
  <IcoSVG href="ico-arw-pull.svg" width="24" height="24" {...props} />
);
export const IcoArwMenu = props => (
  <IcoSVG href="ico-arw-menu.svg" width="24" height="24" {...props} />
);
export const IcoArwExpandCirc = props => (
  <IcoSVG href="ico-arw-expand-circ.svg" width="24" height="24" {...props} />
);
export const IcoArwGoCirc = props => (
  <IcoSVG href="ico-arw-go-circ.svg" width="24" height="24" {...props} />
);
export const IcoLogo = props => <IcoSVG href="ico-Logo.svg" width="52" height="52" {...props} />;
export const IcoLogoTx = props => (
  <IcoSVG href="ico-logo-tx.svg" width="96" height="22" {...props} />
);
export const IcoBg = props => <IcoSVG href="ico-bg.svg" width="54" height="54" {...props} />;
export const IcoProd0 = props => <IcoSVG href="ico-prod-0.svg" width="44" height="44" {...props} />;
export const IcoProd1 = props => <IcoSVG href="ico-prod-1.svg" width="44" height="44" {...props} />;
export const IcoProd2 = props => <IcoSVG href="ico-prod-2.svg" width="44" height="44" {...props} />;
export const IcoProd3 = props => <IcoSVG href="ico-prod-3.svg" width="44" height="44" {...props} />;
export const IcoProd4 = props => <IcoSVG href="ico-prod-4.svg" width="44" height="44" {...props} />;
export const IcoProd5 = props => <IcoSVG href="ico-prod-5.svg" width="44" height="44" {...props} />;
export const IcoProd6 = props => <IcoSVG href="ico-prod-6.svg" width="44" height="44" {...props} />;
export const IcoUses0 = props => <IcoSVG href="ico-uses-0.svg" width="44" height="44" {...props} />;
export const IcoUses1 = props => <IcoSVG href="ico-uses-1.svg" width="44" height="44" {...props} />;
export const IcoUses2 = props => <IcoSVG href="ico-uses-2.svg" width="44" height="44" {...props} />;
export const IcoUses3 = props => <IcoSVG href="ico-uses-3.svg" width="44" height="44" {...props} />;
export const IcoUses4 = props => <IcoSVG href="ico-uses-4.svg" width="44" height="44" {...props} />;
export const IcoUses5 = props => <IcoSVG href="ico-uses-5.svg" width="44" height="44" {...props} />;

function IcoSVG(props) {
  return (
    <div className="ico-poi-selector">
      <ReactSVG src={`${process.env.PUBLIC_URL}/icons/${props.href}`} {...props} />
    </div>
  );
}
