import React, { useEffect, useState } from 'react';
import RadarMap from '../../components/heatmap/RadarMap';
import MetricsCard from './MetricsCard';
import { ResponseLoading } from '../../components/utils/Loading';
import { useDashboardData, useFullDemosObj } from '../../hooks/metrics/esdata';

export default function ViewContent({ filter, responseRadar, poiType }) {
  const stores =
    responseRadar && responseRadar.hasValue('heatmap')
      ? responseRadar.data.value.heatmap.stores
      : [];

  const [storeFilter, setStoreFilter] = useState({ ...filter, groupKeys: [] });
  const [storeSelected, setStoreSelected] = useState(null);
  const { responseFullDemos, responseVisits } = useDashboardData(storeFilter, {
    qVisits: true,
    qFullDemos: true
  });

  const onSelectPOI = React.useCallback(
    selected => {
      storeFilter.storeKey = selected._id;
      setStoreFilter(storeFilter);
      setStoreSelected(selected);
    },
    [storeFilter]
  );

  useEffect(() => {
    setStoreFilter({
      ...filter,
      groupKeys: [],
      storeKey: storeSelected ? storeSelected._id : null
    });
    // if (storeSelected) onSelectPOI(storeSelected);
  }, [filter]);

  const fullDemosObj = useFullDemosObj(responseFullDemos, responseVisits);

  return (
    <div className="view-content view-dashboard view-dashboard-radar p-0 d-flex mb-4">
      <div style={{ position: 'relative', zIndex: 9999 }}>
        <ResponseLoading responses={[responseFullDemos, responseVisits, responseRadar]} />
      </div>
      <div className="col-5 pr-0 h-100">
        <RadarMap {...{ stores }} onChange={onSelectPOI} defaultMap="ll" />
      </div>
      <div className="col-7">
        <MetricsCard
          filter={filter}
          fullDemosObj={fullDemosObj}
          storeSelected={storeSelected}
          poiType={poiType}
        />
      </div>
    </div>
  );
}
