import React from 'react';
import i18n from 'i18n-js';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import StatsChart from './StatsChart';
import InterestsChart from './InterestsChart';
import { ResponseLoading } from '../../components/utils/Loading';
import TopCategoriesChart from './TopCategoriesChart';
import PopoverHelp from '../../components/utils/PopoverHelp';
import numeral from 'numeral';

export default function TechCard({ filter, responseAppBundles, responseTech, poiType }) {
  const responseTopGenres = responseAppBundles.hasValue('appGenres')
    ? responseAppBundles.data.value.appGenres
    : [];

  const responsePlatform = responseTech.hasValue('metricsTechPlatform')
    ? responseTech.data.value.metricsTechPlatform
    : [];
  const responseMake = responseTech.hasValue('metricsTechMake')
    ? responseTech.data.value.metricsTechMake
    : [];
  const responseModels = responseTech.hasValue('metricsTechModel')
    ? responseTech.data.value.metricsTechModel
    : [];
  const responseCarriers = responseTech.hasValue('metricsTechCarrier')
    ? responseTech.data.value.metricsTechCarrier
    : [];

  const fullDetails = window._env_.REACT_APP_USE_FULL_APPS_DETAILS === 'true'; // defaults to false

  const topGenresCount = responseTopGenres.length;
  const topGenresTotalImpressions = responseTopGenres.reduce((a, b) => {
    return a + b.category_value;
  }, 0);

  return (
    <>
      {window._env_.REACT_APP_HIDE_APPS_BEHAVIOR !== 'true' ? (
        <Row>
          <div className="mb-4 col-sm-12 col-md-12">
            <Card>
              <CardBody>
                <CardTitle className="text-uppercase h6">
                  {i18n.t('online_behavior')}
                  {responseTopGenres?.length ? (
                    <PopoverHelp
                      title={i18n.t('online_behavior')}
                      text={i18n.t('online_behavior_text_how', {
                        top_genres_count: topGenresCount,
                        top_genres_total_impressions:
                          numeral(topGenresTotalImpressions).format('0,0')
                      })}
                      html
                    />
                  ) : null}

                  <ResponseLoading response={responseAppBundles} />
                </CardTitle>
                <div className="small mb-4 card-subtitle">
                  {i18n.t(poiType === 'ooh' ? 'online_behavior_text_ooh' : 'online_behavior_text')}
                </div>
                <div style={{ width: '100%', height: '400px' }}>
                  {fullDetails ? (
                    <TopCategoriesChart response={responseTopGenres} />
                  ) : (
                    <InterestsChart
                      response={responseTopGenres}
                      typeKey="name"
                      valueKey="category_perc"
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      ) : null}

      <Row>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-4">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('platform')}
                <ResponseLoading response={responseTech} />
              </CardTitle>
              <div className="pieChart" style={{ marginTop: '-2.8rem' }}>
                <StatsChart response={responsePlatform} dataKey="unique_count" nameKey="value" />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-4">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('make')}
                <ResponseLoading response={responseTech} />
              </CardTitle>
              <div className="pieChart" style={{ marginTop: '-2.8rem' }}>
                <StatsChart response={responseMake} dataKey="unique_count" nameKey="value" />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-4">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('model')}
                <ResponseLoading response={responseTech} />
              </CardTitle>
              <div className="pieChart" style={{ marginTop: '-2.8rem' }}>
                <StatsChart response={responseModels} dataKey="unique_count" nameKey="value" />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-4">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('carrier')}
                <ResponseLoading response={responseTech} />
              </CardTitle>
              <div className="pieChart" style={{ marginTop: '-2.8rem' }}>
                <StatsChart response={responseCarriers} dataKey="unique_count" nameKey="value" />
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>
    </>
  );
}
