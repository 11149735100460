import { IcoArwInput } from '../icons';

export default function BtnGroupArw({ isSelected, isExpanded }) {
  return (
    <IcoArwInput
      className={
        'w-3.5 h-3.5  fill-current -mr-1 ml-0.5 transition-all duration-300 ' +
        (isExpanded ? 'rotate-90' : '5') +
        ' ' +
        (isSelected ? 'text-white' : 'text-slate-400')
      }
    />
  );
}
