export const colorNames = [
  'lime',
  'yellow',
  'orange',
  'violet',
  'teal',
  'fuchsia',
  'pink',
  'sky',
  'green'
];

export const colorStyles = {
  lime: {
    'bg-100': 'bg-lime-50/50',
    'bg-500': 'bg-lime-500',
    'bg-600': 'bg-lime-600',
    'text-500': 'text-lime-500',
    'text-600': 'text-lime-600',
    'border-400': 'border-lime-400',
    'h-border-500': 'hover:border-lime-600',
    'gh-text-400': 'group-hover:text-lime-400'
  },
  yellow: {
    'bg-100': 'bg-yellow-50/50',
    'bg-500': 'bg-yellow-500',
    'bg-600': 'bg-yellow-600',
    'text-500': 'text-yellow-500',
    'text-600': 'text-yellow-600',
    'border-400': 'border-yellow-400',
    'h-border-500': 'hover:border-yellow-600',
    'gh-text-400': 'group-hover:text-yellow-400'
  },
  orange: {
    'bg-100': 'bg-orange-50/50',
    'bg-500': 'bg-orange-500',
    'bg-600': 'bg-orange-600',
    'text-500': 'text-orange-500',
    'text-600': 'text-orange-600',
    'border-400': 'border-orange-400',
    'h-border-500': 'hover:border-orange-600',
    'gh-text-400': 'group-hover:text-orange-400'
  },
  violet: {
    'bg-100': 'bg-violet-50/50',
    'bg-500': 'bg-violet-500',
    'bg-600': 'bg-violet-600',
    'text-500': 'text-violet-500',
    'text-600': 'text-violet-600',
    'border-400': 'border-violet-400',
    'h-border-500': 'hover:border-violet-600',
    'gh-text-400': 'group-hover:text-violet-400'
  },
  sky: {
    'bg-100': 'bg-sky-50/50',
    'bg-500': 'bg-sky-500',
    'bg-600': 'bg-sky-600',
    'text-500': 'text-sky-500',
    'text-600': 'text-sky-600',
    'border-400': 'border-sky-400',
    'h-border-500': 'hover:border-sky-600',
    'gh-text-400': 'group-hover:text-sky-400'
  },
  teal: {
    'bg-100': 'bg-teal-50/50',
    'bg-500': 'bg-teal-500',
    'bg-600': 'bg-teal-600',
    'text-500': 'text-teal-500',
    'text-600': 'text-teal-600',
    'border-400': 'border-teal-400',
    'h-border-500': 'hover:border-teal-600',
    'gh-text-400': 'group-hover:text-teal-400'
  },
  fuchsia: {
    'bg-100': 'bg-fuchsia-50/50',
    'bg-500': 'bg-fuchsia-500',
    'bg-600': 'bg-fuchsia-600',
    'text-500': 'text-fuchsia-500',
    'text-600': 'text-fuchsia-600',
    'border-400': 'border-fuchsia-400',
    'h-border-500': 'hover:border-fuchsia-600',
    'gh-text-400': 'group-hover:text-fuchsia-400'
  },
  pink: {
    'bg-100': 'bg-pink-50/50',
    'bg-500': 'bg-pink-500',
    'bg-600': 'bg-pink-600',
    'text-500': 'text-pink-500',
    'text-600': 'text-pink-600',
    'border-400': 'border-pink-400',
    'h-border-500': 'hover:border-pink-600',
    'gh-text-400': 'group-hover:text-pink-400'
  },
  green: {
    'bg-100': 'bg-green-50/50',
    'bg-500': 'bg-green-500',
    'bg-600': 'bg-green-600',
    'text-500': 'text-green-500',
    'text-600': 'text-green-600',
    'border-400': 'border-green-400',
    'h-border-500': 'hover:border-green-600',
    'gh-text-400': 'group-hover:text-green-400'
  }
};
