import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import i18n from 'i18n-js';
import { useState } from 'react';
import TermsModal from '../../components/utils/TermsModal';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const { setLoading } = props;
  const [isOpen, setOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleSubmit = async event => {
    setLoading(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.confirmSetup({
      elements,
      confirmParams: { return_url: `${window.location.origin}/checkout-success` }
    });

    if (result.error) {
      setLoading(false);
      console.log(result.error.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="w-100">
        <PaymentElement />
        <div className="d-flex flex-column align-items-center mt-5 mb-3">
          <div>
            <button className="btn btn-info btn-lg mb-4" disabled={!stripe || !termsAccepted}>
              {i18n.t('organization_save_payment_info')}
            </button>
          </div>
        </div>
      </form>
      <div className="align-items-center d-flex justify-content-center">
        <input
          name="prev"
          type="checkbox"
          checked={termsAccepted}
          onChange={e => setTermsAccepted(!termsAccepted)}
        />
        <button color="" className="border-0 btn btn-sm shadow-none" onClick={() => setOpen(true)}>
          {i18n.t('terms_please_accept')}
        </button>
      </div>
      <TermsModal
        isOpen={isOpen}
        setOpen={setOpen}
        onAccept={() => {
          setTermsAccepted(true);
          setOpen(false);
        }}
        onDoNotAccept={() => {
          setTermsAccepted(false);
          setOpen(false);
        }}
      />
    </>
  );
}
