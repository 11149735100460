import moment from 'moment';
import { useState } from 'react';
import { useAPIDataLoader, useAPIv2DataLoader } from '../api';

export function useDashboardData(filter, q) {
  const cache = true;

  const types = {
    qVisits: false,
    qDemos: false,
    qTech: false,
    qAppBundles: false,
    qHeatmap: false,
    qRadar: false,
    qFullDemos: false,
    qFullDemosPDVs: false,
    qPerformance: false,
    qFullDemosPrev: false,
    qRanking: false,
    qJourney: false
  };

  const hasFilter =
    filter &&
    ((filter.groupKeys && filter.groupKeys.length > 0) || filter.storeKey) &&
    (filter.startDate !== null || filter.period !== null);

  // console.log('hasFilter', hasFilter, filter, q);

  const responseVisits = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qVisits: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qVisits }
  );
  if (responseVisits.error) {
    console.log('responseVisits error', responseVisits.error);
  }

  const responseDemos = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qDemos: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qDemos }
  );
  if (responseDemos.error) {
    console.log('responseDemos error', responseDemos.error);
  }
  const responseTech = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qTech: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qTech }
  );
  if (responseTech.error) {
    console.log('responseTech error', responseTech.error);
  }

  const responseAppBundles = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qAppBundles: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qAppBundles }
  );
  if (responseAppBundles.error) {
    console.log('responseAppBundles error', responseAppBundles.error);
  }

  const responseHeatmap = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qHeatmap: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qHeatmap }
  );
  if (responseHeatmap.error) {
    console.log('responseHeatmap error', responseHeatmap.error);
  }

  const responseRadar = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qRadar: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qRadar }
  );
  if (responseRadar.error) {
    console.log('responseRadar error', responseRadar.error);
  }

  const responsePerformance = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qPerformance: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qPerformance }
  );
  if (responsePerformance.error) {
    console.log('responsePerformance error', responsePerformance.error);
  }

  const responseRanking = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qRanking: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qRanking }
  );
  if (responseRanking.error) {
    console.log('responseRanking error', responseRanking.error);
  }

  const responseFullDemos = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qFullDemos: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qFullDemos }
  );
  if (responseFullDemos.error) {
    console.log('responseFullDemos error', responseFullDemos.error);
  }

  const responseFullDemosPrev = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qFullDemosPrev: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qFullDemosPrev }
  );
  if (responseFullDemosPrev.error) {
    console.log('responseFullDemosPrev error', responseFullDemosPrev.error);
  }

  const responseFullDemosPDVs = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qFullDemosPDVs: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qFullDemosPDVs }
  );
  if (responseFullDemosPDVs.error) {
    console.log('responseFullDemosPDVs error', responseFullDemosPDVs.error);
  }

  const responseJourney = useAPIDataLoader(
    'metrics.dashboardData',
    {
      filter,
      type: { ...types, qJourney: true }
    },
    { cache, skip: q.skip || !hasFilter || !q.qJourney }
  );

  const baseParams = buildBaseParams(filter);

  const responseProjectionData = useAPIv2DataLoader('projections', 'population', baseParams, {
    skip: !(
      (baseParams.segments.groupKeys && baseParams.segments.groupKeys.length > 0) ||
      baseParams.segments.storeKey
    )
  });

  const projectionData = responseProjectionData?.data?.value?.response;

  return {
    responseVisits,
    responseDemos,
    responseTech,
    responseAppBundles,
    responseHeatmap,
    responseRadar,
    responseFullDemos,
    responseFullDemosPDVs,
    responsePerformance,
    responseFullDemosPrev,
    responseRanking,
    responseJourney,
    projectionData
  };
}

export function buildBaseParams(filter) {
  // TODO: consider all period options depending on periodType. Create an object that builds it!!!! Periods must be fist class!
  const period = {
    timestampStart: filter.startDate,
    timestampEnd: filter.endDate,
    offset: filter.offset
  };
  // TODO: same for segment. Juist something with grouphash? or what?
  const segments = {
    groupKeys: filter.groupKeys,
    storeKey: filter.storeKey
  };

  return {
    period,
    segments
  };
}

export function formattedPeriod(filter) {
  if (window._env_.REACT_APP_TYPE_PERIOD === 'fixed') return filter.period;
  if (window._env_.REACT_APP_TYPE_PERIOD === 'month')
    return `${moment(filter.startDate || filter.timestampStart).format('MMMM YYYY')}`;
  if (window._env_.REACT_APP_TYPE_PERIOD === 'week')
    return `${moment(filter.startDate || filter.timestampStart).format('MMMM DD')} - ${moment(
      filter.endDate || filter.timestampEnd
    ).format('MMMM DD')}`;
}

export function useFullDemosObj(responseFullDemos, responseVisits) {
  const [fullDemosFilter, setFullDemosFilter] = useState({});

  const processDemos = fd => {
    const filterKeys = Object.keys(fullDemosFilter);
    return fd.filter(
      e =>
        filterKeys.filter(k => fullDemosFilter[k] == null || e[k] === fullDemosFilter[k]).length ===
        filterKeys.length
    );
  };
  const rfd = responseFullDemos.hasValue('metricsFullDemos')
    ? responseFullDemos.data.value.metricsFullDemos
    : [];
  const fullDemos = processDemos(rfd);

  const totalMetrics = rfd.reduce((accum, v) => {
    accum.totalUniques = (accum.totalUniques || 0) + v.unique;
    accum.totalVisits = (accum.totalVisits || 0) + v.visits;
    accum.totalRawUniques = (accum.totalRawUniques || 0) + v.raw_unique;
    accum.totalRawVisits = (accum.totalRawVisits || 0) + v.raw_visits;
    accum.totalNewVisitors = (accum.totalNewVisitors || 0) + v.new_visitors;
    accum.totalRepeatVisitors = (accum.totalRepeatVisitors || 0) + v.repeat_visitors;
    return accum;
  }, {});

  const metrics = fullDemos.reduce((accum, v) => {
    accum.uniques = (accum.uniques || 0) + v.unique;
    accum.visits = (accum.visits || 0) + v.visits;
    accum.rawUniques = (accum.rawUniques || 0) + v.raw_unique;
    accum.rawVisits = (accum.rawVisits || 0) + v.raw_visits;
    accum.newVisitors = (accum.newVisitors || 0) + v.new_visitors;
    accum.repeatVisitors = (accum.repeatVisitors || 0) + v.repeat_visitors;
    accum.dwellTimeMins = (accum.dwellTimeMins || 0) + v.dwellTimeMins;
    return accum;
  }, {});

  const freq = metrics.visits / metrics.uniques;

  const metricsWeekly = [];

  if (
    responseVisits &&
    responseVisits.hasValue('metricsWeekly') &&
    responseVisits.data.value.metricsWeekly.length > 0 &&
    responseFullDemos.hasValue('metricsFullDemos')
  ) {
    // if setup is by 'week', then we need to reduce fullDemos by week_part instead of period_N
    if (window._env_.REACT_APP_TYPE_PERIOD === 'week') {
      for (let i = 0; i < responseVisits.data.value.metricsWeekly.length; i++) {
        const w = responseVisits.data.value.metricsWeekly[i]._id.period_quarter_start;
        const fullDemosWeek = fullDemos.filter(f => f.week_part === w);
        metricsWeekly.push({
          _id: responseVisits.data.value.metricsWeekly[i]._id,
          unique_visitors: fullDemosWeek.reduce((accum, v) => accum + v.unique_q1, 0),
          visits: fullDemosWeek.reduce((accum, v) => accum + v.visits_q1, 0),
          dwell_time_mins:
            (fullDemosWeek.reduce((accum, v) => accum + v.dwell_time_mins_q1, 0) /
              fullDemosWeek.length) *
            60,
          new_visitors: fullDemosWeek.reduce((accum, v) => accum + v.new_visitor_q1, 0),
          repeat_visitors: fullDemosWeek.reduce((accum, v) => accum + v.repeat_q1, 0)
        });
      }
    } else {
      for (let i = 0; i < responseVisits.data.value.metricsWeekly.length; i++) {
        const q = responseVisits.data.value.metricsWeekly[i]._id.period_quarter + 1;
        metricsWeekly.push({
          _id: responseVisits.data.value.metricsWeekly[i]._id,
          unique_visitors: fullDemos.reduce((accum, v) => accum + v[`unique_q${q}`], 0),
          visits: fullDemos.reduce((accum, v) => accum + v[`visits_q${q}`], 0),
          dwell_time_mins:
            (fullDemos.reduce((accum, v) => accum + v[`dwell_time_mins_q${q}`], 0) /
              fullDemos.length) *
            60,
          new_visitors: fullDemos.reduce((accum, v) => accum + v[`new_visitor_q${q}`], 0),
          repeat_visitors: fullDemos.reduce((accum, v) => accum + v[`repeat_q${q}`], 0)
        });
      }
    }
  }

  // }

  const fullDemosObj = {
    rfd,
    fullDemos,
    fullDemosFilter,
    setFullDemosFilter,
    ...totalMetrics,
    ...metrics,
    freq,
    metricsWeekly,
    data: {
      state: responseFullDemos.data.state,
      loaded: responseFullDemos.data.loaded
    }
  };

  return fullDemosObj;
}
