import React from 'react';
import i18n from 'i18n-js';

import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import TopOfflineCategoriesChart from '../../dashboard/TopOfflineCategoriesChart';
import PopoverHelp from '../../../components/utils/PopoverHelp';

export default function OfflineInterestsCard({ responseVisits, poiType }) {
  const metricsInterestsOffline = responseVisits.metricsInterestsOffline;

  return (
    <Row>
      <div className="mb-4 col-sm-12 col-md-12">
        <Card>
          <CardBody>
            <CardTitle className="text-uppercase h6">
              {i18n.t('offline_behavior')}
              <PopoverHelp
                title={i18n.t('offline_behavior')}
                text={i18n.t('offline_behavior_text_how')}
              />
            </CardTitle>
            <div className="small mb-4 card-subtitle">
              {i18n.t(poiType === 'ooh' ? 'offline_behavior_text_ooh' : 'offline_behavior_text')}
            </div>
            <div style={{ width: '100%', height: '400px' }}>
              {/* <InterestsChart response={metricsInterestsOffline} /> */}
              <TopOfflineCategoriesChart response={metricsInterestsOffline} />
            </div>
          </CardBody>
        </Card>
      </div>
    </Row>
  );
}
