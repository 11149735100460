import { useEffect, useState } from 'react';

import toastr from 'toastr';

import API from '../api';
import API2 from '../api2';

import { generateHash } from '../api/mofidata';
import { metricsCache } from '../redux/ducks/auth';

import { Buffer } from 'buffer';

const hasValueFn = function (value) {
  return this.data.value != null && (value ? this.data.value[value] != null : true);
};

export const defaultResponse = {
  error: null,
  data: {
    value: null,
    // state: 'INIT'
    state: 'LOADING'
  },
  hasValue: hasValueFn
};

export function useAPICaller(options) {
  options = { cache: true, notification: true, notificationSuccessMsg: 'OK!', ...options };

  const [response, setResponse] = useState(defaultResponse);

  const call = (name, params) => {
    const callback = (err, response) => {
      if (options.notification) {
        if (err) {
          toastr.error(
            err.reason ||
              (err.response && err.response.data && err.response.data.message) ||
              'Error'
          );
        } else {
          toastr.success(options.notificationSuccessMsg);
        }
      }

      setResponse({
        error: err,
        data: {
          value: response.data,
          state: 'LOADED'
        },
        hasValue: hasValueFn
      });
    };

    // RELEASE UI
    setTimeout(() => {
      if (options.cache) {
        API.call(name, params, callback);
      } else {
        API.noCacheCall(name, params, callback);
      }
    }, 50);
  };

  return [response, call];
}

export function useAPIDataLoader(name, params, options) {
  params = params || {};
  options = { cache: true, skip: false, ...options };

  const hash = generateHash(name, params);

  const [response, setResponse] = useState(defaultResponse);

  // console.log(name, options);
  useEffect(() => {
    if (!options.skip) {
      setTimeout(() => {
        setResponse(res => {
          return {
            ...res,
            data: {
              ...res.data,
              state: 'LOADING'
            },
            hasValue: hasValueFn
          };
        });
      }, 50);

      const callback = (err, res) => {
        setResponse({
          error: err,
          data: {
            value: res,
            state: 'LOADED'
          },
          hasValue: hasValueFn
        });
      };

      // RELEASE UI
      setTimeout(() => {
        if (options.cache) {
          API.call(name, params, callback);
        } else {
          API.noCacheCall(name, params, callback);
        }
      }, 50);
    } else {
      setResponse(res => {
        return {
          ...res,
          data: {
            ...res.data,
            state: 'SKIPPED'
          },
          hasValue: hasValueFn
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, options.skip]);

  return response;
}

export function callSyncAPIDataLoader(name, params, options, cb) {
  params = params || {};
  options = { cache: true, ...options };

  cb({
    ...defaultResponse,
    data: {
      value: defaultResponse.data.value,
      // loaded: false
      state: 'LOADING'
    },
    hasValue: hasValueFn
  });

  const callback = (err, response) => {
    cb({
      error: err,
      data: {
        value: response,
        state: 'LOADED'
      },
      hasValue: hasValueFn
    });
  };

  // RELEASE UI
  setTimeout(() => {
    if (options.cache) {
      API.call(name, params, callback);
    } else {
      API.noCacheCall(name, params, callback);
    }
  }, 50);
}

// API V2
export function useAPIv2DataLoader(name, path, params, options) {
  // console.log('useAPIv2DataLoader', name, path, params, options);

  params = params || {};
  options = { cache: true, skip: false, responseHolder: 'response', ...options };

  const hash = generateHash(name + path, params);

  const [response, setResponse] = useState(defaultResponse);

  useEffect(() => {
    // console.log('useAPIv2DataLoader', options);
    if (!options.skip) {
      if (options.cache && metricsCache.peek(hash)) {
        const data = metricsCache.get(hash);
        // console.log('cache', data);
        // cached response
        setResponse(res => {
          return {
            ...res,
            data: {
              value: {
                [options.responseHolder]: data
              },
              state: 'LOADED'
            },
            hasValue: hasValueFn
          };
        });
      } else {
        // console.log('init api');
        // initial response
        setResponse(res => {
          return {
            ...res,
            data: {
              ...res.data,
              // loaded: false
              state: 'LOADING'
            },
            hasValue: hasValueFn
          };
        });

        const callback = (err, res) => {
          // console.log('callback api', res);
          if (options.cache && !err) metricsCache.set(hash, res);

          setResponse({
            error: err,
            data: {
              value: {
                [options.responseHolder]: res
              },
              state: 'LOADED'
            },
            hasValue: hasValueFn
          });
        };

        // RELEASE UI
        setTimeout(() => {
          API2.get(name, path, params, callback);
        }, 50);
      }
    } else {
      setResponse(res => {
        return {
          ...res,
          data: {
            ...res.data,
            // loaded: false,
            state: 'SKIPPED'
          },
          hasValue: hasValueFn
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return response;
}

export function callSyncAPIv2DataLoader(name, path, params, options, cb) {
  params = params || {};
  options = {
    cache: true,
    responseHolder: 'response',
    encode: [],
    maskWithPost: false, // If set to true (in options) the method will use a POST for fetching data
    ...options
  };

  if (options.encode && options.encode.length > 0) {
    for (let o = 0; o < options.encode.length; o++) {
      // console.log(options.encode[o], params[options.encode[o]]);
      params[options.encode[o]] = Buffer.from(params[options.encode[o]]).toString('base64');
    }
  }

  const hash = generateHash(name + path, params);

  if (options.cache && metricsCache.peek(hash)) {
    const data = JSON.parse(JSON.stringify(metricsCache.get(hash)));
    // console.log('from cache', path, data);

    // cached response
    cb({
      error: null,
      data: {
        value: {
          [options.responseHolder]: data
        },
        state: 'LOADED'
      },
      hasValue: hasValueFn
    });
  } else {
    const callback = (err, response) => {
      if (options.cache && !err) {
        // it must by cloned twice so callers cannot modify cache directly
        const resCache = JSON.parse(JSON.stringify(response));
        metricsCache.set(hash, resCache);
      }

      // it must by cloned twice so callers cannot modify cache directly
      const resResponse = JSON.parse(JSON.stringify(response));

      cb({
        error: err,
        data: {
          value: {
            [options.responseHolder]: resResponse
          },
          state: 'LOADED'
        },
        hasValue: hasValueFn
      });
    };
    // RELEASE UI
    setTimeout(() => {
      if (!options.maskWithPost) {
        API2.get(name, path, params, callback);
      } else {
        API2.post(name, path, params, callback);
      }
    }, 50);
  }
}

export function callSyncAPIv2BlobLoader(name, path, params, options, cb) {
  params = params || {};
  options = {
    cache: true,
    responseHolder: 'response',
    encode: [],
    maskWithPost: false, // If set to true (in options) the method will use a POST for fetching data
    ...options
  };

  if (options.encode && options.encode.length > 0) {
    for (let o = 0; o < options.encode.length; o++) {
      // console.log(options.encode[o], params[options.encode[o]]);
      params[options.encode[o]] = Buffer.from(params[options.encode[o]]).toString('base64');
    }
  }

  const hash = generateHash(name + path, params);

  if (options.cache && metricsCache.peek(hash)) {
    const data = JSON.parse(JSON.stringify(metricsCache.get(hash)));
    // console.log('from cache', path, data);

    // cached response
    cb({
      error: null,
      data: {
        value: {
          [options.responseHolder]: data
        },
        state: 'LOADED'
      },
      hasValue: hasValueFn
    });
  } else {
    const callback = (err, response) => {
      if (options.cache && !err) {
        // it must by cloned twice so callers cannot modify cache directly
        const resCache = response;
        metricsCache.set(hash, resCache);
      }

      // it must by cloned twice so callers cannot modify cache directly
      const resResponse = response;

      cb({
        error: err,
        data: {
          value: {
            [options.responseHolder]: resResponse
          },
          state: 'LOADED'
        },
        hasValue: hasValueFn
      });
    };
    // RELEASE UI
    setTimeout(() => {
      if (!options.maskWithPost) {
        API2.blob(name, path, params, callback);
      } else {
        API2.post(name, path, params, callback);
      }
    }, 50);
  }
}
