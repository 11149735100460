import React, { useState, useRef, useMemo } from 'react';
import i18n from 'i18n-js';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import { Treebeard } from 'react-treebeard';
import { MdKeyboardArrowRight, MdKeyboardArrowDown, MdUnfoldMore } from 'react-icons/md/';
import { callSyncAPIv2DataLoader, callSyncAPIDataLoader } from '../../hooks/api';
import * as filters from './tree_filter';
import treeStyle from './treeStyle';
import moment from 'moment';

let searchTask;
const perPage = 100; // TODO: AppConfig?

export default function ModalFilter({
  // responseFilters,
  modal,
  setModal,
  onSelect,
  filterSinglePOI = false,
  filterConditions,
  filter,
  preventDefault = false
}) {
  // backwards compatibility
  const useApiV2 = window._env_.REACT_APP_USE_POI_TREE_NODES === 'true'; // defaults to false

  const _data = useRef(null); // TODO: This name requires improvement and drag other pieces of code too https://www.notion.so/mofiler/Refactor-Variables-for-improving-readibility-88b9ff8e2dc94e5d9ac143d5f30274c7
  const poiType = useRef(filter.type);
  const [data, setData] = useState({});
  const [cursor, setCursor] = useState(false);

  const [isSearching, setIsSearching] = useState(false);
  const [textFilter, setTextFilter] = useState('');

  let loadingNode = null;

  // clone JSON to trigger repaint
  const resetData = d => {
    setData({ ...d });
  };

  const prepareNode = node => {
    // keep backwards compatibility
    return useApiV2
      ? {
          name: node.displayName,
          group_hash: node.groupHash,
          groupCount: node.groupSize,
          validForPeriods: data.validForPeriods
          // month_range_start: node.month_range_start,
          // month_range_end: node.month_range_end,
          // periods: node.periods
        }
      : node;
  };

  const isSelectable = node => {
    if (useApiV2) {
      const selByCondition =
        !filterConditions ||
        filterConditions.length === 0 ||
        !node.metadata ||
        filterConditions.reduce((a, v) => a && node.metadata[v] === true, true);

      return (
        selByCondition &&
        node.nodeType !== 'Folder' &&
        (!filterSinglePOI || node.nodeType === 'ProxyGroup')
      );
    }

    return node.group_hash && (!filterSinglePOI || (node.groupCount === 1 && !node.children));
  };

  const loadNode = nodeId => {
    loadingNode = nodeId;

    callSyncAPIv2DataLoader(
      'poisTree',
      `${nodeId}/children`,
      {
        period: filter.period,
        startDate: filter.startDate.valueOf(),
        endDate: filter.endDate.valueOf(),
        offset: filter.offset,
        type: filter.type,
        perPage
      },
      { responseHolder: `nodes${nodeId}` },
      res => {
        addChildren(nodeId, res.data.value[`nodes${nodeId}`].tree.children, data);
        loadingNode = null;
      }
    );
  };

  const onSelectNode = node => {
    if (cursor) {
      cursor.active = false;
      setCursor(cursor);
      resetData(data);
    }

    if (node.children && !node.toggled) {
      node.toggled = !node.toggled;
    }

    if (
      useApiV2 &&
      node.nodeType !== 'ProxyGroup' &&
      node.children.length === 0 &&
      !node.childrenLoaded
    ) {
      if (!loadingNode) {
        node.loading = true;
        node.toggled = true;
        loadNode(node.nodeId);
      }
    }

    node.active = true;
    setCursor(node);
    resetData(data);
  };

  const checkValidPeriod = d => {
    // TODO: This name requires improvement and the issue starts with the state _data https://www.notion.so/mofiler/Refactor-Variables-for-improving-readibility-88b9ff8e2dc94e5d9ac143d5f30274c7
    if (d && d.current && d.current.validForPeriods) {
      if (window._env_.REACT_APP_TYPE_PERIOD !== 'fixed') {
        const vfp = d.current.validForPeriods[0];
        const dateMonth = moment.utc(filter.startDate).startOf('month');
        if (
          moment.utc(vfp.monthRangeStart).diff(dateMonth) <= 0 &&
          moment.utc(vfp.monthRangeEnd).diff(dateMonth) >= 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  };

  // load root data
  useMemo(() => {
    // initializar
    const validPeriod = checkValidPeriod(_data);
    if (!_data.current || poiType.current !== filter.type || !validPeriod) {
      poiType.current = filter.type;
      if (useApiV2) {
        callSyncAPIv2DataLoader(
          'poisTree',
          `root/children`,
          {
            period: filter.period,
            startDate: filter.startDate.valueOf(),
            endDate: filter.endDate.valueOf(),
            offset: filter.offset,
            type: filter.type,
            perPage
          },
          { cache: true, responseHolder: `nodesroot` },
          res => {
            _data.current = prepareBasicData({
              ts: 2,
              loading: false,
              children: res.data.value.nodesroot.tree.children,
              defaultPoiTreeNode: res.data.value.nodesroot.tree.metadata.defaultPoiTreeNode,
              validForPeriods: res.data.value.nodesroot.tree.metadata.validForPeriods,
              filter
            });

            const d = _data.current;

            if (
              d.ts !== undefined &&
              (data.ts === undefined || d.ts !== data.ts || d.type !== data.type || !validPeriod)
            ) {
              setData({ ...d });
              setTextFilter('');

              // fire default selection (if any)
              if (
                !preventDefault &&
                d.defaultPoiTreeNode &&
                (!filter.groupKeys || filter.groupKeys.length === 0)
              ) {
                onSelect(prepareNode(d.defaultPoiTreeNode));
              }
            }
          }
        );
      } else {
        callSyncAPIDataLoader(
          'pois.getDataForFilters',
          {
            period: filter.period,
            startDate: filter.startDate.valueOf(),
            endDate: filter.endDate.valueOf(),
            offset: filter.offset,
            type: filter.type
          },
          { cache: true },
          res => {
            if (res.hasValue('pdvs')) {
              _data.current = JSON.parse(JSON.stringify(res.data.value.pdvs));

              const d = _data.current;

              if (
                d.ts !== undefined &&
                (data.ts === undefined || d.ts !== data.ts || d.type !== data.type)
              ) {
                setData(d);
              }
            }
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_data, filter]);

  function findChildrenPath(nodeId, lvl, path) {
    if (!lvl.children || lvl.children.length === 0) return null;

    let res = null;

    for (let i = 0; i < lvl.children.length; i++) {
      const currentPath = `${path}.${i}`;

      if (lvl.children[i].nodeId === nodeId) return currentPath; // found
      if (lvl.children[i].children && lvl.children[i].children.length > 0) {
        res = findChildrenPath(nodeId, lvl.children[i], `${currentPath}.children`); // not found, search inside current path
        if (res != null) return res;
      }
    }

    return null;
  }

  // modifies 'data' json
  function addChildren(parentId, nodes, data) {
    // call API to get node's children, then add to data json and refresh screen
    const path = findChildrenPath(parentId, data, 'children');

    if (path !== null) {
      const res = path.split('.').reduce(function (o, k) {
        return o && o[k];
      }, data);

      res.children = nodes || [];
      res.loading = false;
      res.childrenLoaded = true;
      resetData(data);
    }
  }

  const getDisplayName = node => {
    if (!node || !node.displayName) return null;

    return node.nodeType === 'Folder' || node.nodeType === 'ProxyGroup'
      ? node.displayName
      : `${node.displayName} (${node.groupSize})`;
  };

  const onToggle = (node, toggled) => {
    if (cursor) {
      cursor.active = false;
      setCursor(cursor);
    }
    node.active = true;

    if (node.children && node.children.length > 0) {
      node.toggled = toggled;
      setCursor(node.children);
    } else if (
      useApiV2 &&
      node.nodeType !== 'ProxyGroup' &&
      node.children.length === 0 &&
      !node.childrenLoaded
    ) {
      if (!loadingNode) {
        // is nothing is currently loading
        node.loading = true;
        node.toggled = true;
        loadNode(node.nodeId);
      }
    }
    setCursor(node);
    resetData(data);
  };

  const onFilterKeyUp = ({ target: { value } }) => {
    const tf = value.trim();
    if (searchTask) clearTimeout(searchTask);

    return new Promise(resolve => {
      searchTask = setTimeout(() => {
        setTextFilter(tf);
        if (!tf || tf === '') {
          return resetData(_data.current);
        }

        if (useApiV2) {
          if (!isSearching) {
            setIsSearching(true);
            callSyncAPIv2DataLoader(
              'poisTree',
              `upperBranches`,
              {
                filter: tf,
                period: filter.period,
                startDate: filter.startDate.valueOf(),
                endDate: filter.endDate.valueOf(),
                offset: filter.offset,
                type: filter.type,
                perPage
              },
              // if no nodeId then skip this call
              { skip: !filter, cache: true, responseHolder: `searchResult` },
              res => {
                setIsSearching(false);
                if (res.hasValue('searchResult')) {
                  const d = prepareBasicData({
                    ts: 2,
                    loading: false,
                    children: res.data.value.searchResult.tree.children,
                    validForPeriods: res.data.value.searchResult.tree.metadata.validForPeriods,
                    filter
                  });
                  setData({ ...d });
                }
              }
            );
          }
        } else {
          let filtered = filters.filterTree(_data.current, tf);
          filtered = filters.expandFilteredNodes(filtered, tf);
          return setData(filtered);
        }
        return true;
      }, 500);
    });
  };

  const decorators = {
    // eslint-disable-next-line react/no-unstable-nested-components
    Loading: props => {
      return <div style={props.style}>loading...</div>;
    },
    // eslint-disable-next-line react/no-unstable-nested-components
    Header: ({ onSelect, node, style, customStyles }) => (
      <div
        style={isSelectable(node) ? style.baseSelectable : style.base}
        onClick={() => onSelect(node)}
      >
        <div style={node.selected ? { ...style.title, ...customStyles.header.title } : style.title}>
          {useApiV2 ? getDisplayName(node) : node.name}
        </div>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    Container: ({ style, decorators, terminal, node, customStyles, onClick }) => {
      const renderToggle = node.toggled ? (
        <MdKeyboardArrowDown
          size="18"
          color="#000"
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <MdKeyboardArrowRight
          size="18"
          color="#000"
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      );
      return (
        <div style={node.active && isSelectable(node) ? { ...style.container } : { ...style.link }}>
          {!terminal && node.nodeType !== 'ProxyGroup' ? renderToggle : null}
          <decorators.Header
            node={node}
            style={style.header}
            customStyles={customStyles}
            onSelect={onSelectNode}
          />
          {useApiV2 &&
          node.nodeType !== 'ProxyGroup' &&
          !node.childrenLoaded &&
          node.toggled &&
          !node.loading ? (
            <MdUnfoldMore
              size={18}
              className="mt-1"
              title={i18n.t('search_load_more')}
              onClick={() => {
                if (!loadingNode) {
                  // is nothing is currently loading
                  node.loading = true;
                  node.toggled = true;
                  loadNode(node.nodeId);
                }
              }}
            />
          ) : null}
        </div>
      );
    }
    // });
  };

  const searchLoading = (
    <div className="loading_metric float-right position-relative" style={{ right: 0 }}>
      <div className="loading-dot" />
    </div>
  );

  return (
    <Modal size="lg" isOpen={modal} toggle={() => setModal(false)} className="modalFadeInScale">
      <ModalHeader toggle={() => setModal(false)}>
        {i18n.t(filter.type === 'ooh' ? 'admin_oohs_list' : 'admin_pois_list')}
      </ModalHeader>

      <ModalBody>
        <p className="small">{i18n.t('search_for_text', { perPage })}</p>
        {i18n.t('search_for')}
        <div>
          {isSearching && searchLoading}
          <Input type="text" name="groupName" onKeyUp={onFilterKeyUp} defaultValue={textFilter} />
        </div>
        <Treebeard style={treeStyle} data={data} onToggle={onToggle} decorators={decorators} />
      </ModalBody>

      <ModalFooter>
        <Button
          disabled={!cursor.active || !isSelectable(cursor)}
          color="primary"
          onClick={() => onSelect(prepareNode(cursor))}
        >
          {i18n.t('apply_filters_button')}
        </Button>
        <Button color="secondary" onClick={() => setModal(false)}>
          {i18n.t('cancel_button')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const prepareBasicData = ({
  ts,
  loading,
  children,
  defaultPoiTreeNode,
  validForPeriods,
  filter
}) => {
  return {
    ts,
    displayName: 'root',
    nodeId: 'root',
    nodeType: 'Folder',
    toggled: true,
    loading,
    children: children || [],
    childrenLoaded: true,
    type: filter.type,
    defaultPoiTreeNode,
    validForPeriods
  };
};
