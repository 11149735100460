import React from 'react';

import { Row } from 'reactstrap';

import './style.scss';

export default function Footer(props) {
  // TODO: commented because this is not being used and needs to be redefined
  // const notificationsResponse = useAPIDataLoader(
  //   'notifications.last_notifications',
  //   {},
  //   { cache: false }
  // );

  // const notifications = get(notificationsResponse, 'data.value.notifications', []);
  // const notificationsCount = notifications.reduce(
  //   (accum, notif) => (notif.new ? accum + 1 : accum),
  //   0
  // );

  const showFooter = window._env_.REACT_APP_SHOW_FOOTER === 'true';

  return showFooter ? (
    <Row className="m-0">
      <div className="col-12 p-0">
        <footer className="site-foot align-items-center justify-content-between">
          <div className="flex justify-content-center">
            <img
              style={{
                filter: 'saturate(0)'
              }}
              src={`${window._env_.REACT_APP_BASE_BRAND_URL}/${window._env_.REACT_APP_BRAND}/footer.png`}
              alt={window._env_.REACT_APP_TITLE}
            />
          </div>
        </footer>
      </div>
    </Row>
  ) : null;
}
