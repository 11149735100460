import i18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';

import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import * as reactIcons from 'react-icons/md/';

import API2 from '../../api2';
import history from '../../../history';
import LoadingModal from '../../components/utils/LoadingModal';
import numeral from 'numeral';
import moment from 'moment';

export default function EditSubscription(props) {
  const { user, organization } = props;

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('edit_subscription_title')}</h1>
          <p className="mb-0">{i18n.t('manage_subscription')}</p>
        </header>
      </div>
      <ViewContent user={user} organization={organization} />
    </div>
  );
}

function ViewContent({ user, organization }) {
  return (
    <div className="view-content view-components">
      <Card className="mb-4">
        <CardBody className="table-responsive">
          <h6 className="mb-4 text-uppercase">{i18n.t('subscription_info')}</h6>
          <EditSubscriptionForm user={user} organization={organization} />
        </CardBody>
      </Card>
    </div>
  );
}

function EditSubscriptionForm({ user, organization }) {
  const [isLoading, setLoading] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  useEffect(() => {
    setLoading(true);

    API2.get('organizations', `${organization.id}/subscriptionDetails`, {}, (err, res) => {
      if (err) {
        toastr.error(`${i18n.t('error_customer_portal')}: ${err}`);
      } else {
        setSubscriptionDetails(res);
      }
      setLoading(false);
    });
  }, []);

  const details = {};
  if (subscriptionDetails) {
    // details.status = subscriptionDetails.organization.status;
    details.status = subscriptionDetails.subscription.status.toUpperCase();
    details.renewalDate = moment
      .unix(subscriptionDetails.subscription.current_period_end)
      .format('YYYY-MM-DD');
    details.cost = `${numeral(subscriptionDetails.subscription.plan.amount / 100).format(
      '$ 0,0.00'
    )} / ${subscriptionDetails.subscription.plan.interval}`;
    details.lastPayment = moment
      .unix(subscriptionDetails.subscription.current_period_start)
      .format('YYYY-MM-DD HH:mm:ss');
    details.startDate = moment
      .unix(subscriptionDetails.subscription.start_date)
      .format('YYYY-MM-DD HH:mm:ss');
  }

  return (
    <>
      {isLoading && <LoadingModal isOpen={true} text={i18n.t('subscription_loading')} />}
      <Card>
        <CardBody>
          <SubscriptionField
            title="subscription_status"
            icon="MdModeStandby"
            value={details.status}
          />

          <SubscriptionField
            title="subscription_renewal_date"
            icon="MdAutorenew"
            value={details.renewalDate}
          />
          <SubscriptionField title="subscription_cost" icon="MdOutlinePaid" value={details.cost} />

          <SubscriptionField
            title="subscription_last_payment"
            icon="MdUpdate"
            value={details.lastPayment}
          />

          <div className="align-items-end d-flex flex-column">
            {subscriptionDetails && (
              <Button
                color="danger"
                type="button"
                onClick={() => history.push('/settings/billing')}
                className="form_bt bt_common mt-3 w-25"
              >
                {i18n.t('subscription_action_cancel')}
              </Button>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
}

function SubscriptionField({ title, icon, value }) {
  return (
    <CardTitle className={`d-flex justify-content-between align-items-center`}>
      <span className="d-flex align-items-center w-100">
        {React.createElement(reactIcons[icon], { size: 23, className: 'mr-2' })}
        {i18n.t(title)}:
      </span>
      <div className="d-flex flex-column text-nowrap">
        <span className="text-right">
          {/* {reach[field] ? numeral(reach[field]).format(format) : '--'} */}
          {value}
        </span>
      </div>
    </CardTitle>
  );
}
