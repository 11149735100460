export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) return pair[1];
  }
  return null;
}

export function openNewWindow(path, keepVars = []) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  const k = [];

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    for (let h = 0; h < keepVars.length; h++) {
      if (pair[0] === keepVars[h]) {
        k.push(vars[i]);
      }
    }
  }
  window.open(`${path}?${k.join('&')}`, '_blank');
}

export function fileDownload(response, fileName) {
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function buildDefaultDashboardUrl() {
  let url = '/dashboard';
  if (
    window._env_.REACT_APP_MODULE_CATEGORY_PDV === 'true' &&
    window._env_.REACT_APP_MODULE_PDV_DASHBOARD === 'true'
  )
    url += '?type=pdv';
  else if (
    window._env_.REACT_APP_MODULE_CATEGORY_OOH === 'true' &&
    window._env_.REACT_APP_MODULE_OOH_DASHBOARD === 'true'
  )
    url += '?type=ooh';

  return url;
}

const e = {
  getQueryVariable,
  openNewWindow,
  fileDownload
};

export default e;
