import React, { useEffect, useState, useRef } from 'react';
import i18n from 'i18n-js';
import {
  MapContainer,
  TileLayer,
  Marker,
  Circle,
  FeatureGroup,
  Popup,
  Tooltip,
  useMapEvents
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import toastr from 'toastr';
import Freedraw, { ALL, CREATE } from 'react-leaflet-freedraw';
import '../../components/heatmap/tracemap.css';

import { Col, Row, Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  MdDoneOutline,
  MdAddShoppingCart,
  MdCheck,
  MdDeleteOutline,
  MdMotionPhotosAuto,
  MdOutlineCancel,
  MdOutlineDeleteOutline,
  MdInsights,
  MdFileUpload
} from 'react-icons/md/';

import { FaMousePointer, FaObjectGroup, FaObjectUngroup } from 'react-icons/fa';

import * as reactIcons from 'react-icons/md/';

import numeral from 'numeral';
import { callSyncAPIv2DataLoader } from '../../hooks/api';
import { colors } from '../../components/charts/Styles';
import { createClusterCustomIcon, options } from '../../components/heatmap/config';
import GoogleMapComponent from '../../components/heatmap/GoogleMapComponent';

import lasso from '../../../styles/img/lasso.svg';
import { findWithinArea, bboxPois } from '../../components/heatmap/utils';
import PopupConfirm from '../../components/utils/PopupConfirm';
import { openNewWindow } from '../../../routeUtils';
import UseControl from '../../components/heatmap/UseControl';
import PopoverProjectionHelp, {
  formatBaseProjectionData
} from '../../components/utils/PopoverProjectionHelp';
import poiIconCreators from './POIsIcons';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Importer, ImporterField } from 'react-csv-importer';
import { getCustomLocale } from '../../components/filter/customLocale';
import 'react-csv-importer/dist/index.css';
import { filter } from 'lodash';

const defaultProps = {
  center: {
    lat: window._env_.REACT_APP_DEFAULT_LATITUDE,
    lng: window._env_.REACT_APP_DEFAULT_LONGITUDE
  },
  zoom: 5
};

const GRAB_MODE = 1;
const AREA_MODE = 2;
const REACH_MODE = 3;
const IMPORT_MODE = 4;

export default function POIsMatchMap({
  mapPois,
  onSelect,
  heatmapColors,
  poisColors,
  markerColorField
}) {
  const filtered = mapPois.poisFiltered || [];
  const markerColorFieldIndex = markerColorField
    ? filtered.reduce((uniqueField, p) => {
        if (!uniqueField.includes(p[markerColorField])) {
          uniqueField.push(p[markerColorField]);
        }
        return uniqueField;
      }, [])
    : [];
  // console.log(markerColorFieldIndex);

  const { radius, heatmapOn } = mapPois;

  const [filteredFeatures, setFilteredFeatures] = useState(null);
  const [audienceFeatures, setAudienceFeatures] = useState(null);
  const [clustering, setClustering] = useState(true);

  // const [selectedFeatures, setSelectedFeatures] = useState(null);

  const [mapMarkerDisplayConfig, setMapMarkerDisplaysConfig] = useState(null);

  // const cursor = mode === AREA_MODE ? 'crosshair' : 'default';

  const mapRef = useRef(null);
  const lastBox = useRef(null);
  const [bounds, setBounds] = useState(null);
  const clusterGroupRef = useRef(null);

  const isSelected = React.useCallback(
    poi => {
      return mapPois.selectedPois.indexOf(poi._id) > -1;
    },
    [mapPois.selectedPois]
  );

  // LOAD Marker Details Config
  useEffect(() => {
    let isMounted = true;

    callSyncAPIv2DataLoader(
      'appConfig',
      'planner/mapMarkerDetails',
      {},
      { cache: true, responseHolder: `response` },
      res => {
        if (isMounted) {
          res.hasValue('response') &&
            setMapMarkerDisplaysConfig(res.data.value.response.planner.mapMarkerDetails);
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, [setMapMarkerDisplaysConfig]);

  // LOAD Filtered POIs
  useEffect(() => {
    const filtered = mapPois.poisFiltered || [];

    if ((clustering && !clusterGroupRef.current) || !bounds) return;

    // DO NOT PAINT until display configs are loaded
    if (mapMarkerDisplayConfig) {
      const pois = filtered.map(p => {
        if (mapPois.reachThreshold > 0 && p.metrics.uniqueReach < mapPois.reachThreshold) {
          return null;
        }

        p.metrics.reachPercentage =
          p.metrics.uniqueReach > 0 ? p.metrics.uniqueReach / p.metrics.unique : 0;

        // TODO: do not depend on mapPois.selectedPois to display markers as selected.
        // if we do it modifying DOM elements when selecting POIs we will avoid re-rendering
        // the entire Map, which is a very expensive operation.
        // Only re-render map when filteredPOIs have changed, nothing else should trigger it.
        const isCurrentSelected = isSelected(p);
        // console.log(p.name, isCurrentSelected);

        return (
          <MarkerDetails
            key={p._id}
            p={p}
            displayConfigs={mapMarkerDisplayConfig}
            heatmapOn={heatmapOn}
            isCurrentSelected={isCurrentSelected}
            onSelect={onSelect}
            poisColors={poisColors}
            heatmapColors={heatmapColors}
            markerColorField={markerColorField}
            markerColorFieldIndex={markerColorFieldIndex}
            // mapRef={mapRef}
            clusterGroupRef={clusterGroupRef}
            bounds={bounds}
          />
        );
      });

      // reset bounds
      if (mapRef.current) {
        // const mapInst = mapRef.current.leafletElement;
        const mapInst = mapRef.current;

        const box = bboxPois(filtered);

        if (box && box.length === 4) {
          if (JSON.stringify(box) !== JSON.stringify(lastBox.current)) {
            lastBox.current = box;

            const b0 = box[0];
            const b1 = box[1];
            const b2 = box[2];
            const b3 = box[3];

            setTimeout(() => {
              mapInst.fitBounds(
                [
                  [b1, b0],
                  [b3, b2]
                ],
                { animate: false }
              );
            }, 100);
          }
        }
      }

      setFilteredFeatures(pois);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mapPois.poisFiltered,
    mapPois.reachThreshold,
    mapPois.heatmapOn,
    isSelected,
    mapMarkerDisplayConfig,
    bounds,
    clusterGroupRef
  ]);

  // TODO: can we separate filtered from selected?
  // this way we may re-paint only the selected list when a selection occurs and avoid repaiting
  // the entire map (using useEffect above)
  // LOAD Selected POIs
  /*
  useEffect(() => {
    const selectedPois = mapPois.selectedPois || [];

    // DO NOT PAINT unitl display config are loaded
    if (mapMarkerDisplayConfig) {
      console.log('useEffect mapPois selectedPois', selectedPois);

      const pois = selectedPois.map((pid, i) => {
        const p = filtered.find(e => e._id === pid);
        if (!p) return null;
        const isCurrentSelected = isSelected(p);

        return (
          <MarkerDetails
            zIndex={10}
            key={`selected_${p._id}`}
            center={[p.latitude, p.longitude]}
            fillColor={
              heatmapOn && p.heatmapSegment !== undefined
                ? heatmapColors[(p.heatmapSegment - 5) * -1] // inverted
                : poisColors.regular
            }
            radius={5}
            color={poisColors.selected}
            weight={isCurrentSelected ? 3 : 0}
            opacity={1}
            fillOpacity={1}
            p={p}
            displayConfigs={mapMarkerDisplayConfig}
            heatmapOn={heatmapOn}
            isCurrentSelected={isCurrentSelected}
            onSelect={onSelect}
          />
        );
      });

      setSelectedFeatures(pois);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPois.selectedPois, mapMarkerDisplayConfig]);
  */

  // LOAD Audience POIs
  useEffect(() => {
    const audiencePois = mapPois.audiencePois || [];

    const pois = audiencePois.map((p, i) => (
      <FeatureGroup key={p.value} zIndex={0}>
        <Marker position={[p.latitude, p.longitude]} icon={options.redIcon}>
          <Tooltip pane={'tooltipPane'}>{p.label}</Tooltip>
        </Marker>

        <Circle
          center={[p.latitude, p.longitude]}
          fillColor={poisColors.buffer}
          radius={radius}
          color={poisColors.buffer}
          weight={1}
          opacity={1}
          fillOpacity={0.25}
        />
      </FeatureGroup>
    ));

    setAudienceFeatures(pois);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPois.audiencePois, mapPois.radius]);

  return (
    <MapContainer
      className="markercluster-map-full"
      center={defaultProps.center}
      zoom={defaultProps.zoom}
      minZoom={2}
      // zoomAnimation={false}
      // markerZoomAnimation={false}
      // fadeAnimation={false}
      whenCreated={mapInstance => {
        mapRef.current = mapInstance;
        setBounds(mapInstance.getBounds());
      }}
      // preferCanvas={true}
      // ref={mapRef}
      // style={{ cursor }}
      // doubleClickZoom={false}
    >
      <MapEventsLogger setBounds={setBounds} />

      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      <MapControls
        filtered={filtered}
        onSelect={onSelect}
        isSelected={isSelected}
        clustering={clustering}
        setClustering={setClustering}
      />
      <FeatureGroup>
        {audienceFeatures}

        {clustering ? (
          <MarkerClusterGroup
            ref={clusterGroupRef}
            removeOutsideVisibleBounds={true}
            animate={false}
            // chunkedLoading={true}
            showCoverageOnHover={false}
            spiderfyDistanceMultiplier={2}
            spiderfyOnMaxZoom={false}
            zoomToBoundsOnClick={true}
            iconCreateFunction={createClusterCustomIcon}
            disableClusteringAtZoom={15}
          >
            {filteredFeatures}
            {/* {selectedFeatures} */}
          </MarkerClusterGroup>
        ) : (
          filteredFeatures
        )}
      </FeatureGroup>
    </MapContainer>
  );
}
function MapEventsLogger({ setBounds }) {
  const mapEvents = useMapEvents({
    moveend: () => {
      //     mapRef.current && console.log(mapRef.current.getZoom());
      setBounds(mapEvents.getBounds());
    }
  });
  return null;
}

const MarkerDetails = props => {
  // this is a helper to display a marker as selected as soon as is changes it status, instead of waiting for a repaint
  const {
    p,
    displayConfigs = {},
    heatmapOn,
    isCurrentSelected,
    onSelect,
    poisColors,
    heatmapColors,
    markerColorField,
    markerColorFieldIndex,
    //mapRef
    clusterGroupRef,
    bounds
  } = props;

  const [content, setContent] = useState(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (!contentRef.current) return;

    const markerRef = contentRef.current.ref?.current;

    const outOfBounds = !bounds || !bounds.contains([p.latitude, p.longitude]);
    const clustered =
      !clusterGroupRef.current ||
      (markerRef != null && !clusterGroupRef.current.hasLayer(markerRef));

    if (outOfBounds || clustered) {
      setContent(null);
    } else if (!content && contentRef.current) {
      setContent(contentRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  const displayTemplatedDetail = (baseData, template) => {
    if (!template) return null;
    const regex = /\$\{([0-9]|[aA-zZ])*\}/g;
    const variables = template.match(regex);
    if (!variables) return null;

    for (const variable of variables) {
      const variableName = variable.substring(2, variable.length - 1);
      const value = baseData[variableName];
      template = template.replace(variable, value);
    }

    return template;
  };

  const calculateColor = () => {
    if (isCurrentSelected) return poisColors.selected;

    return heatmapOn && p.heatmapSegment !== undefined
      ? heatmapColors[(p.heatmapSegment - 5) * -1] // inverted
      : poisColors.regular;
  };

  const calculateIcon = () => {
    const color = calculateColor();

    const iconCreator = markerColorField
      ? poiIconCreators[markerColorFieldIndex.indexOf(p[markerColorField]) % poiIconCreators.length]
      : poiIconCreators[0];

    return iconCreator(color);
  };

  const basePopulationData = formatBaseProjectionData(p.metrics);
  useEffect(
    () => {
      const c = (
        <Marker position={[p.latitude, p.longitude]} icon={calculateIcon()} key={p._id}>
          <Tooltip pane={'tooltipPane'}>
            {/* {markerColorField ? `- ${p[markerColorField]}` : null} */}
            {displayTemplatedDetail(
              p,
              // eslint-disable-next-line no-template-curly-in-string
              ['${name}']
                .concat(
                  Array.isArray(displayConfigs.genericFields)
                    ? displayConfigs.genericFields
                        .filter(genericField => genericField.tooltip)

                        .map(genericField => genericField.display)
                    : []
                )
                .join(' / ')
            )}
          </Tooltip>
          <Popup closeButton={false} closeOnEscapeKey keepInView pane={'tooltipPane'}>
            <div style={{ cursor: 'default' }}>
              <div className="d-flex d-flex justify-content-between w-100 align-items-center">
                <h4 className="mb-0">{i18n.t('planner_poi_details_title')}</h4>
                <MdInsights
                  size={20}
                  title={i18n.t('smart_insights')}
                  onClick={() => openNewWindow(`/dashboard/${p._id}`, ['type'])}
                  style={{ cursor: 'pointer' }}
                />
              </div>

              <hr />
              {/* TODO: Fix style, BRs could be replaced but most important. Bubble is too slim if the map is not shown */}
              {displayConfigs.showStreetView && (
                <>
                  <GoogleMapComponent
                    mapContainerStyle={{ height: `28vh`, width: `100%` }}
                    pois={[]}
                    center={{ lat: p.latitude, lng: p.longitude }}
                    zoom={18}
                    streetView
                  />
                  <br />
                  <br />
                </>
              )}

              {Array.isArray(displayConfigs.genericFields) &&
                displayConfigs.genericFields.map(genericField => {
                  return (
                    <Row
                      key={p._id + genericField.display}
                      className="text-uppercase d-flex align-items-center mb-2"
                    >
                      <Col sm={1}>
                        {React.createElement(reactIcons[genericField.icon], { size: 25 })}
                      </Col>
                      <Col
                        sm={10}
                        className={[genericField.bold ? 'font-weight-bold' : '', 'text-break'].join(
                          ' '
                        )}
                      >
                        {displayTemplatedDetail(p, genericField.display)}
                      </Col>
                    </Row>
                  );
                })}

              {displayConfigs.showUniqueVisitors && (
                <>
                  {/* <Row className="text-uppercase d-flex align-items-center mb-2 ">
                    <Col sm={1}>{React.createElement(reactIcons.MdSensors, { size: 25 })}</Col>
                    <Col sm={10}>
                      <span className="font-weight-bold">{i18n.t('results_reach')}: </span>
                      {numeral(p.metrics.unique).format('0,0')} {i18n.t('unique_visitors_title')}
                    </Col>
                  </Row> */}
                  <Row className="text-uppercase d-flex align-items-center mb-2 ">
                    <Col sm={1}>{React.createElement(reactIcons.MdSensors, { size: 25 })}</Col>
                    <Col sm={9}>
                      <span className="font-weight-bold">{i18n.t('results_reach')}: </span>
                      {numeral(p.metrics.unique).format('0,0')} {i18n.t('unique_visitors_title')}
                    </Col>
                    <Col sm={1}>
                      <PopoverProjectionHelp
                        title={i18n.t('results_reach')}
                        text={i18n.t('unique_visitors_projected_tooltip')}
                        style={{ paddingRight: '0.5rem' }}
                        projectedData={
                          basePopulationData
                            ? [
                                {
                                  label: i18n.t('results_reach'),
                                  value: numeral(p.metrics.rawUnique).format('0,0')
                                },

                                ...basePopulationData
                              ]
                            : []
                        }
                        id={`markerDetails_${p._id}`}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {displayConfigs.showReach && heatmapOn && p.metrics.uniqueReach ? (
                <Row
                  className="text-uppercase d-flex align-items-center mb-2"
                  style={{ color: colors[1] }}
                >
                  <Col sm={1}>{React.createElement(reactIcons.MdMotionPhotosOn, { size: 25 })}</Col>
                  <Col sm={10}>
                    <span className="font-weight-bold">{i18n.t('planner_metrics_reach')}: </span>
                    {numeral(p.metrics.uniqueReach).format('0,0')} (
                    {numeral(p.metrics.reachPercentage).format('%0.00')})
                  </Col>
                </Row>
              ) : null}

              <div className="d-flex flex-column mt-4">
                <Button
                  color={isCurrentSelected ? 'primary' : 'secondary'}
                  onClick={e => {
                    // closePopups();
                    e.currentTarget.blur();
                    // give UI some time to show selected status in the current popup
                    setTimeout(() => {
                      onSelect([p]);
                    }, 1);
                  }}
                >
                  {isCurrentSelected ? (
                    <MdDoneOutline className="mr-3" />
                  ) : (
                    <MdAddShoppingCart className="mr-3" />
                  )}
                  {isCurrentSelected
                    ? i18n.t('planner_poi_details_remove_from_cart')
                    : i18n.t('planner_poi_details_add_to_cart')}
                </Button>
              </div>
            </div>
          </Popup>
        </Marker>
      );
      contentRef.current = c;
      setContent(c);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCurrentSelected, p.metrics.uniqueReach, markerColorField]
  );

  return content;
};

const MapControls = props => {
  const { filtered, onSelect, isSelected, clustering, setClustering } = props;

  const [mode, setMode] = useState(GRAB_MODE);

  const freedrawRef = useRef(null);

  let currentPolygon = null;
  let currentReachQty = 20;

  // Listen for any markers added, removed or edited, and then output the lat lng boundaries.
  const handleOnMarkers = event => {
    currentPolygon = event.latLngs;
  };

  const clearSelection = () => {
    // freedrawRef.current !== null && freedrawRef.current.leafletElement.clear();
    freedrawRef.current !== null && freedrawRef.current.clear();
  };

  const confirmSelection = () => {
    if (currentPolygon && currentPolygon.length > 0) {
      const area = [currentPolygon[0].map(a => [a.lng, a.lat])];
      const f = findWithinArea(filtered, area);
      onSelect(f, true);
    }
    setMode(GRAB_MODE);
  };

  const confirmReachSelection = () => {
    const sorted = filtered
      .sort((a, b) => a.metrics.uniqueReach > b.metrics.uniqueReach)
      .slice(0, currentReachQty);
    onSelect(sorted, true);
    setMode(GRAB_MODE);
  };

  const confirmImportedSelection = importedRows => {
    const f = filtered.filter(item1 => {
      return importedRows.some(item2 => {
        return (
          // item1.name === item2.name &&
          item1.latitude.toString() === item2.latitude &&
          item1.longitude.toString() === item2.longitude
        );
      });
    });
    if (f.length === 0) {
      toastr.error(i18n.t('planner_csv_selection_match_error') + `${f.length}`);
      return;
    }
    if (f.length !== importedRows.length) {
      toastr.warning(
        i18n.t('planner_csv_selection_match_error') + `${f.length}/${importedRows.length}`
      );
    }
    onSelect(f, true);
    setMode(GRAB_MODE);
  };

  const requestUnselectAll = () => {
    setMode(GRAB_MODE);
    PopupConfirm({
      onConfirm: () => {
        const revert = filtered.filter(f => isSelected(f));
        onSelect(revert, false);
      }
    });
  };

  // Reset selection on Change Mode
  useEffect(() => {
    if (mode === GRAB_MODE) {
      clearSelection();
    }
  }, [mode]);

  let importedRows = [];

  return (
    <>
      <Freedraw
        // eslint-disable-next-line no-bitwise
        mode={mode === AREA_MODE ? ALL : ALL ^ CREATE}
        // onMarkers={handleOnMarkers}
        maximumPolygons={1}
        // onModeChange={() => {}}
        eventHandlers={{
          markers: handleOnMarkers
          // mode: event => console.log('mode changed', event)
        }}
        ref={freedrawRef}
      />

      <UseControl position="topright">
        <div className="align-items-end d-flex flex-column">
          <div>
            <Button
              className="tracemap-control tracemap-control-top bg-white"
              color="tracemap"
              disabled={mode === GRAB_MODE}
              // eslint-disable-next-line no-bitwise
              onClick={() => setMode(GRAB_MODE)}
            >
              <FaMousePointer size="16" color="#000" />
            </Button>
            <Button
              className="tracemap-control tracemap-control-bottom bg-white"
              color="tracemap"
              disabled={mode === AREA_MODE}
              onClick={() => setMode(AREA_MODE)}
            >
              <img alt="" className="" src={lasso} height="16" width="16" color="#000" />
            </Button>
            <Button
              className="tracemap-control tracemap-control-bottom bg-white"
              color="tracemap"
              disabled={mode === REACH_MODE}
              onClick={() => setMode(REACH_MODE)}
            >
              <MdMotionPhotosAuto size="16" color="#000" />
            </Button>
            <Button
              className="tracemap-control tracemap-control-bottom bg-white"
              color="tracemap"
              disabled={mode === IMPORT_MODE}
              onClick={() => setMode(IMPORT_MODE)}
            >
              <MdFileUpload size="16" color="#000" />
            </Button>

            <Button
              className="tracemap-control tracemap-control-bottom bg-white"
              color="tracemap"
              active={clustering}
              onClick={() => {
                console.log(clustering);
                setClustering(!clustering);
              }}
            >
              {clustering ? (
                <FaObjectUngroup size="16" color="#000" />
              ) : (
                <FaObjectGroup size="16" color="#000" />
              )}
            </Button>

            <Button
              className="tracemap-control tracemap-control-bottom bg-white"
              color="tracemap"
              onClick={requestUnselectAll}
            >
              <MdOutlineDeleteOutline size="16" color="#000" />
            </Button>
          </div>

          {/* AREA MODE */}
          <div className="align-items-start d-flex">
            {mode === AREA_MODE && (
              <div className="bg-white">
                <h5 className="btn btn-light-gray font-weight-bold mb-0 w-100">
                  {i18n.t('planner_area_selection_title')}
                </h5>
                <div className="p-2">
                  <Button
                    className="w-100 d-flex justify-content-center align-items-center btn-sm"
                    color="success"
                    onClick={confirmSelection}
                  >
                    <MdCheck size={20} className="mr-2" />
                    {i18n.t('planner_confirm_selection')}
                  </Button>
                  <Button
                    className="w-100 mt-1 d-flex justify-content-center align-items-center btn-sm"
                    color="secondary"
                    onClick={clearSelection}
                  >
                    <MdDeleteOutline size={20} className="mr-2" />
                    {i18n.t('planner_reset_selection')}
                  </Button>
                </div>
              </div>
            )}
          </div>
          {/* REACH MODE */}
          <div className="align-items-start d-flex">
            {mode === REACH_MODE && (
              <div className="bg-white">
                <h5 className="btn btn-light-gray font-weight-bold mb-0 w-100">
                  {i18n.t('planner_reach_selection_title')}
                </h5>
                <div className="p-2">
                  <span>{i18n.t('planner_reach_selection_text_select')}</span>
                  <div className="align-items-end d-flex">
                    <Input
                      type="number"
                      defaultValue={currentReachQty}
                      className="w-50 mr-2"
                      onChange={e => {
                        currentReachQty = e.target.value;
                      }}
                    />
                    <span>{i18n.t('planner_reach_selection_text_pois')} </span>
                  </div>
                  <span>{i18n.t('planner_reach_selection_text_more_reach')}</span>
                  <Button
                    className="w-100 d-flex justify-content-center align-items-center btn-sm mt-3"
                    color="success"
                    onClick={confirmReachSelection}
                  >
                    <MdCheck size={20} className="mr-2" />
                    {i18n.t('planner_confirm_selection')}
                  </Button>
                  <Button
                    className="w-100 mt-1 d-flex justify-content-center align-items-center btn-sm"
                    color="secondary"
                    onClick={() => setMode(GRAB_MODE)}
                  >
                    <MdOutlineCancel size={20} className="mr-2" />
                    {i18n.t('planner_cancel_selection')}
                  </Button>
                </div>
              </div>
            )}
          </div>
          {/* IMPORT MODE */}
          {/* <div className="align-items-start d-flex max-w-lg min-w-3xl"> */}
          {/* {mode === IMPORT_MODE && ( */}

          <Modal isOpen={mode === IMPORT_MODE} className="max-w-lg min-w-3xl">
            <ModalHeader toggle={() => setMode(GRAB_MODE)}>
              {i18n.t('planner_csv_selection_title')}
            </ModalHeader>
            <ModalBody>
              <div className="bg-white w-100">
                <div className="p-2">
                  <Importer
                    locale={getCustomLocale()}
                    assumeNoHeaders={false}
                    restartable={true}
                    onStart={() => (importedRows = [])}
                    processChunk={async (rows, { startIndex }) => {
                      // required, may be called several times
                      // receives a list of parsed objects based on defined fields and user column mapping;
                      // (if this callback returns a promise, the widget will wait for it before parsing more data)
                      //for (const row of rows) {
                      //setImportResults(oldImportResults => [...oldImportResults, row]);
                      //}
                      importedRows = [...importedRows, ...rows];
                      // setFieldTouched('import', true);
                    }}
                    onComplete={() => {
                      confirmImportedSelection(importedRows);
                    }}
                  >
                    {/* <ImporterField name="name" label="name" /> */}
                    <ImporterField name="latitude" label="latitude" />
                    <ImporterField name="longitude" label="longitude" />
                  </Importer>

                  <Button
                    className="w-100 mt-1 d-flex justify-content-center align-items-center btn-sm"
                    color="secondary"
                    onClick={() => setMode(GRAB_MODE)}
                  >
                    <MdOutlineCancel size={20} className="mr-2" />
                    {i18n.t('planner_cancel_selection')}
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          {/* )} */}
          {/* </div> */}
        </div>
      </UseControl>
    </>
  );
};
