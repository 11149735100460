import React from 'react';

export default function Logo(props) {
  console.log(window._env_.REACT_APP_PRODUCT === 'true');
  return (
    <div className="data_col logo_sign mt-3 pt-5 mb-4">
      {window._env_.REACT_APP_PRODUCT === 'true' ? (
        <img
          className="mb-4"
          src={`${window._env_.REACT_APP_BASE_BRAND_URL}/${window._env_.REACT_APP_BRAND}/product-logo-login.png`}
          alt={window._env_.REACT_APP_PRODUCT_TITLE}
        />
      ) : null}
      <img
        className="mb-4"
        src={`${window._env_.REACT_APP_BASE_BRAND_URL}/${window._env_.REACT_APP_BRAND}/logo-login.png`}
        alt={window._env_.REACT_APP_TITLE}
      />
    </div>
  );
}
