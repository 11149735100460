import React from 'react';
import i18n from 'i18n-js';
import { Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { MdArticle } from 'react-icons/md';

const state = window._env_.REACT_APP_TERMS_STATE || 'Florida';
const companyName = window._env_.REACT_APP_TERMS_COMPANY_NAME || 'Mofiler.IO Corp.';

export default function TermsModal({ isOpen, setOpen, onAccept, onDoNotAccept }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setOpen(false)}
      className="modalFadeInScale"
      backdrop={'static'}
      centered={true}
    >
      <ModalHeader toggle={() => setOpen(false)} style={{ zIndex: 11 }}>
        <div className="d-flex align-items-center">
          <MdArticle size="24" className="icon-dashboard m-1" />
          {i18n.t('terms_title')}
        </div>
      </ModalHeader>
      <ModalBody className="modal-wizard">
        <Card className="shadow-none">
          <CardBody className="p-0">
            <span
              className="w-100"
              dangerouslySetInnerHTML={{
                __html: i18n.t('terms_and_conditions', { state, companyName })
              }}
            />
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default " onClick={onDoNotAccept}>
          {i18n.t('terms_do_not_accept')}
        </button>
        <button className="btn btn-primary ml-2" onClick={onAccept}>
          {i18n.t('terms_accept')}
        </button>
      </ModalFooter>
    </Modal>
  );
}
