import React, { useRef } from 'react';

import { Route, Redirect } from 'react-router-dom';

import AppLayout from './modules/containers/layouts/AppLayout';
import CrudLayout from './modules/containers/layouts/CrudLayout';
import SettingsLayout from './modules/containers/layouts/SettingsLayout';
import PublicLayout from './modules/containers/layouts/PublicLayout';
import OnboardingLayout from './modules/containers/layouts/OnboardingLayout';
import { getQueryVariable } from './routeUtils';
import { now } from 'moment';

const defaultPoiType = 'pdv';

export function AppRoute({
  component: Component,
  user,
  organization,
  navMini = false,
  fullMonth,
  useAggregation,
  filterSinglePOI = false,
  filterConditions = null,
  requiresOrganization = true,
  allowUnpaidOrganization = false,
  ...rest
}) {
  const poiType = getQueryVariable('type') || defaultPoiType;
  const scrollableRef = useRef(null);

  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          );
        }
        const { validOrganization, redirect } = validateOrganization(
          requiresOrganization,
          allowUnpaidOrganization,
          organization
        );
        if (!validOrganization) {
          return (
            <Redirect
              to={{
                pathname: redirect,
                state: { from: props.location }
              }}
            />
          );
        }

        return (
          <AppLayout
            user={user}
            organization={organization}
            navMini={navMini}
            fullMonth={fullMonth}
            useAggregation={useAggregation}
            filterSinglePOI={filterSinglePOI}
            filterConditions={filterConditions}
            poiType={poiType}
            ref={scrollableRef}
          >
            <Component
              {...props}
              user={user}
              key={props.location.search}
              poiType={poiType}
              organization={organization}
              scrollableRef={scrollableRef}
            />
          </AppLayout>
        );
      }}
    />
  );
}

export function CRUDRoute({
  component: Component,
  user,
  organization,
  navMini = false,
  requiresOrganization = true,
  allowUnpaidOrganization = false,
  fullMonth,
  withNav = true,
  ...rest
}) {
  const poiType = getQueryVariable('type') || defaultPoiType;
  const scrollableRef = useRef(null);

  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          );
        }

        const { validOrganization, redirect } = validateOrganization(
          requiresOrganization,
          allowUnpaidOrganization,
          organization
        );

        if (!validOrganization) {
          return (
            <Redirect
              to={{
                pathname: redirect,
                state: { from: props.location }
              }}
            />
          );
        }

        return (
          <CrudLayout
            user={user}
            organization={organization}
            navMini={navMini}
            poiType={poiType}
            ref={scrollableRef}
            withNav={withNav}
            {...rest}
          >
            <Component
              {...props}
              user={user}
              fullMonth={fullMonth}
              key={props.location.search}
              poiType={poiType}
              organization={organization}
              scrollableRef={scrollableRef}
              withNav={withNav}
            />
          </CrudLayout>
        );
      }}
    />
  );
}

export function SettingsRoute({
  component: Component,
  user,
  organization,
  navMini = false,
  requiresOrganization = true,
  allowUnpaidOrganization = false,
  fullMonth,
  withNav = true,
  ...rest
}) {
  const poiType = getQueryVariable('type') || defaultPoiType;
  const scrollableRef = useRef(null);

  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          );
        }

        const { validOrganization, redirect } = validateOrganization(
          requiresOrganization,
          allowUnpaidOrganization,
          organization
        );

        if (!validOrganization) {
          return (
            <Redirect
              to={{
                pathname: redirect,
                state: { from: props.location }
              }}
            />
          );
        }

        return (
          <SettingsLayout
            user={user}
            organization={organization}
            navMini={navMini}
            poiType={poiType}
            ref={scrollableRef}
            withNav={withNav}
            {...rest}
          >
            <Component
              {...props}
              user={user}
              fullMonth={fullMonth}
              key={props.location.search}
              poiType={poiType}
              organization={organization}
              scrollableRef={scrollableRef}
              withNav={withNav}
            />
          </SettingsLayout>
        );
      }}
    />
  );
}

function validateOrganization(requiresOrganization, allowUnpaidOrganization, organization) {
  if (window._env_.REACT_APP_ORGANIZATIONS_AWARE !== 'true') {
    return { validOrganization: true, redirect: null };
  }

  if (!requiresOrganization) {
    return { validOrganization: true, redirect: null };
  }

  if (!organization) {
    console.log("The doesn't have an org and this functionality needs it");
    return { validOrganization: false, redirect: '/create-organization' };
  }

  if (
    (!organization.status || organization.status === 'NOT_PAYMENT_INFO') &&
    !allowUnpaidOrganization
  ) {
    console.log('Organization without payment information');
    return { validOrganization: false, redirect: '/checkout' };
  }

  if (
    organization.status === 'UNPAID' ||
    (organization.status === 'ACTIVE' && organization.expiresAt < ~~(now() / 1000))
  ) {
    if (!allowUnpaidOrganization) {
      // This redirect is useles since there is a subscription already (unless it was cancelled by the user). We should
      // try to charge the organization. For that, the stripe customer portal would be handy (reactivate card, etc. Till we have that, this scenario shouls be
      // managed manually and, instead of redirecting to checkout, go to an error CONTACT SUPPORT page)
      return { validOrganization: false, redirect: '/reactivate' };
    }
  }

  return { validOrganization: true, redirect: null };
}

export function PublicRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          return (
            <PublicLayout>
              <Component {...props} />
            </PublicLayout>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
}

export function OnboardingRoute({ component: Component, user, loginRequired = false, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (loginRequired && !user) {
          return (
            <Redirect
              to={{
                pathname: '/signup',
                state: { from: props.location }
              }}
            />
          );
        }
        return (
          <OnboardingLayout user={user}>
            <Component {...props} user={user} />
          </OnboardingLayout>
        );
      }}
    />
  );
}
