import React from 'react';

import { useMappedState } from 'redux-react-hook';

import './dashboard.scss';

import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';

import { useDashboardData, useFullDemosObj } from '../../hooks/metrics/esdata';
import { ExportExcelPanel } from '../../components/utils/ExportExcel';
import { filterSelector } from '../../redux/ducks/filters';
import { ExportAudiencePanel } from '../../components/utils/ExportAudience';
import i18n from 'i18n-js';

export default function Dashboard(props) {
  const { poiType, scrollableRef, organization } = props;
  const { filter } = useMappedState(filterSelector);
  const typeFilter = filter[poiType];

  const {
    responseVisits,
    responseDemos,
    responseTech,
    responseAppBundles,
    responseHeatmap,
    responseFullDemos,
    projectionData
  } = useDashboardData(typeFilter, {
    qVisits: true,
    qDemos: true,
    qTech: true,
    qAppBundles: true,
    qHeatmap: true,
    qFullDemos: true
  });

  const fullDemosObj = useFullDemosObj(responseFullDemos, responseVisits);

  const isSingleView = typeFilter.storeKey !== null;
  const groupCount = isSingleView
    ? 1
    : typeFilter.pois && typeFilter.pois.length > 0
    ? typeFilter.pois[0].count
    : -1;

  const downloadThreshold =
    window._env_.REACT_APP_DOWNLOAD_THRESHOLD ??
    parseInt(window._env_.REACT_APP_DOWNLOAD_THRESHOLD);
  const downloadEnabled = !downloadThreshold || (groupCount > 0 && groupCount <= downloadThreshold);

  const filterComponent = (
    <>
      <ExportAudiencePanel {...{ fullDemosObj, filter: typeFilter, organization }} />
      <div className="mr-1 ml-1" />
      <ExportExcelPanel
        {...{
          responseFullDemos,
          responseVisits,
          filter: typeFilter,
          status: {
            disabled: !downloadEnabled,
            disabledReason: i18n.t('download_disabled_reason', {
              download_threshold: downloadThreshold
            })
          }
        }}
      />
    </>
  );

  return (
    <div className="view">
      <React.StrictMode>
        <ViewHeader {...{ filterComponent }} />
        <ViewContent
          {...{
            filter: typeFilter,
            responseVisits,
            responseDemos,
            responseTech,
            responseAppBundles,
            responseHeatmap,
            fullDemosObj,
            projectionData,
            type: props.user.type,
            poiType,
            scrollableRef
          }}
        />
      </React.StrictMode>
    </div>
  );
}
