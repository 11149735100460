import i18n from 'i18n-js';
import moment from 'moment';
import numeral from 'numeral';
import React, { useState } from 'react';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';

import { MdFileDownload } from 'react-icons/md/';
import { callSyncAPIv2DataLoader } from '../../hooks/api';
import LoadingModal from './LoadingModal';
import PopoverHelp from './PopoverHelp';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const serverSideDownload = async (filter, setDownloading) => {
  const type = {
    qVisits: true,
    qDemos: false,
    qTech: false,
    qAppBundles: false,
    qHeatmap: false,
    qRadar: false,
    qFullDemos: false,
    qFullDemosPDVs: true,
    qPerformance: false,
    qFullDemosPrev: false,
    qRanking: false,
    qJourney: false
  };

  try {
    setDownloading(true);
    filter.brand = window._env_.REACT_APP_BRAND;
    callSyncAPIv2DataLoader(
      'exports',
      'dashboardData',
      {
        filter,
        type
      },
      { responseHolder: 'response', cache: false },
      response => {
        setDownloading(false);
        if (response.error) {
          throw response.error;
        }

        window.location.href = response.data.value.response;
      },
      true
    );
  } catch (error) {
    console.error('Error downloading Excel:', error);
  }
};
export function ExportExcelPanel({ responseFullDemos, responseVisits, filter, status }) {
  let fullDemos = null;
  let prevCols = [];

  const [isDownloading, setDownloading] = useState(false);

  if (filter.agg === 'g') {
    fullDemos = responseFullDemos.hasValue('metricsFullDemos')
      ? responseFullDemos.data.value.metricsFullDemos
      : null;
  } else {
    prevCols = [{ value: i18n.t('poi_name') }];

    return (
      <div className="d-flex">
        <LoadingModal isOpen={isDownloading} text={i18n.t('download_data_generating_file')} />

        <Button
          color="danger"
          size="sm"
          onClick={() => {
            serverSideDownload(filter, setDownloading);
          }}
          disabled={isDownloading || status.disabled}
        >
          <MdFileDownload
            size="16"
            className="icon-dashboard m-1"
            color="#fff"
            style={{ cursor: 'pointer' }}
          />
          {i18n.t('download_data')}
        </Button>

        {status.disabled && (
          <h6 className="subtitle text-white pt-2">
            <PopoverHelp
              title={i18n.t('download_disabled')}
              text={status.disabledReason}
              style={{ paddingLeft: '10px' }}
            />
          </h6>
        )}
      </div>
    );
  }

  if (!fullDemos || fullDemos.length === 0 || !filter.pois || filter.pois.length === 0) return null;

  let period = null;
  if (window._env_.REACT_APP_TYPE_PERIOD === 'fixed') period = filter.period.label;
  else if (window._env_.REACT_APP_TYPE_PERIOD === 'month')
    period = moment(filter.startDate).format('MMMM YY');
  else if (window._env_.REACT_APP_TYPE_PERIOD === 'week')
    period = `${moment(filter.startDate).format('YYYY-MM-DD')} / ${moment(filter.endDate).format(
      'YYYY-MM-DD'
    )}`;

  let labelWeek = '';
  if (window._env_.REACT_APP_TYPE_PERIOD !== 'fixed') labelWeek = i18n.t('week');
  else labelWeek = i18n.t('fraction');

  const isWeekly = window._env_.REACT_APP_TYPE_PERIOD === 'week';

  const w = { width: { wpx: 140 } };

  const multiDataSet = [
    {
      columns: [
        { value: i18n.t('period_label'), ...w },
        { value: i18n.t('segment_label'), ...w }
      ],
      data: [[{ value: period }, { value: filter.pois[0].label }]]
    },
    {
      ySteps: 1,
      columns: prevCols
        .concat([
          { value: i18n.t('age_range') },
          { value: i18n.t('socioeconomic_level') },
          { value: i18n.t('gender') }
        ])
        .concat(
          !isWeekly
            ? [
                { value: i18n.t('unique_visitors_title') },
                { value: `${i18n.t('unique_visitors_title')} ${labelWeek} 1` },
                { value: `${i18n.t('unique_visitors_title')} ${labelWeek} 2` },
                { value: `${i18n.t('unique_visitors_title')} ${labelWeek} 3` },
                { value: `${i18n.t('unique_visitors_title')} ${labelWeek} 4` }
              ]
            : [{ value: i18n.t('unique_visitors_title') }]
        )
        .concat(
          !isWeekly
            ? [
                { value: i18n.t('visits_title') },
                { value: `${i18n.t('visits_title')} ${labelWeek} 1` },
                { value: `${i18n.t('visits_title')} ${labelWeek} 2` },
                { value: `${i18n.t('visits_title')} ${labelWeek} 3` },
                { value: `${i18n.t('visits_title')} ${labelWeek} 4` }
              ]
            : [{ value: i18n.t('visits_title') }]
        )
        .concat([{ value: i18n.t('frequency_title') }])
        .concat(
          !isWeekly
            ? [
                { value: `${i18n.t('dwell_time_title')} (mins)` },
                { value: `${i18n.t('dwell_time_title')} (mins) ${labelWeek} 1` },
                { value: `${i18n.t('dwell_time_title')} (mins) ${labelWeek} 2` },
                { value: `${i18n.t('dwell_time_title')} (mins) ${labelWeek} 3` },
                { value: `${i18n.t('dwell_time_title')} (mins) ${labelWeek} 4` }
              ]
            : [{ value: `${i18n.t('dwell_time_title')} (mins)` }]
        )
        .concat(
          !isWeekly
            ? [
                { value: i18n.t('new_visitors_title') },
                { value: `${i18n.t('new_visitors_title')} ${labelWeek} 1` },
                { value: `${i18n.t('new_visitors_title')} ${labelWeek} 2` },
                { value: `${i18n.t('new_visitors_title')} ${labelWeek} 3` },
                { value: `${i18n.t('new_visitors_title')} ${labelWeek} 4` }
              ]
            : [{ value: i18n.t('new_visitors_title') }]
        )
        .concat(
          !isWeekly
            ? [
                { value: i18n.t('repeat_visitors_title') },
                { value: `${i18n.t('repeat_visitors_title')} ${labelWeek} 1` },
                { value: `${i18n.t('repeat_visitors_title')} ${labelWeek} 2` },
                { value: `${i18n.t('repeat_visitors_title')} ${labelWeek} 3` },
                { value: `${i18n.t('repeat_visitors_title')} ${labelWeek} 4` }
              ]
            : [{ value: i18n.t('repeat_visitors_title') }]
        )
        .concat([
          { value: `${i18n.t('visits_title')} ${i18n.t('monday')} ${i18n.t('block')} 1` },
          { value: `${i18n.t('visits_title')} ${i18n.t('monday')} ${i18n.t('block')} 2` },
          { value: `${i18n.t('visits_title')} ${i18n.t('monday')} ${i18n.t('block')} 3` },
          { value: `${i18n.t('visits_title')} ${i18n.t('monday')} ${i18n.t('block')} 4` },
          { value: `${i18n.t('visits_title')} ${i18n.t('tuesday')} ${i18n.t('block')} 1` },
          { value: `${i18n.t('visits_title')} ${i18n.t('tuesday')} ${i18n.t('block')} 2` },
          { value: `${i18n.t('visits_title')} ${i18n.t('tuesday')} ${i18n.t('block')} 3` },
          { value: `${i18n.t('visits_title')} ${i18n.t('tuesday')} ${i18n.t('block')} 4` },
          { value: `${i18n.t('visits_title')} ${i18n.t('wednesday')} ${i18n.t('block')} 1` },
          { value: `${i18n.t('visits_title')} ${i18n.t('wednesday')} ${i18n.t('block')} 2` },
          { value: `${i18n.t('visits_title')} ${i18n.t('wednesday')} ${i18n.t('block')} 3` },
          { value: `${i18n.t('visits_title')} ${i18n.t('wednesday')} ${i18n.t('block')} 4` },
          { value: `${i18n.t('visits_title')} ${i18n.t('thursday')} ${i18n.t('block')} 1` },
          { value: `${i18n.t('visits_title')} ${i18n.t('thursday')} ${i18n.t('block')} 2` },
          { value: `${i18n.t('visits_title')} ${i18n.t('thursday')} ${i18n.t('block')} 3` },
          { value: `${i18n.t('visits_title')} ${i18n.t('thursday')} ${i18n.t('block')} 4` },
          { value: `${i18n.t('visits_title')} ${i18n.t('friday')} ${i18n.t('block')} 1` },
          { value: `${i18n.t('visits_title')} ${i18n.t('friday')} ${i18n.t('block')} 2` },
          { value: `${i18n.t('visits_title')} ${i18n.t('friday')} ${i18n.t('block')} 3` },
          { value: `${i18n.t('visits_title')} ${i18n.t('friday')} ${i18n.t('block')} 4` },
          { value: `${i18n.t('visits_title')} ${i18n.t('saturday')} ${i18n.t('block')} 1` },
          { value: `${i18n.t('visits_title')} ${i18n.t('saturday')} ${i18n.t('block')} 2` },
          { value: `${i18n.t('visits_title')} ${i18n.t('saturday')} ${i18n.t('block')} 3` },
          { value: `${i18n.t('visits_title')} ${i18n.t('saturday')} ${i18n.t('block')} 4` },
          { value: `${i18n.t('visits_title')} ${i18n.t('sunday')} ${i18n.t('block')} 1` },
          { value: `${i18n.t('visits_title')} ${i18n.t('sunday')} ${i18n.t('block')} 2` },
          { value: `${i18n.t('visits_title')} ${i18n.t('sunday')} ${i18n.t('block')} 3` },
          { value: `${i18n.t('visits_title')} ${i18n.t('sunday')} ${i18n.t('block')} 4` }
        ]),
      data: fullDemos.map(s => {
        let prevColsValues = [];
        if (prevCols.length > 0) {
          prevColsValues = [{ value: s.name }];
        }
        return prevColsValues
          .concat([
            { value: s.demographics_age_range },
            { value: s.demographics_class },
            { value: s.demographics_gender }
          ])
          .concat(
            !isWeekly
              ? [
                  { value: numeral(s.unique).format('0.00000').toString() },
                  { value: numeral(s.unique_q1).format('0.00000').toString() },
                  { value: numeral(s.unique_q2).format('0.00000').toString() },
                  { value: numeral(s.unique_q3).format('0.00000').toString() },
                  { value: numeral(s.unique_q4).format('0.00000').toString() }
                ]
              : [{ value: numeral(s.unique).format('0.00000').toString() }]
          )
          .concat(
            !isWeekly
              ? [
                  { value: numeral(s.visits).format('0.00000').toString() },
                  { value: numeral(s.visits_q1).format('0.00000').toString() },
                  { value: numeral(s.visits_q2).format('0.00000').toString() },
                  { value: numeral(s.visits_q3).format('0.00000').toString() },
                  { value: numeral(s.visits_q4).format('0.00000').toString() }
                ]
              : [{ value: numeral(s.visits).format('0.00000').toString() }]
          )
          .concat([
            {
              value: (s.unique > 0 ? numeral(s.visits / s.unique).format('0.00000') : 0).toString()
            }
          ])
          .concat(
            !isWeekly
              ? [
                  { value: numeral(s.dwell_time_mins).format('0.00000').toString() },
                  { value: numeral(s.dwell_time_mins_q1).format('0.00000').toString() },
                  { value: numeral(s.dwell_time_mins_q2).format('0.00000').toString() },
                  { value: numeral(s.dwell_time_mins_q3).format('0.00000').toString() },
                  { value: numeral(s.dwell_time_mins_q4).format('0.00000').toString() }
                ]
              : [{ value: numeral(s.dwell_time_mins).format('0.00000').toString() }]
          )
          .concat(
            !isWeekly
              ? [
                  { value: numeral(s.new_visitors).format('0.00000').toString() },
                  { value: numeral(s.new_visitor_q1).format('0.00000').toString() },
                  { value: numeral(s.new_visitor_q2).format('0.00000').toString() },
                  { value: numeral(s.new_visitor_q3).format('0.00000').toString() },
                  { value: numeral(s.new_visitor_q4).format('0.00000').toString() }
                ]
              : [{ value: numeral(s.new_visitors).format('0.00000').toString() }]
          )
          .concat(
            !isWeekly
              ? [
                  { value: numeral(s.repeat_visitors).format('0.00000').toString() },
                  { value: numeral(s.repeat_q1).format('0.00000').toString() },
                  { value: numeral(s.repeat_q2).format('0.00000').toString() },
                  { value: numeral(s.repeat_q3).format('0.00000').toString() },
                  { value: numeral(s.repeat_q4).format('0.00000').toString() }
                ]
              : [{ value: numeral(s.repeat_visitors).format('0.00000').toString() }]
          )
          .concat([
            { value: numeral(s.dow_2_h1).format('0.00000').toString() },
            { value: numeral(s.dow_2_h2).format('0.00000').toString() },
            { value: numeral(s.dow_2_h3).format('0.00000').toString() },
            { value: numeral(s.dow_2_h4).format('0.00000').toString() },
            { value: numeral(s.dow_3_h1).format('0.00000').toString() },
            { value: numeral(s.dow_3_h2).format('0.00000').toString() },
            { value: numeral(s.dow_3_h3).format('0.00000').toString() },
            { value: numeral(s.dow_3_h4).format('0.00000').toString() },
            { value: numeral(s.dow_4_h1).format('0.00000').toString() },
            { value: numeral(s.dow_4_h2).format('0.00000').toString() },
            { value: numeral(s.dow_4_h3).format('0.00000').toString() },
            { value: numeral(s.dow_4_h4).format('0.00000').toString() },
            { value: numeral(s.dow_5_h1).format('0.00000').toString() },
            { value: numeral(s.dow_5_h2).format('0.00000').toString() },
            { value: numeral(s.dow_5_h3).format('0.00000').toString() },
            { value: numeral(s.dow_5_h4).format('0.00000').toString() },
            { value: numeral(s.dow_6_h1).format('0.00000').toString() },
            { value: numeral(s.dow_6_h2).format('0.00000').toString() },
            { value: numeral(s.dow_6_h3).format('0.00000').toString() },
            { value: numeral(s.dow_6_h4).format('0.00000').toString() },
            { value: numeral(s.dow_7_h1).format('0.00000').toString() },
            { value: numeral(s.dow_7_h2).format('0.00000').toString() },
            { value: numeral(s.dow_7_h3).format('0.00000').toString() },
            { value: numeral(s.dow_7_h4).format('0.00000').toString() },
            { value: numeral(s.dow_1_h1).format('0.00000').toString() },
            { value: numeral(s.dow_1_h2).format('0.00000').toString() },
            { value: numeral(s.dow_1_h3).format('0.00000').toString() },
            { value: numeral(s.dow_1_h4).format('0.00000').toString() }
          ]);
      })
    }
  ];

  const sheets = [<ExcelSheet dataSet={multiDataSet} key="1" name={i18n.t('metrics_title')} />];

  if (
    window._env_.REACT_APP_MODULE_REPORTS_DAILY === 'true' &&
    responseVisits.hasValue('metricsDaily')
  ) {
    const dailyData = responseVisits.data.value.metricsDaily;

    const multiDataSetDaily = [
      {
        columns: [
          { value: i18n.t('date_title') },
          { value: i18n.t('unique_visitors_title') },
          { value: i18n.t('visits_title') }
        ],
        data: dailyData.map(s => {
          return [
            { value: s._id.day_part },
            { value: s.unique_visitors.toString() },
            { value: s.visits.toString() }
          ];
        })
      }
    ];

    sheets.push(<ExcelSheet dataSet={multiDataSetDaily} key="2" name={i18n.t('by_day')} />);
  }

  // if (window._env_.REACT_APP_TYPE_PERIOD !== 'fixed')
  //   period = moment(filter.startDate).format('YYYYMM');
  let periodName = null;
  if (window._env_.REACT_APP_TYPE_PERIOD === 'fixed') periodName = filter.period.label;
  else if (window._env_.REACT_APP_TYPE_PERIOD === 'month')
    periodName = moment(filter.startDate).format('YYMMM');
  else if (window._env_.REACT_APP_TYPE_PERIOD === 'week')
    periodName = `${moment(filter.startDate).format('YYYYMMDD')}-${moment(filter.endDate).format(
      'YYYYMMDD'
    )}`;

  const fileName = `${window._env_.REACT_APP_BRAND}-${periodName}-${filter.pois[0].label}`;

  return (
    <>
      {filter.agg !== 'g' && (
        <ExcelFile filename={fileName} hideElement>
          {sheets}
        </ExcelFile>
      )}

      <ExcelFile
        filename={fileName}
        element={
          <Button color="danger" size="sm">
            <MdFileDownload
              size="16"
              className="icon-dashboard m-1"
              style={{ color: '#fff', cursor: 'pointer' }}
            />
            {i18n.t('download_data')}
          </Button>
        }
      >
        {sheets}
      </ExcelFile>
    </>
  );
}

export function ExportExcelAudience({ responseData, allMatch, type, filter }) {
  if (!allMatch) return null;

  const w = { width: { wpx: 140 } };

  // const multiDataSet =
  //   type === 'PDV'
  //     ? [
  //         {
  //           columns: [
  //             { value: i18n.t('admin_pois_code'), ...w },
  //             { value: i18n.t('admin_pois_cp') },
  //             { value: i18n.t('admin_pois_so') },
  //             { value: i18n.t('admin_pois_address') },
  //             { value: i18n.t('admin_pois_local') },
  //             { value: i18n.t('admin_pois_type') },
  //             { value: 'Score' }
  //           ],
  //           data: allMatch.map(s => [
  //             { value: s.code },
  //             { value: s.cp_name },
  //             { value: s.sales_office },
  //             { value: s.street },
  //             { value: s.local },
  //             { value: s.typePDV },
  //             { value: s.score }
  //           ])
  //         }
  //       ]
  //     : [
  //         {
  //           columns: [
  //             { value: i18n.t('admin_pois_code'), ...w },
  //             { value: i18n.t('admin_pois_address') },
  //             { value: i18n.t('admin_pois_media_owner') },
  //             { value: i18n.t('admin_pois_media_owner_reference') },
  //             { value: i18n.t('admin_pois_media_format') },
  //             { value: i18n.t('admin_pois_media_format_type') },
  //             { value: i18n.t('admin_pois_media_frame_type') },
  //             { value: 'Score' }
  //           ],
  //           data: allMatch.map(s => [
  //             { value: s.media_owner_frame_reference },
  //             { value: s.address },
  //             { value: s.media_owner },
  //             { value: s.media_owner_reference },
  //             { value: s.format },
  //             { value: s.format_type },
  //             { value: s.frame_type },
  //             { value: s.score }
  //           ])
  //         }
  //       ];
  const multiDataSet = [
    {
      columns: [
        { value: i18n.t('admin_pois_name'), ...w },
        { value: i18n.t('admin_pois_type') },
        { value: i18n.t('estimated_users') }
      ],
      data: allMatch.map(s => [{ value: s.name }, { value: s.group }, { value: s.metrics.unique }])
    }
  ];

  const fileName = `${window._env_.REACT_APP_BRAND}-${responseData.name}`;

  return (
    <ExcelFile
      filename={fileName}
      element={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Button color="danger" size="sm">
          <MdFileDownload
            size="16"
            className="icon-dashboard m-1"
            style={{ color: '#fff', cursor: 'pointer' }}
          />
          {i18n.t('download_data')}
        </Button>
      }
    >
      <ExcelSheet dataSet={multiDataSet} name={i18n.t('audience_title')} />
    </ExcelFile>
  );
}

export function ExportExcelPlanner({ campaign, exportCampaignFields, type }) {
  // console.log(campaign);
  if (!campaign || !campaign.pois) return null;

  const w = { width: { wpx: 140 } };

  const reach = campaign.reach;

  // SUMMARY
  const multiDataSetSummmary = [
    {
      columns: [{ value: i18n.t('campaign_name') }, { value: campaign.name }],
      data: [
        [
          { value: i18n.t('planner_metrics_trp_population') },
          { value: numeral(reach.trpPopulation).format('0.00') }
        ],
        [
          { value: i18n.t('planner_metrics_trp_target') },
          { value: numeral(reach.trpTarget).format('0.00') }
        ],
        [
          { value: i18n.t('planner_metrics_universe') },
          { value: numeral(reach.universe).format('0,0') }
        ],
        [
          { value: i18n.t('planner_metrics_target') },
          { value: numeral(reach.target).format('0,0') }
        ],
        [
          { value: i18n.t('planner_metrics_selected_pois') },
          { value: numeral(campaign.pois.length).format('0,0') }
        ],
        [
          { value: i18n.t('planner_metrics_reach') },
          { value: numeral(reach.uniqueReach).format('0,0') }
        ],
        [
          { value: i18n.t('planner_metrics_share') },
          { value: numeral(reach.shareReach).format('%0.00') }
        ],
        [
          { value: i18n.t('planner_metrics_frequency') },
          { value: numeral(reach.frequency).format('0.00') }
        ],
        [
          { value: i18n.t('planner_metrics_hits') },
          { value: numeral(reach.hitsReach).format('0,0') }
        ]
      ]
    }
  ];

  const extraCols = exportCampaignFields
    ? exportCampaignFields.map(f => {
        return {
          value: f.label
        };
      })
    : [];

  const multiDataSet = [
    {
      columns: [{ value: i18n.t('admin_pois_name'), ...w }, { value: 'lat' }, { value: 'lon' }]
        .concat(extraCols)
        .concat([
          { value: i18n.t('unique_visitors_title') },
          { value: i18n.t(type === 'ooh' ? 'total_visits_title_ooh' : 'total_visits_title') },
          { value: i18n.t('planner_metrics_reach') }
        ]),
      data: campaign.pois.map(poi => {
        const exportFields = exportCampaignFields
          ? exportCampaignFields.map(f => {
              return { value: poi[f.field] };
            })
          : [];

        return [
          { value: poi.name },
          { value: poi.latitude.toString() },
          { value: poi.longitude.toString() }
        ]
          .concat(exportFields)
          .concat([
            { value: numeral(poi.metrics?.unique).format('0,0') },
            { value: numeral(poi.metrics?.hits).format('0,0') },
            { value: numeral(poi.metrics?.uniqueReach).format('0,0') }
          ]);
      })
    }
  ];

  const fileName = `${window._env_.REACT_APP_BRAND}-${campaign.name}`;

  return (
    <ExcelFile
      filename={fileName}
      element={
        <MdFileDownload
          size="16"
          // className="icon-dashboard m-1"
          className="mr-2"
          style={{ cursor: 'pointer' }}
          title={i18n.t('download_campaign')}
        />
      }
    >
      <ExcelSheet dataSet={multiDataSetSummmary} name={i18n.t('download_campaign_summary')} />
      <ExcelSheet dataSet={multiDataSet} name={i18n.t('download_campaign_pois')} />
    </ExcelFile>
  );
}

module.export = {
  ExportExcelPanel,
  ExportExcelAudience,
  ExportExcelPlanner
};
