export default function PoisOptions({ id, children, innerRef }) {
  return (
    <div
      id={id}
      ref={innerRef}
      className="d-flex justify-end absolute right-0 -top-0.5 z-20 space-x-2 bg-white/80"
    >
      {children}
    </div>
  );
}
