import i18n from 'i18n-js';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import 'react-csv-importer/dist/index.css';
import {
  Button,
  CardTitle,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Geosuggest from 'react-geosuggest';
import LocationPicker from 'react-location-picker';
import { Importer, ImporterField } from 'react-csv-importer';
import { MdAddCircle } from 'react-icons/md';
import { useAPIDataLoader } from '../../hooks/api';
import API from '../../api';
import { getCustomLocale } from './customLocale';

let distanceTask;

export default function POIsFilter({
  selectedPOIs,
  selectedDistance,
  onPOISelect,
  onDistanceSelect,
  onSavePOI,
  onSavePOIsGroup,
  maxRangeValueMts = 3000
}) {
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [importResults, setImportResults] = useState([]);
  const [currentDistance, setCurrentDistance] = useState(selectedDistance);

  const [selectedPosition, setSelectedPosition] = useState({
    lat: parseFloat(window._env_.REACT_APP_DEFAULT_LATITUDE),
    lng: parseFloat(window._env_.REACT_APP_DEFAULT_LONGITUDE)
  });

  const [currentActiveTab, setCurrentActiveTab] = useState('importTab');
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const responsePeriods = useAPIDataLoader(
    'periods.getPeriods',
    {},
    { cache: true, skip: window._env_.REACT_APP_TYPE_PERIOD !== 'fixed' }
  );
  if (responsePeriods.error) {
    console.log('getPeriods error', responsePeriods.error);
  }

  let _geoSuggest;

  const parseCoordinates = coords => {
    return {
      lat: parseFloat(coords.lat.toString().replace(',', '.')),
      lng: parseFloat(coords.lng.toString().replace(',', '.'))
    };
  };

  const handleLocationChange = ({ position, address, places }) => {
    setSelectedPosition(
      parseCoordinates({
        ...position
      })
    );

    _geoSuggest && _geoSuggest.clear();
  };

  const onSuggestSelect = (suggest, setFieldValue) => {
    if (!suggest) {
      return;
    }
    setFieldValue('poiName', suggest.label);

    setSelectedPosition(
      parseCoordinates({
        lat: suggest.location.lat,
        lng: suggest.location.lng
      })
    );
  };

  const onDistanceChange = inputValue => {
    if (distanceTask) clearTimeout(distanceTask);
    setCurrentDistance(inputValue);

    distanceTask = setTimeout(() => {
      onDistanceSelect(inputValue);
    }, 300);
  };

  selectedPOIs = selectedPOIs || [];

  return (
    <>
      {locationModal()}

      <CardTitle className="text-uppercase small font-weight-bold d-flex align-items-center justify-content-between">
        {i18n.t('pois_title')}
        <Button
          onClick={() => setLocationModalVisible(true)}
          color="light"
          size="lg"
          className="p-0"
        >
          <MdAddCircle className="align-middle mr-2 ml-2" />
        </Button>
        {/* <ResponseLoading response={responseFilters} /> */}
      </CardTitle>
      <div style={{ width: '100%', marginBottom: '25px', height: 'auto' }}>
        <AsyncSelect
          styles={customStyles}
          isMulti
          isClearable={false}
          // value={workingFilter.pois}
          value={selectedPOIs}
          loadOptions={POIsLoadOptions}
          onChange={onPOISelect}
          cacheOptions
          defaultOptions={[]}
          // defaultOptions
          closeMenuOnSelect
          onSelectResetsInput={false}
          onBlurResetsInput={false}
        />

        {selectedPOIs && selectedPOIs.length > 0 ? (
          <div className="w-100 mt-1">
            <span className="small">{i18n.t('pois_distance')}: </span>
            <br />
            <div className="align-items-center d-flex justify-content-around">
              <Input
                id="distance"
                type="range"
                value={currentDistance}
                tooltip="on"
                min={0}
                max={maxRangeValueMts}
                step={50}
                style={{ width: '60%' }}
                onChange={v => {
                  onDistanceChange(parseInt(v.target.value));
                }}
              />
              <Input
                type="number"
                value={currentDistance}
                className="font-weight-light form-control form_item m-0 p-0 text-center w-25"
                min={0}
                onChange={v => {
                  const value = parseInt(v.target.value || 0);
                  if (value >= 0 && value <= maxRangeValueMts) {
                    onDistanceChange(value);
                  }
                }}
              />
              <span>mts</span>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );

  function closeLocationModal() {
    setImportResults([]);
    setLocationModalVisible(false);
  }

  function locationModal() {
    return (
      <Modal
        isOpen={locationModalVisible}
        toggle={() => closeLocationModal()}
        className="modalFadeInScale"
      >
        <ModalHeader toggle={() => closeLocationModal()}>{i18n.t('location_title')}</ModalHeader>

        <Nav tabs className="pl-3">
          <NavItem>
            <Button
              active={currentActiveTab === 'importTab'}
              color="outline-dark"
              onClick={() => {
                toggle('importTab');
              }}
            >
              {i18n.t('planner_poi_import')}
            </Button>
          </NavItem>
          <NavItem>
            <Button
              active={currentActiveTab === 'createTab'}
              color="outline-dark"
              onClick={() => {
                toggle('createTab');
              }}
            >
              {i18n.t('planner_poi_create')}
            </Button>
          </NavItem>
        </Nav>
        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId="createTab">
            <Row>
              <Col sm="12">{createLocationForm()}</Col>
            </Row>
          </TabPane>
          <TabPane tabId="importTab">
            <Row>
              <Col sm="12">{importLocationForm()}</Col>
            </Row>
          </TabPane>
        </TabContent>
      </Modal>
    );
  }

  function createLocationForm() {
    return (
      <Formik
        initialValues={{ poiName: '' }}
        validationSchema={Yup.object().shape({
          poiName: Yup.string()
            .min(4, i18n.t('error_too_short'))
            .max(100, i18n.t('error_too_long'))
            .required(i18n.t('error_required'))
        })}
        onSubmit={formValues => {
          onSavePOI(formValues, selectedPosition);
          closeLocationModal();
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <ModalBody>
              <br />
              <Geosuggest
                ref={el => {
                  _geoSuggest = el;
                }}
                placeholder="search places"
                onSuggestSelect={suggest => onSuggestSelect(suggest, setFieldValue)}
                onFocus={() => _geoSuggest.clear()}
                style={{
                  input: { width: '100%' },
                  suggests: {
                    position: 'absolute',
                    zIndex: 9999,
                    backgroundColor: 'white',
                    width: '95%'
                  }
                }}
              />
              <div className="d-flex mb-4 align-items-center">
                <LocationPicker
                  containerElement={<div style={{ height: '100%', width: '100%' }} />}
                  mapElement={<div style={{ height: '25vh' }} />}
                  defaultPosition={selectedPosition}
                  onChange={handleLocationChange}
                  zoom={5}
                />
              </div>
              <FormGroup row>
                <Label for="poiName" sm={6}>
                  {i18n.t('poi_name')}
                </Label>
                <Col sm={12}>
                  <Input
                    type="text"
                    name="poiName"
                    tag={Field}
                    invalid={errors.poiName && touched.poiName}
                  />
                  <FormFeedback tooltip>{errors.poiName}</FormFeedback>
                </Col>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit">
                {i18n.t('save_button')}
              </Button>{' '}
              <Button color="secondary" onClick={() => closeLocationModal()}>
                {i18n.t('cancel_button')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    );
  }

  function importLocationForm() {
    const validateForm = formValues => {
      const errors = {};

      if (!(formValues.poisGroupName.length >= 4 && formValues.poisGroupName.length <= 100)) {
        errors.poisGroupName = i18n.t('planner_error_poi_group_name');
      }

      if (importResults.length === 0) {
        errors.import = i18n.t('planner_error_poi_group_empty_import');
      }

      console.log(errors);

      return errors;
    };

    return (
      <Formik
        initialValues={{ poisGroupName: '' }}
        validate={validateForm}
        onSubmit={formValues => {
          onSavePOIsGroup(formValues.poisGroupName, importResults);
          closeLocationModal();
        }}
      >
        {({ errors, touched, setFieldValue, setFieldTouched }) => (
          <Form>
            <ModalBody>
              <FormGroup row>
                <Label for="poiName" sm={6}>
                  {i18n.t('planner_poi_group_name')}
                </Label>
                <Col sm={12}>
                  <Input
                    type="text"
                    name="poisGroupName"
                    tag={Field}
                    invalid={errors.poisGroupName && touched.poisGroupName}
                  />
                  <FormFeedback tooltip>{errors.poisGroupName}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Field name="import">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta
                    }) => (
                      <>
                        <Input className="d-none" {...field} invalid={errors.import} id="import" />
                        <Importer
                          locale={getCustomLocale()}
                          assumeNoHeaders={false}
                          restartable={false}
                          processChunk={async (rows, { startIndex }) => {
                            // required, may be called several times
                            // receives a list of parsed objects based on defined fields and user column mapping;
                            // (if this callback returns a promise, the widget will wait for it before parsing more data)
                            for (const row of rows) {
                              setImportResults(oldImportResults => [...oldImportResults, row]);
                            }
                            setFieldTouched('import', true);
                          }}
                        >
                          <ImporterField name="name" label="name" />
                          <ImporterField name="latitude" label="latitude" />
                          <ImporterField name="longitude" label="longitude" />
                        </Importer>
                      </>
                    )}
                  </Field>
                  <FormFeedback tooltip>{errors.import}</FormFeedback>
                </Col>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit">
                {i18n.t('save_button')}
              </Button>{' '}
              <Button color="secondary" onClick={() => closeLocationModal()}>
                {i18n.t('cancel_button')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    );
  }
}

const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: '90px',
    maxHeight: '90px',
    overflow: 'scroll'
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: '10'
  })
};

let searchTask;

const POIsLoadOptions = inputValue => {
  if (searchTask) clearTimeout(searchTask);

  return new Promise(resolve => {
    searchTask = setTimeout(() => {
      API.call(
        'audiences.searchAudiencePOIs',
        { /* country: workingFilter.country, */ inputValue },
        (err, res) => {
          if (err) {
            console.log('err', err);
            resolve([]);
          } else {
            resolve(res);
          }
        }
      );
    }, 1000);
  });
};
