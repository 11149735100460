import React from 'react';
import i18n from 'i18n-js';
import toastr from 'toastr';
import { useMappedState } from 'redux-react-hook';

import { useAPIv2DataLoader } from '../../hooks/api';
import PlannerStructure from './PlannerStructure';
import LoadingModal from '../../components/utils/LoadingModal';

import { filterSelector } from '../../redux/ducks/filters';
export default function PlannerClone(props) {
  const { poiType } = props;
  const { filter } = useMappedState(filterSelector);
  const typeFilter = filter[poiType];

  const campaignResponse = useAPIv2DataLoader(
    'planner',
    `campaign/${props.match.params.id}`,
    {},
    { cache: false, responseHolder: `campaign` }
  );

  if (campaignResponse.error) {
    console.log('error', campaignResponse.error);
    if (campaignResponse.error.response && campaignResponse.error.response.data) {
      toastr.error(`Error: ${campaignResponse.error.response.data.message}`);
    }
  }

  if (!campaignResponse || !campaignResponse.hasValue('campaign')) {
    //return <div className="text_check">{i18n.t('campaign_loading')}</div>;

    return <LoadingModal isOpen={true} text={i18n.t('campaign_loading')} />;
  }

  const { campaign } = campaignResponse.data.value;

  const workingData = {
    campaignName: `${i18n.t('campaign_name_copy')} ${campaign.name}`,
    workingFilter: campaign.workingFilter,
    mapPois: campaign.mapPois,
    reach: campaign.reach
  };

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('planner_menu')}</h1>
          <p className="mb-0">{i18n.t('campaign_builder')}</p>
        </header>
      </div>
      <PlannerStructure
        filter={typeFilter}
        workingData={workingData}
        type={props.user.type}
        poiType={poiType}
      />
    </div>
  );
}
