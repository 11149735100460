import { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import API2 from '../../api2/index';
import { Card, CardBody } from 'reactstrap';
import { openNewWindow } from '../../../routeUtils';
import './cards.css';

export default function PaymentMethods(props) {
  const { organization, active, previousStep, setLoading, onSelect, setError } = props;
  const [paymentsInfo, setPaymentsInfo] = useState(null);

  useEffect(() => {
    if (active) {
      setLoading(true);
      API2.post(
        'organizations',
        `${organization.id}/paymentMethods`,
        { returnUrl: `${window.location.origin}/settings/subscription` },
        (err, info) => {
          if (err) {
            if (err.code === 403) {
              setError(i18n.t('organization_not_allowed', { organization: organization.name }));
            }
            setError(
              i18n.t('unknown_error_please_contact_support', { msg: JSON.stringify(err.message) })
            );
          } else {
            setPaymentsInfo(info);
          }
          setLoading(false);
        }
      );
    }
  }, [active]);

  if (!paymentsInfo) return null;

  const openCustomerPortal = () => {
    openNewWindow(paymentsInfo.portalUrl);
    previousStep();
  };

  return (
    <div>
      <Card className="shadow-none">
        <CardBody className="p-0">
          {paymentsInfo.paymentMethods.map(p => (
            <div
              className={`credit-card ${p.card.brand} selectable`}
              onClick={() => onSelect(p.id)}
            >
              <div className="credit-card-last4">{p.card.last4}</div>
              <div className="credit-card-expiry">
                {p.card.exp_month}/{p.card.exp_year}
              </div>
            </div>
          ))}
          <div className="align-items-center d-flex flex-column justify-content-center">
            <button className="border-0 btn btn-sm shadow-none" onClick={openCustomerPortal}>
              {i18n.t('manage_payment_methods')}
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
