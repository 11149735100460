import WindowActiveLine from './windowActiveLine';

export default function WindowActive({ children, selected }) {
  return (
    <>
      <WindowActiveLine pos="top-0 left-0 w-0.5 h-full" />
      <WindowActiveLine pos="top-0 right-2 w-0.5 h-full" />
      <WindowActiveLine pos="bottom-0 left-0 w-win-active h-0.5" />
      <WindowActiveLine pos="top-0 left-0 w-win-active h-0.5" />
    </>
  );
}
