import React, { useState } from 'react';
import i18n from 'i18n-js';
import { MdDelete, MdFileDownload } from 'react-icons/md/';

import { Card, CardBody } from 'reactstrap';

import numeral from 'numeral';
import moment from 'moment';
import toastr from 'toastr';

import { useAPIv2DataLoader } from '../../hooks/api';
import API2 from '../../api2';

import Paginator from '../../components/pagination/Paginator';

import { Loading } from '../../components/utils/Loading';

import { Filter, SortIcon } from '../../components/filter/Filter';
import PopupConfirm from '../../components/utils/PopupConfirm';
import { formattedPeriod } from '../../hooks/metrics/esdata';
import { fileDownload } from '../../../routeUtils';

function ExportAudienceRow({ audience }) {
  const deleteExportAudience = () => {
    PopupConfirm({
      onConfirm: () => {
        API2.delete('audiences', `${audience._id}`, {}, (err, res) => {
          if (err) {
            console.log('err', err);
            toastr.error(`${i18n.t('error_saving_audience')}: ${err}`);
          } else {
            toastr.success(`${i18n.t('ok_saving_audience')}`);
            window.location.reload();
          }
        });
      }
    });
  };

  // TODO: hardcoded extension csv.gz?
  const requestDownload = () => {
    API2.blob('audiences', `${audience._id}/download`, {}, (err, res) => {
      if (err) {
        console.log('err', err);
        toastr.error(`${i18n.t('error_saving_audience')}: ${err}`);
      } else {
        fileDownload(res, `${audience.segment_label}-${formattedPeriod(audience.period)}.csv.gz`);
      }
    });
  };

  return (
    <tr>
      <td>{audience.segment_label}</td>
      <td>{formattedPeriod(audience.period)}</td>
      <td>{numeral(audience.quantity).format('0,0')}</td>
      <td>{numeral(audience.total_cost).format('$ 0,0.00')}</td>
      <td>{moment(audience.createdAt).format('YYYY-MM-DD HH:mm')}</td>
      {/* <td>{audience.creator.email}</td> */}
      <td>{audience.status}</td>
      <td className="d-flex justify-content-end">
        {audience.status === 'READY' ? (
          <MdFileDownload
            size="18"
            color="#363537"
            onClick={requestDownload}
            style={{ cursor: 'pointer' }}
            title={i18n.t('download_audience')}
            className="mr-2"
          />
        ) : null}
        <MdDelete
          size="18"
          color="#363537"
          onClick={deleteExportAudience}
          style={{ cursor: 'pointer' }}
          title={i18n.t('audience_actions_delete')}
        />
      </td>
    </tr>
  );
}

function ViewContent({
  totalItems,
  currentPage,
  setPage,
  audiences,
  user,
  filter,
  handleFilterChange,
  sortBy,
  handleSortBy
}) {
  return (
    <div>
      <div className="row">
        <div className="col-md-7 col-sm-7 col-xs-12">
          <h6 className="mb-4 text-uppercase">{i18n.t('audience_list')}</h6>
        </div>
        <Filter filter={filter} handleFilterChange={handleFilterChange} />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              className="mb-2"
              style={{ width: '20%', minWidth: '20%' }}
              onClick={handleSortBy.bind(this, 'segment_label')}
            >
              {i18n.t('download_audience_segment')}
              <SortIcon sortBy={sortBy} field="segment_label" />
            </th>
            <th
              className="mb-2"
              style={{ width: '15%', minWidth: '15%' }}
              onClick={handleSortBy.bind(this, 'period')}
            >
              {i18n.t('download_audience_period')}
              <SortIcon sortBy={sortBy} field="period" />
            </th>
            <th
              className="mb-2"
              style={{ width: '15%', minWidth: '15%' }}
              onClick={handleSortBy.bind(this, 'quantity')}
            >
              {i18n.t('download_audience_details_raw')}
              <SortIcon sortBy={sortBy} field="quantity" />
            </th>
            <th
              className="mb-2"
              style={{ width: '15%', minWidth: '15%' }}
              onClick={handleSortBy.bind(this, 'total_cost')}
            >
              {i18n.t('download_audience_details_cost')}
              <SortIcon sortBy={sortBy} field="total_cost" />
            </th>
            <th
              className="mb-2"
              style={{ width: '15%', minWidth: '15%' }}
              onClick={handleSortBy.bind(this, 'createdAt')}
            >
              {i18n.t('audience_creation_date')}
              <SortIcon sortBy={sortBy} field="createdAt" />
            </th>
            {/* <th
              className="mb-2"
              style={{ width: '20%', minWidth: '20%' }}
              onClick={handleSortBy.bind(this, 'creator.email')}
            >
              {i18n.t('audience_created_by')}
              <SortIcon sortBy={sortBy} field="creator.email" />
            </th> */}
            <th
              className="mb-2"
              style={{ width: '10%', minWidth: '10%' }}
              onClick={handleSortBy.bind(this, 'status')}
            >
              {i18n.t('audience_status')}
              <SortIcon sortBy={sortBy} field="status" />
            </th>
            <th className="mb-4" style={{ textAlign: 'center', width: '15%', minWidth: '15%' }}>
              {i18n.t('audience_actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {audiences &&
            audiences.map((audience, i) => <ExportAudienceRow key={i} audience={audience} />)}
        </tbody>
      </table>
      <Paginator totalItems={totalItems} currentPage={currentPage} setPage={setPage} />
    </div>
  );
}

export default function ExportAudienceManager(props) {
  let { poiType } = props;
  if (!poiType) throw new Error('POI Type is mandatory');

  poiType = poiType.toLowerCase();

  const {
    match: {
      params: { page = 1 }
    },
    history,
    user
  } = props;

  const [filter, setFilter] = useState('');

  const [sortBy, setSortBy] = useState({ createdAt: -1 });

  const audiencesResponse = useAPIv2DataLoader(
    'audiences',
    '',
    { page, poiType, filter, sortBy },

    { cache: false, responseHolder: `response` }
  );

  if (audiencesResponse.error) {
    console.log('error', audiencesResponse.error);
  }

  const setPage = page => {
    history.push(`/export-audience/${page}?type=${poiType}`);
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const handleSortBy = field => {
    const currentSortBy = sortBy || {};

    const _sortBy = currentSortBy[field]
      ? {
          [field]: currentSortBy[field] * -1
        }
      : {
          [field]: 1
        };

    setSortBy(_sortBy);
  };

  const { audiences, count } = audiencesResponse.hasValue('response')
    ? audiencesResponse.data.value.response
    : {};

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('audience_title')}</h1>
          <p className="mb-0">{i18n.t('audience_manager')}</p>
        </header>
      </div>
      <div className="view-content view-components">
        <Card className="mb-4">
          <CardBody className="table-responsive">
            <Loading loaded={audiencesResponse.data.state !== 'LOADING'} />
            <ViewContent
              totalItems={count}
              currentPage={page}
              setPage={setPage}
              audiences={audiences}
              user={user}
              filter={filter}
              handleFilterChange={handleFilterChange}
              sortBy={sortBy}
              handleSortBy={handleSortBy}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
