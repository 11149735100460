import { IcoHierarchy, IcoPoisList } from '../icons';

export default function SubHeader({ title, colors, colorName, ico, children, listed, selected }) {
  const classIco =
    'w-6 h-6 fill-current shrink-0 transition-color duration-500 ' +
    (colorName
      ? colors[colorName]['text-500']
      : listed
      ? 'text-mof-slate-500'
      : selected
      ? 'text-blue-600'
      : 'text-slate-300');

  return (
    <>
      {/* Subtitle */}

      <div
        className={
          'flex items-center text-slate-500 mt-1 mb-2 space-x-1.5 ' +
          (colors && colorName ? colors[colorName]['text-600'] : 'text-slate-300')
        }
      >
        {ico &&
          {
            pois: <IcoPoisList className={classIco} />,
            groups: <IcoHierarchy className={classIco} />
          }[ico]}

        {children ? (
          children
        ) : (
          <p className={'font-open-sans text-sm font-medium translate-y-px tracking-minimal '}>
            {title}
          </p>
        )}
      </div>
    </>
  );
}
