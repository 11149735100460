import React, { useState } from 'react';
import i18n from 'i18n-js';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import { GeodesicLine } from 'react-leaflet-geodesic';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
import 'leaflet/dist/leaflet.css';
import { Button } from 'reactstrap';
import POIMarker from './POIMarker';
import { createClusterCustomIcon } from './config';

const gradientPrev = {
  0: '#c6ddff',
  0.5: '#6ea0c8',
  1: '#005384'
};
const gradientPost = {
  0: '#ffd8dc',
  0.5: '#ef959e',
  1: '#da4150'
};

export default function HeatMap({ responseHeatmap, poiType }) {
  const [flags, setFlags] = useState({ prev: true, post: true });

  if (!responseHeatmap || !responseHeatmap.hasValue('heatmap')) return null;

  const heatmapDataPrev = responseHeatmap.data.value.heatmap.pointsPrev.map(p => [
    p.latitude,
    p.longitude,
    p.count
  ]);
  const heatmapDataPost = responseHeatmap.data.value.heatmap.pointsPost.map(p => [
    p.latitude,
    p.longitude,
    p.count
  ]);

  const flowmapDataPrev = responseHeatmap.data.value.heatmap.pointsPrev;
  const flowmapDataPost = responseHeatmap.data.value.heatmap.pointsPost;

  const handleChangeFlag = f => {
    const newFlags = {
      ...flags
    };
    newFlags[f] = !newFlags[f];
    setFlags(newFlags);
  };

  return (
    <>
      <MyLeafLetMapComponent
        pois={responseHeatmap.data.value.heatmap.stores}
        {...{ heatmapDataPrev, heatmapDataPost, flowmapDataPrev, flowmapDataPost, flags }}
      />

      <div className="d-flex small mt-2 mb-2" style={{ justifyContent: 'flex-end' }}>
        <input
          name="prev"
          type="checkbox"
          checked={flags.prev}
          onChange={e => handleChangeFlag('prev')}
        />
        <div style={{ backgroundColor: gradientPrev[0.5] }}>
          {i18n.t(poiType === 'ooh' ? 'prev_visit_ooh' : 'prev_visit_poi')}
        </div>
        &nbsp;&nbsp;
        <input
          name="post"
          type="checkbox"
          checked={flags.post}
          onChange={e => handleChangeFlag('post')}
        />
        <div style={{ backgroundColor: gradientPost[0.5] }}>
          {i18n.t(poiType === 'ooh' ? 'post_visit_ooh' : 'post_visit_poi')}
        </div>
      </div>
    </>
  );
}

function MyLeafLetMapComponent(props) {
  const [mapType, setMapType] = useState('both');

  const defaultProps = {
    center: {
      lat: window._env_.REACT_APP_DEFAULT_LATITUDE,
      lng: window._env_.REACT_APP_DEFAULT_LONGITUDE
    },
    zoom: 4
  };

  const { pois, heatmapDataPrev, heatmapDataPost, flowmapDataPrev, flowmapDataPost, flags } = props;

  const optionsPrev = {
    weight: 3,
    opacity: 0.8,
    color: gradientPrev[0.5]
    // steps: 1
  };
  const optionsPost = {
    weight: 3,
    opacity: 0.8,
    color: gradientPost[0.5]
    // steps: 1
  };

  function HeatMapContainer() {
    return (
      <>
        {flags.prev ? (
          <HeatmapLayer
            fitBoundsOnLoad
            // fitBoundsOnUpdate
            points={heatmapDataPrev}
            longitudeExtractor={m => m[1]}
            latitudeExtractor={m => m[0]}
            intensityExtractor={m => parseFloat(m[2])}
            gradient={gradientPrev}
            max={30}
            radius={40}
            blur={40}
            minOpacity={0.3}
          />
        ) : null}
        {flags.post ? (
          <HeatmapLayer
            fitBoundsOnLoad
            // fitBoundsOnUpdate
            points={heatmapDataPost}
            longitudeExtractor={m => m[1]}
            latitudeExtractor={m => m[0]}
            intensityExtractor={m => parseFloat(m[2])}
            gradient={gradientPost}
            max={30}
            radius={40}
            blur={40}
            minOpacity={0.3}
          />
        ) : null}
      </>
    );
  }

  function FlowMapContainer() {
    const map = useMap();
    if (!map) return null;

    return (
      <>
        {flags.prev
          ? flowmapDataPrev.map(prev => {
              const p = pois.find(p => p._id === prev.store_id);

              if (!p) return null;

              return (
                <GeodesicLine
                  key={prev._id}
                  positions={[
                    [parseFloat(p.latitude), parseFloat(p.longitude)],
                    [prev.latitude, prev.longitude]
                  ]}
                  map={map}
                  options={optionsPrev}
                />
              );
            })
          : null}
        {flags.post
          ? flowmapDataPost.map(post => {
              const p = pois.find(p => p._id === post.store_id);

              if (!p) return null;

              return (
                <GeodesicLine
                  key={post._id}
                  positions={[
                    [parseFloat(p.latitude), parseFloat(p.longitude)],
                    [post.latitude, post.longitude]
                  ]}
                  map={map}
                  options={optionsPost}
                />
              );
            })
          : null}
      </>
    );
  }

  return (
    <>
      <div className="small mb-4 mt-2 card-subtitle">
        <Button
          className="btn-sm"
          color={mapType === 'both' ? 'dark' : 'light-gray'}
          onClick={() => setMapType('both')}
        >
          {i18n.t('map_type_both')}
        </Button>
        <Button
          className="btn-sm"
          color={mapType === 'heatmap' ? 'dark' : 'light-gray'}
          onClick={() => setMapType('heatmap')}
        >
          {i18n.t('map_type_heatmap')}
        </Button>
        <Button
          className="btn-sm"
          color={mapType === 'flowmap' ? 'dark' : 'light-gray'}
          onClick={() => setMapType('flowmap')}
        >
          {i18n.t('map_type_flowmap')}
        </Button>
      </div>
      <MapContainer
        className="markercluster-map"
        center={defaultProps.center}
        zoom={defaultProps.zoom}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        {mapType === 'heatmap' || mapType === 'both' ? <HeatMapContainer /> : null}
        {mapType === 'flowmap' || mapType === 'both' ? <FlowMapContainer /> : null}
        <MarkerClusterGroup
          showCoverageOnHover={false}
          spiderfyOnMaxZoom={false}
          zoomToBoundsOnClick={true}
          spiderfyDistanceMultiplier={2}
          disableClusteringAtZoom={12}
          iconCreateFunction={createClusterCustomIcon}
        >
          {pois.map(p => (
            <POIMarker key={p._id} poi={p} />
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </>
  );
}
