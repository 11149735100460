import React from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle, Row } from 'reactstrap';

import { ResponseLoading } from '../../components/utils/Loading';
import JourneyCard from './JourneyCard';
import JourneyFlowChart from './JourneyFlowChart';

export default function ViewContent({ responseJourney }) {
  const responseByShare = responseJourney.data.value
    ? responseJourney.data.value.metricsCorrelationByShare
    : null;
  const responseByDwell = responseJourney.data.value
    ? responseJourney.data.value.metricsCorrelationByDwell
    : null;
  const responseJourneyFlow = responseJourney.data.value
    ? responseJourney.data.value.journeyFlow
    : null;

  return (
    <div className="view-content">
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <CardGroup className="metric-card ">
            <Card style={{ flex: '3 0 0' }}>
              <CardBody>
                <CardTitle className="text-uppercase h6">
                  {i18n.t('journey_flow')}
                  <ResponseLoading response={responseJourney} />
                </CardTitle>
                <div className="small mb-4 card-subtitle">{i18n.t('journey_flow_text')}</div>
                <div className="d-flex">
                  <Card className="col-12 col-md-12 col-sm-12">
                    <div style={{ width: '100%', height: '700px' }}>
                      <JourneyFlowChart response={responseJourneyFlow} />
                    </div>
                  </Card>
                </div>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <CardGroup className="metric-card ">
            <Card style={{ flex: '3 0 0' }}>
              <CardBody>
                <CardTitle className="text-uppercase h6">
                  {i18n.t('journey_correlation')}
                  <ResponseLoading response={responseJourney} />
                </CardTitle>
                <div className="small mb-4 card-subtitle">{i18n.t('journey_by_share')}</div>
                <div style={{ width: '100%' }} className="mb-4">
                  <JourneyCard response={responseByShare} dataKey="visitors" format="0,00[a]" />
                </div>
                <div className="small mb-4 card-subtitle">{i18n.t('journey_by_dwell')}</div>
                <div style={{ width: '100%' }}>
                  <JourneyCard
                    response={responseByDwell}
                    dataKey="dwell_time"
                    format="00:00:00"
                    suffix="hs"
                  />
                </div>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>
    </div>
  );
}
