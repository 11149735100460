import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';
import i18n from 'i18n-js';
import moment from 'moment';
import history from './history';
import { AppRoute, CRUDRoute, OnboardingRoute, SettingsRoute } from './AppRoutes';
import Login from './modules/containers/login/Login';
import Dashboard from './modules/containers/dashboard/Dashboard';
import DashboardPOIDetails from './modules/containers/dashboard/DashboardPOIDetails';
import Compare from './modules/containers/compare/Compare';
import Heatmap from './modules/containers/heatmap/Heatmap';
import Radar from './modules/containers/radar/Radar';
import Performance from './modules/containers/performance/Performance';
import Journey from './modules/containers/journey/Journey';
import Glossary from './modules/containers/info/Glossary';
import POIs from './modules/containers/pois/POIs';
import POIEdit from './modules/containers/pois/POIEdit';
import POICreate from './modules/containers/pois/POICreate';
import Ranking from './modules/containers/ranking/Ranking';
import EditProfile from './modules/containers/user/EditProfile';
import EditPassword from './modules/containers/user/EditPassword';
import ForgotPassword from './modules/containers/user/ForgotPassword';
import NewPassword from './modules/containers/user/NewPassword';
import AudienceBuilder from './modules/containers/audiences/AudienceBuilder';
import PlannerBuilder from './modules/containers/planner/PlannerBuilder';
import PlannerManager from './modules/containers/planner/PlannerManager';
import PlannerClone from './modules/containers/planner/PlannerClone';
import PlannerEdit from './modules/containers/planner/PlannerEdit';
import AudienceManager from './modules/containers/audiences/AudienceManager';
import AudienceDetails from './modules/containers/audiences/AudienceDetails';
import AudienceEdit from './modules/containers/audiences/AudienceEdit';
import ExportAudienceManager from './modules/containers/audience/ExportAudienceManager';
import ExportAudienceManagerCheckoutSuccess from './modules/containers/audience/ExportAudienceManagerCheckoutSuccess';
import AIAdvisor from './modules/containers/ai/advisor/Advisor';
import AIForecast from './modules/containers/ai/forecast/Forecast';
import PremiumSentiment from './modules/containers/premium/sentiment/Sentiment';
import PremiumFinancial from './modules/containers/premium/financial/Financial';
import PremiumLoyalty from './modules/containers/premium/loyalty/Loyalty';
import PremiumNeighborhood from './modules/containers/premium/neighborhood/Neighborhood';
import AuditManager from './modules/containers/audit/AuditManager';
import NewAudit from './modules/containers/audit/create/NewAudit';
import AuditDetails from './modules/containers/audit/details/AuditDetails';
import { translations } from './modules/assets/translations';
import { logout } from './modules/redux/ducks/auth';
import { isCurrentFilterValid } from './modules/redux/ducks/filters';
import Register from './modules/containers/login/Register';
import Validate from './modules/containers/login/Validate';
import Registered from './modules/containers/login/Registered';
import EditOrganization from './modules/containers/user/EditOrganization';
import axios from 'axios';
import Checkout from './modules/containers/user/Checkout';
import CheckoutSuccess from './modules/containers/user/CheckoutSuccess';
import EditSubscription from './modules/containers/user/EditSubscription';
import ManageBilling from './modules/containers/user/ManageBilling';
import Reactivate from './modules/containers/user/Reactivate';

history.listen(location => {});

// Set the key-value pairs for the different languages you want to support.
i18n.translations = translations;

i18n.locale = window._env_.REACT_APP_LANGUAGE;

if (window._env_.REACT_APP_LANGUAGE !== 'en') {
  import(`moment/locale/${window._env_.REACT_APP_LANGUAGE}`).then();
  moment.locale(window._env_.REACT_APP_LANGUAGE);
}

const mapState = state => ({
  user: state.auth.user,
  filters: state.filters,
  selectedOrganization: state.auth.organization
});

function App() {
  const { user, filters, selectedOrganization } = useMappedState(mapState);
  if (window._env_.REACT_APP_ORGANIZATIONS_AWARE === 'true') {
    axios.defaults.headers.common.organization = JSON.stringify(selectedOrganization);
  }

  // backwards compatibility
  // https://www.notion.so/mofiler/Fix-localStorage-crash-83c333f486e243498be02ad85a47e097
  const dispatch = useDispatch();
  if (!isCurrentFilterValid(filters)) {
    dispatch(logout());
  }

  useEffect(() => {
    const root = document.documentElement;
    if (root) {
      root.style.setProperty('--brand-primary', window._env_.REACT_APP_BRAND_COLOR_PRIMARY);
      root.style.setProperty('--brand-second', window._env_.REACT_APP_BRAND_COLOR_SECONDARY);
      root.style.setProperty('--brand-second-b', window._env_.REACT_APP_BRAND_COLOR_SECONDARY_DARK);
      root.style.setProperty('--brand-third', window._env_.REACT_APP_BRAND_COLOR_THIRD);
      root.style.setProperty('--brand-fourth', window._env_.REACT_APP_BRAND_COLOR_FOURTH);
      root.style.setProperty('--brand-fifth', window._env_.REACT_APP_BRAND_COLOR_FIFTH);
      root.style.setProperty('--brand-fifth-b', window._env_.REACT_APP_BRAND_COLOR_FIFTH_DARK);
      root.style.setProperty('--brand-sixth', window._env_.REACT_APP_BRAND_COLOR_SIXTH);
      root.style.setProperty('--brand-seventh', window._env_.REACT_APP_BRAND_COLOR_SEVENTH);
      root.style.setProperty('--brand-eighth', window._env_.REACT_APP_BRAND_COLOR_EIGHTH);
      root.style.setProperty('--brand-ninth', window._env_.REACT_APP_BRAND_COLOR_NINTH);
      root.style.setProperty('--body-bg', window._env_.REACT_APP_BRAND_COLOR_BODY);
    }
  }, []);

  const fullMonth = window._env_.REACT_APP_TYPE_PERIOD === 'month';
  const useAggregation = window._env_.REACT_APP_USE_AGGREGATION === 'true';
  return (
    <Router history={history}>
      <div>
        {/* AUTH ROUTES */}
        <OnboardingRoute exact path="/" component={Login} loginRequired={false} />
        <OnboardingRoute exact path="/login" component={Login} loginRequired={false} />
        <OnboardingRoute exact path="/validate" component={Validate} loginRequired={false} />
        {window._env_.REACT_APP_SELF_REGISTER === 'true' ? (
          <OnboardingRoute exact path="/register" component={Register} loginRequired={false} />
        ) : null}
        {window._env_.REACT_APP_SELF_REGISTER === 'true' ? (
          <OnboardingRoute exact path="/registered" component={Registered} loginRequired={false} />
        ) : null}
        <OnboardingRoute exact path="/forgot" component={ForgotPassword} user={user} />
        <OnboardingRoute exact path="/update-password" component={NewPassword} user={user} />
        {/* USER ROUTES */}
        <SettingsRoute
          exact
          path="/settings/profile"
          component={EditProfile}
          user={user}
          organization={selectedOrganization}
          requiresOrganization={false}
        />
        <SettingsRoute
          exact
          path="/settings/password"
          component={EditPassword}
          user={user}
          organization={selectedOrganization}
          requiresOrganization={false}
        />
        {/* ORG ROUTES */}
        {window._env_.REACT_APP_ORGANIZATIONS_AWARE === 'true' ? (
          <>
            <CRUDRoute
              exact
              path="/create-organization/"
              component={EditOrganization}
              user={user}
              requiresOrganization={false}
              withNav={false}
            />
            <CRUDRoute
              exact
              path="/checkout/"
              component={Checkout}
              organization={selectedOrganization}
              user={user}
              allowUnpaidOrganization
              withNav={false}
            />
            <CRUDRoute
              exact
              path="/checkout-success/"
              component={CheckoutSuccess}
              organization={selectedOrganization}
              user={user}
              allowUnpaidOrganization
              withNav={false}
            />
            <CRUDRoute
              exact
              path="/reactivate/"
              component={Reactivate}
              organization={selectedOrganization}
              user={user}
              allowUnpaidOrganization
              withNav={false}
            />
            <SettingsRoute
              exact
              path="/settings/organization/"
              component={EditOrganization}
              organization={selectedOrganization}
              user={user}
            />
            <SettingsRoute
              exact
              path="/settings/organization/:id"
              component={EditOrganization}
              user={user}
              requiresOrganization={false}
            />
            <SettingsRoute
              exact
              path="/settings/subscription"
              component={EditSubscription}
              organization={selectedOrganization}
              user={user}
            />
            <SettingsRoute
              exact
              path="/settings/billing"
              component={ManageBilling}
              organization={selectedOrganization}
              user={user}
            />
          </>
        ) : null}

        {/* REPORTS ROUTES */}
        {window._env_.REACT_APP_MODULE_PDV_DASHBOARD === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_DASHBOARD === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_DASHBOARD === 'true' ? (
          <>
            <AppRoute
              exact
              path="/dashboard"
              component={Dashboard}
              user={user}
              organization={selectedOrganization}
              fullMonth={fullMonth}
              useAggregation={useAggregation}
            />
            <CRUDRoute
              exact
              path="/dashboard/:id"
              component={DashboardPOIDetails}
              user={user}
              organization={selectedOrganization}
              navMini
            />
          </>
        ) : null}
        {window._env_.REACT_APP_MODULE_PDV_COMPARE === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_COMPARE === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_COMPARE === 'true' ? (
          <CRUDRoute
            exact
            path="/compare"
            component={Compare}
            user={user}
            organization={selectedOrganization}
            navMini
            fullMonth={fullMonth}
          />
        ) : null}
        {window._env_.REACT_APP_MODULE_PDV_HEATMAP === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_HEATMAP === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_HEATMAP === 'true' ? (
          <AppRoute
            exact
            path="/heatmaps"
            component={Heatmap}
            user={user}
            organization={selectedOrganization}
            fullMonth={fullMonth}
          />
        ) : null}
        {window._env_.REACT_APP_MODULE_PDV_RADAR === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_RADAR === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_RADAR === 'true' ? (
          <AppRoute
            exact
            path="/radar"
            component={Radar}
            user={user}
            organization={selectedOrganization}
            navMini
            fullMonth={fullMonth}
          />
        ) : null}
        {window._env_.REACT_APP_MODULE_PDV_PERFORMANCE === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_PERFORMANCE === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_PERFORMANCE === 'true' ? (
          <AppRoute
            exact
            path="/performance"
            component={Performance}
            user={user}
            organization={selectedOrganization}
            navMini
            fullMonth={fullMonth}
            useAggregation={useAggregation}
          />
        ) : null}
        {window._env_.REACT_APP_MODULE_PDV_JOURNEY === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_JOURNEY === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_JOURNEY === 'true' ? (
          <AppRoute
            exact
            path="/journey"
            component={Journey}
            user={user}
            organization={selectedOrganization}
            navMini
            fullMonth={fullMonth}
            filterSinglePOI
            filterConditions={['hasCustomerJourney']}
          />
        ) : null}
        {window._env_.REACT_APP_MODULE_PDV_RANKING === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_RANKING === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_RANKING === 'true' ? (
          <CRUDRoute
            exact
            path="/ranking"
            component={Ranking}
            user={user}
            organization={selectedOrganization}
            fullMonth={fullMonth}
          />
        ) : null}
        {window._env_.REACT_APP_MODULE_REPORTS_AUDIT === 'true' ? (
          <>
            <CRUDRoute
              exact
              path="/audit/:page?"
              component={AuditManager}
              user={user}
              organization={selectedOrganization}
            />
            <CRUDRoute
              exact
              path="/audit-new"
              component={NewAudit}
              user={user}
              organization={selectedOrganization}
            />
            <CRUDRoute
              exact
              path="/audit/details/:id"
              component={AuditDetails}
              user={user}
              organization={selectedOrganization}
            />
          </>
        ) : null}
        <CRUDRoute
          exact
          path="/glossary"
          component={Glossary}
          user={user}
          organization={selectedOrganization}
          requiresOrganization={false}
        />
        {/* PLANNER ROUTES */}
        {window._env_.REACT_APP_MODULE_CATEGORY_PLANNER === 'true' &&
          (window._env_.REACT_APP_USE_SUPER_PLANNER === 'true' ? (
            <>
              <CRUDRoute
                exact
                path="/planner-new"
                component={PlannerBuilder}
                user={user}
                organization={selectedOrganization}
                navMini
              />
              <CRUDRoute
                exact
                path="/planner/:page?"
                component={PlannerManager}
                user={user}
                organization={selectedOrganization}
              />
              <CRUDRoute
                exact
                path="/planner/clone/:id"
                component={PlannerClone}
                user={user}
                organization={selectedOrganization}
                navMini
              />
              <CRUDRoute
                exact
                path="/planner/edit/:id"
                component={PlannerEdit}
                user={user}
                organization={selectedOrganization}
                navMini
              />
              <CRUDRoute
                exact
                path="/planner-hidden"
                component={AudienceBuilder}
                user={user}
                organization={selectedOrganization}
              />
            </>
          ) : (
            <>
              <CRUDRoute
                exact
                path="/planner-new"
                component={AudienceBuilder}
                user={user}
                organization={selectedOrganization}
                navMini
              />
              <CRUDRoute
                exact
                path="/planner"
                component={AudienceManager}
                user={user}
                organization={selectedOrganization}
              />
              <CRUDRoute
                exact
                path="/planner/edit/:id"
                component={AudienceEdit}
                user={user}
                organization={selectedOrganization}
                navMini
              />
              <CRUDRoute
                exact
                path="/planner/details/:id"
                component={AudienceDetails}
                user={user}
                organization={selectedOrganization}
              />
            </>
          ))}
        {/* ADMIN ROUTES */}
        {window._env_.REACT_APP_MODULE_CATEGORY_POI === 'true' ? (
          <>
            <CRUDRoute
              exact
              path="/poi/new/"
              component={POICreate}
              user={user}
              organization={selectedOrganization}
            />
            <CRUDRoute
              exact
              path="/pois/details/:id"
              component={POIEdit}
              user={user}
              organization={selectedOrganization}
            />
            <CRUDRoute
              exact
              path="/pois/:page?"
              component={POIs}
              user={user}
              organization={selectedOrganization}
            />
          </>
        ) : null}
        {/* EXPORT AUDIENCE */}
        <CRUDRoute
          exact
          path="/export-audience"
          component={ExportAudienceManager}
          user={user}
          organization={selectedOrganization}
        />
        <CRUDRoute
          exact
          path="/export-audience-checkout-success/:id"
          component={ExportAudienceManagerCheckoutSuccess}
          user={user}
          organization={selectedOrganization}
        />

        {window._env_.REACT_APP_MODULE_CATEGORY_AI === 'true' ? (
          <>
            {window._env_.REACT_APP_MODULE_CATEGORY_AI_ADVISOR === 'true' ? (
              <CRUDRoute
                exact
                path="/ai/advisor/"
                component={AIAdvisor}
                user={user}
                organization={selectedOrganization}
              />
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_AI_FORECAST === 'true' ? (
              <CRUDRoute
                exact
                path="/ai/forecast/"
                component={AIForecast}
                user={user}
                organization={selectedOrganization}
              />
            ) : null}
          </>
        ) : null}
        {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM === 'true' ? (
          <>
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_SENTIMENT === 'true' ? (
              <AppRoute
                exact
                path="/premium/sentiment/"
                component={PremiumSentiment}
                user={user}
                organization={selectedOrganization}
                fullMonth
              />
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_FINANCIAL === 'true' ? (
              <AppRoute
                exact
                path="/premium/financial/"
                component={PremiumFinancial}
                user={user}
                organization={selectedOrganization}
                fullMonth
              />
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_LOYALTY === 'true' ? (
              <AppRoute
                exact
                path="/premium/loyalty/"
                component={PremiumLoyalty}
                user={user}
                organization={selectedOrganization}
                fullMonth
              />
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_NEIGHBORHOOD_PATTERNS === 'true' ? (
              <AppRoute
                exact
                path="/premium/neighborhood/"
                component={PremiumNeighborhood}
                user={user}
                organization={selectedOrganization}
                fullMonth
              />
            ) : null}
          </>
        ) : null}
      </div>
    </Router>
  );
}

export default App;
