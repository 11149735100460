import React, { useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { Alert, FormGroup, Col, FormFeedback, Button, Input, Card } from 'reactstrap';
import { Loading } from '../../components/utils/Loading';
import Logo from '../login/Logo';

export default function ForgotPassword(props) {
  const [loaded, setLoaded] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const onSubmit = params => {
    setLoaded(false);
    axios
      .post('/v2/auth/reset-password', params)
      .then(res => {
        setLoaded(true);
        toastr.success('OK!');
        setMessage('OK! Please check your e-mail');
      })
      .catch(err => {
        setLoaded(true);
        const errorMessage =
          err.response && err.response.data ? err.response.data.message : 'Error';

        toastr.error(errorMessage);
        setError(errorMessage);
      });
  };

  return (
    <div className="view_cont">
      <Card className="main_window">
        <div className="main_data main_signin mt-3">
          <Loading loaded={loaded} />
          <Logo />
          <div className="data_col m_10">
            <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Is not a valid e-mail').required('Email is required')
              })}
              onSubmit={onSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  {message && <Alert color="info">{message}</Alert>}
                  {error && <Alert color="danger">{error}</Alert>}
                  <Col msm={9}>
                    <FormGroup>
                      <Input
                        placeholder="E-mail"
                        className="form_item text_form"
                        type="email"
                        name="email"
                        tag={Field}
                        invalid={errors.email && touched.email}
                      />
                      {errors.email && touched.email && <FormFeedback>{errors.email}</FormFeedback>}
                    </FormGroup>
                  </Col>
                  <Button color="primary" type="submit" className="form_bt bt_common mt-3">
                    <div className="form_bt_label label_common text_label">RESET PASSWORD</div>
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="main_common m_10">
          <div className="text_check text_small text_3_color">
            <p>
              Back to <Link to="/login">login</Link>.
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
}
