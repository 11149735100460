import React from 'react';

import { useMappedState } from 'redux-react-hook';

import './dashboard.scss';

import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';

import { useDashboardData, useFullDemosObj } from '../../hooks/metrics/esdata';
import { ExportExcelPanel } from '../../components/utils/ExportExcel';
import { filterSelector } from '../../redux/ducks/filters';

export default function DashboardPOIDetails(props) {
  const { poiType, scrollableRef } = props;
  const { filter } = useMappedState(filterSelector);
  const typeFilter = filter[poiType];

  const singlePoiFilter = {
    ...typeFilter,
    groupKeys: [],
    storeKey: props.match.params.id
  };

  const {
    responseVisits,
    responseDemos,
    responseTech,
    responseAppBundles,
    responseHeatmap,
    responseFullDemos,
    projectionData
  } = useDashboardData(singlePoiFilter, {
    qVisits: true,
    qDemos: true,
    qTech: true,
    qAppBundles: true,
    qHeatmap: true,
    qFullDemos: true
  });

  const filterComponent = (
    <ExportExcelPanel
      {...{
        responseFullDemos,
        responseVisits,
        filter: singlePoiFilter,
        status: {
          disabled: false
        }
      }}
    />
  );

  const fullDemosObj = useFullDemosObj(responseFullDemos, responseVisits);

  return (
    <div className="view">
      <ViewHeader {...{ filterComponent }} />
      <ViewContent
        {...{
          filter: singlePoiFilter,
          responseVisits,
          responseDemos,
          responseTech,
          responseAppBundles,
          responseHeatmap,
          fullDemosObj,
          type: props.user.type,
          scrollableRef,
          projectionData
        }}
      />
    </div>
  );
}
