import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import i18n from 'i18n-js';

import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar
} from 'recharts';

import { colors } from '../../components/charts/Styles';

export default function VisitorsChart({
  uniquesTitle,
  visitsTitle,
  visitorsAndVisitsResponse,
  fixedPeriods
}) {
  if (!visitorsAndVisitsResponse || visitorsAndVisitsResponse.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const max = Math.round(
    visitorsAndVisitsResponse.reduce((accum, val) => (accum > val.visits ? accum : val.visits), 0)
  );
  const multi = '1'.padEnd(String(max).length - 1, '0');

  const d = Math.ceil(max / multi) * multi + multi * 2;

  function CustomTooltip({ active, payload, label, fixedPeriods }) {
    let v = null;
    if (fixedPeriods === 'false')
      v = visitorsAndVisitsResponse.find(r => r._id.period_quarter === label);
    else v = visitorsAndVisitsResponse.find(r => r._id.period_quarter_start === label);
    if (active && v !== null) {
      return (
        <div className="custom-tooltip">
          <p>
            <b>
              {moment(v._id.period_quarter_start).utc().format('MMMM DD')} -{' '}
              {moment(v._id.period_quarter_end).utc().format('MMMM DD')}
            </b>
          </p>
          <hr />
          <p style={{ color: payload[1].color }}>
            {` ${payload[1].name}`} :{' '}
            {` ${numeral(payload[1].value).format(payload[1].value > 10000000 ? '0.000a' : '0,0')}`}
          </p>
          <p style={{ color: payload[0].color }}>
            {` ${payload[0].name}`} :{' '}
            {` ${numeral(payload[0].value).format(payload[0].value > 10000000 ? '0.000a' : '0,0')}`}
          </p>
        </div>
      );
    }

    return null;
  }

  return (
    <ResponsiveContainer>
      <ComposedChart
        data={visitorsAndVisitsResponse}
        margin={{ top: 10, right: 10, left: 10, bottom: 0 }}
      >
        <CartesianGrid stroke="#eee" vertical={false} />
        <XAxis
          dataKey={fixedPeriods === 'false' ? '_id.period_quarter' : '_id.period_quarter_start'}
          axisLine={false}
          fontSize={10}
          tickLine={false}
          padding={{ left: 0, right: 5 }}
          tickFormatter={value =>
            fixedPeriods === 'false'
              ? `${i18n.t('week')} #${value + 1}`
              : moment(value).utc().format('DD/MM')
          }
        />
        <YAxis
          yAxisId="visitors"
          fontSize={10}
          axisLine={false}
          tickLine={false}
          type="number"
          domain={[0, d]}
          tickFormatter={value =>
            // eslint-disable-next-line no-nested-ternary
            numeral(value).format(value > 1000000 ? '0.00a' : value > 100000 ? '0a' : '0,0')
          }
        />
        <YAxis
          yAxisId="impressions"
          orientation="right"
          fontSize={10}
          axisLine={false}
          tickLine={false}
          domain={[0, d]}
          tickFormatter={value =>
            // eslint-disable-next-line no-nested-ternary
            numeral(value).format(value > 1000000 ? '0.00a' : value > 100000 ? '0a' : '0,0')
          }
        />
        <Tooltip content={<CustomTooltip fixedPeriods={fixedPeriods} />} />
        <Legend />
        <Area
          type="monotone"
          yAxisId="impressions"
          dataKey="visits"
          name={i18n.t(visitsTitle)}
          stackId="1"
          strokeWidth={2}
          stroke={colors[9]}
          fill={colors[9]}
          fillOpacity="0.9"
        />
        <Bar
          type="monotone"
          barSize={20}
          yAxisId="visitors"
          name={i18n.t(uniquesTitle)}
          dataKey="unique_visitors"
          stackId="1"
          strokeWidth={2}
          stroke={colors[0]}
          fill={colors[0]}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
