import i18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';

import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';

import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';

import { useDispatch } from 'redux-react-hook';
import { logout } from '../../redux/ducks/auth';

import API2 from '../../api2';

import { authSetOrganization, authSetUser } from '../../redux/ducks/auth';
import LoadingModal from '../../components/utils/LoadingModal';
import { callSyncAPIv2DataLoader } from '../../hooks/api';

export default function EditOrganization(props) {
  const { user, withNav } = props;
  const [success, setSuccess] = useState(false);
  const [trialPeriodDays, setTrialPeriodDays] = useState(0);

  useEffect(() => {
    if (success && user.organizations) {
      setTimeout(props.history.push('/'), 200);
    }
  }, [success, props, user]);

  useEffect(() => {
    let isMounted = true;

    callSyncAPIv2DataLoader(
      'appConfig',
      'billing/trialPeriod',
      {},
      { cache: true, responseHolder: `response` },
      res => {
        if (isMounted) {
          res.hasValue('response') &&
            setTrialPeriodDays(res.data.value.response?.billing?.trialPeriod?.days);
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, []);

  let { organization } = props;

  if (props.match.params.id) {
    organization = { id: 'mooooock', name: 'orgaaamock' };
  }

  const editOrganizationLabel = organization
    ? i18n.t('edit_organization')
    : i18n.t('create_organization');
  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('edit_organization_title')}</h1>
          <p className="mb-0">{editOrganizationLabel}</p>
        </header>
      </div>
      <ViewContent
        user={user}
        organization={organization}
        onSuccess={setSuccess}
        withNav={withNav}
        trialPeriodDays={trialPeriodDays}
      />
    </div>
  );
}

function ViewContent({ user, organization, onSuccess, withNav, trialPeriodDays }) {
  return (
    <div className="view-content view-components">
      <Card className="mb-4">
        <CardBody className="table-responsive">
          <h6 className="mb-4 text-uppercase">{i18n.t('organization_info')}</h6>
          <Card>
            <CardBody>
              <EditProfileForm
                user={user}
                organization={organization}
                onSuccess={onSuccess}
                withNav={withNav}
                trialPeriodDays={trialPeriodDays}
              />
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </div>
  );
}

function EditProfileForm({ user, organization, onSuccess, withNav, trialPeriodDays }) {
  const dispatch = useDispatch();
  const logoutDispath = () => dispatch(logout());
  const [isLoading, setLoading] = useState(false);

  const onSubmit = params => {
    setLoading(true);
    API2.post(
      'organizations',
      '',
      { organization: { name: params.name }, associateOwner: true },
      (err, newOrg) => {
        if (err) {
          toastr.error(`${i18n.t('error_saving_organization')}: ${err}`);
        } else {
          dispatch(authSetOrganization(newOrg)); // This is not necessarily correct if I'm not creating a new org, or working as an admin
          toastr.success(`${i18n.t('ok_saving_organization')}`);
          API2.get('users', `${user.id}/organizations`, {}, (err, orgs) => {
            if (err) {
              toastr.error(`${i18n.t('error_loading_organizations')}: ${err}`);
            } else {
              user.organizations = orgs;
              dispatch(authSetUser(user));
              onSuccess(true);
            }
            setLoading(false);
          });
        }
      }
    );
  };

  return (
    <>
      {isLoading && <LoadingModal isOpen={true} text={i18n.t('organization_loading')} />}
      <Formik
        initialValues={{ name: organization?.name }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(i18n.t('organization_name_required'))
        })}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            {/* {authError && <Alert color="danger">{authError}</Alert>} */}
            <Row>
              {!organization && (
                <Col sm={5} className="pr-5">
                  <span
                    className="w-100"
                    dangerouslySetInnerHTML={{
                      __html: i18n.t('organization_signup_text', { trialPeriodDays })
                    }}
                  ></span>
                </Col>
              )}
              <Col sm={!organization ? 7 : 12}>
                <FormGroup>
                  <Label for="name">{i18n.t('organization_name')}</Label>
                  <Input
                    placeholder="ACME Inc."
                    className="form_item text_form"
                    type="text"
                    name="name"
                    tag={Field}
                    invalid={errors.name && touched.name}
                    readOnly={organization != null}
                  />
                  {errors.name && touched.name && <FormFeedback>{errors.name}</FormFeedback>}
                </FormGroup>
                <div className="align-items-center d-flex flex-column">
                  {!organization && (
                    <Button color="primary" type="submit" className="form_bt bt_common mt-3 w-25">
                      {i18n.t('save_button')}
                    </Button>
                  )}
                  {!withNav && (
                    <Button
                      color=""
                      onClick={logoutDispath}
                      className="btn-block border-0 shadow-none btn-sm mt-5"
                    >
                      {i18n.t('logout')}
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}
