import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
import ZoneMarker from './ZoneMarker';
import { createClusterCustomIcon } from './config';

export function ZoneMap(props) {
  const { pois, onChange, zoom } = props;

  const defaultProps = {
    center: {
      lat: window._env_.REACT_APP_DEFAULT_LATITUDE,
      lng: window._env_.REACT_APP_DEFAULT_LONGITUDE
    },
    zoom
  };

  return (
    <MapContainer
      className="markercluster-map-full"
      center={defaultProps.center}
      zoom={defaultProps.zoom}
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      <MarkerClusterGroup
        showCoverageOnHover={false}
        spiderfyOnMaxZoom={false}
        zoomToBoundsOnClick={true}
        spiderfyDistanceMultiplier={2}
        disableClusteringAtZoom={12}
        iconCreateFunction={createClusterCustomIcon}
      >
        {pois.map(p => (
          <ZoneMarker key={p._id} poi={p} onClick={() => onChange && onChange(p)} />
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
}
