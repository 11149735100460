export const translations = {
  es: {
    dashboard_title: 'Panel',
    dashboard_text: 'Foot Traffic Analytics',
    dashboard_reports: 'REPORTES',
    dashboard_pdv: 'PDI',
    dashboard_ooh: 'OOH',
    visitors_title: 'Visitas totales y visitantes únicos',
    visitors_title_ooh: 'Usuarios totales y usuarios únicos',
    visits_tooltip: `Personas que pasaron por PDIs del segmento seleccionado y cumplen con los criterios de permanencia`,
    visits_projected_tooltip: `Esta proyección se basa en la proporción entre la muestra y la población del área de los PDIs analizados. Dicha proporción se aplica a la cantidad de visitas observadas`,
    unique_visitors_tooltip: `Las personas visitan <superscript>1</superscript> un PDI m;as de una vez. La métrica de Usuarios Únicos no considera esas repeticiones. En otras palabras <b>si una persona visita uno o más PDIs del segmento más de una vez, serán contadas una única vez.</b>
    <br /><br /><small>1: Una visita es una persona pasando por un PDI que cumple con los criterios de permanencia.</small>`,
    unique_visitors_projected_tooltip:
      'Esta proyección se basa en la proporción entre la muestra y la población del área de los PDIs analizados. ',
    sample_size: 'Sample Size',
    reference_population: 'Reference Population',
    observations_count: 'Observations Count',
    unique_visitors_title: 'Visitantes únicos',
    total_unique_visitors_title: 'Total de visitantes únicos',
    total_unique_visitors_title_ooh: 'Total de usuarios únicos',
    avg_unique_visitors_title: 'Promedio de visitantes únicos',
    avg_unique_visitors_title_ooh: 'Promedio de usuarios únicos',
    total_unique_visitors_by_poi_title: 'Visitantes únicos por PDI',
    total_unique_visitors_text: 'El número de usuarios únicos vistos en el segmento',
    visits_title: 'Visitas',
    visits_title_ooh: 'Impresiones',
    total_visits_title: 'Visitas totales',
    total_visits_title_ooh: 'Impresiones totales',
    total_visits_text: 'El número de veces que los usuarios fueron vistos en el PDI',
    total_visits_text_ooh: 'El número de veces que los usuarios fueron vistos en el OOH',
    avg_unique_users_title: 'Usuarios únicos promedio',
    avg_unique_users_text: 'Volumen de usuarios únicos',
    avg_visits_title: 'Promedio de Visitas',
    avg_visits_title_ooh: 'Promedio de Impresiones',
    frequency_title: 'Frecuencia',
    frequency_text: 'El número promedio de veces que un usuario pasa por un PDI',
    frequency_text_ooh: 'El número promedio de veces que un usuario pasa por un OOH',
    avg_frequency_title: 'Frecuencia',
    accum_visits_title: 'Visitas acumuladas',
    avg_new_visitors_title: 'Nuevos visitantes',
    avg_new_visitors_title_ooh: 'Nuevos usuarios',
    avg_dwell_time_title: 'Tiempo de permanencia',
    avg_dwell_time_text: 'Tiempo promedio que los usuarios pasaron en la tienda por visita',
    repeat_visitors_title: 'Visitantes recurrentes',
    repeat_visitors_title_ooh: 'Usuarios recurrentes',
    total_repeat_visitors_title: 'Total de visitantes recurrentes',
    repeat_visits_text: 'Número total de usuarios únicos con más de una visita por semana',
    repeat_visits_text_ooh: 'Número total de usuarios únicos con más de una impresión por semana',
    new_visitors_title: 'Nuevos visitantes',
    new_visitors_title_ooh: 'Nuevos usuarios',
    new_visitors_text: 'Número de usuarios vistos por primera vez en los últimos 30 días en el PDI',
    new_visitors_text_ooh:
      'Número de usuarios vistos por primera vez en los últimos 30 días en el OOH',
    visitors_ratio_title: 'Proporción de visitantes nuevos',
    visitors_ratio_text: 'El porcentaje de visitantes nuevos del total de visitantes únicos',
    demographics_title: 'Datos Demográficos',
    demographics_filter_title: 'Segmentos Demográficos',
    mobility_trends: 'Tendencias de Movilidad',
    mobility_trends_general_info: 'Información general del segmento',
    mobility_per_day: 'Movilidad por día',
    mobility_by_mobile: 'Porcentaje de Usuarios en Movilidad',
    distance_distribution: 'Distribución de distancia',
    mobility_per_week: 'Visitas por semana',
    mobility_per_week_ooh: 'Impresiones por semana',
    mobility_per_week_text: 'Distribución de visitas según día y bloque horario',
    mobility_per_week_text_ooh: 'Distribución de impresiones según día y bloque horario',
    mobility_per_hour_block: 'Visitas por bloque horario',
    mobility_per_hour_block_ooh: 'Impresiones por bloque horario',
    mobility_per_hour_block_text: 'Distribución de visitas según bloque horario',
    mobility_per_hour_block_text_ooh: 'Distribución de impresiones según bloque horario',
    offline_behavior: 'Comportamiento offline',
    offline_behavior_text_how: `Analizamos cuántas de las personas impactadas en los PDIs del segmento seleccionado, se detectaron en PDIs de una determinada categoría<sup>1</sup><br /><br />
    Por ejemplo, del total de <b>{{total_impressions}}</b> personas impactadas por PDIs del segemento, <b>{{example_category_total_impressions}}</b> visitaron PDIs de la categoría <b>{{example_category}}</b> <br /><br />
    <small>1: Partiendo de una base de datos general de PDIs y sus categorías </small>`,
    offline_behavior_text: 'Otros lugares visitados por quienes visitan el PDI / Segmento',
    offline_behavior_text_ooh: 'Otros lugares visitados por quienes visitan el OOH / Segmento',
    online_behavior: 'Comportamiento online',
    online_behavior_text_how: `Se consideran las <b>{{top_genres_count}}</b> categorías con mayor cantidad de impresiones<sup>1</sup> y se calcula la distribución (es decir, el porcentaje sobre las <b>{{top_genres_total_impressions}}</b> impresiones de las categorías del top <b>{{top_genres_count}}</b>)<br /><br />
    <small>1: Se desestiman las categorías triviales por su popularidad. Por ejemplo "Redes Sociales"</small>`,
    online_behavior_text: 'Categorias de Apps utilizadas por quienes visitan el PDI / Segmento',
    online_behavior_text_ooh: 'Categorias de Apps utilizadas por quienes visitan el OOH / Segmento',
    gender: 'Género',
    age_range: 'Rango de edad',
    '18_24': '18 a 24 ',
    '20_24': '20 a 24 ',
    '25_29': '25 a 29 ',
    '30_34': '30 a 34 ',
    '35_39': '35 a 39 ',
    '30_39': '30 a 39 ',
    '40_44': '40 a 44 ',
    '45_49': '45 a 49 ',
    '40_49': '40 a 49 ',
    '50_54': '50 a 54 ',
    '55_59': '55 a 59 ',
    '50_59': '50 a 59 ',
    '60_64': '60 a 64 ',
    '65_69': '65 a 69 ',
    '60_69': '60 a 69 ',
    '70_79': '70 a 79 ',
    '70_plus': '70 o más',
    '80+': '80 o más',
    '70+': '70 o más',
    '80_plus': '80 o más',
    socioeconomic_level: 'Nivel socioeconómico',
    sunday: 'domingo',
    monday: 'lunes',
    tuesday: 'martes',
    wednesday: 'miércoles',
    thursday: 'jueves',
    friday: 'viernes',
    saturday: 'sábado',
    mobility_per_neighborhood: 'Movilidad por barrio',
    mobility_per_region: 'Movilidad por región',
    platform: 'Plataforma',
    make: 'Marca',
    model: 'Modelo',
    carrier: 'Operador',
    top_app_categories: 'Principales categorías Online',
    heatmap_title: 'Mapa de calor',
    segment_heatmap: 'Mapa de calor',
    by_segment: 'Por segmento',
    by_day: 'por día',
    download_data: 'Descargar Métricas',
    download_data_generating_file: 'Generando Archivo...',
    download_disabled: 'Descarga Desactivada',
    download_disabled_reason:
      'Actualmente no se puede descargar datos de grupos con más de {{download_threshold}} POIs',
    glossary_title: 'Glosario',
    definitions_title: 'Definiciones',
    metrics_title: 'Métricas',
    reports_title: 'Informes',
    mobility_avg: 'Prom. Movilidad',
    date_title: 'Fecha',
    share: 'Cuota',
    total: 'total',
    avg: 'promedio semanal',
    avg_period: 'promedio cuartos',
    neighborhood_title: 'Barrio',
    trends_day_title: 'por día',
    trends_neighborhood_title: 'por barrio',
    period: 'Periodo',
    projected_value: 'Valor Proyectado',
    projected_value_tooltip: `Valor Pryectado: En los casos en que algunos datos no se encuentran disponibles, utilizamos herramientas para completar la información faltante`,
    by_week: 'por semana',
    home_to_poi_title: 'de casa a pdv',
    home_to_poi_title_ooh: 'de casa a ooh',
    home_to_poi_text_how: `Representa grupos de personas que viven en un rango de distancia de los PDIs seleccionados. Por ejemplo, para el rango "<b>{{example_range}}</b>", 
    la cantidad de Impresiones es de <b>{{example_range_impressions}}</b>, sobre un total de <b>{{total_impressions}}</b>.
    
    <br /><br /><b>Fórmula</b><br />(Impresiones en el rango / Impresiones totales) * 100<br/>
    <b>Para el rango "{{example_range}}"</b><br/>
     ({{example_range_impressions}} / {{total_impressions}}) * 100 = {{result}}`,
    work_to_poi_title: 'del trabajo a pdv',
    work_to_poi_title_ooh: 'del trabajo a ooh',
    work_to_poi_text_how: `Representa grupos de personas que trabajan en un rango de distancia de los PDIs seleccionados. Por ejemplo, para el rango "<b>{{example_range}}</b>",
    la cantidad de Impresiones es de <b>{{example_range_impressions}}</b>, sobre un total de gente que trabaja<sup>1</sup> de <b>{{total_work_impressions}}</b>
    
    <br /><br /><b>Formula</b><br />(Impresiones en el rango / Impresiones total de gente que trabaja) * 100<br/>
    <b>Para el rango "{{example_range}}"</b><br/>
     ({{example_range_impressions}} / {{total_work_impressions}}) * 100 = {{result}}
     
     <br /><br />
     <small>1: Total de gente que trabaja: Solo considera gente que trabaja.</small>
     `,
    no_data: 'No hay datos para mostrar',
    no_data_min: 'Sin datos',
    up_to: 'hasta',
    more_than: 'más que',
    or_more: 'o más',
    dwell_time_title: 'Tiempo de permanencia',
    dwell_time_text: 'Distribución de la duración de las visitas al PDI/Segmento',
    quartile: 'cuartil',
    percentile: 'percentil',
    flow_map: 'Mapas de flujo',
    flow_map_text: 'Concentración de personas antes y después de visitar el PDI / Segmento',
    flow_map_text_ooh: 'Concentración de personas antes y después de visitar el OOH / Segmento',
    by_poi: 'por PDI',
    by_ooh: 'por OOH',
    compare_menu: 'Análisis Competitivo',
    planner_menu: 'Planner',
    planner_menu_new: 'nuevo',
    planner_menu_manager: 'Administrar',
    admin_menu: 'Admin',
    admin_menu_poi: 'PDI',
    info_menu: 'Información',
    info_menu_glossary: 'Glosario',
    audience_builder: 'Audience Builder',
    ai_menu: 'AI (demo)',
    ai_menu_advisor: 'Advisor',
    ai_menu_forecast: 'Forecasts',
    heatmaps_title: 'Mapa de calor',
    save_audience_title: 'Guardar audiencia',
    save_audience_text: 'Elija un nombre. Esta audiencia estará lista en su Manager pronto. ',
    audience_name: 'Nombre',
    save_button: 'Guardar',
    cancel_button: 'Cancelar',
    confirm_button: 'Confirmar',
    location_title: 'Ubicación',
    error_too_short: '¡Demasiado corto!',
    error_too_long: '¡Demasiado largo!',
    error_required: 'Requerido',
    error_saving_poi: 'Error al guardar el PDI',
    ok_saving_poi: '¡PDI creado!',
    ok_saving_pois_group: '¡Grupo de PDIs creado!',
    poi_name: 'Nombre de PDI',
    filters_title: 'Filtro',
    pois_title: 'Punto de interés',
    pois_distance: 'Distancia',
    apps_usage: 'Uso de apps',
    apply_filters_button: 'Aplicar filtro',
    save_audience_button: 'Guardar audiencia',
    results_title: 'Resultados',
    results_filters: 'Filtros aplicados',
    estimated_users: 'Alcance estimado',
    top_match: 'Top Match',
    map: 'Mapa',
    audience_title: 'Audiencias',
    audience_list: 'Lista de audiencias',
    audience_size: 'Tamaño',
    audience_creation_date: 'Creado',
    audience_status: 'Estado',
    audience_actions: 'Acciones',
    audience_details: 'Detalles de la audiencia',
    audience_request_feed: 'Solicitar fuente de datos',
    audience_activate: 'Activar audiencia',
    planner_manager: 'Planner Manager',
    search_for: 'Buscar ...',
    visitor_title: 'Visitante',
    visitor_text: 'Un dispositivo visto dentro de los 50 mts del segmento',
    work_title: 'Trabajo',
    work_text:
      'Área donde se vio el dispositivo la mayor parte del tiempo durante el horario laboral (9 am a 6pm) ',
    home_title: 'Casa',
    home_text:
      'Área donde se vio el dispositivo la mayor parte del tiempo fuera del horario laboral (9 a.m. a 6 p.m.)',
    admin_poi_new: 'Nuevo PDI',
    admin_poi_title: 'Administrar PDI',
    admin_pois_list: 'Lista de PDIs',
    admin_oohs_list: 'Lista de OOHs',
    admin_pois_code: 'Código',
    admin_pois_name: 'Nombre',
    admin_pois_cp: 'Nombre de CP',
    admin_pois_rep: 'Nombre de Rep',
    admin_pois_so: 'Oficina de ventas',
    admin_pois_address: 'Dirección',
    admin_pois_address_number: 'Número',
    admin_pois_ownership: 'Compañía',
    admin_pois_city: 'Ciudad',
    admin_pois_state: 'Región',
    admin_pois_zipcode: 'Código postal',
    admin_pois_country: 'País',
    admin_pois_local: 'Local',
    admin_pois_status: 'Estado',
    admin_pois_actions: 'Acciones',
    admin_pois_action_delete: 'Eliminar PDI',
    admin_pois_status_open: 'Abierto',
    admin_pois_status_closed: 'Cerrado',
    admin_pois_status_unknown: 'Desconocido',
    admin_pois_location: 'Ubicación',
    admin_pois_type: 'Tipo',
    prev_visit_poi: 'Antes de visitar el PDI',
    prev_visit_ooh: 'Antes de visitar el OOH',
    post_visit_poi: 'Después de visitar el PDI',
    post_visit_ooh: 'Después de visitar el OOH',
    radar_title: 'Radar',
    radar_text: 'Radar de PDIs',
    radar_text_ooh: 'Radar de OOHs',
    performance_title: 'Rendimiento',
    performance_text: 'Rendimiento de PDIs',
    performance_text_ooh: 'Rendimiento de OOHs',
    performance_best: 'Mejores rendimientos',
    performance_worst: 'Peores rendimientos',
    performance_visits: 'Visitas período seleccionado',
    performance_previous_visits: 'Visitas período anterior',
    performance_variation: 'Variación M/M',
    performance_previous_period: 'Período previo',
    performance_selected_period: 'Período seleccionado',
    error_saving_audience: 'Error al guardar la audiencia',
    ok_saving_audience: '¡Audiencia creada!',
    out_of: 'de',
    audience_loading: 'Cargando audiencia ...',
    popup_confirm_title: '¿Estás seguro?',
    popup_confirm_text: 'Confirme su acción',
    notifications_none: 'Sin notificaciones',
    notifications_new: 'nuevas notificaciones',
    edit_profile: 'Editar perfil',
    edit_organization: 'Editar organización',
    create_organization: 'Crear organización',
    edit_password: 'Cambiar contraseña',
    logout: 'Cerrar sesión',
    edit_password_title: 'Contraseña',
    new_password_title: 'Nueva contraseña',
    password_too_short: 'La contraseña debe tener al menos 6 caracteres',
    password_required: 'Se requiere contraseña',
    password_confirm_required: 'Se requiere confirmación de contraseña',
    password_confirm_not_match: 'Las contraseñas no coinciden',
    password_field: 'Contraseña',
    password_confirm_field: 'Confirmar contraseña',
    edit_profile_title: 'Perfil',
    edit_organization_title: 'Organización',
    organization_info: 'Información de la organización',
    organization_name: 'Nombre de la organización',
    organization_name_required: 'El nombre de la organización es requerido',
    new_profile_title: 'Información de usuario',
    profile_first_name_too_short: 'El nombre debe tener al menos 2 caracteres',
    profile_first_name_required: 'Nombre es obligatorio',
    profile_last_name_too_short: 'El apellido debe tener al menos 2 caracteres',
    profile_last_name_required: 'Apellido es obligatorio',
    profile_first_name: 'Nombre',
    profile_last_name: 'Apellido',
    week: 'Semana',
    country_label: 'País',
    segment_label: 'PDI/Segmento',
    segment_label_ooh: 'OOH/Segmento',
    period_label: 'Periodo',
    class_a: 'A',
    class_b: 'B',
    class_c: 'C',
    class_d: 'D',
    class_e: 'E',
    yes: 'Si',
    no: 'No',
    store_age_title: 'Antigüedad',
    mobility_trends_by_zone: 'En zona de influencia',
    visits_label: 'Visitas',
    visits_label_ooh: 'Impresiones',
    mobility_week: 'Visitas Semana',
    mobility_week_ooh: 'Impresiones Semana',
    mobility_weekend: 'Visitas Fin de Semana',
    mobility_weekend_ooh: 'Impresiones Fin de Semana',
    aggregation_label: 'Agregación',
    aggregation_text:
      'En agregación individual se promedian los valores correspondientes a cada PDI que forman el segmento. ',
    aggregation_group: 'Grupo',
    aggregation_single: 'Individual',
    pois_by_segment: 'PDIs en segmento',
    pois_by_segment_ooh: 'OOHs en segmento',
    pois_by_segment_tooltip:
      'Un segmento puede contener uno o más PDIs/OOHs. En este caso, el segmento seleccionado {{segment}} contiene {{poisCount}} PDIs/OOHs',
    '0_500_mts': 'hasta 500 mts',
    '500_1000_mts': 'de 500 a 1000 mts',
    '1000_2000_mts': 'de 1000 a 2000 mts',
    '0_03_miles': 'hasta 0.3 millas',
    '03_06_miles': 'de 0.3 a 0.6 millas',
    '06_125_miles': 'de 0.6 a 1.25 millas',
    by_foot: 'A Pie',
    by_car: 'En automóvil',
    block: 'Bloque',
    go_to_dashboard: 'Ver Panel',
    pois_status_open_text: 'Visitas superior a 50% versus Marzo 2020',
    pois_status_closed_text: 'Visitas inferior a 50% versus Marzo 2020',
    filters_value: 'Valor',
    filters_score: 'Puntos',
    results_reach: 'Audiencia',
    click_to_filter: 'Click para filtrar',
    ranking_title: 'Ranking',
    ranking_subtitle: 'Medición por visitas',
    ranking_visits: 'Visitas período seleccionado',
    ranking_visits_ooh: 'Impresiones período seleccionado',
    ranking_variation: 'Variación',
    ranking_ooh_title: 'Ranking de OOH',
    ranking_ooh_subtitle: 'Ranking de carteles según impresiones',
    ranking_pdv_title: 'Ranking de PDI',
    ranking_pdv_subtitle: 'Ranking PDIs por visitas',
    ranking_pdv_by_group_title: 'Ranking de PDI',
    ranking_pdv_by_group_subtitle: 'Grupos por visitas',
    ranking_attribution_ooh_title: 'Ranking de OOH por atribución',
    ranking_attribution_ooh_subtitle:
      'Ranking de carteles por efectividad según visitas generadas en restaurantes',
    ranking_attribution_pdv_title: 'Ranking de PDI por atribución',
    ranking_attribution_pdv_subtitle: 'Ranking de PDIs por efectividad de campaña',
    by_fraction: 'por cuartos del periodo',
    fraction: 'Cuarto',
    map_type_both: 'Ambos',
    map_type_heatmap: 'Heatmap',
    map_type_flowmap: 'Flowmap',
    ai_advisor_title: 'Advisor',
    ai_advisor_text:
      'Puede utilizar estos datos para mejorar la productividad, aumentar los ingresos y brindar un mejor servicio al cliente optimizando varias partes de su operación.',
    ai_advisor_recommendations: {
      one: 'recomendación',
      other: 'recomendaciones'
    },
    ai_advisor_recommendation_impact: 'Impacto',
    ai_advisor_recommendation_description: 'Descripción',
    ai_advisor_recommendation_benefit: 'Beneficio',
    ai_advisor_marketing: 'Marketing',
    ai_advisor_marketing_text:
      'Distribución de inversiones en medios online y offline para obtener el mejor ROI.',
    ai_advisor_performance: 'Performance',
    ai_advisor_performance_text:
      'Incrementar el volumen de visitantes, visitas y mejorar el tiempo de permanencia.',
    ai_advisor_churn: 'Churn',
    ai_advisor_churn_text:
      'Reducir la cantidad de clientes que abandonan sus productos o servicios.',
    ai_advisor_competition: 'Competencia',
    ai_advisor_competition_text:
      'Capture la audiencia de su competencia y conviértala en consumidores.',
    ai_advisor_revenue: 'Ingresos',
    ai_advisor_revenue_text: 'Incrementar el crecimiento de las ventas y los ingresos.',
    ai_advisor_costs: 'Costos',
    ai_advisor_costs_text: 'Reducir costos y optimizar operaciones.',
    ai_forecast_title: 'Forecast',
    ai_forecast_text:
      'Puede utilizar estos datos para anticipar cambios en el comportamiento de los consumidores y adaptar su operación.',
    ai_forecast_visits: 'Visitas',
    ai_forecast_visits_text: 'Proyección de visitas basada en datos históricos.',
    ai_forecast_revenue: 'Ingresos',
    ai_forecast_revenue_text: 'Predicción de ingresos diarios basada en datos históricos.',
    ai_forecast_projected_revenue: 'Ingresos proyectados',
    ai_forecast_projected_visits: 'Visitas proyectadas',
    ai_forecast_projected_visitors: 'Visitantes proyectados',
    ai_forecast_current: 'Actual',
    ai_forecast_projected: 'Proyectado',
    premium_menu: 'Premium',
    premium_menu_sentiment: 'Sentiment',
    premium_sentiment_title: 'Sentiment Intelligence',
    premium_sentiment_text:
      'Data Appeal proporciona una combinación única de datos geoespaciales, de opinión y de inteligencia de mercado.',
    premium_sentiment_average: 'Sentiment Score',
    premium_sentiment_unique_authors: 'Unique Authors',
    premium_sentiment_posts: 'Posts',
    premium_menu_financial: 'Financial',
    premium_financial_title: 'Financial',
    premium_financial_text:
      'Nuestros datos diferenciados y potentes análisis significan conocimientos y soluciones para cada etapa del customer journey.',
    premium_financial_economic_level: 'Socioeconomic Levels',
    premium_financial_credit_risk: 'Credit Risk Analysis',
    premium_financial_own_car: 'Car Owner',
    premium_menu_loyalty: 'Loyalty',
    premium_loyalty_title: 'Loyalty',
    premium_loyalty_text:
      'Aplicamos Inteligencia Artificial transparente a los datos para agregar acción y medibilidad.',
    premium_loyalty_customer_title: 'Customer loyalty to brands',
    premium_loyalty_receptive_title: 'How receptive are consumer to new brands?',
    premium_loyalty_receptive_1: 'Are sometimes open to trying new brands',
    premium_loyalty_receptive_2: 'Would love to try new brands',
    premium_loyalty_receptive_3: 'Never try new brands and products',
    premium_menu_neighborhood_patterns: 'Neighborhood Patterns',
    premium_neighborhood_patterns_title: 'Neighborhood Patterns',
    premium_neighborhood_patterns_text:
      'Comprenda qué tan ocupada está un área, cuándo está ocupada y la composición demográfica de los visitantes.',
    premium_neighborhood_patterns_composition: 'Composiciones comunitarias',
    premium_neighborhood_patterns_class: 'Niveles socioeconómicos',
    audit_menu: 'Auditoría',
    audit_title: 'Auditoría',
    audit_subtitle: 'Atribución y Medición de campañas OOH',
    back: 'Volver',
    continue: 'Continuar',
    finish: 'Finalizar',
    new: 'Nuevo',
    audit_period: 'Período',
    audit_ooh: 'OOH',
    audit_pdv: 'PDI',
    audit_status: 'Estado',
    audit_actions: 'Acciones',
    audit_result_title: 'Resultados',
    audit_result_subtitle: 'Auditoría OOH y reportes de atribución',
    audit_result_description: 'Descripción',
    audit_result_value: 'Valor',
    audit_age_range_title: 'Rangos de edades',
    audit_age_range_subtitle: 'Rangos de edades por grupo',
    audit_dwell_time_title: 'Tiempo de permanencia',
    audit_dwell_time_subtitle: 'Tiempo de permanencia promedio por grupo',
    audit_control_group_title: 'Monitoreo',
    audit_control_group_subtitle: 'Cantidad de visitantes vs Grupo de control',
    audit_visitors_title: 'Datos de los visitantes',
    audit_visitors_subtitle: 'Visitas totales y visitantes únicos',
    audit_total_impacted: 'Personas expuestas al circuito OOH',
    audit_total_impacted_visitors: 'Personas expuestas al circuito OOH que visitaron los PDI',
    audit_conv_rate: 'Tasa de conversión',
    audit_uplift: 'Incremento afectado por campaña',
    audit_total_oohs: 'Número de OOHs',
    audit_total_pois: 'Número de PDIs',
    audit_visitors_real: 'Real',
    audit_visitors_control: 'Control',
    audit_visitors_predict: 'Esperado',
    journey_title: 'Customer Journey',
    journey_text: 'Análisis de visitas a PDI',
    journey_text_ooh: 'Análisis de impactos de OOH',
    journey_correlation: 'Correlación de visitantes',
    journey_by_share: 'Por visitantes compartidos',
    journey_by_dwell: 'Por tiempo de permanencia',
    journey_flow: 'Flujo de visitas',
    journey_flow_text: 'Análisis de visitas según origen y destino',
    dwell_time_label: 'Tiempo de Permanencia',
    visitors_label: 'Visitantes',
    error_filtering_audience: 'Debe completar todos los filtros requeridos',
    planner_poi_details_title: 'Detalles del PDI',
    planner_poi_details_add_to_cart: 'Seleccionar',
    planner_poi_details_remove_from_cart: 'Seleccionado',
    planner_filters_matchers: 'Target',
    planner_distance_no_filter: 'Sin filtro',
    planner_refresh_reach: 'Actualizar Audiencia',
    planner_metrics_title: 'Métricas',
    planner_metrics_universe: 'Universo Población',
    planner_metrics_target: 'Universo Target',
    planner_metrics_reach: 'Alcance Target',
    planner_metrics_share: 'Alcance Target %',
    planner_metrics_frequency: 'Frecuencia Media',
    planner_metrics_hits: 'Impactos Target',
    planner_metrics_reach_less: 'Menor',
    planner_metrics_reach_more: 'Mayor',
    planner_fraction: 'Cuartos de período',
    planner_update_reach_warning:
      'Actualice métricas para corresponder a selección de puntos actual.',
    planner_update_filters_warning:
      'Actualice los filtros para corresponder a selección de puntos actual.',
    planner_update_warning: 'Hay actualizaciones pendientes.',
    planner_poi_create: 'Crear',
    planner_poi_import: 'Importar',
    planner_poi_group_name: 'Nombre del grupo de ubicaciones',
    planner_error_poi_group_name:
      'Debe completar el nombre del grupo de ubicaciones (min: 4 - max: 100)',
    planner_error_poi_import: 'Debe finalizar la importación',
    planner_error_poi_group_empty_import: 'Debe completar el proceso de importación',
    planner_pois_partial_result: 'Se encontraron demasiados puntos. Mostrando:',
    csv_importer_fileStep_initialDragDropPrompt:
      'Arrastre y suelte un archivo CSV aquí, o haga click para seleccionar de una carpeta',
    csv_importer_fileStep_activeDragDropPrompt: 'Suelte el archivo CSV aquí...',
    csv_importer_fileStep_rawFileContentsHeadings: 'Contenido del archivo crudo',
    csv_importer_fileStep_previewImportHeading: 'Vista previa de la importación',
    csv_importer_fileStep_nextButton: 'Elegir columnas',
    csv_importer_fileStep_dataHasHeadersCheckbox: 'Las columnas tienen encabezado',
    csv_importer_fieldStep_dragTargetPlaceholder: 'Arrastre una columna aquí',
    csv_importer_fieldStep_stepSubtitle: 'Seleccione columnas',
    csv_importer_fieldStep_requiredFieldsError: 'Por favor, asigne todos los campos requeridos',
    csv_importer_fieldStep_nextButton: 'Importar',
    csv_importer_progressStep_stepSubtitle: 'Importar',
    csv_importer_progressStep_uploadMorebutton: 'Subir otro',
    csv_importer_progressStep_finshButton: 'Finalizar',
    csv_importer_progressStep_statusError: 'No se pudo importar',
    csv_importer_progressStep_statusComplete: 'Completo',
    csv_importer_progressStep_statusPending: 'Importando...',
    csv_importer_progressStep_processedRowsLabel: 'Filas procesadas:',
    csv_importer_fieldStep_page: 'Página',
    csv_importer_fieldStep_of: 'de',
    planner_save_campaign: 'Guardar Campaña',
    planner_save_campaign_title: 'Guardar Campaña',
    planner_save_campaign_text:
      'Elija un nombre. Esta campaña estará lista en su Administrador pronto.',
    campaign_name: 'Nombre',
    error_saving_campaign: 'Error al guardar Campaña',
    ok_saving_campaign: '¡Campaña creada!',
    campaign_list: 'Campañas',
    campaign_reach: 'Alcance',
    campaign_trp_population: 'TRP Población',
    campaign_trp_target: 'TRP Target',
    campaign_creation_date: 'Creada',
    campaign_created_by: 'Creado por',
    campaign_actions: 'Acciones',
    campaign_actions_clone: 'Clonar Campaña',
    campaign_actions_edit: 'Editar Campaña',
    campaign_actions_delete: 'Eliminar Campaña',
    campaign_loading: 'Cargando Campaña...',
    campaign_saving: 'Grabando Campaña...',
    campaign_name_copy: 'Copia de',
    campaign_builder: 'Audience Builder',
    campaign_reset_data: 'Restablecer filtros',
    planner_filter_by_reach: 'Filtrar puntos por alcance',
    planner_filter_by_reach_min: 'Al menos',
    planner_area_selection_title: 'Selección por Área',
    planner_confirm_selection: 'Confirmar',
    planner_reset_selection: 'Limpiar',
    planner_cancel_selection: 'Cancelar',
    planner_reach_selection_title: 'Selección por Alcance',
    planner_reach_selection_text_select: 'Seleccionar',
    planner_reach_selection_text_pois: 'puntos',
    planner_reach_selection_text_more_reach: 'con mayor alcance',
    smart_insights: 'Smart Insights',
    mobility_trends_general_info_single: 'Información general del {{type}}',
    planner_metrics_trp_population: 'TRP Población',
    planner_metrics_trp_target: 'TRP Target',
    audit_creation_date: 'Creada',
    audit_created_by: 'Creado por',
    audit_actions_delete: 'Eliminar Auditoría',
    audit_list: 'Auditorías',
    audit_campaign_period: 'Período de Campaña OOH',
    audit_campaign_config: 'Configuración del proceso de auditoría',
    audit_config_ooh_radius: 'Radio OOH',
    audit_config_pdv_radius: 'Radio PDI',
    audit_config_pdv_dwell_time: 'Tiempo de Permanencia en PDI',
    audit_config_pdv_dwell_time_mins: 'minutos',
    audit_config_attr_window: 'Ventana de Atribución',
    audit_config_attr_window_days: 'días desde el último impacto',
    planner_metrics_selected_pois: 'POIs seleccionados',
    search_for_text:
      'Se muestran hasta {{perPage}} resultados al mismo tiempo. Si no puede encontrar su PDI/Segmento, restrinja la búsqueda.',
    search_load_more: 'Cargar más',
    showing_results_for: 'Mostrando resultados para',
    search_for_help:
      'Puede buscar varios términos separando las palabras con espacios. Utilice comillas dobles para la coincidencia exacta.',
    search_selected_pois: 'POIs seleccionados',
    download_audience: 'Descargar Audiencia',
    download_audience_details:
      'Está a punto de solicitar un archivo CSV que contiene IDs de dispositivos únicos, que representan a los visitantes del segmento para el período seleccionado.',
    download_audience_details_raw: 'Audiencia Alcanzable',
    download_audience_details_raw_ids: 'IDs únicos',
    download_audience_details_segment: 'Segmento Seleccionado',
    download_audience_details_period: 'Período Seleccionado',
    download_audience_details_cost: 'Costo Total',
    download_audience_details_checkout: 'Checkout',
    download_audience_segment: 'Segmento',
    download_audience_period: 'Período',
    audiences_menu_manager: 'Administrar',
    audience_manager: 'Audience Manager',
    audience_created_by: 'Creado por',
    audience_actions_delete: 'Eliminar Audiencia',
    or_click_to_register: 'O haga clic aquí para registrarse',
    already_user_login: '¿Ya eres usuario? Acceder',
    register: 'Crear Cuenta',
    register_thanks:
      '¡Gracias por registrarte! Hemos enviado un correo electrónico a la dirección especificada para activar su cuenta.',
    register_close_window: 'Puede cerrar esta ventana ahora.',
    register_activating: 'Espere mientras activamos su cuenta...',
    register_now_active: 'Su cuenta ahora está activa. Redireccionando para iniciar sesión...',
    error_saving_organization: 'Error al guardar la organización',
    ok_saving_organization: '¡Organización creada!',
    organization_not_allowed:
      'No tiene permisos suficientes para administrar la facturación de esta organización: {{organization}}',
    organization_payment_ok: '¡Tu cuenta ha sido activada! Ahora puede continuar a la plataforma.',
    organization_payment_not_valid:
      'La información de pago de la organización {{organization}} no es válida.',
    organization_not_found: 'No se encontró la organización {{organization}} ',
    organization_save_payment_info: 'Agregar Información de Pago',
    organization_loading: 'Cargando Organización...',
    try_again: 'Intentar otra vez',
    edit_account: 'Cuenta',
    error_saving_profile: 'Error al guardar el perfil',
    ok_saving_profile: '¡Perfil guardado!',
    edit_subscription_title: 'Suscripción',
    manage_subscription: 'Admin Suscripción',
    subscription_info: 'Detalles de Suscripción',
    subscription_status: 'Status',
    subscription_action_cancel: 'Cancelar suscripción',
    subscription_renewal_date: 'Fecha de renovación',
    subscription_cost: 'Costo',
    subscription_last_payment: 'Último pago',
    edit_billing_title: 'Facturación',
    manage_billing: 'Administrar Facturación',
    manage_payment_methods: 'Administrar métodos de pago',
    billing_loading: 'Cargando portal de cliente...',
    error_customer_portal: 'Error al cargar el portal de cliente',
    billing_info: 'Detalles de facturación',
    subscription_loading: 'Cargando detalles de la suscripción...',
    organization_signup_text: `Tenga en cuenta que nuestro servicio viene con un período de prueba de <strong>{{trialPeriodDays}} días</strong>, durante el cual puede probar todas nuestras funciones y servicios sin cargo. Al final del período de prueba, se le cobrará a su tarjeta de crédito una suscripción completa a menos que la cancele.
    <br /><br />
    Si decide que no desea continuar con la suscripción, puede cancelar fácilmente en cualquier momento durante el período de prueba visitando la configuración de su cuenta o comunicándose con nuestro equipo de atención al cliente.`,
    audience_payment_ok: '¡El pago fue procesado con éxito!',
    select_payment_method: 'Por favor seleccione el método de pago',
    audiencie_purchase_confirm_title: 'Confirmar Pago',
    audiencie_purchase_confirm_text:
      'Está a punto de comprar la audiencia {{segmentLabel}}, período {{formattedSegmentPeriod}} por un precio total de {{formattedSegmentCost}}.',
    terms_please_accept: 'Acepte nuestros términos y condiciones.',
    terms_title: 'Términos y Condiciones',
    terms_accept: 'Acepto',
    terms_do_not_accept: 'No Acepto',
    terms_and_conditions: '',
    unknown_error_please_contact_support:
      'Ha ocurrido un error. Por favor, contacte a soporte ({{msg}}).',
    audience_not_allowed: 'No tienes suficientes permisos para administrar esta audiencia.',
    audience_payment_not_valid: 'La información de pago no es válida para esta audiencia.',
    search_no_data: 'No hay datos para mostrar para el período seleccionado.',
    error_selecting_poi: 'Este grupo tiene más PDIs que el máximo permitido',
    download_campaign: 'Descargar Campaña',
    download_campaign_summary: 'Resumen',
    download_campaign_pois: 'PDIs',
    planner_csv_selection_title: 'Selección por CSV',
    planner_csv_selection_match_error: 'Error de coincidencia: '
  },
  en: {
    dashboard_title: 'Dashboard',
    dashboard_text: 'Foot Traffic Analytics',
    dashboard_reports: 'REPORTS',
    dashboard_pdv: 'POI',
    dashboard_ooh: 'OOH',
    visitors_title: 'Total Visits and Unique Visitors',
    visitors_title_ooh: 'Total Impressions and Unique Users',
    visits_tooltip: `People who passed by the selected segment's POIs and fulfill permanence criteria`,
    visits_projected_tooltip: `This projections is based on the proportion between a sample and the population within the area of the analyzed POIs. This proportion is applied to the observed visits count`,
    unique_visitors_tooltip: `People visit <superscript>1</superscript> a POI more than once. Unique Users metric doesn't consider those repetitions. In otherwords <b>if a person visits one or more segment's POIs more than once, they will be counteed as only ONE unique visitor.</b>
    <br /><br /><small>1: A visit is a person passing by a POI that fulfill permanence criteria.</small>`,
    unique_visitors_projected_tooltip: `This projection is based on the proportion between the sample and the population the the area of the analyzed POIs`,
    sample_size: 'Sample Size',
    reference_population: 'Reference Population',
    observations_count: 'Observations Count',
    unique_visitors_title: 'Unique Visitors',
    total_unique_visitors_title: 'Total Unique Visitor',
    total_unique_visitors_title_ooh: 'Total Unique Users',
    avg_unique_visitors_title: 'Average unique visitors',
    avg_unique_visitors_title_ooh: 'Average unique users',
    total_unique_visitors_text: 'The number of unique Users seen at Segment',
    visits_title: 'Visits',
    visits_title_ooh: 'Impressions',
    total_visits_title: 'Total Visits',
    total_visits_title_ooh: 'Total impressions',
    total_visits_text: 'The number of times Users were seen at the POI',
    total_visits_text_ooh: 'The number of times Users were seen at the OOH',
    avg_unique_users_title: 'Average Unique Users',
    avg_unique_users_text: 'Volume of unique users',
    avg_visits_title: 'Average Visits',
    avg_visits_title_ooh: 'Average Impressions',
    frequency_title: 'Frequency',
    frequency_text: 'The average number of times a user passes through POI',
    frequency_text_ooh: 'The average number of times a user passes through OOH',
    avg_frequency_title: 'Frequency',
    accum_visits_title: 'Accumulated visits',
    avg_new_visitors_title: 'Average new visitors',
    avg_new_visitors_title_ooh: 'Average new users',
    avg_dwell_time_title: 'Dwell Time',
    avg_dwell_time_text: 'Average time that Users spent in store per visit',
    repeat_visitors_title: 'Recurring Visitors',
    repeat_visitors_title_ooh: 'Recurring Users',
    repeat_visits_text: 'Total number of unique Users with more than one visit',
    repeat_visits_text_ooh: 'Total number of unique Users with more than one visit',
    new_visitors_title: 'New Visitors',
    new_visitors_title_ooh: 'New Users',
    new_visitors_text: 'Number of users first seen in the last 30 days at the POI',
    new_visitors_text_ooh: 'Number of users first seen in the last 30 days at the OOH',
    visitors_ratio_title: 'New Visitors Ratio',
    visitors_ratio_text: 'The percentage of new visitors out of total unique visitors',
    demographics_title: 'Demographic Data',
    demographics_filter_title: 'Demographic Segments',
    mobility_trends: 'Mobility Trends',
    mobility_trends_general_info: 'Segment overview',
    mobility_per_day: 'Mobility by Day',
    distance_distribution: 'Distance distribution',
    mobility_per_week: 'Visits per week',
    mobility_per_week_ooh: 'Impressions per week',
    mobility_per_week_text: 'Distribution of visits according to day and time',
    mobility_per_week_text_ooh: 'Distribution of impressions according to day and time',
    mobility_per_hour_block: 'Visits by hour block',
    mobility_per_hour_block_ooh: 'Impressions by hour block',
    mobility_per_hour_block_text: 'Distribution of visits according to time block',
    mobility_per_hour_block_text_ooh: 'Distribution of impressions according to time block',
    offline_behavior: 'Offline behavior',
    offline_behavior_text_how: `
    
    We analyze how many of the persons impacted at the selected segmen POIs, were also detected at POIs belonging to a particular category<sup>1</sup><br /><br />

    For instance, from a total amount of <b>{{total_impressions}}</b> people impacted at Segment POIs <b>{{example_category_total_impressions}}</b> of them also visited POIs belonging to the "<b>{{example_category}}</b>" category <br /><br />
    
    <small>1: Taken from a POIs general database including the categories these belong to</small>`,
    offline_behavior_text: 'Other places visited by those who visit the POI / Segment',
    offline_behavior_text_ooh: 'Other places visited by those who visit the OOH / Segment',
    online_behavior: 'Online behavior',
    online_behavior_text_how: `Starting with the top <b>{{top_genres_count}}</b> of categories by impressions<sup>1</sup>, we calculate the impressions distribution. This means the percentage based on the <b>{{top_genres_total_impressions}}</b> impressions belonging to the categories in the top <b>{{top_genres_count}}</b>)<br /><br />
    
    <small>1: Popular categories (such as Social Networks) are considered trivial and not taken into account for the ranking</small>`,
    online_behavior_text: 'Categories of Apps used by those who visit the POI / Segment',
    online_behavior_text_ooh: 'Categories of Apps used by those who visit the OOH / Segment',
    gender: 'Gender',
    age_range: 'Age Range',
    '18_24': '18 to 24',
    '20_24': '20 to 24 ',
    '25_29': '25 to 29',
    '30_34': '30 to 34 ',
    '35_39': '35 to 39 ',
    '30_39': '30 to 39',
    '40_44': '40 to 44 ',
    '45_49': '45 to 49 ',
    '40_49': '40 to 49',
    '50_54': '50 to 54 ',
    '55_59': '55 to 59 ',
    '50_59': '50 to 59',
    '60_64': '60 to 64 ',
    '65_69': '65 to 69 ',
    '60_69': '60 to 69',
    '70_79': '70 to 79',
    '70_plus': '70 or more',
    '80+': '80 or more',
    '70+': '70 or more',
    '80_plus': '80 or more',
    socioeconomic_level: 'Socioeconomic Level',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    mobility_per_neighborhood: 'Mobility by Neighborhood',
    mobility_per_region: 'Mobility by Region',
    platform: 'Platform',
    make: 'Make',
    model: 'Model',
    carrier: 'Carrier',
    top_app_categories: 'Top Online Categories',
    heatmap_title: 'Heatmap',
    segment_heatmap: 'Segment Heatmap',
    by_segment: 'By segment',
    by_day: 'by day',
    download_data: 'Download Metrics',
    download_data_generating_file: 'Generating File...',
    download_disabled: 'Download Disabled',
    download_disabled_reason: `Currently it's not possible to download data for groups with more than {{download_threshold}} POIs`,
    glossary_title: 'Glossary',
    definitions_title: 'Definitions',
    metrics_title: 'Metrics',
    reports_title: 'Reports',
    mobility_avg: 'Avg. Mobility',
    date_title: 'Date',
    share: 'Share',
    total: 'total',
    avg: 'weekly average',
    avg_period: 'daily average',
    neighborhood_title: 'Neighborhood',
    trends_day_title: 'Mobility Trends - By Day',
    trends_neighborhood_title: 'Mobility Trends - By Neighborhood',
    period: 'Period',
    projected_value: 'Projected Value',
    projected_value_tooltip: `Projected Value: In case not all data is available, we use tools to complete missing information`,
    by_week: 'by week',
    home_to_poi_title: 'from home to poi',
    home_to_poi_title_ooh: 'from home to ooh',
    home_to_poi_text_how: `Represents groups of people living within the selected POIs distance range. For instance, for the "<b>{{example_range}}</b>" range,
    the impressions count is <b>{{example_range_impressions}}</b> of a total amount of <b>{{total_impressions}}</b>
    
    <br /><br /><b>Formula</b><br />(Range Impressions / Total Impressions) * 100<br/>
    <b>For the "{{example_range}}" range</b><br/>
     ({{example_range_impressions}} / {{total_impressions}}) * 100 = {{result}}`,
    work_to_poi_title: 'from work to poi',
    work_to_poi_title_ooh: 'from work to ooh',
    work_to_poi_text_how: `Represents groups of people working within the selected POIs distance range. For instance, for the "<b>{{example_range}}</b>" range,
    the impressions count is <b>{{example_range_impressions}}</b> of a total working amount<sup>1</sup> of <b>{{total_work_impressions}}</b>
    
    <br /><br /><b>Formula</b><br />(Range Impressions / Total Working Impressions) * 100<br/>
    <b>For the "{{example_range}}" range</b><br/>
     ({{example_range_impressions}} / {{total_work_impressions}}) * 100 = {{result}}
     
     <br /><br />
     <small>1: Total Working Amount: Only considers people who works.</small>
     `,
    no_data: 'No data to display',
    no_data_min: 'No data',
    up_to: 'up to',
    more_than: 'more than',
    or_more: 'or more',
    dwell_time_title: 'Dwell time',
    dwell_time_text: 'Minutes spent at Segment calculated 10 minutes blocks',
    quartile: 'Quartile',
    percentile: 'Percentile',
    flow_map: 'Flow Maps',
    flow_map_text: 'Concentration of people before and after visiting the POI / Segment',
    flow_map_text_ooh: 'Concentration of people before and after visiting the OOH / Segment',
    by_poi: 'by POI',
    by_ooh: 'by OOH',
    compare_menu: 'Competitive analysis',
    planner_menu: 'Planner',
    planner_menu_new: 'new',
    planner_menu_manager: 'Manage',
    admin_menu: 'Admin',
    admin_menu_poi: 'POIs',
    info_menu: 'Info',
    info_menu_glossary: 'Glossary',
    ai_menu: 'AI (demo)',
    ai_menu_advisor: 'Advisor',
    ai_menu_forecast: 'Forecasts',
    audience_builder: 'Audience Builder',
    heatmaps_title: 'Heatmap',
    save_audience_title: 'Save Audience',
    save_audience_text: 'Choose a name. This audience will be ready in your Manager soon.',
    audience_name: 'Name',
    save_button: 'Save',
    cancel_button: 'Cancel',
    confirm_button: 'Confirm',
    location_title: 'Location',
    error_too_short: 'Too short!',
    error_too_long: 'Too long!',
    error_required: 'Required',
    error_saving_poi: 'Error saving POI',
    ok_saving_poi: 'POI created!',
    ok_saving_pois_group: 'POIs Group created!',
    poi_name: 'POI Name',
    filters_title: 'Filter',
    pois_title: 'Point of Interests',
    pois_distance: 'Distance',
    apps_usage: 'App Usage',
    apply_filters_button: 'Apply Filter',
    save_audience_button: 'Save Audience',
    results_title: 'Results',
    results_filters: 'Filters Applied',
    estimated_users: 'Estimated Reach',
    top_match: 'Top Match',
    map: 'Map',
    audience_title: 'Audiences',
    audience_list: 'Audiences List',
    audience_size: 'Estimated Reach',
    audience_creation_date: 'Created',
    audience_status: 'Status',
    audience_actions: 'Actions',
    audience_details: 'Audience Details',
    audience_request_feed: 'Request Data Feed',
    audience_activate: 'Activate Audience',
    planner_manager: 'Planner Manager',
    search_for: 'Search for...',
    visitor_title: 'Visitor',
    visitor_text: 'A device seen within 50 mts of the POI',
    work_title: 'Work',
    work_text: 'Area where device was seen most of the time during working hours (9am-6pm)',
    home_title: 'Home',
    home_text: 'Area where device was seen most of the time outside working hours (9am-6pm)',
    admin_poi_new: 'New POI',
    admin_poi_title: 'Manage POIs',
    admin_pois_list: 'List of POIs',
    admin_oohs_list: 'List of OOHs',
    admin_pois_code: 'Code',
    admin_pois_name: 'Name',
    admin_pois_cp: 'CP Name',
    admin_pois_rep: 'Rep Name',
    admin_pois_so: 'Sales Office',
    admin_pois_address: 'Address',
    admin_pois_address_number: 'Number',
    admin_pois_ownership: 'Stores',
    admin_pois_city: 'City',
    admin_pois_state: 'State',
    admin_pois_zipcode: 'Zipcode',
    admin_pois_country: 'Country',
    admin_pois_local: 'Local',
    admin_pois_status: 'Status',
    admin_pois_actions: 'Actions',
    admin_pois_action_delete: 'Delete POI',
    admin_pois_status_open: 'Open',
    admin_pois_status_closed: 'Closed',
    admin_pois_status_unknown: 'Unknown',
    admin_pois_location: 'Location',
    admin_pois_type: 'Type',
    prev_visit_poi: 'Before visiting POI',
    prev_visit_ooh: 'Before visiting OOH',
    post_visit_poi: 'After visiting POI',
    post_visit_ooh: 'After visiting OOH',
    radar_title: 'Radar',
    radar_text: 'POIs Radar',
    radar_text_ooh: 'OOHs Radar',
    performance_title: 'Performance',
    performance_text: 'POIs Performance',
    performance_text_ooh: 'OOHs Performance',
    performance_best: 'Best Performing',
    performance_worst: 'Worst Performing',
    performance_visits: 'Visits selected period',
    performance_previous_visits: 'Visits previous period',
    performance_variation: 'MoM Variation',
    performance_previous_period: 'Previous period',
    performance_selected_period: 'Selected period',
    error_saving_audience: 'Error saving Audience',
    ok_saving_audience: 'Audience created!',
    out_of: 'out of',
    audience_loading: 'Loading audience...',
    popup_confirm_title: 'Are you sure?',
    popup_confirm_text: 'Please confirm your action',
    notifications_none: 'No notifications',
    notifications_new: 'new notifications',
    edit_profile: 'Edit Profile',
    edit_organization: 'Edit Organization',
    create_organization: 'Create Organization',
    edit_password: 'Change Password',
    logout: 'Logout',
    edit_password_title: 'Password',
    new_password_title: 'New password',
    password_too_short: 'Password should be at least 6 characters',
    password_required: 'Password is required',
    password_confirm_required: 'Password confirmation is required',
    password_confirm_not_match: 'Passwords do not match',
    password_field: 'Password',
    password_confirm_field: 'Confirm Password',
    edit_profile_title: 'Profile',
    edit_organization_title: 'Organization',
    organization_info: 'Organization information',
    organization_name: `Organization's name`,
    organization_name_required: `Organization's name is mandatory`,
    new_profile_title: 'User information',
    profile_first_name_too_short: 'First Name should be at least 2 characters',
    profile_first_name_required: 'First Name is required',
    profile_last_name_too_short: 'Last Name should be at least 2 characters',
    profile_last_name_required: 'Last Name is required',
    profile_first_name: 'First name',
    profile_last_name: 'Last name',
    week: 'Week',
    country_label: 'Country',
    segment_label: 'POI/Segment',
    segment_label_ooh: 'OOH/Segment',
    period_label: 'Period',
    class_a: 'A',
    class_b: 'B',
    class_c: 'C',
    class_d: 'D',
    class_e: 'E',
    yes: 'Yes',
    no: 'No',
    store_age_title: 'Antiquity',
    mobility_trends_by_zone: 'In area of influence',
    visits_label: 'Visits',
    visits_label_ooh: 'Impressions',
    mobility_week: 'Week Visits',
    mobility_week_ooh: 'Week Impressions',
    mobility_weekend: 'Weekend Visits',
    mobility_weekend_ooh: 'Weekend Impressions',
    aggregation_label: 'Aggregation',
    aggregation_text:
      'In individual aggregation, the values corresponding to each POI that make up the segment are averaged.',
    aggregation_group: 'Group',
    aggregation_single: 'Individual',
    pois_by_segment: 'POIs in segment',
    pois_by_segment_ooh: 'OOHs in segment',
    pois_by_segment_tooltip:
      'A segment can contain one or many POIs/OOHs. In this case, the selected segment {{segment}} contains {{poisCount}} POIs/OOHs',
    '0_500_mts': 'up to 500 mts',
    '500_1000_mts': 'from 500 to 1000 mts',
    '1000_2000_mts': 'from 1000 to 2000 mts',
    '0_03_miles': 'up to 0.3 miles',
    '03_06_miles': 'from 0.3 to 0.6 miles',
    '06_125_miles': 'from 0.6 to 1.25 miles',
    by_foot: 'Walking',
    by_car: 'By car',
    block: 'Block',
    go_to_dashboard: 'See Dashboard',
    pois_status_open_text: 'Visits greater than 50% versus March 2020',
    pois_status_closed_text: 'Visits less than 50% versus March 2020',
    filters_value: 'Value',
    filters_score: 'Points',
    results_reach: 'Audience',
    click_to_filter: 'Click to filter',
    ranking_title: 'Ranking',
    ranking_subtitle: 'Measurement by visits',
    ranking_visits: 'Visits selected period',
    ranking_visits_ooh: 'Impressions selected period',
    ranking_variation: 'Variation',
    ranking_ooh_title: 'OOH Ranking',
    ranking_ooh_subtitle: 'Ranking of billboards by impressions',
    ranking_pdv_title: 'POI Ranking',
    ranking_pdv_subtitle: 'POIs Ranking by visits',
    ranking_pdv_by_group_title: 'POI Ranking',
    ranking_pdv_by_group_subtitle: 'Groups by visits',
    ranking_attribution_ooh_title: 'OOH ranking by attribution',
    ranking_attribution_ooh_subtitle:
      'Ranking of billboards by effectiveness according to visits generated in POIs',
    ranking_attribution_pdv_title: 'POIs ranking by attribution',
    ranking_attribution_pdv_subtitle: 'Ranking of POIs by campaign effectiveness',
    by_fraction: 'by quarters of period',
    fraction: 'Quarter',
    map_type_both: 'Both',
    map_type_heatmap: 'Heatmap',
    map_type_flowmap: 'Flowmap',
    ai_advisor_title: 'Advisor',
    ai_advisor_text:
      'You may utilize this data to improve productivity, increase revenue and provide better customer service by optimizing various parts of your operation.',
    ai_advisor_recommendations: {
      one: 'recommendation',
      other: 'recommendations'
    },
    ai_advisor_recommendation_impact: 'Impact',
    ai_advisor_recommendation_description: 'Description',
    ai_advisor_recommendation_benefit: 'Benefit',
    ai_advisor_marketing: 'Marketing',
    ai_advisor_marketing_text:
      'Investment distribution in online and offline media to get the best ROI.',
    ai_advisor_performance: 'Performance',
    ai_advisor_performance_text: 'Increase the volume of visitors, visits and improve dwell time.',
    ai_advisor_churn: 'Churn',
    ai_advisor_churn_text: 'Reduce the number of customers who abandon your products or services.',
    ai_advisor_competition: 'Competition',
    ai_advisor_competition_text: 'Capture your competitors audience and turn them into consumers.',
    ai_advisor_revenue: 'Revenue',
    ai_advisor_revenue_text: 'Increase sales and revenue growth.',
    ai_advisor_costs: 'Costs',
    ai_advisor_costs_text: 'Reduce costs and optimize operations.',
    ai_forecast_title: 'Forecast',
    ai_forecast_text:
      'You may utilize this data to anticipate changes in consumers behavior and adapt your operation.',
    ai_forecast_visits: 'Visits',
    ai_forecast_visits_text: 'Visits prediction based on historical data.',
    ai_forecast_revenue: 'Revenue',
    ai_forecast_revenue_text: 'Daily revenue prediction based on historical data.',
    ai_forecast_projected_revenue: 'Projected Revenue',
    ai_forecast_projected_visits: 'Projected Visits',
    ai_forecast_projected_visitors: 'Projected Visitors',
    ai_forecast_current: 'Current',
    ai_forecast_projected: 'Projected',
    premium_menu: 'Premium',
    premium_menu_sentiment: 'Sentiment',
    premium_sentiment_title: 'Sentiment Intelligence',
    premium_sentiment_text:
      'Data Appeal provides a unique combination of geospatial, sentiment and market intelligence data.',
    premium_sentiment_average: 'Sentiment Score',
    premium_sentiment_unique_authors: 'Unique Authors',
    premium_sentiment_posts: 'Posts',
    premium_menu_financial: 'Financial',
    premium_financial_title: 'Financial',
    premium_financial_text:
      'Our differentiated data and powerful analytics means insights and solutions for every stage of the customer journey.',
    premium_financial_economic_level: 'Socioeconomic Levels',
    premium_financial_credit_risk: 'Credit Risk Analysis',
    premium_financial_own_car: 'Car Owner',
    premium_menu_loyalty: 'Loyalty',
    premium_loyalty_title: 'Loyalty',
    premium_loyalty_text:
      'We apply transparent Artificial Intelligence to data to add action and measurability.',
    premium_loyalty_customer_title: 'Customer loyalty to brands',
    premium_loyalty_receptive_title: 'How receptive are consumer to new brands?',
    premium_loyalty_receptive_1: 'Are sometimes open to trying new brands',
    premium_loyalty_receptive_2: 'Would love to try new brands',
    premium_loyalty_receptive_3: 'Never try new brands and products',
    premium_menu_neighborhood_patterns: 'Neighborhood Patterns',
    premium_neighborhood_patterns_title: 'Neighborhood Patterns',
    premium_neighborhood_patterns_text:
      'Understand how busy an area is, when it is busy and the demographics of the visitors.',
    premium_neighborhood_patterns_composition: 'Community Compositions',
    premium_neighborhood_patterns_class: 'Socioeconomic Levels',
    audit_menu: 'Audit',
    audit_title: 'Audit',
    audit_subtitle: 'Attribution and Measurement of OOH campaigns',
    back: 'Back',
    continue: 'Continue',
    finish: 'Finish',
    new: 'New',
    audit_period: 'Period',
    audit_ooh: 'OOH',
    audit_pdv: 'POI',
    audit_status: 'Status',
    audit_actions: 'Actions',
    audit_result_title: 'Results',
    audit_result_subtitle: 'OOH audit and attribution reports',
    audit_result_description: 'Description',
    audit_result_value: 'Value',
    audit_age_range_title: 'Age Range',
    audit_age_range_subtitle: 'Age ranges by group',
    audit_dwell_time_title: 'Dwell Time',
    audit_dwell_time_subtitle: 'Average length of stay per group',
    audit_control_group_title: 'Monitoring',
    audit_control_group_subtitle: 'Number of visitors vs. Control group',
    audit_visitors_title: 'Visitors data',
    audit_visitors_subtitle: 'Total visits and unique visitors',
    audit_total_impacted: 'People exposed to the OOH circuit',
    audit_total_impacted_visitors: 'People exposed to the OOH circuit who visited any POI',
    audit_conv_rate: 'Conversion Rate',
    audit_uplift: 'Uplift',
    audit_total_oohs: 'Number of OOHs',
    audit_total_pois: 'Number of POIs',
    audit_visitors_real: 'Real',
    audit_visitors_control: 'Control',
    audit_visitors_predict: 'Expected',
    journey_title: 'Customer Journey',
    journey_text: 'POI visits analysis',
    journey_text_ooh: 'OOH impact analysis',
    journey_correlation: 'Visitors Correlation',
    journey_by_share: 'By shared visitors',
    journey_by_dwell: 'By dwell time',
    journey_flow: 'Visits flow',
    journey_flow_text: 'Analysis of visits according to origin and destination',
    dwell_time_label: 'Dwell Time',
    visitors_label: 'Visitors',
    error_filtering_audience: 'Must complete all required filters',
    planner_poi_details_title: 'POI Details',
    planner_poi_details_add_to_cart: 'Select',
    planner_poi_details_remove_from_cart: 'Unselect',
    planner_filters_matchers: 'Target',
    planner_distance_no_filter: 'Unfiltered',
    planner_refresh_reach: 'Update Audience',
    planner_metrics_title: 'Metrics',
    planner_metrics_universe: 'Universe Population',
    planner_metrics_target: 'Universe Target',
    planner_metrics_reach: 'Reach Target',
    planner_metrics_share: 'Reach Target %',
    planner_metrics_frequency: 'Avg. Frequency',
    planner_metrics_hits: 'Hits Target',
    planner_metrics_reach_less: 'Less',
    planner_metrics_reach_more: 'More',
    planner_fraction: 'Quarters of period',
    planner_update_reach_warning: 'Update metrics to correspond to current points selection.',
    planner_update_filters_warning: 'Update filters to correspond to current points selection.',
    planner_update_warning: 'There are pending updates.',
    planner_poi_create: 'Create',
    planner_poi_import: 'Import',
    planner_poi_group_name: 'Locations Group Name',
    planner_error_poi_group_name: 'Locations Group Name field is required (min: 4 - max: 100)',
    planner_error_poi_group_empty_import: 'You must complete the import process',
    planner_pois_partial_result: 'Too many points were found. Displaying:',
    csv_importer_fileStep_initialDragDropPrompt:
      'Drag-and-drop CSV file here, or click to select in folder',
    csv_importer_fileStep_activeDragDropPrompt: 'Drop CSV file here...',
    csv_importer_fileStep_rawFileContentsHeadings: 'Raw File Contents',
    csv_importer_fileStep_previewImportHeading: 'Preview import',
    csv_importer_fileStep_nextButton: 'Choose columns',
    csv_importer_fileStep_dataHasHeadersCheckbox: 'Data has headers',
    csv_importer_fieldStep_dragTargetPlaceholder: 'Drag column here',
    csv_importer_fieldStep_stepSubtitle: 'Select Columns',
    csv_importer_fieldStep_requiredFieldsError: 'Please assign all required fields',
    csv_importer_fieldStep_nextButton: 'Import',
    csv_importer_progressStep_stepSubtitle: 'Import',
    csv_importer_progressStep_uploadMorebutton: 'Upload More',
    csv_importer_progressStep_finshButton: 'Finish',
    csv_importer_progressStep_statusError: 'Could not import',
    csv_importer_progressStep_statusComplete: 'Complete',
    csv_importer_progressStep_statusPending: 'Importing...',
    csv_importer_progressStep_processedRowsLabel: 'Processed rows:',
    csv_importer_fieldStep_page: 'Page',
    csv_importer_fieldStep_of: 'of',
    planner_save_campaign: 'Save Campaign',
    planner_save_campaign_title: 'Save Campaign',
    planner_save_campaign_text: 'Choose a name. This campaign will be ready in your Manager soon.',
    campaign_name: 'Name',
    error_saving_campaign: 'Error saving Campaign',
    ok_saving_campaign: 'Campaign created!',
    campaign_list: 'Campaigns',
    campaign_reach: 'Reach',
    campaign_trp_population: 'TRP Population',
    campaign_trp_target: 'TRP Target',
    campaign_creation_date: 'Created',
    campaign_created_by: 'Created by',
    campaign_actions: 'Actions',
    campaign_actions_clone: 'Clone Campaign',
    campaign_actions_edit: 'Edit Campaign',
    campaign_actions_delete: 'Delete Campaign',
    campaign_loading: 'Loading Campaign...',
    campaign_saving: 'Saving Campaign...',
    campaign_name_copy: 'Copy of',
    campaign_builder: 'Audience Builder',
    campaign_reset_data: 'Reset Filters',
    planner_filter_by_reach: 'Filter points by reach',
    planner_filter_by_reach_min: 'At least',
    planner_area_selection_title: 'Select by Area',
    planner_confirm_selection: 'Confirm',
    planner_reset_selection: 'Reset',
    planner_cancel_selection: 'Cancel',
    planner_reach_selection_title: 'Select by Reach',
    planner_reach_selection_text_select: 'Select',
    planner_reach_selection_text_pois: 'points',
    planner_reach_selection_text_more_reach: 'with greater reach',
    smart_insights: 'Smart Insights',
    mobility_trends_general_info_single: '{{type}} general overview',
    planner_metrics_trp_population: 'TRP Population',
    planner_metrics_trp_target: 'TRP Target',
    audit_creation_date: 'Created',
    audit_created_by: 'Created by',
    audit_actions_delete: 'Delete Audit',
    audit_list: 'Audits',
    audit_campaign_period: 'OOH Campaign Period',
    audit_campaign_config: 'Audit process configuration',
    audit_config_ooh_radius: 'OOH Radius',
    audit_config_pdv_radius: 'POI Radius',
    audit_config_pdv_dwell_time: 'POI Dwell Time Range',
    audit_config_pdv_dwell_time_mins: 'minutes',
    audit_config_attr_window: 'Attributtion Window',
    audit_config_attr_window_days: 'days since last impact',
    planner_metrics_selected_pois: 'Selected POIs',
    search_for_text:
      "Up to {{perPage}} results are displayed at the same time. If you can't find your POI/Segment please narrow the search.",
    search_load_more: 'Load more',
    showing_results_for: 'Showing results for',
    search_for_help:
      'You may search multiple terms separating words with space. Use double quotes for exact match.',
    search_selected_pois: 'Selected POIs',
    download_audience: 'Download Audience',
    download_audience_details:
      'You are about to request a CSV file that contains unique device IDs, that represent segment visitors for the selected period.',
    download_audience_details_raw: 'Reachable Audience',
    download_audience_details_raw_ids: 'unique IDs',
    download_audience_details_segment: 'Selected Segment',
    download_audience_details_period: 'Selected Period',
    download_audience_details_cost: 'Total Cost',
    download_audience_details_checkout: 'Checkout',
    download_audience_segment: 'Segment',
    download_audience_period: 'Period',
    audiences_menu_manager: 'Manage',
    audience_manager: 'Audience Manager',
    audience_created_by: 'Created by',
    audience_actions_delete: 'Delete Audience',
    or_click_to_register: 'Or click here to sign-up',
    already_user_login: 'Already user? Login',
    register: 'Create Account',
    register_thanks:
      'Thanks for registering! We have sent an email to the specified address to activate your account.',
    register_close_window: 'You may close this window now.',
    register_activating: 'Please wait while we activate your account...',
    register_now_active: 'Your account is now active. Redirecting to login...',
    error_saving_organization: 'Error saving Organization',
    ok_saving_organization: 'Organization created!',
    organization_not_allowed:
      "You don't have enough permissions to manage billing for this organization: {{organization}}",
    organization_payment_ok:
      'Your account has been activated! You may now continue to the platform.',
    organization_payment_not_valid:
      'The payment information for the organization {{organization}} is not valid.',
    organization_not_found: 'Organization {{organization}} not found',
    organization_save_payment_info: 'Add Payment Information',
    organization_loading: 'Loading Organization...',
    try_again: 'Try Again',
    edit_account: 'Your Account',
    error_saving_profile: 'Error saving profile',
    ok_saving_profile: 'Profile saved!',
    edit_subscription_title: 'Subscription',
    manage_subscription: 'Manage Subscription',
    subscription_info: 'Subscription Details',
    subscription_status: 'Status',
    subscription_action_cancel: 'Cancel Subscription',
    subscription_renewal_date: 'Renewal Date',
    subscription_cost: 'Cost',
    subscription_last_payment: 'Last Payment',
    edit_billing_title: 'Billing',
    manage_billing: 'Manage Billing',
    manage_payment_methods: 'Manage Payment Methods',
    billing_loading: 'Loading Customer Portal...',
    error_customer_portal: 'Error loading customer portal',
    billing_info: 'Billing Details',
    subscription_loading: 'Loading Subscription Details...',
    organization_signup_text: `
    Thank you for signing up! To get started, we just need a few pieces of information from you. Please fill out the form below with your company name and credit card details.
    <br /><br />    
    Please note that our service comes with a <strong>{{trialPeriodDays}} days</strong> trial period, during which you can try out all of our features and services at no charge. At the end of the trial period, your credit card will be charged for a full subscription unless you cancel.
    <br /><br />
    If you decide that you don't want to continue with the subscription, you can easily cancel at any time during the trial period by visiting your account settings or contacting our customer support team.`,
    audience_payment_ok: 'The payment was processed successfully!',
    select_payment_method: 'Please select payment method to proceed:',
    audiencie_purchase_confirm_title: 'Confirm Checkout',
    audiencie_purchase_confirm_text:
      'You are about to purchase the {{segmentLabel}} audience, period {{formattedSegmentPeriod}} for a total price of {{formattedSegmentCost}}.',
    terms_please_accept: 'Accept our terms and conditions.',
    terms_title: 'Terms and Conditions',
    terms_accept: 'I Accept',
    terms_do_not_accept: "I Don't Accept",
    terms_and_conditions: `
    Thank you for considering using our online service. By accessing or using our service, you agree to be bound by the following terms and conditions ("Terms"). If you do not agree to all of the Terms, do not use our service.
    
    <br /><br />
    <strong>1. Introduction</strong><br />
    These Terms apply to your use of our online service, including any content, functionality, and services offered on or through our website or mobile application (the "Service").
    <br /><br />
    <strong>2. Trial Period and Subscription</strong><br />
    Our Service offers a 7-day trial period during which you can use the Service for free. After the trial period, you will be required to pay a monthly subscription fee in advance in order to continue using the Service. The subscription fee will be automatically charged to your payment method on a recurring basis, unless you cancel your subscription.
    <br /><br />
    <strong>3. Cancellation</strong><br />
    You may cancel your subscription at any time by contacting us or through the account management section of the Service. Upon cancellation, you will no longer be charged the subscription fee, but you will not be entitled to a refund for any subscription fees that have already been paid.
    <br /><br />
    <strong>4. Changes to the Service and Terms</strong><br />
    We reserve the right to modify or discontinue the Service or any part thereof at any time, with or without notice to you. We may also modify these Terms at any time, and such modifications will be effective immediately upon posting the modified Terms on the Service. It is your responsibility to review these Terms periodically for any changes. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.
    <br /><br />
    <strong>5. Intellectual Property</strong><br />
    All content included on the Service, such as text, graphics, logos, images, and software, is the property of {{companyName}} or its content suppliers and protected by United States and international copyright laws. The compilation of all content on the Service is the exclusive property of {{companyName}} and protected by U.S. and international copyright laws.
    <br /><br />
    <strong>6. Disclaimer of Warranties</strong><br />
    The Service is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the Service or the information, content, materials, or products included on the Service. We do not warrant that the Service will be uninterrupted or error-free.
    <br /><br />
    <strong>7. Limitation of Liability</strong><br />
    We will not be liable for any damages of any kind arising from the use of the Service, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.
    <br /><br />
    <strong>8. Governing Law</strong><br />
    These Terms and your use of the Service shall be governed by and construed in accordance with the laws of the State of {{state}}, without giving effect to any principles of conflicts of law.
    <br /><br />
    <strong>9. Dispute Resolution</strong><br />
    Any dispute arising out of or relating to these Terms or the Service shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall be conducted in the State of {{state}}.
    <br /><br />
    <strong>10. Miscellaneous</strong><br />
    These Terms constitute the entire agreement between you and {{companyName}} and govern your use of the Service. If any part of these Terms is found to be invalid or unenforceable, that part will be enforced to the maximum extent possible, and the remaining provisions will remain in full force and effect. Our failure to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.
    <br /><br />
    By using the Service, you represent that you are at least the age of majority in your state or province of residence and have the legal capacity to enter into a binding contract. If you are using the Service on behalf of an entity,`,
    unknown_error_please_contact_support:
      'An unexpected error happened. Please contact support ({{msg}}).',
    audience_not_allowed: "You don't have enough permissions to manage this audience.",
    audience_payment_not_valid: 'Payment information is not valid for this audience.',
    search_no_data: 'No data to display for selected period.',
    error_selecting_poi: 'This group has more POIs than the maximum allowed',
    download_campaign: 'Download Campaign',
    download_campaign_summary: 'Summary',
    download_campaign_pois: 'POIs',
    planner_csv_selection_title: 'Select by CSV',
    planner_csv_selection_match_error: 'Match error: '
  },
  pt: {
    dashboard_title: 'Painel',
    dashboard_text: 'Análise de Tráfego de Pedestres',
    dashboard_reports: 'RELATÓRIOS',
    dashboard_pdv: 'PDI',
    dashboard_ooh: 'OOH',
    visitors_title: 'Visitantes Totais x Visitantes Únicos',
    visitors_title_ooh: 'Usuários Totais e Usuários Únicos',
    visits_tooltip: `Pessoas que passaram pelos PDIs do segmento selecionado e atenderam aos critérios de permanência`,
    visits_projected_tooltip: `Esta projeção é baseada na proporção da amostra em relação à população da área dos PDIs analisados. Esta proporção é aplicada ao número de visitas observadas`,
    unique_visitors_tooltip: `As pessoas visitam <superscript>1</superscript> um PDI mais de uma vez. Usuários únicos não contam essas repetições. Por outras palavras, <b>se uma pessoa visitar um ou mais POIs do segmento mais do que uma vez, será contabilizado como UM único utilizador</b>.
      <br /><br /><small>1: Consideram-se visitas as disciplinas que passaram perto de um ponto e cumpriram as condições de permanência.</small>`,
    unique_visitors_projected_tooltip:
      'Esta projeção é baseada na proporção da amostra em relação à população da área dos PDIs analisados.',
    sample_size: 'Tamanho da amostra',
    reference_population: 'População de referência',
    observations_count: 'Número de observações',
    unique_visitors_title: 'Usuários Únicos',
    total_unique_visitors_title: 'Total de Visitantes Únicos',
    total_unique_visitors_title_ooh: 'Total de Usuários Únicos',
    avg_unique_visitors_title: 'Visitantes únicos médios',
    avg_unique_visitors_title_ooh: 'Usuários únicos médios',
    total_unique_visitors_text: 'Total de usuários únicos que passaram por PDIs',
    visits_title: 'Visitas',
    visits_title_ooh: 'Impressões',
    total_visits_title: 'Visitas totais',
    total_visits_title_ooh: 'Impressões Totais',
    total_visits_text: 'Número de vezes que os usuários passaram por PDIs',
    total_visits_text_ooh: 'Número de vezes que os usuários passaram por OOHs',
    avg_unique_users_title: 'Média usuários únicos',
    avg_unique_users_text: 'Volume de usuários únicos',
    avg_visits_title: 'Média Visitas',
    avg_visits_title_ooh: 'Média de Impressões',
    frequency_title: 'Frequência',
    frequency_text: 'O número médio de vezes que um usuário passa por PDIs',
    frequency_text_ooh: 'O número médio de vezes que um usuário passa por OOHs',
    avg_frequency_title: 'Frequência',
    accum_visits_title: 'Visitas acumuladas',
    avg_new_visitors_title: 'Média de novos visitantes',
    avg_new_visitors_title_ooh: 'Novos Usuários',
    avg_dwell_time_title: 'Minutos de permanência',
    avg_dwell_time_text: 'Média de minutos passados em PDI',
    repeat_visitors_title: 'Visitantes recorrentes',
    repeat_visitors_title_ooh: 'Usuários recorrentes',
    repeat_visits_text: 'Número de visitantes com mais de uma visita ao PDI',
    repeat_visits_text_ooh: 'Número de usuários com mais de uma visita ao OOH',
    new_visitors_title: 'Novos Visitantes',
    new_visitors_title_ooh: 'Novos Usuários',
    new_visitors_text:
      'Número de usuários vistos pela primeira vez nos últimos 30 dias no ponto de venda',
    new_visitors_text_ooh: 'Número de usuários vistos pela primeira vez nos últimos 30 dias no OOH',
    visitors_ratio_title: 'Taxa de novos visitantes',
    visitors_ratio_text:
      'A porcentagem de novos visitantes em relação ao total de visitantes únicos',
    demographics_title: 'Dados Demográficos',
    demographics_filter_title: 'Segmentos Demográficos',
    mobility_trends: 'Tendência de Visitas',
    mobility_trends_general_info: 'Visão geral do segmento',
    mobility_per_day: 'Porcentagem de visitas por dia',
    distance_distribution: 'Distribuição de distância',
    mobility_per_week: 'Visitas por semana',
    mobility_per_week_ooh: 'Impressões por Semana',
    mobility_per_week_text: 'Distribuição das visitas de acordo com o dia e bloco de tempo',
    mobility_per_week_text_ooh: 'Distribuição de impressões segundo dia e faixa horária',
    mobility_per_hour_block: 'Visitas por bloco de horas',
    mobility_per_hour_block_ooh: 'Impressões por Faixa Horária',
    mobility_per_hour_block_text: 'Distribuição de visitas de acordo com o bloco de tempo',
    mobility_per_hour_block_text_ooh: 'Distribuição de impressões segundo faixa horária',
    offline_behavior: 'Comportamento offline',
    offline_behavior_text_how: `Analisamos quantas pessoas impactadas nos POIs do segmento selecionado, foram detectadas em POIs de uma determinada categoria<sup>1</sup><br /><br />
     Por exemplo, do total de <b>{{total_impressions}}</b> pessoas impactadas por POIs no segmento, <b>{{example_category_total_impressions}}</b> visitaram POIs na categoria <b>{{example_category}} </b> <br /><br />
     <small>1: A partir de um banco de dados geral de POIs e suas categorias </small>`,
    offline_behavior_text: 'Outros locais visitados por quem visita o PDI / Segmento',
    offline_behavior_text_ooh: 'Outros locais visitados por quem visita o OOH / Segmento',
    online_behavior: 'Comportamento online',
    online_behavior_text_how: `As <b>{{top_genres_count}}</b> categorias com o maior número de impressões<sup>1</sup> são consideradas e a distribuição é calculada (ou seja, a porcentagem sobre as <b>{{top_genres_total_impressions}}</b> impressões da categoria principal <b>{{top_genres_count}}</b>)<br /><br />
     <small>1: As categorias triviais são ignoradas devido à sua popularidade. Por exemplo "Redes Sociais"</small>`,
    online_behavior_text: 'Categorias de Apps utilizadas por quem visita o PDI / Segmento',
    online_behavior_text_ooh: 'Categorias de Apps utilizadas por quem visita o OOH / Segmento',
    gender: 'Gênero',
    age_range: 'Faixa Etária',
    '18_24': '18 a 24',
    '20_24': '20 a 24 ',
    '25_29': '25 a 29',
    '30_34': '30 a 34 ',
    '35_39': '35 a 39 ',
    '30_39': '30 a 39',
    '40_44': '40 a 44 ',
    '45_49': '45 a 49 ',
    '40_49': '40 a 49',
    '50_54': '50 a 54 ',
    '55_59': '55 a 59 ',
    '50_59': '50 a 59',
    '60_64': '60 a 64 ',
    '65_69': '65 a 69 ',
    '60_69': '60 a 69',
    '70_79': '70 a 79',
    '70_plus': '70 ou mais',
    '80+': '80 ou mais',
    '70+': '70 ou mais',
    '80_plus': '80 ou mais',
    socioeconomic_level: 'Nível Socioeconômico',
    sunday: 'Domingo',
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado',
    mobility_per_neighborhood: 'Mobilidade por Bairros',
    mobility_per_region: 'Mobilidade por Administração Regional',
    platform: 'Sistema Operacional',
    make: 'Fabricantes',
    model: 'Modelo',
    carrier: 'Operadoras',
    top_app_categories: 'Comportamento de aplicativos',
    heatmap_title: 'Mapa de Calor',
    segment_heatmap: 'Mapa de Calor dos Focos de Aglomerações',
    by_segment: 'Por segmento',
    by_day: 'por dia',
    download_data: 'Baixar Métricas',
    download_data_generating_file: 'Gerando Arquivo...',
    download_disabled: 'Download Desativado',
    download_disabled_reason:
      'Atualmente não é possível baixar dados de grupos com mais de {{download_threshold}} POIs',
    glossary_title: 'Glossário',
    definitions_title: 'Definições',
    metrics_title: 'Métricas',
    reports_title: 'Informes',
    mobility_avg: 'Mobilidade Média',
    date_title: 'Data',
    share: 'Taxa',
    total: 'total',
    avg: 'média semanal',
    avg_period: 'média quartos',
    neighborhood_title: 'Bairro',
    trends_day_title: 'Tendências de Mobilidade - Por Dia',
    trends_neighborhood_title: 'Tendências de Mobilidade - Por Bairro',
    period: 'Período',
    projected_value: 'Valor Projetado',
    projected_value_tooltip: `Valor projetado: Quando todos os dados não estão disponíveis, ferramentas são usadas para completar as informações que faltam.`,
    by_week: 'por semana',
    home_to_poi_title: 'de casa para pdi',
    home_to_poi_title_ooh: 'de casa para ooh',
    home_to_poi_text_how: `Representa grupos de pessoas que vivem dentro de um intervalo dos POIs selecionados. Por exemplo, para o intervalo <b>{{example_range}}</b>,
     o número de impressões é <b>{{example_range_impressions}}</b>, de um total de <b>{{total_impressions}}</b>.
    
     <br /><br /><b>Fórmula</b><br />(Impressões no intervalo / Total de impressões) * 100<br/>
     <b>Para o intervalo {{example_range}}</b><br/>
      ({{example_range_impressions}} / {{total_impressions}}) * 100 = {{result}}`,
    work_to_poi_title: 'do trabalho para pdi',
    work_to_poi_title_ooh: 'do trabalho para ooh',
    work_to_poi_text_how: `Representa grupos de pessoas que trabalham em uma faixa de distância dos POIs selecionados. Por exemplo, para o intervalo "<b>{{example_range}}</b>",
     o número de impressões é <b>{{example_range_impressions}}</b>, de um total de <sup>1</sup> pessoas trabalhando <b>{{total_work_impressions}}</b>
    
     <br /><br /><b>Fórmula</b><br />(Impressões no intervalo / Total de impressões de pessoas que trabalham) * 100<br/>
     <b>Para o intervalo "{{example_range}}"</b><br/>
      ({{example_range_impressions}} / {{total_work_impressions}}) * 100 = {{result}}
     
      <br /><br />
      <small>1: Número total de pessoas que trabalham: Considera apenas as pessoas que trabalham.</small>`,
    no_data: 'Sem dados para exibir',
    no_data_min: 'Sem dados',
    up_to: 'até',
    more_than: 'mais que',
    or_more: 'ou mais',
    dwell_time_title: 'Tempos de Permanência',
    dwell_time_text: 'Distribuição da duração das visitas',
    quartile: 'Quartil',
    percentile: 'Percentil',
    flow_map: 'Mapas de Fluxos',
    flow_map_text: 'Concentração de pessoas antes e depois de visitar o PDI / Segmento',
    flow_map_text_ooh: 'Concentração de pessoas antes e depois de visitar o OOH / Segmento',
    by_poi: 'por PDI',
    by_ooh: 'por OOH',
    compare_menu: 'Analise competitiva',
    planner_menu: 'Planner',
    planner_menu_new: 'Novo',
    planner_menu_manager: 'Administrador',
    admin_menu: 'Administração',
    admin_menu_poi: 'PDIs',
    info_menu: 'Informações',
    info_menu_glossary: 'Glossário',
    ai_menu: 'AI (demo)',
    ai_menu_advisor: 'Advisor',
    ai_menu_forecast: 'Forecasts',
    audience_builder: 'Construtor de Audiência',
    heatmaps_title: 'Mapas de calor',
    save_audience_title: 'Salvar Audiência',
    save_audience_text: 'Escolha um nome. Este público estará pronto em seu Gerenciador em breve.',
    audience_name: 'Nome',
    save_button: 'Salvar',
    cancel_button: 'Cancelar',
    confirm_button: 'Confirmar',
    location_title: 'Localização',
    error_too_short: 'Muito curto!',
    error_too_long: 'Muito longo!!',
    error_required: 'Requerido',
    error_saving_poi: 'Erro ao gravar PDI',
    ok_saving_poi: 'PDI criado!',
    ok_saving_pois_group: 'Grupo de PDIs criado!',
    poi_name: 'Nome PDI',
    filters_title: 'Filtros',
    pois_title: 'Pontos de Interesse',
    pois_distance: 'Distância',
    apps_usage: 'Uso de Aplicativos',
    apply_filters_button: 'Aplicar Filtros',
    save_audience_button: 'Salvar Audiência',
    results_title: 'Resultados',
    results_filters: 'Filtros Selecionados',
    estimated_users: 'Usuários estimados',
    top_match: 'Top Match',
    map: 'Mapa',
    audience_title: 'Audiências',
    audience_list: 'Listado de Audiências',
    audience_size: 'Tamanho',
    audience_creation_date: 'Data de Criação',
    audience_status: 'Estado',
    audience_actions: 'Ações',
    audience_details: 'Detalhe do audiência',
    audience_request_feed: 'Solicitar feed de dados',
    audience_activate: 'Ativar audiência',
    planner_manager: 'Planner Manager',
    search_for: 'Procurar por...',
    visitor_title: 'Visitante',
    visitor_text: 'Um dispositivo visto a menos de 50 metros do ponto de interesse',
    work_title: 'Trabalho',
    work_text:
      'Área onde o dispositivo foi visto a maior parte do tempo durante o horário de trabalho (9h-18h)',
    home_title: 'Casa',
    home_text:
      'Área onde o dispositivo foi visualizado na maior parte do tempo fora do horário comercial (9h-18h)',
    admin_poi_new: 'Novo PDI',
    admin_poi_title: 'Gerenciar PDIs',
    admin_pois_list: 'Lista de PDIs',
    admin_oohs_list: 'Lista de OOHs',
    admin_pois_code: 'Código',
    admin_pois_name: 'Nome',
    admin_pois_cp: 'Nome CP',
    admin_pois_rep: 'Nome Rep',
    admin_pois_so: 'Escritório de vendas',
    admin_pois_address: 'Endereço',
    admin_pois_address_number: 'Número',
    admin_pois_ownership: 'Concessionarias',
    admin_pois_city: 'Cidade',
    admin_pois_state: 'UF',
    admin_pois_zipcode: 'CEP',
    admin_pois_country: 'País',
    admin_pois_local: 'Local do PDI',
    admin_pois_status: 'Estado',
    admin_pois_actions: 'Ações',
    admin_pois_action_delete: 'Remover PDI',
    admin_pois_status_open: 'Aberto',
    admin_pois_status_closed: 'Fechado',
    admin_pois_status_unknown: 'Desconhecido',
    admin_pois_location: 'Localização',
    admin_pois_type: 'Tipo',
    prev_visit_poi: 'Visita anterior PDI',
    prev_visit_ooh: 'Visita anterior OOH',
    post_visit_poi: 'Visita posterior ao PDI',
    post_visit_ooh: 'Visita posterior ao OOH',
    radar_title: 'Radar',
    radar_text: 'Radar das Lojas Abertas e Fechadas',
    radar_text_ooh: 'Radar das OOHs',
    performance_title: 'Desempenho',
    performance_text: 'Desempenho da PDIs',
    performance_text_ooh: 'Desempenho da OOHs',
    performance_best: 'Melhor Desempenho',
    performance_worst: 'Pior Desempenho',
    performance_visits: 'Visitas período selecionado',
    performance_previous_visits: 'Visitas período anterior',
    performance_variation: 'Variação M/M',
    performance_previous_period: 'Período anterior',
    performance_selected_period: 'Período selecionado',
    error_saving_audience: 'Erro ao gravar Audiência',
    ok_saving_audience: 'Audiência criada!',
    out_of: 'de',
    audience_loading: 'Carregando audiência...',
    popup_confirm_title: 'Tem certeza?',
    popup_confirm_text: 'Por favor, confirme sua ação',
    notifications_none: 'nenhuma notificação',
    notifications_new: 'novas notificações',
    edit_profile: 'Editar Perfil',
    edit_organization: 'Editar organização',
    create_organization: 'Criar organização',
    edit_password: 'Mudar senha',
    logout: 'Deslogar',
    edit_password_title: 'Senha',
    new_password_title: 'Nova senha',
    password_too_short: 'A senha deve ter pelo menos 6 caracteres',
    password_required: 'Senha requerida',
    password_confirm_required: 'A confirmação da senha é necessária',
    password_confirm_not_match: 'As senhas não coincidem',
    password_field: 'Senha',
    password_confirm_field: 'Confirme a Senha',
    edit_profile_title: 'Perfil',
    edit_organization_title: 'Organização',
    organization_info: 'Informação do organização',
    organization_name: 'Nome do organização',
    organization_name_required: 'O nome do organização é necessário',
    new_profile_title: 'Informação do usuário',
    profile_first_name_too_short: 'O primeiro nome deve ter pelo menos 2 caracteres',
    profile_first_name_required: 'O primeiro nome é necessário',
    profile_last_name_too_short: 'O sobrenome deve ter pelo menos 2 caracteres',
    profile_last_name_required: 'O sobrenome é obrigatório',
    profile_first_name: 'Primeiro nome',
    profile_last_name: 'Último nome',
    week: 'Semana',
    country_label: 'Pais',
    segment_label: 'PDI/Segmento',
    segment_label_ooh: 'OOH/Segmento',
    period_label: 'Período',
    class_a: 'A',
    class_b: 'B',
    class_c: 'C',
    class_d: 'D',
    class_e: 'E',
    yes: 'Sim',
    no: 'Não',
    store_age_title: 'Antiguidade',
    mobility_trends_by_zone: 'Na área de influência',
    visits_label: 'Visitas',
    visits_label_ooh: 'Impresiones',
    mobility_week: 'Visitas semana',
    mobility_week_ooh: 'Impressões Semana',
    mobility_weekend: 'Visitas de fim de semana',
    mobility_weekend_ooh: 'Impressões Final de Semana',
    aggregation_label: 'Agregação',
    aggregation_text:
      'Na agregação individual, os valores correspondentes a cada PDI que compõem o segmento são calculados em média.',
    aggregation_group: 'Grupo',
    aggregation_single: 'Individual',
    pois_by_segment: 'PDIs no segmento',
    pois_by_segment_ooh: 'OOHs no segmento',
    pois_by_segment_tooltip:
      'Um segmento pode conter um ou mais POIs/OOHs. Neste caso, o segmento selecionado <b>{{segment}}</b> contém <b>{{poisCount}}</b> POIs/OOHs',
    '0_500_mts': 'até 500 mts',
    '500_1000_mts': 'de 500 a 1000 mts',
    '1000_2000_mts': 'de 1000 a 2000 mts',
    '0_03_miles': 'até 0.3 milhas',
    '03_06_miles': 'de 0.3 a 0.6 milhas',
    '06_125_miles': 'de 0.6 a 1.25 milhas',
    by_foot: 'A pé',
    by_car: 'De carro',
    block: 'Bloco',
    go_to_dashboard: 'Veja o painel',
    pois_status_open_text: 'Visitas superiores a 50% em relação a março de 2020',
    pois_status_closed_text: 'Visitas inferiores a 50% em relação a março de 2020',
    filters_value: 'Valor',
    filters_score: 'Pontos',
    results_reach: 'Público',
    click_to_filter: 'Clique para filtrar',
    ranking_title: 'Classificação',
    ranking_subtitle: 'Medição por visitas',
    ranking_visits: 'Visitas período selecionado',
    ranking_visits_ooh: 'impressões período selecionado',
    ranking_variation: 'Variação',
    ranking_ooh_title: 'Classificação de OOH',
    ranking_ooh_subtitle: 'Classificação de pôsteres de acordo com as impressões',
    ranking_pdv_title: 'Classificação PDI',
    ranking_pdv_subtitle: 'Classificação PDIs por visitas',
    ranking_pdv_by_group_title: 'Classificação PDI',
    ranking_pdv_by_group_subtitle: 'Grupos por visitas',
    ranking_attribution_ooh_title: 'Classificação OOH por atribuição',
    ranking_attribution_ooh_subtitle:
      'Classificação de cartazes por eficácia de acordo com visitas geradas em PDIs',
    ranking_attribution_pdv_title: 'Classificação PDIs por atribuição',
    ranking_attribution_pdv_subtitle: 'Classificação de PDIs por eficácia da campanha',
    by_fraction: 'por quartas do período',
    fraction: 'Quarto',
    map_type_both: 'Ambos',
    map_type_heatmap: 'Heatmap',
    map_type_flowmap: 'Flowmap',
    ai_advisor_title: 'Advisor',
    ai_advisor_text:
      'Você pode utilizar esses dados para melhorar a produtividade, aumentar a receita e fornecer um melhor atendimento ao cliente, otimizando várias partes de sua operação.',
    ai_advisor_recommendations: {
      one: 'recomendação',
      other: 'recomendações'
    },
    ai_advisor_recommendation_impact: 'Impacto',
    ai_advisor_recommendation_description: 'Descrição',
    ai_advisor_recommendation_benefit: 'Benefício',
    ai_advisor_marketing: 'Marketing',
    ai_advisor_marketing_text:
      'Distribuição de investimentos em mídia online e offline para obter o melhor ROI.',
    ai_advisor_performance: 'Performance',
    ai_advisor_performance_text:
      'Aumente o volume de visitantes, visitas e melhore o tempo de permanência.',
    ai_advisor_churn: 'Churn',
    ai_advisor_churn_text: 'Reduza o número de clientes que abandonam seus produtos ou serviços.',
    ai_advisor_competition: 'Concorrência',
    ai_advisor_competition_text:
      'Capture o público de seus concorrentes e transforme-os em consumidores.',
    ai_advisor_revenue: 'Rendimento',
    ai_advisor_revenue_text: 'Aumente o crescimento das vendas e da rendimento.',
    ai_advisor_costs: 'Custos',
    ai_advisor_costs_text: 'Reduza custos e otimize operações.',
    ai_forecast_title: 'Forecast',
    ai_forecast_text:
      'Você pode utilizar esses dados para antecipar mudanças no comportamento dos consumidores e adaptar sua operação.',
    ai_forecast_visits: 'Visitas',
    ai_forecast_visits_text: 'Projeção de visitas com base em dados históricos.',
    ai_forecast_revenue: 'Rendimento',
    ai_forecast_revenue_text: 'Previsão de receita diária com base em dados históricos.',
    ai_forecast_projected_revenue: 'Receita projetada',
    ai_forecast_projected_visits: 'Visitas Projetadas',
    ai_forecast_projected_visitors: 'Visitantes Projetados',
    ai_forecast_current: 'Atual',
    ai_forecast_projected: 'Projetado',
    premium_menu: 'Premium',
    premium_menu_sentiment: 'Sentiment',
    premium_sentiment_title: 'Sentiment Intelligence',
    premium_sentiment_text:
      'O Data Appeal fornece uma combinação única de dados geoespaciais, de sentimento e de inteligência de mercado.',
    premium_sentiment_average: 'Sentiment Score',
    premium_sentiment_unique_authors: 'Unique Authors',
    premium_sentiment_posts: 'Posts',
    premium_menu_financial: 'Financial',
    premium_financial_title: 'Financial',
    premium_financial_text:
      'Nossos dados diferenciados e análises poderosas significam insights e soluções para todas as etapas da jornada do cliente.',
    premium_financial_economic_level: 'Socioeconomic Levels',
    premium_financial_credit_risk: 'Credit Risk Analysis',
    premium_financial_own_car: 'Car Owner',
    premium_menu_loyalty: 'Loyalty',
    premium_loyalty_title: 'Loyalty',
    premium_loyalty_text:
      'Aplicamos Inteligência Artificial transparente aos dados para adicionar ação e mensurabilidade.',
    premium_loyalty_customer_title: 'Customer loyalty to brands',
    premium_loyalty_receptive_title: 'How receptive are consumer to new brands?',
    premium_loyalty_receptive_1: 'Are sometimes open to trying new brands',
    premium_loyalty_receptive_2: 'Would love to try new brands',
    premium_loyalty_receptive_3: 'Never try new brands and products',
    premium_menu_neighborhood_patterns: 'Neighborhood Patterns',
    premium_neighborhood_patterns_title: 'Neighborhood Patterns',
    premium_neighborhood_patterns_text:
      'Entenda o quão movimentada é uma área, quando está movimentada e a demografia dos visitantes.',
    premium_neighborhood_patterns_composition: 'Composições da Comunidade',
    premium_neighborhood_patterns_class: 'Níveis Socioeconômicos',
    audit_menu: 'Audit',
    audit_title: 'Audit',
    audit_subtitle: 'Atribuição e Mensuração de campanhas OOH',
    back: 'Retornar',
    continue: 'Continuar',
    finish: 'Terminar',
    new: 'Novo',
    audit_period: 'Período',
    audit_ooh: 'OOH',
    audit_pdv: 'POI',
    audit_status: 'Status',
    audit_actions: 'Ações',
    audit_result_title: 'Resultados',
    audit_result_subtitle: 'Relatórios de auditoria e atribuição OOH',
    audit_result_description: 'Descrição',
    audit_result_value: 'Valor',
    audit_age_range_title: 'Faixa Etária',
    audit_age_range_subtitle: 'Faixas etárias por grupo',
    audit_dwell_time_title: 'Tempo de Permanência',
    audit_dwell_time_subtitle: 'Tempo médio de permanência por grupo',
    audit_control_group_title: 'Monitoramento',
    audit_control_group_subtitle: 'Número de Visitantes x Grupo de Controle',
    audit_visitors_title: 'Dados do visitante',
    audit_visitors_subtitle: 'Total de visitas e visitantes únicos',
    audit_total_impacted: 'Pessoas expostas ao circuito OOH',
    audit_total_impacted_visitors: 'Pessoas expostas ao circuito OOH que visitaram o PDI',
    audit_conv_rate: 'Taxa de conversão',
    audit_uplift: 'Aumento afetado pela campanha',
    audit_total_oohs: 'Número de OOHs',
    audit_total_pois: 'Número de PDIs',
    audit_visitors_real: 'Real',
    audit_visitors_control: 'Control',
    audit_visitors_predict: 'Previsto',
    journey_title: 'Customer Journey',
    journey_text: 'Análise de visitas de PDI',
    journey_text_ooh: 'Análise de impacto OOH',
    journey_correlation: 'Correlação do visitantes',
    journey_by_share: 'Por visitantes compartilhados',
    journey_by_dwell: 'Por tempo de permanência',
    journey_flow: 'Fluxo de visitas',
    journey_flow_text: 'Análise de visitas segundo origem e destino',
    dwell_time_label: 'Tempo de permanência',
    visitors_label: 'Visitantes',
    error_filtering_audience: 'Deve preencher todos os filtros necessários',
    planner_poi_details_title: 'Detalhes do POI',
    planner_poi_details_add_to_cart: 'Selecionar',
    planner_poi_details_remove_from_cart: 'Selecionado',
    planner_filters_matchers: 'Target',
    planner_distance_no_filter: 'Sem filtro',
    planner_refresh_reach: 'Atualizar Audiencia',
    planner_metrics_title: 'Métricas',
    planner_metrics_universe: 'Universo População',
    planner_metrics_target: 'Universo Target',
    planner_metrics_reach: 'Alcance Target',
    planner_metrics_share: 'Alcance Target %',
    planner_metrics_frequency: 'Frequência Média',
    planner_metrics_hits: 'Impactos Target',
    planner_metrics_reach_less: 'Menor',
    planner_metrics_reach_more: 'Maior',
    planner_fraction: 'Quartas do período',
    planner_update_reach_warning:
      'Atualize as métricas para corresponder à seleção de pontos atual.',
    planner_update_filters_warning:
      'Atualizar filtros para corresponder à seleção de pontos atual.',
    planner_update_warning: 'Há atualizações pendentes.',
    planner_poi_create: 'Criar',
    planner_poi_import: 'Importar',
    planner_poi_group_name: 'Nome do grupo de locais',
    planner_error_poi_group_name:
      'Você deve preencher o nome do grupo de locais (min: 4 - max: 100)',
    planner_error_poi_group_empty_import: 'Você deve concluir o processo de importação',
    planner_pois_partial_result: 'Muitos pontos foram encontrados. Exibindo:',
    csv_importer_fileStep_initialDragDropPrompt:
      'Arraste e solte o arquivo CSV aqui ou clique para selecionar na pasta',
    csv_importer_fileStep_activeDragDropPrompt: 'Solte o arquivo CSV aqui...',
    csv_importer_fileStep_rawFileContentsHeadings: 'Conteúdo do arquivo bruto',
    csv_importer_fileStep_previewImportHeading: 'Visualizar importação',
    csv_importer_fileStep_nextButton: 'Escolher colunas',
    csv_importer_fileStep_dataHasHeadersCheckbox: 'Os dados têm cabeçalhos',
    csv_importer_fieldStep_dragTargetPlaceholder: 'Arraste a coluna aqui',
    csv_importer_fieldStep_stepSubtitle: 'Selecionar Colunas',
    csv_importer_fieldStep_requiredFieldsError: 'Por favor, atribua todos os campos obrigatórios',
    csv_importer_fieldStep_nextButton: 'Importar',
    csv_importer_progressStep_stepSubtitle: 'Importar',
    csv_importer_progressStep_uploadMorebutton: 'Carregar mais',
    csv_importer_progressStep_finshButton: 'Concluir',
    csv_importer_progressStep_statusError: 'Não foi possível importar',
    csv_importer_progressStep_statusComplete: 'Completo',
    csv_importer_progressStep_statusPending: 'Importando...',
    csv_importer_progressStep_processedRowsLabel: 'Linhas processadas:',
    csv_importer_fieldStep_page: 'Página',
    csv_importer_fieldStep_of: 'de',
    planner_save_campaign: 'Salvar Campanha',
    planner_save_campaign_title: 'Salvar Campanha',
    planner_save_campaign_text:
      'Escolha um nome. Esta campanha estará pronta no seu Manager em breve.',
    campaign_name: 'Nome',
    error_saving_campaign: 'Erro ao salvar Campanha',
    ok_saving_campaign: '¡Campanha criada!',
    campaign_list: 'Campanhas',
    campaign_reach: 'Alcance',
    campaign_trp_population: 'TRP População',
    campaign_trp_target: 'TRP Target',
    campaign_creation_date: 'Data de Criação',
    campaign_created_by: 'Criado por',
    campaign_actions: 'Ações',
    campaign_actions_clone: 'Clonar Campanha',
    campaign_actions_edit: 'Editar Campanha',
    campaign_actions_delete: 'Remover Campanha',
    campaign_loading: 'Cargando Campanha...',
    campaign_saving: 'Salvando Campanha...',
    campaign_name_copy: 'Copia de',
    campaign_builder: 'Audience Builder',
    campaign_reset_data: 'Limpar filtros',
    planner_filter_by_reach: 'Filtrar pontos por alcance',
    planner_filter_by_reach_min: 'Pelo menos',
    planner_area_selection_title: 'Seleção por Área',
    planner_confirm_selection: 'Confirmar',
    planner_reset_selection: 'Limpar',
    planner_cancel_selection: 'Cancelar',
    planner_reach_selection_title: 'Seleção por Alcance',
    planner_reach_selection_text_select: 'Seleccionar',
    planner_reach_selection_text_pois: 'pontos',
    planner_reach_selection_text_more_reach: 'com maior alcance',
    smart_insights: 'Smart Insights',
    mobility_trends_general_info_single: 'Visão geral do {{type}}',
    planner_metrics_trp_population: 'TRP População',
    planner_metrics_trp_target: 'TRP Target',
    audit_creation_date: 'Data de Criação',
    audit_created_by: 'Criado por',
    audit_actions_delete: 'Remover Auditoria',
    audit_list: 'Auditorias',
    audit_campaign_period: 'Período da campanha OOH',
    audit_campaign_config: 'Configuração do processo de auditoria',
    audit_config_ooh_radius: 'Raio OOH',
    audit_config_pdv_radius: 'Raio PDI',
    audit_config_pdv_dwell_time: 'Tempo de Permanência no PDI',
    audit_config_pdv_dwell_time_mins: 'minutos',
    audit_config_attr_window: 'Janela de Atribuição',
    audit_config_attr_window_days: 'dias desde o último impacto',
    planner_metrics_selected_pois: 'POIs selecionados',
    search_for_text:
      'Até {{perPage}} resultados são exibidos ao mesmo tempo. Se não conseguir encontrar o seu PDI/Segmento, restrinja a pesquisa.',
    search_load_more: 'Carregar mais',
    showing_results_for: 'Mostrando resultados para',
    search_for_help:
      'Você pode pesquisar vários termos separando as palavras com espaço. Use aspas duplas para correspondência exata.',
    search_selected_pois: 'POIs selecionados',
    download_audience: 'Baixar Audiência',
    download_audience_details:
      'Você está prestes a solicitar um arquivo CSV contendo IDs de dispositivos exclusivos, que representam os visitantes do segmento no período selecionado.',
    download_audience_details_raw: 'Público Alcançável',
    download_audience_details_raw_ids: 'IDs únicos',
    download_audience_details_segment: 'Segmento Seleccionado',
    download_audience_details_period: 'Período Seleccionado',
    download_audience_details_cost: 'Custo Total',
    download_audience_details_checkout: 'Checkout',
    download_audience_segment: 'Segmento',
    download_audience_period: 'Período',
    audiences_menu_manager: 'Administrar',
    audience_manager: 'Audience Manager',
    audience_created_by: 'Criado por',
    audience_actions_delete: 'Remover Audiência',
    or_click_to_register: 'Ou clique aqui para se inscrever',
    already_user_login: 'Já é usuário? Conecte-se',
    register: 'Criar Conta',
    register_thanks:
      'Obrigado por se registrar! Enviamos um e-mail para o endereço especificado para ativar sua conta.',
    register_close_window: 'Você pode fechar esta janela agora.',
    register_activating: 'Aguarde enquanto ativamos sua conta...',
    register_now_active: 'Sua conta agora está ativa. Redirecionando para login...',
    error_saving_organization: 'Erro ao salvar a organização',
    ok_saving_organization: '¡Organização criada!',
    organization_not_allowed:
      'Você não tem permissões suficientes para gerenciar o faturamento desta organização: {{organization}}',
    organization_payment_ok: '¡Sua conta foi ativada! Agora você pode continuar na plataforma.',
    organization_payment_not_valid:
      'As informações de pagamento da organização {{organization}} não são válidas.',
    organization_not_found: 'Organização não encontrada: {{organization}} ',
    organization_save_payment_info: 'Adicionar Informações de Pagamento',
    organization_loading: 'Carregando Organização...',
    try_again: 'Tente Novamente',
    edit_account: 'Conta',
    error_saving_profile: 'Erro ao salvar perfil',
    ok_saving_profile: '¡Perfil salvo!',
    edit_subscription_title: 'Inscrição',
    manage_subscription: 'Gerir Inscrição',
    subscription_info: 'Detalhes da assinatura',
    subscription_status: 'Status',
    subscription_action_cancel: 'Cancelar assinatura',
    subscription_renewal_date: 'Data de renovação',
    subscription_cost: 'Custo',
    subscription_last_payment: 'Último pagamento',
    edit_billing_title: 'Faturamento',
    manage_billing: 'Gerenciar Faturamento',
    manage_payment_methods: 'Gerenciar métodos de pagamento',
    billing_loading: 'Carregando Portal do Cliente...',
    error_customer_portal: 'Erro ao carregar o portal do cliente',
    billing_info: 'Detalhes de cobrança',
    subscription_loading: 'Carregando detalhes da assinatura...',
    organization_signup_text: `Observe que nosso serviço vem com um período de avaliação de <strong>{{trialPeriodDays}} dias</strong>, durante o qual você pode experimentar todos os nossos recursos e serviços sem nenhum custo. No final do período de avaliação, o valor total da assinatura será cobrado em seu cartão de crédito, a menos que você cancele.
    <br /><br />
    Se você decidir que não deseja continuar com a assinatura, poderá cancelá-la facilmente a qualquer momento durante o período de avaliação visitando as configurações da sua conta ou entrando em contato com nossa equipe de suporte ao cliente.`,
    audience_payment_ok: '¡O pagamento foi processado com sucesso!',
    select_payment_method: 'Selecione o método de pagamento',
    audiencie_purchase_confirm_title: 'Confirmar Pagamento',
    audiencie_purchase_confirm_text:
      'Você está prestes a comprar o público {{segmentLabel}}, período {{formattedSegmentPeriod}} por um preço total de {{formattedSegmentCost}}.',
    terms_please_accept: 'Aceite nossos termos e condições.',
    terms_title: 'Termos e Condições',
    terms_accept: 'Aceito',
    terms_do_not_accept: 'Não Aceito',
    terms_and_conditions: '',
    unknown_error_please_contact_support:
      'Ocorreu um erro. Entre em contato com o suporte ({{msg}}).',
    audience_not_allowed: 'Você não tem permissões suficientes para gerenciar este público.',
    audience_payment_not_valid: 'As informações de pagamento não são válidas para este público.',
    search_no_data: 'Nenhum dado a ser exibido para o período selecionado.',
    error_selecting_poi: 'Este grupo tem mais PDIs do que o máximo permitido',
    download_campaign: 'Baixar Campanha',
    download_campaign_summary: 'Resumo',
    download_campaign_pois: 'Pontos',
    planner_csv_selection_title: 'Seleção por CSV',
    planner_csv_selection_match_error: 'Erro de correspondência: '
  }
};
