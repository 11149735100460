import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle, Row, Button } from 'reactstrap';

// import InterestsChart from './InterestsChart';
import VisitsDailyTrendsChart from './VisitsDailyTrendsChart';
import VisitsDistanceTrendsChart from './VisitsDistanceTrendsChart';
import DwellTimeQuartilesCard from './DwellTimeQuartilesCard';

import { ResponseLoading } from '../../components/utils/Loading';
import { callSyncAPIv2DataLoader } from '../../hooks/api';

export default function VisitsTrendsCard({ responseVisits, poiType, totalHits }) {
  const currentLang = i18n.locale;
  const useMiles = window._env_.REACT_APP_DISTANCE_UNITS === 'miles'; // defaults to km
  const metricsWeekDay = responseVisits.hasValue('metricsWeekDay')
    ? responseVisits.data.value.metricsWeekDay
    : [];

  const [blockIndex, setBlockIndex] = useState(0);
  const [influenceAreas, setInfluenceAreas] = useState(
    backwardCompatibleInfluenceAreas(currentLang, useMiles, metricsWeekDay)
  );

  useEffect(() => {
    let isMounted = true;
    callSyncAPIv2DataLoader(
      'appConfig',
      `visit-trends/influenceAreas`,
      {},
      { responseHolder: 'response' },
      res => {
        // console.log(res);
        if (isMounted && !res.error && res.data.value.response['visit-trends']) {
          setInfluenceAreas(res.data.value.response['visit-trends'].influenceAreas);
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <CardGroup className="metric-card ">
            <Card style={{ flex: '3 0 0' }}>
              <CardBody>
                <CardTitle className="text-uppercase h6">{i18n.t('mobility_trends')}</CardTitle>
                <div className="small mb-4 mt-2 card-subtitle">
                  {i18n.t('mobility_trends_by_zone')}
                  <br />
                  <br />
                  {influenceAreas.map((influenceArea, index) => (
                    <Button
                      key={index}
                      className="btn-sm"
                      color={blockIndex === index ? 'dark' : 'light-gray'}
                      onClick={() => setBlockIndex(index)}
                    >
                      {influenceArea.label[i18n.locale]}
                    </Button>
                  ))}
                </div>
                <div className="d-flex">
                  <Card className="col-12 col-md-12 col-sm-12" style={{ height: '23rem' }}>
                    <div className="small mb-4 mt-2 card-subtitle">
                      {i18n.t('mobility_per_day')}
                    </div>
                    <div style={{ width: '100%', height: '280px' }}>
                      <VisitsDailyTrendsChart
                        response={metricsWeekDay}
                        block={influenceAreas[blockIndex].block}
                        useMiles={useMiles}
                      />
                    </div>
                  </Card>
                </div>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>

      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <CardGroup className="metric-card ">
            <Card style={{ flex: '3 0 0' }}>
              <CardBody>
                <CardTitle className="text-uppercase h6">
                  {i18n.t('mobility_trends')}
                  <ResponseLoading response={responseVisits} />
                </CardTitle>
                <div className="small mb-4 card-subtitle">{i18n.t('distance_distribution')}</div>
                <div style={{ width: '100%' }}>
                  <VisitsDistanceTrendsChart
                    response={responseVisits}
                    poiType={poiType}
                    useMiles={useMiles}
                    totalHits={totalHits}
                  />
                </div>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>
      {
        // eslint-disable-next-line no-nested-ternary
        poiType === 'ooh' ? null : window._env_.REACT_APP_MODULE_REPORTS_DWELLTIME === 'true' ? (
          <DwellTimeQuartilesCard responseVisits={responseVisits} />
        ) : null
      }
    </>
  );
}

// TODO: https://www.notion.so/mofiler/Decommission-backwardCompatibleInfluenceAreas-25c06ce22a8a4ae69f150ac524425998 | Once every instance has this configuration in the proper App Config, we can BURN this function 💣
function backwardCompatibleInfluenceAreas(currentLang, useMiles, metricsWeekDay) {
  const influenceAreas = [
    {
      block: '0_500',
      label: { [currentLang]: useMiles ? i18n.t('0_03_miles') : i18n.t('0_500_mts') }
    }
  ];
  if (metricsWeekDay.filter(m => m['500_1000_uniques'] > 0).length > 0) {
    influenceAreas.push({
      block: '500_1000',
      label: { [currentLang]: useMiles ? i18n.t('03_06_miles') : i18n.t('500_1000_mts') }
    });
  }
  if (metricsWeekDay.filter(m => m['1000_2000_uniques'] > 0).length > 0) {
    influenceAreas.push({
      block: '1000_2000',
      label: { [currentLang]: useMiles ? i18n.t('06_125_miles') : i18n.t('1000_2000_mts') }
    });
  }
  return influenceAreas;
}
