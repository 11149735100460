import React from 'react';
import i18n from 'i18n-js';

import { useMappedState } from 'redux-react-hook';

import ViewHeader from '../layouts/AppHeader';

import { useDashboardData, useFullDemosObj } from '../../hooks/metrics/esdata';

import ViewContent from './ViewContent';
import { filterSelector } from '../../redux/ducks/filters';

export default function Journey(props) {
  const { poiType } = props;
  const { filter } = useMappedState(filterSelector);
  const typeFilter = filter[poiType];

  const { responseJourney, responseFullDemos, responseFullDemosPrev } = useDashboardData(
    typeFilter,
    {
      qJourney: true,
      qFullDemos: true,
      qFullDemosPrev: true
    }
  );

  const fullDemosObj = useFullDemosObj(responseFullDemos);
  const fullDemosPrevObj = useFullDemosObj(responseFullDemosPrev);

  return (
    <div className="view">
      <ViewHeader
        title={i18n.t('journey_title')}
        subtitle={i18n.t(poiType === 'ooh' ? 'journey_text_ooh' : 'journey_text')}
      />
      <ViewContent
        filter={typeFilter}
        responseJourney={responseJourney}
        fullDemosObj={fullDemosObj}
        fullDemosPrevObj={fullDemosPrevObj}
      />
    </div>
  );
}
