import { useState } from 'react';
import i18n from 'i18n-js';
import toastr from 'toastr';
import { Alert, Button, Card, CardBody } from 'reactstrap';
import { useDispatch } from 'redux-react-hook';
import API2 from '../../api2/index';
import { logout } from '../../redux/ducks/auth';
import LoadingModal from '../../components/utils/LoadingModal';

export default function Reactivate(props) {
  const { organization } = props;
  const dispatch = useDispatch();
  const logoutDispath = () => dispatch(logout());

  const [isLoading, setLoading] = useState(false);

  const openCustomerPortal = () => {
    setLoading(true);

    API2.post(
      'organizations',
      `${organization.id}/portal`,
      { returnUrl: `${window.location.origin}/settings/subscription` },
      (err, portal) => {
        if (err) {
          toastr.error(`${i18n.t('error_customer_portal')}: ${err}`);
          setLoading(false);
        } else {
          window.location.href = portal;
        }
      }
    );
  };

  return (
    <>
      {isLoading && <LoadingModal isOpen={true} text={i18n.t('billing_loading')} />}

      <div className="view-content view-components">
        <div className="align-items-center justify-content-center d-flex mt-5">
          <Card className="main_window">
            <CardBody className="align-items-center card-body d-flex flex-column h-100 justify-content-center">
              <Alert color="danger">
                {i18n.t('organization_payment_not_valid', { organization: organization.name })}
              </Alert>
              <div className="align-items-center d-flex flex-column justify-content-center">
                <Button color="primary" onClick={openCustomerPortal}>
                  {i18n.t('billing_info')}
                </Button>
                <Button
                  color=""
                  onClick={logoutDispath}
                  className="btn-block border-0 shadow-none btn-sm mt-3"
                >
                  {i18n.t('logout')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
