import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
// import { IcoPlus, IcoMinus, IcoList, IcoPoisList, IcoArwInput } from '../icons';

import InputRadio from './inputRadio';
import StatTotal from './statTotal';
import BtnGroupArw from './btnGroupArw';
import { IcoCloseCirc, IcoFilter } from '../icons';

export default function GroupItem({
  label,
  value,
  children,
  hasSelect,
  hasMore,
  selected,
  hasExpand,
  expanded,
  onExpand,
  onSelect,
  onMore
}) {
  const [isSelected, setIsSelected] = useState(selected);
  const toggleSelect = () => {
    setIsSelected(!isSelected);
    onSelect(!isSelected);
  };

  const [isExpanded, setIsExpanded] = useState(expanded);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    onExpand(!isExpanded);
  };

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <div className="static font-open-sans text-slate-500 text-sm pr-1 anim-item-tree">
      <div className={'static flex items-center pl-2.5'}>
        {/* BG Active  */}
        <div
          className={
            'absolute w-full h-7 left-0 ' +
            (!isSelected ? 'transition-color duration-500' : 'bg-blue-500')
          }
        />

        {/* RADIO Select */}
        {hasSelect && (
          <div className="relative p-1 cursor-pointer -ml-1 mr-1" onClick={() => toggleSelect()}>
            <InputRadio isSelected={isSelected} />
          </div>
        )}

        {/* EXPAND / COLLAPSE*/}

        <div
          onClick={() => hasExpand && toggleExpand()}
          className={`flex items-center group ${hasExpand ? 'cursor-pointer' : ''}`}
        >
          {hasExpand && (
            <>
              {/* <BtnGroup isSelected={isSelected} isExpanded={isExpanded} /> */}
              <div className="relative mr-1.5">
                <BtnGroupArw isSelected={isSelected} isExpanded={isExpanded} />
              </div>
            </>
          )}
          {/* LABEL  */}
          <p
            className={
              'relative transition-color duration-300 truncate text-2xs font-medium tracking-normal ' +
              (isSelected
                ? 'text-white font-medium pr-2'
                : 'text-slate-600 group-hover:text-slate-900 pr-1.5')
            }
          >
            {label}
          </p>
        </div>

        {/* POIs number */}
        {value && (
          <StatTotal
            value={value}
            color={isSelected ? 'bg-blue-700' : 'bg-white'}
            textColor={
              (isSelected ? 'text-white border-blue-600' : 'text-mof-slate-300 border-slate-300') +
              ' border'
            }
          />
        )}

        {hasMore ? (
          <div
            className="relative flex shrink-0 w-5 h-5 items-center border justify-center cursor-pointer rounded transition-all duration-300 hover:scale-105 ml-1.5 text-blue-400"
            onClick={onMore}
          >
            <IcoFilter className={'w-5 h-5 fill-current '} title={i18n.t('search_load_more')} />
            <IcoCloseCirc className="absolute w-3.5 h-3.5 -right-2 -bottom-1.5 text-rose-700 fill-current bg-white rounded-full" />
          </div>
        ) : null}

        {/*  POis LIST action  */}
        {/* {hasSelect && (
          <div
            onClick={() => toggleList()}
            className={
              'relative flex shrink-0 w-5 h-5 items-center border justify-center cursor-pointer rounded transition-all duration-300 hover:scale-105 ml-1.5 ' +
              (isListed
                ? isSelected
                  ? 'border-blue-700 bg-blue-700 text-white cursor-not-allowed'
                  : 'border-blue-500 bg-blue-500 text-white'
                : 'border-slate-300 bg-white text-blue-400 hover:border-blue-400')
            }
          >
            <IcoPoisList className={'w-5 h-5 fill-current '} />
          </div>
        )} */}
      </div>

      {/* CHILDRENS  */}
      {isExpanded && children && children.length > 0 && (
        <div className="static pl-3 pt-2 space-y-1.5">{children}</div>
      )}
    </div>
  );
}
