import i18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';

import { Card, CardBody } from 'reactstrap';

import API2 from '../../api2';
import LoadingModal from '../../components/utils/LoadingModal';

export default function ManageBilling(props) {
  const { user, organization } = props;

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('edit_billing_title')}</h1>
          <p className="mb-0">{i18n.t('manage_billing')}</p>
        </header>
      </div>
      <ViewContent user={user} organization={organization} />
    </div>
  );
}

function ViewContent({ user, organization }) {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    API2.post(
      'organizations',
      `${organization.id}/portal`,
      { returnUrl: `${window.location.origin}/settings/subscription` },
      (err, portal) => {
        if (err) {
          toastr.error(`${i18n.t('error_customer_portal')}: ${err}`);
          setLoading(false);
        } else {
          window.location.href = portal;
        }
      }
    );
  }, []);

  return (
    <>
      {isLoading && <LoadingModal isOpen={true} text={i18n.t('billing_loading')} />}
      <div className="view-content view-components">
        <Card className="mb-4">
          <CardBody className="table-responsive">
            <h6 className="mb-4 text-uppercase">{i18n.t('billing_info')}</h6>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
