import React, { useRef } from 'react';
import i18n from 'i18n-js';
import { colorStyles, colorNames } from './tree/theme';

import LayoutPopXl from './tree/layoutPopXl';
import PoisSearch from './tree/poisSearch';
import Btn from './tree/btn';

import Categories from './tree/categories';
import CategoryItem from './tree/categoryItem';

import SubHeader from './tree/subHeader';

import Groups from './tree/groups';
import GroupItem from './tree/groupItem';
import GroupsCategoryTitle from './tree/groupsCategoryTitle';

import ScrollWindow from './tree/scrollWindow';

import ContentMain from './tree/contentMain';
import ContentColumn from './tree/contentColumn';

import PoisOptions from './tree/poisOptions';
import PoisListPath from './tree/poisListPath';
import PoiItem from './tree/poiItem';

import PopupHeader from './tree/popupHeader';
import PopupFooter from './tree/popupFooter';
import PoisSelected from './tree/poisSelected';

import './styles.scss';

export default function POISelectorContent({
  data,
  filter,
  filterConditions,
  perPage,
  cursor,
  cursorSelected,
  nodeSelected,
  categoryExpanded,
  setCategoryExpanded,
  isSearching,
  textFilter,
  filterSinglePOI,
  onFilterSearh,
  onToggle,
  onSelectNode,
  onCancel,
  onConfirm,
  onMore
}) {
  const searchRef = useRef(null);

  const isGroupSelected = nodeSelected && nodeSelected.nodeType === 'Group';
  const isRootData = data?.ts === 2; // TODO: :@

  const getDisplayName = node => {
    if (!node || !node.displayName) return null;

    return node.displayName;
  };

  const getDisplayValue = node => {
    return !node || !node.groupSize || node.nodeType === 'ProxyGroup' ? null : node.groupSize;
  };

  const findNode = (nodeId, children = data.children) => {
    if (!nodeId || !children) return null;

    for (let i = 0; i < children.length; i++) {
      const c = children[i];
      if (c.nodeId === nodeId) {
        return c;
      }
      const f = findNode(nodeId, c.children);
      if (f) return f;
    }

    // not found
    return null;
  };
  const getDisplayPath = (node, path = [], first = true) => {
    !first && path.push(getDisplayName(node));
    const parent = findNode(node.parentId);

    if (parent) return getDisplayPath(parent, path, false);
    // else return path;

    const strPath = path.reverse().join(' - ') + ' - ';
    return strPath;
  };

  const isSelectable = node => {
    const selByCondition =
      !filterConditions ||
      filterConditions.length === 0 ||
      filterConditions.reduce((a, v) => a && node.metadata[v] === true, true);

    // console.log(selByCondition, filterConditions, node);

    return (
      selByCondition &&
      node.nodeType !== 'Folder' &&
      (!filterSinglePOI || node.nodeType === 'ProxyGroup')
    );
  };

  const isExpandable = node => {
    return node.nodeType !== 'ProxyGroup';
  };

  const hasExpandableChildren = node => {
    const hasChildren =
      !node.children ||
      node.children.length === 0 ||
      (node.children && node.children.find(c => c.nodeType !== 'ProxyGroup')) != null;
    // console.log(node.nodeId, node.children, hasChildren);
    return hasChildren;
  };

  const isSelectablePOI = node => {
    return node.nodeType === 'ProxyGroup';
  };

  const isExpanded = node => {
    // if last level of folders is expanded, BUT is not the current cursor, then show it as not expanded,
    // because when expanded it has to show its POIs in the POIs list, but hide them when not selected
    return node.toggled && (hasExpandableChildren(node) || node.nodeId === cursor.nodeId);
  };

  const hasMore = node => {
    return node.nodeType !== 'ProxyGroup' && !node.childrenLoaded && node.toggled && !node.loading;
  };

  const displayExpandedNode = (node, isChild = false) => {
    if (!node || !node.children) return null;

    // Group Item
    return node.children
      .map(c =>
        isExpandable(c) ? (
          <GroupItem
            key={c.nodeId}
            isChild={isChild}
            label={getDisplayName(c)}
            value={isSelectable(c) ? getDisplayValue(c) : null}
            hasExpand
            expanded={isExpanded(c)}
            hasSelect={isSelectable(c)}
            hasMore={hasMore(c)}
            onMore={() => onMore(c)}
            onExpand={expanded => {
              onToggle(c, expanded, newNode => {
                // console.log('newNode', newNode);
                // if last level, then mark it as selected
                if (!hasExpandableChildren(c)) {
                  if (!nodeSelected || nodeSelected.nodeId !== c.nodeId) {
                    let markSelected = !isSelectable(c) && expanded; // mark it as selected if it is not selectable -> to force the selection effect, if is selectable, no need to trigger that effect
                    markSelected =
                      markSelected && !(nodeSelected && nodeSelected.nodeId !== c.nodeId); // remove current selection

                    onSelectNode(c, markSelected, true);
                    // setIsGroupSelected(false);
                    // setCountSelected(0);
                    // !isSelectable(c) && setNodeSelected(expanded ? c : null); // mark it as selected if it is not selectable -> to force the selection effect, if is selectable, no need to trigger that effect
                    // if (nodeSelected && nodeSelected.nodeId !== c.nodeId) setNodeSelected(null); // remove current selection
                  }
                }
              });
            }}
            selected={isSelectable(c) && nodeSelected && nodeSelected.nodeId === c.nodeId}
            onSelect={selected => {
              onSelectNode(c, selected);
              // setIsGroupSelected(selected);
              // setCountSelected(selected ? c.groupSize : 0);
              // setNodeSelected(selected ? c : null);
            }}
          >
            {displayExpandedNode(c, true)}
          </GroupItem>
        ) : null
      )
      .filter(c => c !== null);
  };

  return (
    <LayoutPopXl zindex="z-20" onClose={onCancel}>
      {/* Popup Header */}
      <PopupHeader
        ico="location"
        title={i18n.t(filter.type === 'ooh' ? 'admin_oohs_list' : 'admin_pois_list')}
        info={i18n.t('search_for_text', { perPage })}
      />
      {(!data || !data.children || data.children.length === 0) && isRootData ? (
        <>
          <Categories>
            <span>{i18n.t('search_no_data')}</span>
          </Categories>
          <ContentMain cols="3"></ContentMain>
        </>
      ) : (
        <>
          {isSearching && (
            <div className="loading_metric float-right position-relative" style={{ right: 0 }}>
              <div className="loading-dot" />
            </div>
          )}
          {/* content Top */}
          <Categories searchString={textFilter} onClearSearch={() => onFilterSearh('')}>
            {data.children &&
              data.children.map((c, i) => {
                const v = getDisplayValue(c);
                const colorIndex = i % colorNames.length;
                // Categorie Item
                return (
                  <CategoryItem
                    key={c.nodeId}
                    colors={colorStyles}
                    colorName={colorNames[colorIndex]}
                    label={getDisplayName(c)}
                    value={v}
                    hasSelect={isSelectable(c)}
                    onExpand={expanded => {
                      setCategoryExpanded({
                        ...categoryExpanded,
                        [data.ts]: expanded ? c : null,
                        [`${data.ts}-index`]: expanded ? colorIndex : null
                      });
                      onToggle(c, expanded);
                    }}
                    selected={nodeSelected && nodeSelected.nodeId === c.nodeId}
                    expanded={
                      categoryExpanded[data.ts] &&
                      categoryExpanded[data.ts].nodeId === c.nodeId &&
                      isExpanded(c)
                    }
                    onSelect={selected => {
                      onSelectNode(c, selected);
                      // setIsGroupSelected(selected);
                      // setCountSelected(selected ? c.groupSize : 0);
                      // setNodeSelected(selected ? c : null);
                    }}
                    // showList={v !== null}
                  />
                );
              })}
          </Categories>

          {/* content Body */}
          <ContentMain cols="3">
            {/* Pois tools  */}
            <PoisOptions innerRef={searchRef}>
              <PoisSearch
                type="text"
                searchFieldRef={searchRef}
                placeholder={i18n.t('search_for')}
                addClass={'w-60'}
                onSearch={onFilterSearh}
                defaultValue={textFilter}
                helpText={i18n.t('search_for_help')}
              />
            </PoisOptions>

            {/* LEFT: Groups  */}
            <ContentColumn>
              <SubHeader
                title="--"
                colors={colorStyles}
                colorName={
                  categoryExpanded[`${data.ts}-index`] !== null
                    ? colorNames[categoryExpanded[`${data.ts}-index`]]
                    : null
                }
                ico="groups"
              >
                {categoryExpanded[data.ts] && (
                  <GroupsCategoryTitle title={getDisplayName(categoryExpanded[data.ts])} />
                )}
              </SubHeader>

              {/*  Groups TREE   */}
              <ScrollWindow>
                <Groups>{displayExpandedNode(categoryExpanded[data.ts])}</Groups>
              </ScrollWindow>
            </ContentColumn>

            {/* RIGHT: Pois  */}
            <ContentColumn addClass="col-span-2">
              <SubHeader title="POIs list" ico="pois" selected={isGroupSelected}>
                {/* current Tree path */}
                <PoisListPath
                  title={getDisplayPath(cursorSelected)}
                  active={getDisplayName(cursorSelected)}
                />
              </SubHeader>

              {/*  Pois List   */}
              <ScrollWindow selected={isGroupSelected}>
                {/* List  */}
                <Groups>
                  {cursorSelected &&
                    //cursor.toggled &&
                    cursorSelected.children &&
                    cursorSelected.children.map(
                      c =>
                        isSelectablePOI(c) && (
                          <PoiItem
                            key={c.nodeId}
                            label={getDisplayName(c)}
                            hasSelect={isSelectable(c)}
                            selected={nodeSelected && nodeSelected.nodeId === c.nodeId}
                            multiSelected={isGroupSelected}
                            onSelect={selected => {
                              onSelectNode(c, selected);
                              // setIsGroupSelected(false);
                              // setCountSelected(selected ? 1 : 0);
                              // setNodeSelected(selected ? c : null);
                            }}
                          />
                        )
                    )}
                </Groups>
              </ScrollWindow>
            </ContentColumn>
          </ContentMain>
        </>
      )}

      {/* Popup Footer */}
      <PopupFooter>
        <PoisSelected val={nodeSelected ? nodeSelected.groupSize : 0} />
        <Btn label={i18n.t('cancel_button')} onClick={onCancel} />
        <Btn
          label={i18n.t('confirm_button')}
          disabled={!nodeSelected || !isSelectable(nodeSelected)}
          onClick={() => onConfirm(nodeSelected)}
          ico="ok"
          inverted
        />
      </PopupFooter>
    </LayoutPopXl>
  );
}
