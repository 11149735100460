import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle, Button } from 'reactstrap';

import VisitorsComparedChart from './VisitorsComparedChart';

import { ResponseLoading } from '../../components/utils/Loading';
import { callSyncAPIv2DataLoader } from '../../hooks/api';

export default function VisitorsComparedCard({ responseVisits, poiType }) {
  const [thresholdIndex, setThresholdIndex] = useState(0);
  const [recurrencyThresholds, setRecurrencyThresholds] = useState([
    { label: { pt: '2', es: '2', en: '2' }, value: 1 }
  ]);

  useEffect(() => {
    let isMounted = true;
    callSyncAPIv2DataLoader(
      'appConfig',
      `recurrent-visitors/recurrencyThresholds`,
      {},
      { responseHolder: 'response' },
      res => {
        // console.log(res);
        if (isMounted && !res.error) {
          setRecurrencyThresholds(
            res.data.value.response['recurrent-visitors'].recurrencyThresholds
          );
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, []);

  const visitsWeekly =
    responseVisits && responseVisits.hasValue('metricsWeekly')
      ? responseVisits.data.value.metricsWeekly
      : null;

  const metricsGroup =
    responseVisits && responseVisits.hasValue('metricsGroup')
      ? responseVisits.data.value.metricsGroup
      : {};

  const visitsTitle = poiType === 'ooh' ? 'visits_title_ooh' : 'visits_title';
  const repeatTitle = poiType === 'ooh' ? 'repeat_visitors_title_ooh' : 'repeat_visitors_title';
  const repeatVisitsTitle = poiType === 'ooh' ? 'visits_title_ooh' : 'visits_title';

  const repeatCount = recurrencyThresholds[thresholdIndex].value;

  const repeatMetric = (title, repeatCount, metric, format) => {
    return (
      <Card className="ml-3">
        <CardBody>
          <h6 className="text-uppercase title font-weight-bold small text-center">
            {i18n.t(title)}
          </h6>
          <h4 className="font-weight-normal mb-0 text-center">
            {numeral(metricsGroup[`${metric}_${repeatCount}`]).format(format)}
          </h4>
        </CardBody>
      </Card>
    );
  };

  return (
    <CardGroup className="metric-card mb-4">
      <Card style={{ flex: '3 0 0' }}>
        <CardBody>
          <CardTitle className="text-uppercase h6">
            {i18n.t(repeatTitle)}
            <ResponseLoading response={responseVisits} />
          </CardTitle>
          <div className="small mb-4 card-subtitle">
            {i18n.t('by_fraction')}
            <br />
            <div className="d-flex w-100">
              <div className="d-flex w-50 align-items-center text-lowercase">
                <div className="m-3">
                  {recurrencyThresholds.map((threshold, index) => (
                    <Button
                      key={index}
                      className="btn-sm"
                      color={thresholdIndex === index ? 'dark' : 'light-gray'}
                      onClick={() => setThresholdIndex(index)}
                    >
                      {threshold.label[i18n.locale]}
                    </Button>
                  ))}
                </div>
                {`${i18n.t('or_more')} ${i18n.t(visitsTitle)}`}
              </div>
              <div className="d-flex w-50 align-items-center justify-content-center">
                {repeatMetric(repeatTitle, repeatCount, 'repeat_visitors', '0,0')}
                {repeatMetric(repeatVisitsTitle, repeatCount, 'repeat_visits', '0,0')}
                {repeatMetric('frequency_title', repeatCount, 'frequency', '0,0.00')}
              </div>
            </div>
          </div>
          <div style={{ width: '100%', height: '280px' }}>
            <VisitorsComparedChart
              repeatTitle={repeatTitle}
              repeatVisitsTitle={repeatVisitsTitle}
              uniquesTitle={
                poiType === 'ooh'
                  ? 'total_unique_visitors_title_ooh'
                  : 'total_unique_visitors_title'
              }
              visitsWeekly={visitsWeekly}
              repeatCount={repeatCount}
              fixedPeriods={window._env_.REACT_APP_TYPE_PERIOD === 'fixed'}
            />
          </div>
        </CardBody>
      </Card>
    </CardGroup>
  );
}
