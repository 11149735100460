import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import numeral from 'numeral';
import i18n from 'i18n-js';
import { BarChart, XAxis, YAxis, Bar, Tooltip, ResponsiveContainer, Cell } from 'recharts';

import { MapContainer, TileLayer } from 'react-leaflet';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { colors } from '../../components/charts/Styles';
import PopoverHelp from '../../components/utils/PopoverHelp';

L.Icon.Default.imagePath = '/img/maps/';

export default function VisitsDistanceTrendsChart({ response, poiType, useMiles, totalHits }) {
  if (!response || !response.hasValue('metrics'))
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const { metricsHome } = response.data.value;

  const totalHome = metricsHome
    ? metricsHome.home_distance_mts_0_500 +
      metricsHome.home_distance_mts_501_1000 +
      metricsHome.home_distance_mts_1001_2000 +
      metricsHome.home_distance_mts_2001_5000 +
      metricsHome.home_distance_mts_5001_10000 +
      metricsHome.home_distance_mts_10001_more
    : 0;

  const home = metricsHome
    ? [
        {
          name: useMiles ? `${i18n.t('up_to')} 0.3 mi` : `${i18n.t('up_to')} 500m`,
          value: metricsHome.home_distance_mts_0_500
        },
        {
          name: useMiles ? '0.3 mi - 0.6 mi' : '500m - 1.000m',
          value: metricsHome.home_distance_mts_501_1000
        },
        {
          name: useMiles ? '0.6 mi - 1.25 mi' : '1.000m - 2.000m',
          value: metricsHome.home_distance_mts_1001_2000
        },
        {
          name: useMiles ? '1.25 mi - 3 mi' : '2.000m - 5.000m',
          value: metricsHome.home_distance_mts_2001_5000
        },
        {
          name: useMiles ? '3 mi - 6 mi' : '5.000m - 10.000m',
          value: metricsHome.home_distance_mts_5001_10000
        },
        {
          name: useMiles ? `${i18n.t('more_than')} 6 mi` : `${i18n.t('more_than')} 10.000m`,
          value: metricsHome.home_distance_mts_10001_more
        }
      ]
    : [];

  const responseHomeHeatmap = response.hasValue('metricsHomeHeatpoints')
    ? response.data.value.metricsHomeHeatpoints.map(r => [r.latitude, r.longitude, r.count])
    : [];

  const { metricsWork } = response.data.value;

  const totalWork = metricsWork
    ? metricsWork.work_distance_mts_0_500 +
      metricsWork.work_distance_mts_501_1000 +
      metricsWork.work_distance_mts_1001_2000 +
      metricsWork.work_distance_mts_2001_5000 +
      metricsWork.work_distance_mts_5001_10000 +
      metricsWork.work_distance_mts_10001_more
    : 0;

  const work = metricsWork
    ? [
        {
          name: useMiles ? `${i18n.t('up_to')} 0.3 mi` : `${i18n.t('up_to')} 500m`,
          value: metricsWork.work_distance_mts_0_500
        },
        {
          name: useMiles ? '0.3 mi - 0.6 mi' : '500m - 1.000m',
          value: metricsWork.work_distance_mts_501_1000
        },
        {
          name: useMiles ? '0.6 mi - 1.25 mi' : '1.000m - 2.000m',
          value: metricsWork.work_distance_mts_1001_2000
        },
        {
          name: useMiles ? '1.25 mi - 3 mi' : '2.000m - 5.000m',
          value: metricsWork.work_distance_mts_2001_5000
        },
        {
          name: useMiles ? '3 mi - 6 mi' : '5.000m - 10.000m',
          value: metricsWork.work_distance_mts_5001_10000
        },
        {
          name: useMiles ? `${i18n.t('more_than')} 6 mi` : `${i18n.t('more_than')} 10.000m`,
          value: metricsWork.work_distance_mts_10001_more
        }
      ]
    : [];

  const responseWorkHeatmap = response.hasValue('metricsWorkHeatpoints')
    ? response.data.value.metricsWorkHeatpoints.map(r => [r.latitude, r.longitude, r.count])
    : [];

  const { stores } = response.data.value;

  return (
    <>
      <div>
        <Card>
          <CardBody>
            <CardTitle className="text-uppercase h6">
              {i18n.t(poiType === 'ooh' ? 'home_to_poi_title_ooh' : 'home_to_poi_title')}

              {home && home.length > 0 && (
                <PopoverHelp
                  title={i18n
                    .t(poiType === 'ooh' ? 'home_to_poi_title_ooh' : 'home_to_poi_title')
                    .toUpperCase()}
                  text={i18n.t('home_to_poi_text_how', {
                    example_range: home[0].name,
                    example_range_impressions: numeral(
                      homeOOHCalculate(home[0].value).calculatedTotal
                    ).format('0,0'),
                    total_impressions: numeral(totalHits).format('0,0'),
                    result: numeral(homeOOHCalculate(home[0].value).percentage).format('% 0,0.00')
                  })}
                  html
                />
              )}
            </CardTitle>
            <div className="small mb-4 card-subtitle">{i18n.t('home_text')}</div>
            {metricsHome ? (
              <div className="d-flex">
                <Card className="col-8 col-md-8 col-sm-12" style={{ height: '23rem' }}>
                  <ResponsiveContainer>
                    <BarChart data={home} margin={{ top: 0, right: 35, left: 0, bottom: -10 }}>
                      <YAxis
                        type="number"
                        tickFormatter={value => numeral(value).format('0,00[a]')}
                      />
                      <XAxis dataKey="name" tick={{ fontSize: 11 }} />
                      <Tooltip
                        formatter={value => {
                          // calculate based on total visits
                          const { calculatedTotal, percentage } = homeOOHCalculate(value);

                          return `${numeral(calculatedTotal).format('0,0')} (${numeral(
                            percentage
                          ).format('% 0,0.00')})`;
                        }}
                      />
                      <Bar
                        dataKey="value"
                        name={i18n.t(poiType === 'ooh' ? 'visits_label_ooh' : 'visits_label')}
                        barSize={30}
                      >
                        {home.map((entry, index) => (
                          <Cell fill={colors[index]} key={index} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Card>
                <Card className="ml-2 col-4 col-md-4 col-sm-12 pb-3">
                  <MyLeafLetMapComponent
                    key="homeMap"
                    pois={stores}
                    heatmapData={responseHomeHeatmap}
                  />
                  {/* DONT ASK WHY I ADDED THIS MAP! -> if it is not there, workMap doesn't work (flicking issue) */}
                  <div style={{ position: 'absolute', height: 1, width: 1 }}>
                    <MyLeafLetMapComponent
                      key="homeMap2"
                      pois={stores}
                      heatmapData={responseHomeHeatmap}
                    />
                  </div>
                </Card>
              </div>
            ) : (
              <div className="text_check">{i18n.t('no_data')}</div>
            )}
          </CardBody>
        </Card>
      </div>
      <div>
        <Card style={{ marginTop: '20px' }}>
          <CardBody>
            <CardTitle className="text-uppercase h6">
              {i18n.t(poiType === 'ooh' ? 'work_to_poi_title_ooh' : 'work_to_poi_title')}
              {work && work.length > 0 && (
                <PopoverHelp
                  title={i18n
                    .t(poiType === 'ooh' ? 'work_to_poi_title_ooh' : 'work_to_poi_title')
                    .toUpperCase()}
                  text={i18n.t('work_to_poi_text_how', {
                    example_range: work[0].name,
                    example_range_impressions: numeral(work[0].value).format('0,0'),
                    total_work_impressions: numeral(totalWork).format('0,0'),
                    result: numeral(work[0].value / totalWork).format('% 0,0.00')
                  })}
                  html
                />
              )}
            </CardTitle>
            <div className="small mb-4 card-subtitle">{i18n.t('work_text')}</div>
            {metricsWork ? (
              <div className="d-flex">
                <Card className="col-8 col-md-8 col-sm-12" style={{ height: '23rem' }}>
                  <ResponsiveContainer>
                    <BarChart data={work} margin={{ top: 0, right: 35, left: 0, bottom: -10 }}>
                      <YAxis
                        type="number"
                        tickFormatter={value => numeral(value).format('0,00[a]')}
                      />
                      <XAxis dataKey="name" tick={{ fontSize: 11 }} />
                      <Tooltip
                        formatter={value =>
                          `${numeral(value).format('0,0')} (${numeral(value / totalWork).format(
                            '% 0,0.00'
                          )})`
                        }
                      />
                      <Bar
                        dataKey="value"
                        name={i18n.t(poiType === 'ooh' ? 'visits_label_ooh' : 'visits_label')}
                        barSize={30}
                      >
                        {work.map((entry, index) => (
                          <Cell fill={colors[index]} key={index} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Card>
                <Card className="ml-2 col-4 col-md-4 col-sm-12 pb-3" style={{ height: '23rem' }}>
                  <MyLeafLetMapComponent
                    key="workMap"
                    pois={stores}
                    heatmapData={responseWorkHeatmap}
                  />
                </Card>
              </div>
            ) : (
              <div className="text_check">{i18n.t('no_data')}</div>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );

  function homeOOHCalculate(value) {
    const percentage = parseInt('' + (value / totalHome) * 10000) / 10000;
    const calculatedTotal = totalHits * percentage;
    return { calculatedTotal, percentage };
  }
}

function MyLeafLetMapComponent(props) {
  const defaultProps = {
    center: {
      lat: window._env_.REACT_APP_DEFAULT_LATITUDE,
      lng: window._env_.REACT_APP_DEFAULT_LONGITUDE
    },
    zoom: 12
  };

  const { heatmapData } = props;

  return (
    <MapContainer
      className="markercluster-map-full"
      center={defaultProps.center}
      zoom={defaultProps.zoom}
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      {/* pois.map(p => (
          <Marker position={[p.latitude, p.longitude]} key={p._id} />
        )) */}
      <HeatmapLayer
        fitBoundsOnLoad
        // fitBoundsOnUpdate
        points={heatmapData}
        longitudeExtractor={m => m[1]}
        latitudeExtractor={m => m[0]}
        intensityExtractor={m => parseFloat(m[2])}
        radius={20}
        minOpacity={0.5}
      />
    </MapContainer>
  );
}
