export default function StatTotal({ value, color, textColor }) {
  return (
    <div
      className={
        'relative flex items-center justify-center text-xs px-1.5 rounded-lg h-5.5 transition-color duration-300 cursor-default min-w-6 ' +
        (color ? color : 'bg-slate-600 ') +
        ' ' +
        (textColor ? textColor : 'text-white ')
      }
    >
      <p className="mb-0 font-poppins font-semibold tracking-wide text-center translate-y-px leading-none">
        {value}
      </p>
    </div>
  );
}
