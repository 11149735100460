import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody } from 'reactstrap';

// icons
import StickyHeader from '../header/StickyHeader';
import moment from 'moment';
import { ResponseLoading } from '../../components/utils/Loading';
import PopoverHelp from '../../components/utils/PopoverHelp';
import PopoverProjectionHelp, {
  formatBaseProjectionData
} from '../../components/utils/PopoverProjectionHelp';

export default function DashboardSummaryCard({
  filter,
  fullDemosObj,
  groupCount,
  poiType,
  scrollableRef,
  projectionData
}) {
  const { componentRef, sticky } = StickyHeader(scrollableRef);

  const totalVisitsTitle = poiType === 'ooh' ? 'total_visits_title_ooh' : 'total_visits_title';
  const avgUniqueVisitorsTitle =
    poiType === 'ooh' ? 'avg_unique_visitors_title_ooh' : 'avg_unique_visitors_title';
  const totalUniqueVisitorsTitle =
    poiType === 'ooh' ? 'total_unique_visitors_title_ooh' : 'total_unique_visitors_title';
  const avgVisitsTitle = poiType === 'ooh' ? 'avg_visits_title_ooh' : 'avg_visits_title';

  const segment = filter && filter.pois && filter.pois.length > 0 ? filter.pois[0].label : '--';

  const basePopulationData = formatBaseProjectionData(projectionData);

  return (
    <>
      <div className={`sticky ${sticky.isSticky ? '' : 'd-none'}`}>
        <div className="d-flex justify-content-around pt-2 small">
          <div className="p-3">
            {i18n.t('period_label')} <br />
            <b>
              {window._env_.REACT_APP_TYPE_PERIOD === 'fixed' && filter?.period?.label}
              {window._env_.REACT_APP_TYPE_PERIOD === 'month' &&
                `${moment(filter.startDate).format('MMMM YYYY')}`}
              {window._env_.REACT_APP_TYPE_PERIOD === 'week' &&
                `${moment(filter.startDate).format('MMMM DD')} - ${moment(filter.endDate).format(
                  'MMMM DD'
                )}`}
            </b>
          </div>
          <div className="p-3" style={{ maxWidth: '34vw' }}>
            {i18n.t(poiType === 'ooh' ? 'segment_label_ooh' : 'segment_label')} <br />
            <div className="cap-text">
              <b>{filter.pois && filter.pois.length > 0 ? filter.pois[0].label : '--'}</b>
            </div>
          </div>
          <div className="p-3">
            {i18n.t(filter.agg === 'g' ? totalUniqueVisitorsTitle : avgUniqueVisitorsTitle)} <br />
            <b>{numeral(fullDemosObj.totalUniques).format('0,0')}</b>
          </div>
          <div className="p-3">
            {i18n.t(filter.agg === 'g' ? totalVisitsTitle : avgVisitsTitle)}
            <br />
            <b>{numeral(fullDemosObj.totalVisits).format('0,0')}</b>
          </div>
          <div className="p-3">
            {i18n.t('avg_frequency_title')} <br />
            <b>{numeral(fullDemosObj.totalVisits / fullDemosObj.totalUniques).format('0,0.00')}</b>
          </div>
        </div>
      </div>

      <div ref={componentRef}>
        <CardGroup className="metric-card mb-4">
          <Card className="card-group">
            <ResponseLoading response={fullDemosObj} />

            <SummaryCard
              id={'pois_by_segment'}
              title={i18n.t(poiType === 'ooh' ? 'pois_by_segment_ooh' : 'pois_by_segment')}
              value={groupCount > 0 ? numeral(groupCount).format('0,0') : '--'}
              tooltip={
                groupCount > 0
                  ? i18n.t('pois_by_segment_tooltip', {
                      segment: segment,
                      poisCount: numeral(groupCount).format('0,0')
                    })
                  : null
              }
            />

            <SummaryCard
              id={totalUniqueVisitorsTitle}
              title={i18n.t(filter.agg === 'g' ? totalUniqueVisitorsTitle : avgUniqueVisitorsTitle)}
              value={numeral(fullDemosObj.totalUniques).format('0,0')}
              tooltip={i18n.t('unique_visitors_tooltip')}
              projected={{
                text: i18n.t('unique_visitors_projected_tooltip'),
                data: basePopulationData
                  ? [
                      {
                        label: i18n.t(totalUniqueVisitorsTitle),
                        value: numeral(fullDemosObj.totalRawUniques).format('0,0')
                      },
                      ...basePopulationData
                    ]
                  : []
              }}
            />

            <SummaryCard
              id={totalVisitsTitle}
              title={i18n.t(filter.agg === 'g' ? totalVisitsTitle : avgVisitsTitle)}
              value={numeral(fullDemosObj.totalVisits).format('0,0')}
              tooltip={i18n.t('visits_tooltip')}
              projected={{
                text: i18n.t('visits_projected_tooltip'),
                data: basePopulationData
                  ? [
                      {
                        label: i18n.t(totalVisitsTitle),
                        value: numeral(fullDemosObj.totalRawVisits).format('0,0')
                      },
                      ...basePopulationData
                    ]
                  : []
              }}
            />
            <SummaryCard
              id={'avg_frequency_title'}
              title={i18n.t('avg_frequency_title')}
              value={numeral(fullDemosObj.freq).format('0,0.00')}
              tooltip={i18n.t(poiType === 'ooh' ? 'frequency_text_ooh' : 'frequency_text')}
            />
          </Card>
        </CardGroup>
      </div>
    </>
  );

  function SummaryCard({ id, title, value, tooltip, projected = null }) {
    return (
      <CardBody>
        <h6 className="text-uppercase title font-weight-bold small text-center">
          {title}
          {tooltip ? <PopoverHelp title={title} id={id} text={tooltip} html /> : null}
        </h6>
        <h4 className="font-weight-normal mb-0 text-center">
          {value}
          {projected ? (
            <PopoverProjectionHelp
              title={title}
              text={projected.text}
              projectedData={projected.data}
            />
          ) : null}
        </h4>
      </CardBody>
    );
  }
}
