export default function ContentMain({ children, cols }) {
  return (
    <div
      className={
        'relative grid gap-5 grow ' +
        (cols
          ? {
              2: 'grid-cols-2',
              3: 'grid-cols-3',
              4: 'grid-cols-4',
              5: 'grid-cols-5'
            }[cols]
          : 'grid-cols-1')
      }
    >
      {children}
    </div>
  );
}
