import { IcoCheckCirc } from '../icons';

export default function Btn({ label, ico, addClass, inverted, disabled, onClick }) {
  const classIco =
    'w-5 h-5 fill-current mx-0.5 transition-color duration-300 ' +
    (inverted ? 'text-white' : 'text-blue-600');

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        'd-flex align-items-center font-poppins justify-center h-9 min-w-7 px-3 border-2 rounded-md border-blue-500 ' +
        (disabled
          ? 'opacity-50 cursor-default'
          : 'group cursor-pointer transition-all duration-300 hover:border-blue-600 ' +
            (inverted && ' hover:bg-blue-600')) +
        ' ' +
        (inverted ? 'bg-blue-500' : 'bg-white') +
        ' ' +
        (ico && 'pl-2') +
        ' ' +
        (addClass && addClass)
      }
    >
      {ico &&
        {
          ok: <IcoCheckCirc className={classIco} />
        }[ico]}
      {label && (
        <p
          className={
            'mb-0 tracking-wide text-sm font-medium pl-2 pr-1.5 translate-y-px ' +
            (inverted
              ? 'text-white'
              : 'text-blue-500 group-hover:text-blue-700 transition-color duration-300')
          }
        >
          {label}
        </p>
      )}
    </button>
  );
}
