import React, { useEffect, useState } from 'react';

import InputRadio from './inputRadio';
import StatTotal from './statTotal';
import BtnGroup from './btnGroup';

export default function CategoryItem({
  colors,
  colorName,
  label,
  value,
  selected,
  expanded,
  hasSelect,
  onSelect,
  onExpand
}) {
  const [isSelected, setIsSelected] = useState(selected);
  const toggleSelect = () => {
    setIsSelected(!isSelected);
    onSelect && onSelect(!isSelected);
  };

  const [isExpanded, setIsExpanded] = useState(expanded);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    onExpand && onExpand(!isExpanded);
  };

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <div
      className={
        'flex items-center w-fit text-sm tracking-minimal select-none overflow-hidden transition-color duration-300 shrink-0 ' +
        (isSelected
          ? ' '
          : isExpanded
          ? colors[colorName]['h-border-500']
          : 'border-slate-300 hover:border-slate-400')
      }
    >
      {/* RADIO Select */}
      {hasSelect && (
        <div
          className="p-0.5 cursor-pointer -translate-y-px -translate-x-px mr-0.5 ml-0.5"
          onClick={() => toggleSelect()}
        >
          <InputRadio isSelected={isSelected} />
        </div>
      )}

      <div className={'flex items-center group pr-1 mr-0.5'}>
        {/* Expand Groups / List */}
        <div
          onClick={() => toggleExpand()}
          className="flex items-center mr-0.5 cursor-pointer group"
        >
          <BtnGroup isSelected={isSelected} isExpanded={isExpanded} />
          {/*<BtnGroupArw isSelected={isSelected} isExpanded={isExpanded} />*/}
        </div>

        <div
          className="flex items-center p-1 pr-0.5 space-x-1 group cursor-pointer"
          onClick={() => toggleExpand()}
        >
          {/* LABEL category NAME */}
          <p
            className={
              'text-xs font-medium transition-color duration-300 max-w-52 truncate ' +
              (isSelected
                ? 'text-blue-600'
                : isExpanded
                ? colors[colorName]['text-800']
                : 'text-slate-500 group-hover:text-slate-900')
            }
          >
            {label}
          </p>

          {/* POIs number */}
          {value && (
            <StatTotal
              value={value}
              color={
                colors[colorName]['bg-500'] +
                ' border transition-all duration-300 ' +
                (isSelected ? 'border-blue-600' : 'border-white')
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
