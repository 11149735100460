import React, { useState, useEffect } from 'react';
import i18n from 'i18n-js';

import API2 from '../../api2/index';
import LoadingModal from '../../components/utils/LoadingModal';
import { Alert, Card, CardBody } from 'reactstrap';

export default function ExportAudienceManagerCheckoutSuccess(props) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { history } = props;
  const audienceId = props.match.params.id;

  useEffect(() => {
    const paymentIntentId = new URLSearchParams(props.location.search).get('payment_intent');
    console.log(`toFetch: ${audienceId}/purchase/paymentIntents/${paymentIntentId}`);
    API2.post(
      'audiences',
      `${audienceId}/purchase/paymentIntents/${paymentIntentId}`,
      {},
      (err, audience) => {
        if (err) {
          if (err.code === 403) {
            setError(i18n.t('audience_not_allowed'));
            return;
          }
          if (err.code === 404) {
            setError(i18n.t('audience_payment_not_valid'));
            return;
          }
          setError(
            i18n.t('unknown_error_please_contact_support', { msg: JSON.stringify(err.message) })
          );
        } else {
          setSuccess(i18n.t('audience_payment_ok'));
          history.push(`/export-audience`);
        }
      }
    );
  }, []);

  if (!error && !success) {
    return <LoadingModal isOpen={true} text={i18n.t('audience_loading')} />;
  } else {
    return (
      <div className="view_cont">
        <Card className="main_window">
          <CardBody className="main_data main_signin mt-3">
            {error && <Alert color="danger">{error}</Alert>}
            {success && <Alert color="success">{success}</Alert>}
          </CardBody>
        </Card>
      </div>
    );
  }
}
