import BtnClose from './btnClose';

export default function LayoutPop({ children, addClass, onClose }) {
  return (
    <div className="flex align-items-center justify-center w-full h-full anim-tree-pop">
      {/* Popup content */}
      <div
        className={
          'bg-white px-5.5 py-4 rounded-lg font-poppins text-slate-600 tracking-wide anim-tree-conte ' +
          addClass
        }
      >
        {/* Close  */}

        <div className="absolute top-4 right-4 z-10">
          <BtnClose onClick={onClose} />
        </div>

        {/* Content  */}
        <div className="d-flex flex-column h-full">{children}</div>
      </div>
    </div>
  );
}
