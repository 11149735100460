import React from 'react';
// import i18n from 'i18n-js';
import {
  GoogleMap,
  Marker,
  StreetViewPanorama,
  useJsApiLoader,
  MarkerClusterer
} from '@react-google-maps/api';

const icons = {
  redIcon: new window.google.maps.MarkerImage(
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(25, 41)
  ),
  blueIcon: new window.google.maps.MarkerImage(
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(25, 41)
  )
};

function GoogleMapComponent(props) {
  const defaultProps = {
    center: props.center || {
      lat: parseFloat(window._env_.REACT_APP_DEFAULT_LATITUDE),
      lng: parseFloat(window._env_.REACT_APP_DEFAULT_LONGITUDE)
    },
    zoom: props.zoom || 10,
    mapContainerStyle: props.mapContainerStyle || { height: `100%`, width: `auto` }
  };
  const { pois, onChange } = props;

  // const [isOpen, setOpen] = useState({});

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'YOUR_API_KEY'
  });

  // const [, setMap] = React.useState(null);

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds(defaultProps.center);
  //   map.fitBounds(bounds);
  //   setMap(map);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  // const handleOpen = id => {
  //   setOpen(value => ({ ...value, [id]: !value[id] }));
  // };

  // console.log(isOpen);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={defaultProps.mapContainerStyle}
      zoom={defaultProps.zoom}
      center={defaultProps.center}
      // onLoad={onLoad}
      // onUnmount={onUnmount}
    >
      <StreetViewPanorama position={defaultProps.center} visible={props.streetView} />
      <MarkerClusterer averageCenter enableRetinaIcons maxZoom={14}>
        {clusterer => (
          <>
            {pois.map(p => (
              <Marker
                title={p.name}
                icon={p.own ? icons.redIcon : icons.blueIcon}
                key={p._id}
                position={{ lat: p.latitude, lng: p.longitude }}
                onClick={() => {
                  // handleOpen(p._id);
                  onChange && onChange(p);
                }}
                // onClick={() => handleMarkerClick(p)}
                // onMouseOver={() => handleMarkerClick(p)}
                // onMouseOut={() => handleCloseInfoWindow()}
                onFocus={() => {}}
                onBlur={() => {}}
                optimized
                clusterer={clusterer}
              ></Marker>
            ))}
          </>
        )}
      </MarkerClusterer>
    </GoogleMap>
  ) : null;
}

export default React.memo(GoogleMapComponent);
