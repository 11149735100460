import React, { useEffect, useState } from 'react';

// import toastr from 'toastr';

// import { Link } from 'react-router-dom';

import { Alert, Card, CardBody, Row } from 'reactstrap';

import { useDispatch, useMappedState } from 'redux-react-hook';

import { authClearError, authClearSuccess, validate } from '../../redux/ducks/auth';

import i18n from 'i18n-js';
import { NavLink } from 'react-router-dom';
import { buildDefaultDashboardUrl, getQueryVariable } from '../../../routeUtils';
import Logo from './Logo';

const mapState = state => ({
  authError: state.auth.error,
  user: state.auth.user,
  token: state.auth.token,
  authSuccess: state.auth.success
});

export default function Validate(props) {
  const hash = getQueryVariable('hash');
  const autoLogin = window._env_.REACT_APP_AUTO_LOGIN_AFTER_VALIDATE;
  const { authError, user, token, authSuccess } = useMappedState(mapState);
  const [message, setMessage] = useState(i18n.t('register_activating'));

  const dispatch = useDispatch();

  useEffect(() => {
    if (authError) {
      setTimeout(() => dispatch(authClearError()), 5000);
    }
    if (authSuccess) {
      setMessage(i18n.t('register_now_active'));
      dispatch(authClearSuccess());
      setTimeout(() => props.history.push('/'), 1000);
    }
    if (user) {
      const url = buildDefaultDashboardUrl();
      if (token && autoLogin) {
        setTimeout(() => props.history.push(url), 100);
      }
    }
  }, [user, authError, token, dispatch, props.history, autoLogin, authSuccess]);

  useEffect(() => {
    dispatch(validate(hash, autoLogin));
  }, [autoLogin, hash, dispatch]);

  return (
    <div className="view_cont">
      <Card className="main_window">
        <CardBody className="main_data main_signin mt-3">
          <Row>
            <div className="col-md-12 registered_message">
              <Logo />
              {message}
              {authError && <Alert color="danger">{authError}</Alert>}
              {(user || authSuccess) && <Alert color="success">Alle is güt</Alert>}
            </div>
          </Row>
          <Row className="text_check text_small text_3_color">
            <NavLink to="/register" activeClassName="active">
              {i18n.t('or_click_to_register')}
            </NavLink>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
