import React, { useEffect } from 'react';

// import toastr from 'toastr';

import * as Yup from 'yup';

// import { Link } from 'react-router-dom';

import { Field, Form, Formik } from 'formik';

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from 'reactstrap';

import { useDispatch, useMappedState } from 'redux-react-hook';

import { authClearError, login } from '../../redux/ducks/auth';

import i18n from 'i18n-js';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';
import { buildDefaultDashboardUrl } from '../../../routeUtils';

const mapState = state => ({
  authError: state.auth.error,
  user: state.auth.user
});

export default function Login(props) {
  const { authError, user } = useMappedState(mapState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (authError) {
      setTimeout(() => dispatch(authClearError()), 5000);
    }

    if (user) {
      const url = buildDefaultDashboardUrl();
      setTimeout(() => props.history.push(url), 100);
    }
  }, [user, authError, dispatch, props.history]);

  const onSubmit = params => {
    dispatch(login(params.email, params.password));
  };

  return (
    <div className="view_cont">
      <Card className="main_window">
        <CardBody className="main_data main_signin mt-3">
          <Row>
            <div className="col-md-12">
              <Logo />
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('Is not a valid e-mail').required('Email is required'),
                  password: Yup.string().required('Password is required')
                })}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    {authError && <Alert color="danger">{authError}</Alert>}
                    <Col msm={9}>
                      <FormGroup>
                        <Input
                          placeholder="Email"
                          className="form_item text_form"
                          type="email"
                          name="email"
                          tag={Field}
                          invalid={errors.email && touched.email}
                        />
                        {errors.email && touched.email && (
                          <FormFeedback>{errors.email}</FormFeedback>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          placeholder="Password"
                          className="form_item text_form"
                          type="password"
                          name="password"
                          tag={Field}
                          invalid={errors.password && touched.password}
                        />
                        {errors.password && touched.password && (
                          <FormFeedback>{errors.password}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Button color="primary" type="submit" className="form_bt bt_common mt-3">
                      LOGIN
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Row>
          {window._env_.REACT_APP_SELF_REGISTER === 'true' ? (
            <Row className="text_check text_small text_3_color">
              <NavLink to="/register" activeClassName="active">
                {i18n.t('or_click_to_register')}
              </NavLink>
            </Row>
          ) : (
            <Row className="text_check text_small text_3_color">&nbsp;</Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
}
