import React, { useEffect, useState } from 'react';

// import toastr from 'toastr';

import * as Yup from 'yup';

// import { Link } from 'react-router-dom';

import { Field, Form, Formik } from 'formik';

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from 'reactstrap';

import { register } from '../../redux/ducks/auth';

import i18n from 'i18n-js';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';

export default function Register(props) {
  const [authError, setAuthError] = useState(null);
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    if (authError) {
      setTimeout(() => setAuthError(false), 5000);
    }

    if (registered) {
      let url = '/registered';
      setTimeout(() => props.history.push(url), 100);
    }
  }, [registered, authError, props.history]);

  const onSubmit = async params => {
    const { created, err } = await register(
      params.firstName,
      params.lastName,
      params.email,
      params.password
    );
    if (created) {
      setRegistered(true);
      setAuthError(null);
    } else {
      setRegistered(false);
      setAuthError(err.message);
    }
  };

  return (
    <div className="view_cont">
      <Card className="main_window">
        <CardBody className="main_data main_signin mt-3">
          <Row>
            <div className="col-md-12">
              <Logo />
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  password: '',
                  repeatPassword: ''
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string().required(i18n.t('profile_first_name_required')),
                  lastName: Yup.string().required(i18n.t('profile_last_name_required')),
                  email: Yup.string().email('Is not a valid e-mail').required('Email is required'),
                  password: Yup.string().required('Password is required'),
                  repeatPassword: Yup.string()
                    .required('Repeat Passwrd')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                })}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    {authError && <Alert color="danger">{authError}</Alert>}
                    <Col msm={9}>
                      <FormGroup>
                        <Input
                          placeholder={i18n.t('profile_first_name')}
                          className="form_item text_form"
                          type="text"
                          name="firstName"
                          tag={Field}
                          invalid={errors.firstName && touched.firstName}
                        />
                        {errors.firstName && touched.firstName && (
                          <FormFeedback>{errors.firstName}</FormFeedback>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          placeholder={i18n.t('profile_last_name')}
                          className="form_item text_form"
                          type="text"
                          name="lastName"
                          tag={Field}
                          invalid={errors.lastName && touched.lastName}
                        />
                        {errors.firstName && touched.firstName && (
                          <FormFeedback>{errors.lastName}</FormFeedback>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          placeholder="Email"
                          className="form_item text_form"
                          type="email"
                          name="email"
                          tag={Field}
                          invalid={errors.email && touched.email}
                        />
                        {errors.email && touched.email && (
                          <FormFeedback>{errors.email}</FormFeedback>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          placeholder="Password"
                          className="form_item text_form"
                          type="password"
                          name="password"
                          tag={Field}
                          invalid={errors.password && touched.password}
                        />
                        {errors.password && touched.password && (
                          <FormFeedback>{errors.password}</FormFeedback>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          placeholder={i18n.t('password_confirm_field')}
                          className="form_item text_form"
                          type="password"
                          name="repeatPassword"
                          tag={Field}
                          invalid={errors.repeatPassword && touched.repeatPassword}
                        />
                        {errors.repeatPassword && touched.repeatPassword && (
                          <FormFeedback>{errors.repeatPassword}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Button color="primary" type="submit" className="form_bt bt_common mt-3">
                      {i18n.t('register')}
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Row>
          <Row className="text_check text_small text_3_color">
            <NavLink to="/login" activeClassName="active">
              {i18n.t('already_user_login')}
            </NavLink>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
