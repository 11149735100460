import { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Alert, Card, CardBody } from 'reactstrap';
import { useDispatch } from 'redux-react-hook';
import API2 from '../../api2/index';
import { authSetOrganization } from '../../redux/ducks/auth';
import LoadingModal from '../../components/utils/LoadingModal';
import { Link } from 'react-router-dom';
import { buildDefaultDashboardUrl } from '../../../routeUtils';

export default function CheckoutSuccess(props) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { organization } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const setupIntentId = new URLSearchParams(props.location.search).get('setup_intent');

    API2.post(
      'organizations',

      `${organization.id}/subscription/setupIntents/${setupIntentId}`,
      {},
      (err, subscription) => {
        if (err) {
          console.log(err);
          if (err.code === 403) {
            setError(i18n.t('organization_not_allowed', { organization: organization.name }));
            return;
          }
          if (err.code === 404) {
            setError(i18n.t('organization_payment_not_valid', { organization: organization.name }));
            return;
          }
          setError(
            i18n.t('unknown_error_please_contact_support', { msg: JSON.stringify(err.message) })
          );
        } else {
          setSuccess(i18n.t('organization_payment_ok'));
          API2.get('organizations', `${organization.id}`, {}, (err, organization) => {
            if (err) {
              console.log(err);
              setError(i18n.t('organization_not_found', { organization: organization.name }));
              return;
            } else {
              dispatch(authSetOrganization(organization));
              return;
            }
          });
        }
      }
    );
  }, []);

  const url = buildDefaultDashboardUrl();

  return (
    <>
      {!error && !success && <LoadingModal isOpen={true} text={i18n.t('organization_loading')} />}
      <div className="view-content view-components">
        <div className="align-items-center justify-content-center d-flex mt-5">
          <Card className="main_window">
            <CardBody className="align-items-center card-body d-flex flex-column h-100 justify-content-center">
              {error && (
                <>
                  <Alert color="danger">{error}</Alert>
                  <Link to="/checkout" className="btn btn-secondary">
                    {i18n.t('try_again')}
                  </Link>
                </>
              )}
              {success && (
                <>
                  <Alert color="success">{success}</Alert>
                  <Link to={url} className="btn btn-primary">
                    {i18n.t('go_to_dashboard')}
                  </Link>
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
