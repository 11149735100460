import React, { useEffect, useRef, useState } from 'react';

import { IcoSearch, IcoArwGoCirc, IcoCloseCirc } from '../icons';

const minChars = 1;

export default function PoisSearch({
  type,
  searchFieldRef,
  placeholder,
  disabled,
  addClass,
  onSearch,
  defaultValue,
  helpText
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [searchingText, setSearchingText] = useState(defaultValue);
  const inputRef = useRef(null);
  const helpRef = useRef(null);

  useEffect(() => {
    setSearchingText(defaultValue);
  }, [defaultValue]);

  const handleChange = event => {
    const val = event.target.value;
    setSearchingText(val);
    checkFocus();
  };

  const handleKeys = event => {
    if (event.key === 'Escape') {
      clearInput();
      setIsSearching(false);
    } else if (event.key === 'Enter') {
      if (searchingText.length >= minChars) {
        onSearch(searchingText);
        inputRef.current.blur();
      }
    }
  };

  const checkInput = () => {
    onSearch(searchingText);
    setIsSearching(false);
  };

  const checkFocus = () => {
    setIsSearching(searchingText.length >= minChars);
    searchFieldRef.current.style.width = '66%';
    helpRef.current.style['transition-delay'] = '175ms';
    helpRef.current.style['transition-duration'] = '0.3s';
    helpRef.current.style.opacity = 1;
  };
  const checkBlur = () => {
    setTimeout(() => {
      setIsSearching(false);
    }, 100);
    searchFieldRef.current.style.width = 'auto';
    helpRef.current.style['transition-delay'] = '0ms';
    helpRef.current.style['transition-duration'] = '0s';
    helpRef.current.style.opacity = 0;
  };

  const clearInput = () => {
    inputRef.current.focus();
    setSearchingText('');
    onSearch('');
    checkFocus();
  };

  return (
    <div className={`relative bg-white ${disabled ? 'opacity-50' : ''}`}>
      <IcoSearch className="absolute left-1 translate-x-px top-1 w-5.5 h-5.5 text-blue-500 fill-current anim-item-tree" />

      <input
        // onInputCapture={() => checkInput()}
        onFocus={checkFocus}
        onBlur={checkBlur}
        onKeyUp={handleKeys}
        onChange={handleChange}
        ref={inputRef}
        className={`relative flex align-items-center border border-slate-400 outline-4 outline-white rounded h-7.5 pt-px text-slate-800 text-sm bg-transparent focus:bg-transparent pr-7.5 focus:pr-14  focus:border-blue-500  outline-none w-7.5 focus:w-112 transition-w duration-300 cursor-pointer focus:cursor-text ${
          addClass ? addClass : ''
        } search focus:pl-8 ${
          !disabled ? 'hover:border-blue-300 transition transition-color' : ''
        }`}
        type={type}
        value={searchingText}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete="off"
        //defaultValue={defaultValue}
      />

      {isSearching && (
        <div className="d-flex align-items-center absolute top-1.5 right-1.5 translate-x-px space-x-0.5 anim-item-tree">
          <IcoArwGoCirc
            className="w-5 h-5 text-blue-500 fill-current transition-transform hover:scale-110 duration-100 cursor-pointer"
            onClick={checkInput}
          />

          <IcoCloseCirc
            className="w-5 h-5 text-slate-400 fill-current transition-color duration-500 hover:scale-100 hover:text-slate-700 cursor-pointer"
            onClick={clearInput}
          />
        </div>
      )}
      {helpText && (
        <p
          id="op-help"
          ref={helpRef}
          className={`absolute text-xs tracking-minimal text-slate-500 bg-white opacity-0 transition-opacity duration-300 delay-175 px-2 translate-y-px pt-0.5 overflow-hidden w-112`}
        >
          {helpText}
        </p>
      )}
    </div>
  );
}
