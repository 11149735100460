export default function Loader({ addClass }) {
  return (
    <div
      className={
        'd-flex align-items-center justify-center w-8 h-8 shrink-0 ' + (addClass && addClass)
      }
    >
      <img src="images/loading-spinner.svg" className="w-7 h-7" />
    </div>
  );
}
