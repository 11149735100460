import React from 'react';

import { Button, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';

import { MdHelp } from 'react-icons/md';

const defaultStyle = { float: 'right', marginTop: '-3px', marginRight: '-3px' };

export default function PopoverHelp({
  title,
  text,
  style = defaultStyle,
  html = false,
  icon = MdHelp,
  id = null
}) {
  // const [popoverOpen, setPopoverOpen] = useState(false);

  const _id = id || title?.replace(/ /gi, '_');

  return (
    <span style={style}>
      {/* <Button className="btn-fab" size="xs" id={id} onClick={() => setPopoverOpen(true)}> */}
      <Button className="btn-fab" size="xs" id={_id}>
        {React.createElement(icon, { size: 16, color: '#FFF' })}
      </Button>
      <UncontrolledPopover placement="right" target={_id} trigger="legacy">
        {/* <Popover
        placement="right"
        isOpen={popoverOpen}
        target={id}
        toggle={() => setPopoverOpen(false)}
      > */}
        <PopoverHeader>{title}</PopoverHeader>
        {html ? (
          <PopoverBody dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <PopoverBody>{text}</PopoverBody>
        )}
        {/* </Popover> */}
      </UncontrolledPopover>
    </span>
  );
}
