import React, { useState } from 'react';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { logout } from '../../redux/ducks/auth';
import Nav from '../nav/Nav';
import Header from '../header/Header';
import { setFilter, filterSelector } from '../../redux/ducks/filters';

import './app_layout.scss';
import Footer from '../footer/Footer';

// export default function AppLayout(props) {

export const AppLayout = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const { poiType } = props;

  const { filter, defaultFilter } = useMappedState(filterSelector);

  const [navMini, setNavMini] = useState(props.navMini);

  const logoutDispath = () => dispatch(logout());

  const setFilterDispath = filterValues => {
    const f = {
      ...filter[poiType],
      ...filterValues
    };
    dispatch(setFilter(f));
  };

  const toggleNav = e => {
    e.preventDefault();
    setNavMini(!navMini);
  };

  const hideNav = e => {
    e.stopPropagation();
    e.preventDefault();
    setNavMini(false);
  };

  if (!filter) {
    return <h1>Loading</h1>;
  }

  return (
    <div className="app-wrapper" ref={ref}>
      <Nav mini={navMini} toggleNav={toggleNav} user={props.user} />
      <div className={`content-container ${navMini ? 'full' : ''}`}>
        {/* dropshadow for mobile nav triggering */}
        <div
          className="menu-dropshadow"
          style={{ display: navMini ? 'block' : 'none' }}
          onClick={hideNav}
        />
        <Header
          toggleNav={toggleNav}
          logout={logoutDispath}
          filter={filter[poiType]}
          defaultFilter={defaultFilter}
          setFilterValues={setFilterDispath}
          user={props.user}
          organization={props.organization}
          fullMonth={props.fullMonth}
          useAggregation={props.useAggregation}
          filterSinglePOI={props.filterSinglePOI}
          filterConditions={props.filterConditions}
        />
        {props.children}
        <Footer />
      </div>
    </div>
  );
});

export default AppLayout;
