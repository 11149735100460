import React from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle, Row } from 'reactstrap';
import DwellTimeQuartileCard from './DwellTimeQuartileCard';
import { ResponseLoading } from '../../components/utils/Loading';

export default function DwellTimeQuartilesCard({ filter, responseVisits }) {
  return (
    <Row>
      <div className="mb-4 col-sm-12 col-md-12">
        <CardGroup className="metric-card ">
          <Card style={{ flex: '3 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('dwell_time_title')}
                <ResponseLoading response={responseVisits} />
              </CardTitle>
              <div className="small mb-4 card-subtitle">{i18n.t('dwell_time_text')}</div>
              <Row>
                <div className="col-sm-6 col-md-3">
                  <DwellTimeQuartileCard quartile={1} filter={filter} response={responseVisits} />
                </div>
                <div className="col-sm-6 col-md-3">
                  <DwellTimeQuartileCard quartile={2} filter={filter} response={responseVisits} />
                </div>
                <div className="col-sm-6 col-md-3">
                  <DwellTimeQuartileCard quartile={3} filter={filter} response={responseVisits} />
                </div>
                <div className="col-sm-6 col-md-3">
                  <DwellTimeQuartileCard quartile={4} filter={filter} response={responseVisits} />
                </div>
              </Row>{' '}
            </CardBody>
          </Card>
        </CardGroup>
      </div>
    </Row>
  );
}
