import i18n from 'i18n-js';
import numeral from 'numeral';
import React from 'react';

import { MdAutoGraph } from 'react-icons/md';
import PopoverHelp from './PopoverHelp';

const defaultStyle = { float: 'right', marginTop: '-3px', marginRight: '-3px' };

export default function PopoverProjectionHelp({
  title,
  text,
  projectedData = [],
  style = defaultStyle,
  id = null
}) {
  if (projectedData.length === 0) return null;

  const projectedDataText = projectedData
    .map(concept => `${concept.label}: <b>${concept.value}</b>`)
    .join('<br />');

  const projectionTooltip = `${i18n.t(
    'projected_value_tooltip'
  )}<br /><br />${text}<br /><br />${projectedDataText}`;
  return (
    <PopoverHelp
      title={title}
      text={projectionTooltip}
      style={style}
      html
      icon={MdAutoGraph}
      id={id}
    />
  );
}

export function formatBaseProjectionData(projectionData) {
  if (projectionData?.sampleSize > 0) {
    return [
      {
        label: i18n.t('sample_size'),
        value: projectionData?.sampleSize ? numeral(projectionData.sampleSize).format('0,0') : '--'
      },
      {
        label: i18n.t('reference_population'),
        value: projectionData?.referencePopulation
          ? numeral(projectionData.referencePopulation).format('0,0')
          : '--'
      },
      {
        label: i18n.t('observations_count'),
        value: projectionData?.referencePopulation
          ? numeral(projectionData.observationsCount).format('0,0')
          : '--'
      }
    ];
  } else {
    return null;
  }
}
