import React from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';
import * as reactIcons from 'react-icons/md/';
import { CardTitle } from 'reactstrap';
import { colors } from '../../components/charts/Styles';
import PopoverProjectionHelp from '../../components/utils/PopoverProjectionHelp';
function calculateFontSize(digits) {
  const maxDigits = 9; // Define the threshold for decreasing the font size
  const baseFontSize = 2.5; // The base font size for 10 digits

  if (digits <= maxDigits) {
    return `${baseFontSize}rem`; // Font size for up to 10 digits
  } else {
    // Calculate the scaled font size using a logarithmic function
    const scaleFactor = 0.1; // Adjust this value to control the rate of decrease
    const scaledFontSize = baseFontSize / Math.log(digits - maxDigits + 2 + scaleFactor);

    // Ensure a minimum font size to prevent it from becoming too small
    const minimumFontSize = 1.0; // Define your minimum font size
    return `${Math.max(scaledFontSize, minimumFontSize)}rem`;
  }
}
const fontStyle = digits => {
  const fontSize = calculateFontSize(digits);
  const styles = { fontSize: `${fontSize}` };

  return styles;
};

export default function ReachDetails(props) {
  const { reach, selectedPois } = props;
  const showTRPPopulation = reach.trpPopulation
    ? numeral(reach.trpPopulation).format('0.00')
    : '--';

  const showTRPTarget = reach.trpTarget ? numeral(reach.trpTarget).format('0.00') : '--';

  const totalDigits = showTRPPopulation.toString().length + showTRPTarget.toString().length;

  return (
    <>
      <div className="d-flex justify-content-around align-items-center mt-5 mb-5">
        <div className="text-center">
          <h6 className="font-weight-bold small" style={{ color: 'gray' }}>
            {i18n.t('planner_metrics_trp_population')}
          </h6>
          <h2 style={fontStyle(totalDigits)}>{showTRPPopulation}</h2>
        </div>
        <div className="text-center">
          <h6 className="font-weight-bold small" style={{ color: 'gray' }}>
            {i18n.t('planner_metrics_trp_target')}
          </h6>
          <h2 style={fontStyle(totalDigits)}>{showTRPTarget}</h2>
        </div>
      </div>
      {ReachField(reach, 'MdOutlinePeopleAlt', 'planner_metrics_universe', 'universe', '0,0')}
      {ReachField(
        reach,
        'MdCenterFocusStrong',
        'planner_metrics_target',
        'target',
        '0,0',
        'share',
        colors[0],
        true
      )}
      <CardTitle
        className={`text-uppercase small d-flex justify-content-between align-items-center`}
      >
        <span className="d-flex align-items-center w-100">
          {React.createElement(reactIcons['MdOutlineLocationOn'], { size: 23, className: 'mr-2' })}
          {i18n.t('planner_metrics_selected_pois')}:
        </span>
        <div className="d-flex flex-column text-nowrap">
          <span className="text-right">{selectedPois ? selectedPois.length : '--'}</span>
        </div>
      </CardTitle>

      {ReachField(
        reach,
        'MdMotionPhotosOn',
        'planner_metrics_reach',
        'uniqueReach',
        '0,0',
        '',
        colors[1],
        true,
        'rawUniqueReach'
      )}
      {ReachField(reach, 'MdMotionPhotosOn', 'planner_metrics_share', 'shareReach', '%0.00')}
      {ReachField(reach, 'MdRotateRight', 'planner_metrics_frequency', 'frequency', '0.00')}
      {ReachField(reach, 'MdStreetview', 'planner_metrics_hits', 'hitsReach', '0,0')}
    </>
  );
}

function ReachField(
  reach,
  icon,
  title,
  field,
  format,
  shareField,
  color = '#000',
  bold = false,
  rawField = null
) {
  return (
    <CardTitle
      className={`text-uppercase small ${
        bold ? 'font-weight-bold' : null
      } d-flex justify-content-between align-items-center`}
    >
      <span className="d-flex align-items-center w-100" style={{ color }}>
        {React.createElement(reactIcons[icon], { size: 23, className: 'mr-2' })}
        {i18n.t(title)}:
      </span>
      <div className="d-flex flex-column text-nowrap">
        <span style={{ color }} className="text-right">
          {reach[field] ? numeral(reach[field]).format(format) : '--'}
        </span>

        <span style={{ color }} className="text-right small">
          {reach[shareField] ? ` (${numeral(reach[shareField]).format('%0.00')})` : ''}
        </span>
      </div>
      {rawField && (
        <PopoverProjectionHelp
          title={i18n.t(title)}
          text={i18n.t('unique_visitors_projected_tooltip')}
          style={{ paddingLeft: '0.5rem' }}
          projectedData={
            reach[rawField]
              ? [
                  {
                    label: i18n.t(title),
                    value: reach[rawField] ? numeral(reach[rawField]).format(format) : '--'
                  }
                ]
              : []
          }
          id={`reachDetails_${rawField}`}
        />
      )}
    </CardTitle>
  );
}
