import React from 'react';
import i18n from 'i18n-js';
import { Link, NavLink } from 'react-router-dom';
// import { Button } from 'reactstrap';
// icons
import {
  MdDvr,
  MdTrackChanges,
  MdDashboard,
  MdMap,
  MdAvTimer,
  MdApps,
  MdBlurLinear,
  MdAssessment,
  MdLightbulbOutline,
  MdDataExploration,
  MdAddReaction,
  MdAttachMoney,
  MdFace,
  MdLayers,
  MdCardTravel,
  MdAddCircle
} from 'react-icons/md/';

import ScrollArea from '../../components/scrollbar';

import './style.scss';
import { buildDefaultDashboardUrl } from '../../../routeUtils';

function NavHead(props) {
  const url = buildDefaultDashboardUrl();

  return (
    <header className="nav-head m-0 p-3 w-100 align-items-center d-flex justify-content-center">
      <Link to={url}>
        <img
          src={`${window._env_.REACT_APP_BASE_BRAND_URL}/${
            window._env_.REACT_APP_BRAND
          }/logo.png?ts=${new Date().getTime()}`}
          alt={window._env_.REACT_APP_TITLE}
        />
      </Link>
    </header>
  );
}

function NavList(props) {
  return (
    <ScrollArea
      className="nav-list-container"
      horizontal={false}
      verticalScrollbarStyle={{ width: '4px', marginLeft: '10px' }}
    >
      <ul className="list-unstyled nav-list clearfix">
        <li>
          <div
            style={{
              position: 'relative'
            }}
          />
        </li>
        {window._env_.REACT_APP_MODULE_CATEGORY_PDV === 'true' ? (
          <>
            <li>
              <div className="nav-list-title">{i18n.t('dashboard_pdv')}</div>
            </li>
            {window._env_.REACT_APP_MODULE_PDV_DASHBOARD === 'true' ? (
              <li>
                <NavLink
                  to="/dashboard?type=pdv"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/dashboard?type=pdv') return true;
                    return false;
                  }}
                >
                  <MdDashboard size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('dashboard_title')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_PDV_COMPARE === 'true' ? (
              <li>
                <NavLink
                  to="/compare?type=pdv"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/compare?type=pdv') return true;
                    return false;
                  }}
                >
                  <MdBlurLinear size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('compare_menu')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_PDV_PERFORMANCE === 'true' ? (
              <li>
                <NavLink
                  to="/performance?type=pdv"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/performance?type=pdv')
                      return true;
                    return false;
                  }}
                >
                  <MdAvTimer size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('performance_title')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_PDV_JOURNEY === 'true' ? (
              <li>
                <NavLink
                  to="/journey?type=pdv"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/journey?type=pdv') return true;
                    return false;
                  }}
                >
                  <MdAvTimer size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('journey_title')}</span>
                </NavLink>
              </li>
            ) : null}

            {window._env_.REACT_APP_MODULE_PDV_HEATMAP === 'true' ? (
              <li>
                <NavLink
                  to="/heatmaps?type=pdv"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/heatmaps?type=pdv') return true;
                    return false;
                  }}
                >
                  <MdMap size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('flow_map')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_PDV_PLANNER === 'true' ? (
              <li>
                {/* TODO: Let's talk about styling stuff */}
                <div className="align-items-center d-flex justify-content-between">
                  <NavLink to="/planner?type=pdv" activeClassName="active">
                    <MdDvr size="18" className="icon-dashboard" />
                    <span className="name">{i18n.t('planner_menu')}</span>
                  </NavLink>
                  <NavLink to="/planner-new?type=pdv" activeClassName="active">
                    <MdAddCircle size="18" className="icon-dashboard" />
                  </NavLink>
                </div>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_PDV_RADAR === 'true' ? (
              <li>
                <NavLink
                  to="/radar?type=pdv"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/radar?type=pdv') return true;
                    return false;
                  }}
                >
                  <MdTrackChanges size="18" className="icon-dashboard" />
                  <span className="name">Radar</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_PDV_RANKING === 'true' ? (
              <li>
                <NavLink
                  to="/ranking?type=pdv"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/ranking?type=pdv') return true;
                    return false;
                  }}
                >
                  <MdAssessment size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('ranking_title')}</span>
                </NavLink>
              </li>
            ) : null}
          </>
        ) : null}
        {window._env_.REACT_APP_MODULE_CATEGORY_OOH === 'true' ? (
          <>
            <li>
              <div className="nav-list-title">{i18n.t('dashboard_ooh')}</div>
            </li>
            {window._env_.REACT_APP_MODULE_OOH_DASHBOARD === 'true' ? (
              <li>
                <NavLink
                  to="/dashboard?type=ooh"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/dashboard?type=ooh') return true;
                    return false;
                  }}
                >
                  <MdDashboard size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('dashboard_title')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_OOH_COMPARE === 'true' ? (
              <li>
                <NavLink
                  to="/compare?type=ooh"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/compare?type=ooh') return true;
                    return false;
                  }}
                >
                  <MdBlurLinear size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('compare_menu')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_OOH_PERFORMANCE === 'true' ? (
              <li>
                <NavLink
                  to="/performance?type=ooh"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/performance?type=ooh')
                      return true;
                    return false;
                  }}
                >
                  <MdAvTimer size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('performance_title')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_OOH_JOURNEY === 'true' ? (
              <li>
                <NavLink
                  to="/journey?type=ooh"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/journey?type=ooh') return true;
                    return false;
                  }}
                >
                  <MdAvTimer size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('journey_title')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_OOH_HEATMAP === 'true' ? (
              <li>
                <NavLink
                  to="/heatmaps?type=ooh"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/heatmaps?type=ooh') return true;
                    return false;
                  }}
                >
                  <MdMap size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('flow_map')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_OOH_PLANNER === 'true' ? (
              <li>
                <div className="align-items-center d-flex justify-content-between">
                  {/* TODO: Let's talk about styling stuff */}
                  <NavLink to="/planner?type=ooh" activeClassName="active">
                    <MdDvr size="18" className="icon-dashboard" />
                    <span className="name">{i18n.t('planner_menu')}</span>
                  </NavLink>
                  <NavLink to="/planner-new?type=ooh" activeClassName="active">
                    <MdAddCircle size="18" className="icon-dashboard" />
                  </NavLink>
                </div>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_OOH_RADAR === 'true' ? (
              <li>
                <NavLink
                  to="/radar?type=ooh"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/radar?type=ooh') return true;
                    return false;
                  }}
                >
                  <MdTrackChanges size="18" className="icon-dashboard" />
                  <span className="name">Radar</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_OOH_RANKING === 'true' ? (
              <li>
                <NavLink
                  to="/ranking?type=ooh"
                  activeClassName="active"
                  isActive={(match, location) => {
                    if (location.pathname + location.search === '/ranking?type=ooh') return true;
                    return false;
                  }}
                >
                  <MdAssessment size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('ranking_title')}</span>
                </NavLink>
              </li>
            ) : null}
          </>
        ) : null}
        {window._env_.REACT_APP_MODULE_CATEGORY_REPORTS === 'true' ? (
          <>
            <li>
              <div className="nav-list-title">{i18n.t('dashboard_reports')}</div>
            </li>
            {window._env_.REACT_APP_MODULE_REPORTS_DASHBOARD === 'true' ? (
              <li>
                <NavLink to="/dashboard" activeClassName="active">
                  <MdDashboard size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('dashboard_title')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_REPORTS_COMPARE === 'true' ? (
              <li>
                <NavLink to="/compare" activeClassName="active">
                  <MdBlurLinear size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('compare_menu')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_REPORTS_PERFORMANCE === 'true' ? (
              <li>
                <NavLink to="/performance" activeClassName="active">
                  <MdAvTimer size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('performance_title')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_REPORTS_JOURNEY === 'true' ? (
              <li>
                <NavLink to="/journey" activeClassName="active">
                  <MdAvTimer size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('journey_title')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_REPORTS_HEATMAP === 'true' ? (
              <li>
                <NavLink to="/heatmaps" activeClassName="active">
                  <MdMap size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('flow_map')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_REPORTS_RADAR === 'true' ? (
              <li>
                <NavLink to="/radar" activeClassName="active">
                  <MdTrackChanges size="18" className="icon-dashboard" />
                  <span className="name">Radar</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_REPORTS_RANKING === 'true' ? (
              <li>
                <NavLink to="/ranking" activeClassName="active">
                  <MdAssessment size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('ranking_title')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_REPORTS_AUDIT === 'true' ? (
              <li>
                <div className="align-items-center d-flex justify-content-between">
                  <NavLink to="/audit" activeClassName="active">
                    <MdCardTravel size="18" className="icon-dashboard" />
                    <span className="name">{i18n.t('audit_menu')}</span>
                  </NavLink>
                  <NavLink to="/audit-new" activeClassName="active">
                    <MdAddCircle size="18" className="icon-dashboard" />
                  </NavLink>
                </div>
              </li>
            ) : null}
          </>
        ) : null}
        {window._env_.REACT_APP_MODULE_CATEGORY_POI === 'true' ? (
          <>
            <li>
              <div className="nav-list-title">{i18n.t('admin_menu')}</div>
            </li>
            {window._env_.REACT_APP_MODULE_CATEGORY_POI_ADMIN === 'true' ? (
              <li>
                <NavLink to="/pois" activeClassName="active">
                  <MdApps size="18" className="icon-dashboard" />
                  <span className="name">
                    {i18n.t('admin_menu_poi', { type: props.user.type })}
                  </span>
                </NavLink>
              </li>
            ) : null}
          </>
        ) : null}
        {window._env_.REACT_APP_MODULE_AUDIENCE_EXPORT === 'true' ? (
          <>
            <li>
              <div className="nav-list-title">{i18n.t('audience_title')}</div>
            </li>
            <li>
              <NavLink to="/export-audience" activeClassName="active">
                <MdApps size="18" className="icon-dashboard" />
                <span className="name">{i18n.t('audiences_menu_manager')}</span>
              </NavLink>
            </li>
          </>
        ) : null}

        {window._env_.REACT_APP_MODULE_CATEGORY_AI === 'true' ? (
          <>
            <li>
              <div className="nav-list-title">{i18n.t('ai_menu')}</div>
            </li>
            {window._env_.REACT_APP_MODULE_CATEGORY_AI_ADVISOR === 'true' ? (
              <li>
                <NavLink to="/ai/advisor" activeClassName="active">
                  <MdLightbulbOutline size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('ai_menu_advisor')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_AI_FORECAST === 'true' ? (
              <li>
                <NavLink to="/ai/forecast" activeClassName="active">
                  <MdDataExploration size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('ai_menu_forecast')}</span>
                </NavLink>
              </li>
            ) : null}
          </>
        ) : null}
        {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM === 'true' ? (
          <>
            <li>
              <div className="nav-list-title">{i18n.t('premium_menu')}</div>
            </li>
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_SENTIMENT === 'true' ? (
              <li>
                <NavLink to="/premium/sentiment" activeClassName="active">
                  <MdAddReaction size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('premium_menu_sentiment')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_FINANCIAL === 'true' ? (
              <li>
                <NavLink to="/premium/financial" activeClassName="active">
                  <MdAttachMoney size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('premium_menu_financial')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_LOYALTY === 'true' ? (
              <li>
                <NavLink to="/premium/loyalty" activeClassName="active">
                  <MdFace size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('premium_menu_loyalty')}</span>
                </NavLink>
              </li>
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_NEIGHBORHOOD_PATTERNS === 'true' ? (
              <li>
                <NavLink to="/premium/neighborhood" activeClassName="active">
                  <MdLayers size="18" className="icon-dashboard" />
                  <span className="name">{i18n.t('premium_menu_neighborhood_patterns')}</span>
                </NavLink>
              </li>
            ) : null}
          </>
        ) : null}
      </ul>
      {window._env_.REACT_APP_SUB_BRAND === 'true' ? (
        <div
          className="d-flex justify-content-center"
          style={{ position: 'fixed', bottom: 10, width: '100%' }}
        >
          <img
            src={`${window._env_.REACT_APP_BASE_BRAND_URL}/${window._env_.REACT_APP_BRAND}/sub-logo.png`}
            alt={window._env_.REACT_APP_SUB_BRAND_TITLE}
          />
        </div>
      ) : null}
    </ScrollArea>
  );
}

export default function Nav(props) {
  return (
    <nav className={`site-nav ${props.mini ? 'mini' : ''}`}>
      <NavHead {...props} />
      <NavList {...props} />
    </nav>
  );
}
