import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { MdAutorenew, MdPeople } from 'react-icons/md/';
import * as reactIcons from 'react-icons/md/';
import numeral from 'numeral';
import './wizard.css';
import StepWizard from 'react-step-wizard';
// import CheckoutAudience from '../../containers/audience/CheckoutAudience';
import API2 from '../../api2/index';
import { buildBaseParams, formattedPeriod } from '../../hooks/metrics/esdata';
import PaymentMethods from './PaymentMethods';
import history from '../../../history';
import PopupConfirm from './PopupConfirm';

export function ExportAudiencePanel({ fullDemosObj, filter, organization }) {
  const [isOpen, setOpen] = useState(false);
  const [audienceId, setAudienceId] = useState(null);
  const [quote, setQuote] = useState({});

  if (window._env_.REACT_APP_MODULE_AUDIENCE_EXPORT !== 'true') return null;

  if (!fullDemosObj || fullDemosObj.uniques === 0) return null;

  return (
    <>
      <RequestModal
        isOpen={isOpen}
        setOpen={setOpen}
        filter={filter}
        fullDemosObj={fullDemosObj}
        organization={organization}
        audienceId={audienceId}
        setAudienceId={setAudienceId}
        quote={quote}
        setQuote={setQuote}
      />
      <Button
        color="info"
        size="sm"
        onClick={() => {
          setQuote({});
          setAudienceId(null);
          setOpen(true);
        }}
      >
        <MdPeople
          size="16"
          className="icon-dashboard m-1"
          style={{ color: '#fff', cursor: 'pointer' }}
        />
        {i18n.t('download_audience')}
      </Button>
    </>
  );
}

function RequestModal({
  isOpen,
  setOpen,
  filter,
  fullDemosObj,
  organization,
  audienceId,
  setAudienceId,
  quote,
  setQuote
}) {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // const quantity = fullDemosObj.totalRawUniques;
  const quantity = parseInt(fullDemosObj.totalUniques);
  const segmentLabel = filter && filter.pois.length > 0 ? filter.pois[0].label : null;
  const formattedSegmentCost = numeral(quote.totalCost).format('$ 0,0.00');
  const formattedSegmentPeriod = formattedPeriod(filter);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      API2.post('quotes', '', { quantity, organization, filter }, (err, quote) => {
        if (err) {
          if (err.code === 403) {
            setError(i18n.t('organization_not_allowed', { organization: organization.name }));
          }
          setError(
            i18n.t('unknown_error_please_contact_support', { msg: JSON.stringify(err.message) })
          );
        } else {
          // console.log(quote);
          if (!quote) {
            setError(i18n.t('unknown_error_please_contact_support', { msg: 'NO-QUOTE' }));
          }

          // setCPM(cpm);
          setQuote(quote);
          setLoading(false);
        }
      });
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setOpen(false)}
      className="modalFadeInScale"
      backdrop={'static'}
      centered={true}
    >
      <ModalHeader toggle={() => setOpen(false)} style={{ zIndex: 11 }}>
        <div className="d-flex align-items-center">
          <MdPeople size="24" className="icon-dashboard m-1" />
          {i18n.t('download_audience')}
        </div>
      </ModalHeader>
      {error && <Alert color="danger">{error}</Alert>}
      {isLoading ? (
        <div className="align-items-center bg-white d-flex h-100 justify-content-center m-0 position-absolute text-center w-100 wizard-loading">
          <MdAutorenew size={35} className="rotate" />
        </div>
      ) : null}
      <StepWizard className="modal-wizard">
        <StepDetails
          step={1}
          filter={filter}
          quote={quote}
          organization={organization}
          audienceId={audienceId}
          setAudienceId={setAudienceId}
          segmentLabel={segmentLabel}
          formattedSegmentCost={formattedSegmentCost}
          formattedSegmentPeriod={formattedSegmentPeriod}
          setError={setError}
        />
        {/* <StepPayment
          step={2}
          filter={filter}
          quote={quote}
          organization={organization}
          audienceId={audienceId}
          setLoading={setLoading}
        /> */}

        <StepPaymentMethods
          step={2}
          filter={filter}
          quote={quote}
          organization={organization}
          audienceId={audienceId}
          setLoading={setLoading}
          segmentLabel={segmentLabel}
          formattedSegmentCost={formattedSegmentCost}
          formattedSegmentPeriod={formattedSegmentPeriod}
          setError={setError}
        />
        <StepConfirm step={3} setOpen={setOpen} />
      </StepWizard>
    </Modal>
  );
}

function StepDetails(props) {
  const {
    filter,
    quote,
    nextStep,
    organization,
    audienceId,
    setAudienceId,
    segmentLabel,
    formattedSegmentCost,
    formattedSegmentPeriod,
    setError
  } = props;

  const baseParams = buildBaseParams(filter);

  const initPurchase = () => {
    if (!audienceId) {
      // Create audience, then move to checkout
      API2.post(
        'audiences',
        '',
        {
          audience: {
            ...baseParams,
            segmentLabel: segmentLabel
          },
          quoteId: quote.quoteId,
          organization
        },
        (err, audience) => {
          if (err) {
            if (err.code === 403) {
              setError(i18n.t('organization_not_allowed', { organization: organization.name }));
            }
            setError(
              i18n.t('unknown_error_please_contact_support', { msg: JSON.stringify(err.message) })
            );
          } else {
            if (!audience) {
              setError(i18n.t('unknown_error_please_contact_support', { msg: 'NO-AUDIENCE' }));
            }

            setAudienceId(audience.id);
            nextStep();
          }
        }
      );
    } else {
      nextStep();
    }
  };

  return (
    <>
      <div>
        <ModalBody>
          {i18n.t('download_audience_details')}
          <Row className="mt-5">
            <DetailsField
              icon="MdPlace"
              title={i18n.t('download_audience_details_segment')}
              text={segmentLabel}
              color="primary"
            />
            <DetailsField
              icon="MdDateRange"
              title={i18n.t('download_audience_details_period')}
              text={formattedSegmentPeriod}
              color="secondary"
            />
            <DetailsField
              icon="MdPeople"
              title={i18n.t('download_audience_details_raw')}
              text={`${numeral(quote.quantity).format('0,0')} ${i18n.t(
                'download_audience_details_raw_ids'
              )}`}
              color="warning"
            />
            <DetailsField
              icon="MdAttachMoney"
              title={i18n.t('download_audience_details_cost')}
              text={`${formattedSegmentCost} (
                  ${numeral(quote.cpm).format('$ 0,0.00')} CPM)`}
              color="success"
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary ml-2" onClick={initPurchase}>
            {i18n.t('continue')}
          </button>
        </ModalFooter>
      </div>
    </>
  );
}

function DetailsField({ icon, title, text, color }) {
  return (
    <div className="col-6 d-flex mb-4 align-items-center">
      {React.createElement(reactIcons[icon], {
        size: 60,
        style: { border: '1px solid #eee', minWidth: '60' },
        className: `mr-3 text-${color} rounded-circle p-3`
      })}
      <div>
        <div className="small mb-1">{title}</div>
        <h6 className="font-weight-semi-bold">{text}</h6>
      </div>
    </div>
  );
}

/*
function StepPayment(props) {
  const { quote, isActive, previousStep, organization, audienceId, setLoading } = props;

  return (
    <div>
      <ModalBody>
        <CheckoutAudience
          organization={organization}
          quantity={quote.quantity}
          active={isActive}
          totalCost={quote.totalCost}
          previousStep={previousStep}
          audienceId={audienceId}
          quoteId={quote.quoteId}
          setLoading={setLoading}
        />
      </ModalBody>
    </div>
  );
}
*/

function StepPaymentMethods(props) {
  const {
    quote,
    isActive,
    previousStep,
    nextStep,
    organization,
    audienceId,
    setLoading,
    segmentLabel,
    formattedSegmentCost,
    formattedSegmentPeriod,
    setError
  } = props;

  const confirmPurchase = paymentId => {
    PopupConfirm({
      title: i18n.t('audiencie_purchase_confirm_title'),
      text: i18n.t('audiencie_purchase_confirm_text', {
        segmentLabel,
        formattedSegmentCost,
        formattedSegmentPeriod
      }),
      onConfirm: () => {
        setLoading(true);
        API2.post(
          'audiences',
          `${audienceId}/purchase`,
          { quoteId: quote.quoteId, paymentId },
          (err, confirmation) => {
            if (err) {
              if (err.code === 403) {
                setError(i18n.t('organization_not_allowed', { organization: organization.name }));
              }
              if (err.code === 500) {
                setError('Ooops: ' + JSON.stringify(err.message));
              }
            } else {
              nextStep();
            }
            setLoading(false);
          }
        );
      }
    });
  };

  return (
    <div>
      <ModalBody>
        {/* {error && <Alert color="danger">{error}</Alert>} */}
        <PaymentMethods
          organization={organization}
          active={isActive}
          setLoading={setLoading}
          previousStep={previousStep}
          onSelect={confirmPurchase}
          setError={setError}
        />
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default " onClick={previousStep}>
          {i18n.t('back')}
        </button>
      </ModalFooter>
    </div>
  );
}

function StepConfirm(props) {
  return (
    <div>
      <ModalBody>
        <Alert color="success">{i18n.t('audience_payment_ok')}</Alert>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-info"
          onClick={() => {
            history.push(`/export-audience`);
          }}
        >
          {i18n.t('audience_manager')}
        </button>
      </ModalFooter>
    </div>
  );
}

module.export = {
  ExportAudiencePanel
};
