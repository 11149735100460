import axios from 'axios';
import LRU from 'lru-cache';
import API2 from '../../api2';

import { clearFilter } from './filters';

// Actions

const AUTH_SET_LOGIN = 'auth/SET_LOGIN';
const AUTH_SET_LOGOUT = 'auth/SET_LOGOUT';
const AUTH_SET_USER = 'auth/SET_USER';
const AUTH_SET_ERROR = 'auth/AUTH_SET_ERROR';
const AUTH_SET_SUCCESS = 'auth/AUTH_SET_SUCCESS';
const AUTH_SET_REGISTERED = 'auth/AUTH_SET_REGISTERED';
const AUTH_SET_VALIDATED = 'auth/AUTH_SET_VALIDATED';
const AUTH_SET_ORGANIZATION = 'auth/AUTH_SET_ORGANIZATION';

export const metricsCache = new LRU({ max: 1000 });
// Reducer

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case AUTH_SET_LOGIN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user
      };
    case AUTH_SET_LOGOUT:
      return {};
    case AUTH_SET_USER:
      return {
        ...state,
        user: action.payload.user
      };
    case AUTH_SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload.organization
      };
    case AUTH_SET_ERROR:
      return {
        ...state,
        error: action.payload.error
      };
    case AUTH_SET_SUCCESS:
      return {
        ...state,
        success: action.payload.success
      };
    default:
      return state;
  }
}

// Action Creators

export function authSetLogin(user, token) {
  return {
    type: AUTH_SET_LOGIN,
    payload: {
      user,
      token
    }
  };
}

export function authSetOrganization(organization) {
  return {
    type: AUTH_SET_ORGANIZATION,
    payload: {
      organization
    }
  };
}

export function authSetLogout() {
  return {
    type: AUTH_SET_LOGOUT
  };
}

export function authSetRegistered() {
  return {
    type: AUTH_SET_REGISTERED
  };
}

export function authSetValidated() {
  return {
    type: AUTH_SET_VALIDATED,
    payload: {
      validated: true
    }
  };
}

export function authSetUser(user) {
  return {
    type: AUTH_SET_USER,
    payload: {
      user
    }
  };
}

export function authSetError(error) {
  return {
    type: AUTH_SET_ERROR,
    payload: {
      error
    }
  };
}

export function authSetSuccess() {
  return {
    type: AUTH_SET_SUCCESS,
    payload: {
      success: true
    }
  };
}

export function authClearSuccess() {
  return {
    type: AUTH_SET_SUCCESS,
    payload: {
      error: null
    }
  };
}

export function authClearError() {
  return {
    type: AUTH_SET_ERROR,
    payload: {
      error: null
    }
  };
}

export function logout() {
  return dispatch => {
    metricsCache.clear();
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common.organization;
    dispatch(authSetLogout());
    dispatch(clearFilter());
  };
}

export function validate(hash, autoLogin = false) {
  return dispatch => {
    dispatch(authSetError(null));

    axios
      .post(`/v2/auth/validate/${hash}`, { autoLogin })
      .then(({ data }) => {
        const accessToken = data.token || null;

        const user = {
          id: data.user.id,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          email: data.user.email,
          role: data.user.role,
          roles: [data.user.role],
          type: data.user.type
        };

        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        dispatch(clearFilter());
        dispatch(authSetSuccess());
        if (autoLogin) {
          dispatch(authSetLogin(user, accessToken));
        }
      })
      .catch(err => {
        dispatch(
          authSetError((err.response && err.response.data.message) || 'Something went wrong.')
        );
      });
  };
}

export function login(email, password) {
  return dispatch => {
    dispatch(authSetError(null));

    axios
      .post('/v2/auth/login', {
        email: email.toLowerCase().trim(),
        password
      })
      .then(({ data }) => {
        const { accessToken } = data.token;
        const user = {
          id: data.user.id,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          email: data.user.email,
          role: data.user.role,
          roles: [data.user.role],
          type: data.user.type,
          organizations: data.user.organizations,
          lastSelectedOrganization: data.user.lastSelectedOrganization
        };
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        dispatch(clearFilter());
        if (window._env_.REACT_APP_ORGANIZATIONS_AWARE === 'true') {
          let selectedOrganization = null;
          if (
            user.lastSelectedOrganization &&
            user.organizations.find(organization => {
              return organization.id === user.lastSelectedOrganization.id;
            })
          ) {
            selectedOrganization = user.lastSelectedOrganization;
          } else {
            if (user.organizations?.length) {
              selectedOrganization = user.organizations[0];
            }
          }
          API2.get('users', `${user.id}/organizations`, {}, (err, orgs) => {
            if (err) {
              // setOrgError(`${i18n.t('error_loading_organizations')}: ${err}`);
            } else {
              user.organizations = orgs;
              const completeSelectedOrg = orgs.find(organization => {
                return organization.id === selectedOrganization?.id;
              });
              dispatch(authSetOrganization(completeSelectedOrg));
              dispatch(authSetLogin(user, data.token));
            }
          });
        } else {
          dispatch(authSetLogin(user, data.token));
        }
      })
      .catch(err => {
        dispatch(
          authSetError((err.response && err.response.data.message) || 'Something went wrong.')
        );
      });
  };
}

export async function register(firstName, lastName, email, password) {
  try {
    await axios.post('/v2/auth/register', {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password
    });
    return { created: true, err: null };
  } catch (err) {
    if (err.response && err.response.status === 409) {
      return { created: false, err: new Error('Email already exists') };
    } else {
      return { created: false, err: new Error('Something went wrong.') };
    }
  }
}

export function refreshToken() {
  return (dispatch, getState) => {
    const state = getState();
    if (state.auth.user) {
      const { refreshToken } = state.auth.token;
      const { email } = state.auth.user;

      axios
        .post('/v2/auth/refresh-token', { refreshToken, email })
        .then(({ data }) => {
          axios.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;

          dispatch(authSetLogin(state.auth.user, data));

          // eslint-disable-next-line
          location.reload();
        })
        .catch(err => {
          dispatch(logout());
        });
    }
  };
}
