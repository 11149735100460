import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Modal, ModalBody } from 'reactstrap';

import { callSyncAPIv2DataLoader } from '../../hooks/api';
import moment from 'moment';
import POISelectorContent from './POISelectorContent';
import { formattedPeriod } from '../../hooks/metrics/esdata';

let searchTask;
const perPage = 100; // TODO: AppConfig?

export default function POISelector({
  modal,
  setModal,
  onSelect,
  filterSinglePOI = false,
  filterConditions,
  filter,
  preventDefault = false
}) {
  const _data = useRef(null); // TODO: This name requires improvement and drag other pieces of code too https://www.notion.so/mofiler/Refactor-Variables-for-improving-readibility-88b9ff8e2dc94e5d9ac143d5f30274c7
  const poiType = useRef(filter.type);
  const [data, setData] = useState({});
  const [cursor, setCursor] = useState(false);
  const [cursorSelected, setCursorSelected] = useState(false);
  const [nodeSelected, setNodeSelected] = useState(null);
  const [categoryExpanded, setCategoryExpanded] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [textFilter, setTextFilter] = useState('');

  // console.log('data', data);
  let loadingNode = null;

  const hasFilterConditions = filterConditions && filterConditions.length > 0;

  // clone JSON to trigger repaint
  const resetData = d => {
    setData({ ...d });
  };

  const prepareNode = node => {
    // keep backwards compatibility
    return {
      name: node.displayName,
      group_hash: node.groupHash,
      groupCount: node.groupSize,
      validForPeriods: data.validForPeriods
      // month_range_start: node.month_range_start,
      // month_range_end: node.month_range_end,
      // periods: node.periods
    };
  };

  const loadNode = (nodeId, cb) => {
    // console.log('loading node', nodeId, data);
    // setLoadingNode(nodeId);
    loadingNode = nodeId;
    setIsSearching(true);
    callSyncAPIv2DataLoader(
      'poisTree',
      `${nodeId}/children`,
      {
        period: filter.period,
        startDate: filter.startDate.valueOf(),
        endDate: filter.endDate.valueOf(),
        offset: filter.offset,
        type: filter.type,
        perPage,
        filterConditions
      },
      { responseHolder: `nodes${nodeId}` },
      res => {
        // console.log('finish loading', nodeId, res);
        const newNode = res.data.value[`nodes${nodeId}`].tree.children;
        addChildren(nodeId, newNode, data);
        cb && cb(newNode);

        // setLoadingNode(null);
        loadingNode = null;

        setIsSearching(false);
      }
    );
  };

  const loadNodeProxyGroups = nodeId => {
    // console.log('loading node', nodeId, data);
    // setLoadingNode(nodeId);
    loadingNode = nodeId;
    setIsSearching(true);
    callSyncAPIv2DataLoader(
      'poisTree',
      `${nodeId}/proxyGroups`,
      {
        period: filter.period,
        startDate: filter.startDate.valueOf(),
        endDate: filter.endDate.valueOf(),
        offset: filter.offset,
        type: filter.type,
        perPage,
        filterConditions
      },
      { responseHolder: `pois${nodeId}` },
      res => {
        // console.log('finish loading', nodeId, res);

        setCursorSelected(res.data.value[`pois${nodeId}`].tree);
        loadingNode = null;

        setIsSearching(false);
      }
    );
  };

  const onSelectNode = (node, selected, fromExpand = false) => {
    // console.log('node', node.active, node.childrenLoaded, loadingNode);
    // console.log('onSelect', data);
    // const a = node.active;
    const isProxyGroup = node.nodeType === 'ProxyGroup';

    if (cursor && !isProxyGroup) {
      cursor.active = false;
      setCursor(cursor);
      resetData(data);
    }

    // if (node.children && !node.toggled) {
    //   node.toggled = !node.toggled;
    // }

    if (!isProxyGroup && node.children.length === 0 && !node.childrenLoaded) {
      if (!loadingNode) {
        // is nothing is currently loading
        node.loading = true;
        // node.toggled = true;
        loadNode(node.nodeId); // load data even tho we are not showing children when selecting node (not changing toggle state)
      }
    }

    if (!isProxyGroup) {
      node.active = true;
      setCursor(node);
      fromExpand ? setCursorSelected(node) : loadNodeProxyGroups(node.nodeId);
      resetData(data);
    }

    setNodeSelected(selected ? node : null);
  };

  const onMore = node => {
    if (!loadingNode) {
      // is nothing is currently loading
      node.loading = true;
      node.toggled = true;
      loadNode(node.nodeId);
    }
  };

  const onToggle = (node, toggled, cb) => {
    // console.log('toggle', node, loadingNode);
    if (cursor) {
      cursor.active = false;
      setCursor(cursor);
    }
    node.active = true;

    if (node.children && node.children.length > 0) {
      node.toggled = toggled;
      setCursor(node.children);
      cb && cb();
      // cursor.active = false;
    } else if (
      node.nodeType !== 'ProxyGroup' &&
      node.children.length === 0 &&
      !node.childrenLoaded
    ) {
      if (!loadingNode) {
        // is nothing is currently loading
        node.loading = true;
        node.toggled = true;
        loadNode(node.nodeId, cb);
      }
    }
    setCursor(node);
    resetData(data);
  };

  const onFilterSearh = value => {
    const tf = value.trim();
    // textFilter = value.trim();
    // setTextFilter(value.trim());
    if (searchTask) clearTimeout(searchTask);

    return new Promise(resolve => {
      searchTask = setTimeout(() => {
        setTextFilter(tf);
        if (!tf || tf === '') {
          return resetData(_data.current);
        }

        // return resetData(data);
        if (!isSearching) {
          setIsSearching(true);
          callSyncAPIv2DataLoader(
            'poisTree',
            `upperBranches`,
            {
              filter: tf,
              period: filter.period,
              startDate: filter.startDate.valueOf(),
              endDate: filter.endDate.valueOf(),
              offset: filter.offset,
              type: filter.type,
              perPage
            },
            // if no nodeId then skip this call
            { skip: !filter, cache: true, responseHolder: `searchResult` },
            res => {
              setIsSearching(false);
              // console.log(res);
              if (res.hasValue('searchResult')) {
                const d = prepareBasicData({
                  ts: 3,
                  v: new Date().getTime(), // each search result has its own version code
                  loading: false,
                  children: res.data.value.searchResult.tree.children,
                  validForPeriods: res.data.value.searchResult.tree.metadata.validForPeriods,
                  filter
                });
                // console.log('Reset', d);
                setCursor(false);
                setCursorSelected(false);
                setData({ ...d });

                // setData(res.data.value.searchResult); // do not reset to avoid overwriting original tree (_data)
              }
            }
          );
        }

        return true;
      }, 500);
    });
  };

  const checkValidPeriod = validForPeriods => {
    // TODO: This name requires improvement and the issue starts with the state _data https://www.notion.so/mofiler/Refactor-Variables-for-improving-readibility-88b9ff8e2dc94e5d9ac143d5f30274c7
    // if (d && d.current && d.current.validForPeriods) {
    if (validForPeriods) {
      if (window._env_.REACT_APP_TYPE_PERIOD !== 'fixed') {
        // const vfp = d.current.validForPeriods[0];
        const vfp = validForPeriods[0];
        const dateMonth = moment.utc(filter.startDate).startOf('month');
        if (
          moment.utc(vfp.monthRangeStart).diff(dateMonth) <= 0 &&
          moment.utc(vfp.monthRangeEnd).diff(dateMonth) >= 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  function findChildrenPath(nodeId, lvl, path) {
    // console.log('findChildrenPath', nodeId, lvl, path);
    if (!lvl.children || lvl.children.length === 0) return null;

    let res = null;

    for (let i = 0; i < lvl.children.length; i++) {
      const currentPath = `${path}.${i}`;

      if (lvl.children[i].nodeId === nodeId) return currentPath; // found
      if (lvl.children[i].children && lvl.children[i].children.length > 0) {
        res = findChildrenPath(nodeId, lvl.children[i], `${currentPath}.children`); // not found, search inside current path
        if (res != null) return res;
      }
    }

    return null;
  }

  // modifies 'data' json
  function addChildren(parentId, nodes, data) {
    // call API to get node's children, then add to data json and refresh screen
    // console.log('addChildren', parentId, nodes);

    const path = findChildrenPath(parentId, data, 'children');
    // console.log('addChildren path', path);

    if (path !== null) {
      // console.log('start TO');
      const res = path.split('.').reduce(function (o, k) {
        return o && o[k];
      }, data);

      res.children = nodes || [];
      res.loading = false;
      res.childrenLoaded = true;
      // console.log('reset', data);
      resetData(data);

      // console.log('end TO');
    }

    // setCurrentNodeId(null); // reset
  }

  function findNodeByGroupHash(data, groupHash) {
    // console.log('data', data);
    if (!data) return null;

    for (let item of data) {
      // console.log(item, item.children, item.groupHash, groupHash, item.groupHash === groupHash);
      if (item.groupHash === groupHash) {
        return item;
      }
      // console.log('item.children2', item.children);
      if (item.children) {
        let found = findNodeByGroupHash(item.children, groupHash);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  useEffect(() => {
    // console.log('setCategoryExpanded', data);
    // expand first node by default
    if (data?.children?.length > 0) {
      const def = data.children[0];
      setCategoryExpanded({ ...categoryExpanded, [data.ts]: def, [`${data.ts}-index`]: 0 });
      const validPeriod =
        !hasFilterConditions && checkValidPeriod(nodeSelected?.metadata?.validForPeriods);
      // console.log('validPeriod', validPeriod, filterConditions, _data, nodeSelected);
      !validPeriod && setCursorSelected(false);
      onToggle(def, true);

      // this is the case where the user selected a diferent period and the current node is no longer valid, so we fire a selecNode in case the same group_hash is found in the new tree
      if (!validPeriod) {
        const node = findNodeByGroupHash(data?.children, filter.groupKeys[0]);
        node && !preventDefault && onSelect(prepareNode(node));
      }
    } else {
      data.ts &&
        setCategoryExpanded({ ...categoryExpanded, [data.ts]: null, [`${data.ts}-index`]: null });
      setCursorSelected(false);
      setNodeSelected(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.v]);

  useEffect(() => {
    // expand first node by default
    if (filterSinglePOI && nodeSelected && nodeSelected.nodeType !== 'ProxyGroup') {
      setNodeSelected(null);
    }
  }, [filterSinglePOI, nodeSelected]);

  // load root data
  useMemo(() => {
    // initializar
    // check for filterConditions to ensure that data refreshes if moved to a screen where we need to check any conditions
    const validPeriod = !hasFilterConditions && checkValidPeriod(_data?.current?.validForPeriods);
    // console.log('useMemo', _data, hasFilterConditions, validPeriod, filterConditions);
    if (!_data.current || poiType.current !== filter.type || !validPeriod) {
      poiType.current = filter.type;
      // console.log('useMemo filter', _data, filter);

      callSyncAPIv2DataLoader(
        'poisTree',
        `root/children`,
        {
          period: filter.period,
          startDate: filter.startDate.valueOf(),
          endDate: filter.endDate.valueOf(),
          offset: filter.offset,
          type: filter.type,
          perPage
        },
        { cache: true, responseHolder: `nodesroot` },
        res => {
          _data.current = prepareBasicData({
            ts: 2,
            v: validPeriod && _data.current?.v ? _data.current.v : formattedPeriod(filter),
            loading: false,
            children: res.data.value.nodesroot.tree.children,
            defaultPoiTreeNode: res.data.value.nodesroot.tree.metadata.defaultPoiTreeNode,
            validForPeriods: res.data.value.nodesroot.tree.metadata.validForPeriods,
            filter
          });

          const d = _data.current;

          if (
            d.ts !== undefined &&
            (data.ts === undefined || d.ts !== data.ts || d.type !== data.type || !validPeriod)
          ) {
            // console.log('first time setting data', d);
            setData({ ...d });
            setTextFilter('');

            // fire default selection (if any)
            if (
              !preventDefault &&
              d.defaultPoiTreeNode &&
              (!filter.groupKeys || filter.groupKeys.length === 0)
            ) {
              // console.log('going to pre select', d.defaultPoiTreeNode);
              onSelect(prepareNode(d.defaultPoiTreeNode));
            }
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_data, filter.period, filter.startDate]);

  return (
    <Modal
      size="xl"
      isOpen={modal}
      toggle={() => {}}
      className="modalFadeInScale ml-4 mr-4 mw-100 modal-selector"
      contentClassName="bg-transparent border-none"
    >
      <ModalBody className="p-0">
        <POISelectorContent
          data={data}
          filter={filter}
          filterConditions={filterConditions}
          perPage={perPage}
          cursor={cursor}
          cursorSelected={cursorSelected}
          nodeSelected={nodeSelected}
          categoryExpanded={categoryExpanded}
          setCategoryExpanded={setCategoryExpanded}
          isSearching={isSearching}
          textFilter={textFilter}
          onFilterSearh={onFilterSearh}
          onToggle={onToggle}
          onSelectNode={onSelectNode}
          filterSinglePOI={filterSinglePOI}
          onCancel={() => setModal(false)}
          onConfirm={nodeSelected => onSelect(prepareNode(nodeSelected))}
          onMore={onMore}
        />
      </ModalBody>
    </Modal>
  );
}

const prepareBasicData = ({
  ts,
  v,
  loading,
  children,
  defaultPoiTreeNode,
  validForPeriods,
  filter
}) => {
  // console.log(children);
  return {
    ts,
    v,
    displayName: 'root',
    nodeId: 'root',
    nodeType: 'Folder',
    toggled: true,
    loading,
    children: children || [],
    childrenLoaded: true,
    type: filter.type,
    defaultPoiTreeNode,
    validForPeriods
  };
};
