import { useState, useEffect, useRef, useCallback } from 'react';
const StickyHeader = scrollableRef => {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });

  const componentRef = useRef(null);

  const toggleSticky = useCallback(
    (elTopOffset, elHeight) => {
      // console.log(scrollableRef.current.scrollTop, window.scrollY);
      if (scrollableRef.current && scrollableRef.current.scrollTop > elTopOffset + elHeight) {
        setSticky({ isSticky: true, offset: scrollableRef.current.scrollTop });
      } else {
        setSticky({ isSticky: false, offset: 0 });
      }
    },
    [setSticky, scrollableRef]
  );

  useEffect(() => {
    const header = componentRef.current ? componentRef.current.getBoundingClientRect() : null;
    const ar = scrollableRef?.current;

    const handleScroll = () => {
      // toggleSticky(componentRef.current.getBoundingClientRect());
      // console.log('handleScroll', header);
      header && toggleSticky(header.top, header.height);
    };
    ar && ar.addEventListener('scroll', handleScroll);
    // console.log(scrollableRef.current);

    return () => {
      ar.removeEventListener('scroll', handleScroll);
    };
  }, [toggleSticky, scrollableRef]);

  return { componentRef, sticky };
};
export default StickyHeader;
