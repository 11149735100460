import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { DatePicker, DateRangePicker } from 'rsuite';
import './rsuite-default-custom.scss';

export default function CustomDatePicker({ fullMonth = true, value, valueRange, onChange }) {
  const [isLangLoaded, setLangLoaded] = useState(false);

  // load locale and repaint date components
  useEffect(() => {
    (async () => {
      // Dynamically imported module (runtime)

      if (moment.locale() !== window._env_.REACT_APP_LANGUAGE) {
        await import(`moment/locale/${window._env_.REACT_APP_LANGUAGE}`);
        moment.locale(window._env_.REACT_APP_LANGUAGE);

        setLangLoaded(true);
      } else {
        setLangLoaded(true);
      }
    })();
  }, []);

  // TODO: return a placeholder
  if (!isLangLoaded) return null;

  const nextWeekPeriod = forward => {
    if (valueRange) {
      const diff = (moment(valueRange[1]).diff(valueRange[0], 'days') + 1) * (forward ? 1 : -1);
      const newValue = [
        moment(valueRange[0]).add(diff, 'days').toDate(),
        moment(valueRange[1]).add(diff, 'days').toDate()
      ];
      onChange(newValue);
    }
  };

  const { allowedMaxDays } = DateRangePicker;

  return fullMonth ? (
    <DatePicker
      appearance="default"
      format="MMMM YYYY"
      block
      size="lg"
      value={value}
      renderValue={value => `${moment(value).format('MMMM')}  ${moment(value).format('YYYY')}`}
      ranges={[]}
      onChange={onChange}
      cleanable={false}
    />
  ) : (
    <div className="d-flex justify-center align-items-center text-white">
      <MdKeyboardArrowLeft
        size={25}
        onClick={() => nextWeekPeriod(false)}
        className="cursor-pointer"
      />
      <DateRangePicker
        appearance="default"
        hoverRange="week"
        isoWeek
        block
        size="lg"
        disabledDate={allowedMaxDays(35)} // 5 weeks max
        // showOneCalendar
        // oneTap
        value={valueRange}
        renderValue={value =>
          `${moment(value[0]).format('MMMM DD')} - ${moment(value[1]).format('MMMM DD')}`
        }
        ranges={[]}
        onChange={onChange}
        cleanable={false}
        className="w-100"
      />
      <MdKeyboardArrowRight
        size={25}
        onClick={() => nextWeekPeriod(true)}
        className="cursor-pointer"
      />
    </div>
  );
}
