import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { MdDelete, MdContentCopy, MdEdit } from 'react-icons/md/';

import { Card, CardBody } from 'reactstrap';

import numeral from 'numeral';
import moment from 'moment';
import toastr from 'toastr';
import history from '../../../history';

import { callSyncAPIv2DataLoader, useAPIv2DataLoader } from '../../hooks/api';
import API2 from '../../api2';

import Paginator from '../../components/pagination/Paginator';

import { Loading } from '../../components/utils/Loading';

import { Filter, SortIcon } from '../../components/filter/Filter';
import PopupConfirm from '../../components/utils/PopupConfirm';
import { ExportExcelPlanner } from '../../components/utils/ExportExcel';
import { buildModuleKey } from './utils';

function CampaignRow({ campaign, poiType, exportCampaignFields }) {
  const deleteCampaign = () => {
    PopupConfirm({
      onConfirm: () => {
        // apiCall('campaigns.delete', [campaign._id]);
        // window.location.reload();

        API2.delete('planner', `campaign/${campaign._id}`, {}, (err, res) => {
          if (err) {
            console.log('err', err);
            toastr.error(`${i18n.t('error_saving_campaign')}: ${err}`);
          } else {
            toastr.success(`${i18n.t('ok_saving_campaign')}`);
            window.location.reload();
          }
        });
      }
    });
  };

  const cloneCampaign = () => {
    history.push(`/planner/clone/${[campaign._id]}?type=${poiType}`);
  };

  const editCampaign = () => {
    history.push(`/planner/edit/${[campaign._id]}?type=${poiType}`);
  };

  return (
    <tr>
      <td>{campaign.name}</td>
      <td>{numeral(campaign.reach.uniqueReach).format('0.[0] a')}</td>
      <td>{numeral(campaign.reach.trpPopulation).format('0.0')}</td>
      <td>{numeral(campaign.reach.trpTarget).format('0.0')}</td>
      <td>{moment(campaign.createdAt).format('YY-MM-DD HH:mm:ss')}</td>
      <td>{campaign.user.email}</td>
      <td className="d-flex justify-content-end">
        <ExportExcelPlanner
          campaign={campaign}
          exportCampaignFields={exportCampaignFields}
          type={poiType}
        />
        <MdContentCopy
          size="18"
          color="#363537"
          onClick={cloneCampaign}
          style={{ cursor: 'pointer' }}
          title={i18n.t('campaign_actions_clone')}
          className="mr-2"
        />
        <MdEdit
          size="18"
          color="#363537"
          onClick={editCampaign}
          style={{ cursor: 'pointer' }}
          title={i18n.t('campaign_actions_edit')}
          className="mr-2"
        />
        <MdDelete
          size="18"
          color="#363537"
          onClick={deleteCampaign}
          style={{ cursor: 'pointer' }}
          title={i18n.t('campaign_actions_delete')}
        />
      </td>
    </tr>
  );
}

function ViewContent({
  totalItems,
  currentPage,
  setPage,
  campaigns,
  user,
  filter,
  handleFilterChange,
  sortBy,
  handleSortBy,
  poiType,
  exportCampaignFields
}) {
  return (
    <div>
      <div className="row">
        <div className="col-md-7 col-sm-7 col-xs-12">
          <h6 className="mb-4 text-uppercase">{i18n.t('campaign_list')}</h6>
        </div>
        <Filter filter={filter} handleFilterChange={handleFilterChange} />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              className="mb-2"
              style={{ width: '25%', minWidth: '25%' }}
              onClick={handleSortBy.bind(this, 'name')}
            >
              {i18n.t('campaign_name')}
              <SortIcon sortBy={sortBy} field="name" />
            </th>
            <th
              className="mb-2"
              style={{ width: '15%', minWidth: '15%' }}
              onClick={handleSortBy.bind(this, 'reach.uniqueReach')}
            >
              {i18n.t('campaign_reach')}
              <SortIcon sortBy={sortBy} field="reach.uniqueReach" />
            </th>
            <th
              className="mb-2"
              style={{ width: '10%', minWidth: '10%' }}
              onClick={handleSortBy.bind(this, 'reach.trpPopulation')}
            >
              {i18n.t('campaign_trp_population')}
              <SortIcon sortBy={sortBy} field="reach.trpPopulation" />
            </th>
            <th
              className="mb-2"
              style={{ width: '10%', minWidth: '10%' }}
              onClick={handleSortBy.bind(this, 'reach.trpTarget')}
            >
              {i18n.t('campaign_trp_target')}
              <SortIcon sortBy={sortBy} field="reach.trpTarget" />
            </th>
            <th
              className="mb-2"
              style={{ width: '20%', minWidth: '20%' }}
              onClick={handleSortBy.bind(this, 'createdAt')}
            >
              {i18n.t('campaign_creation_date')}
              <SortIcon sortBy={sortBy} field="createdAt" />
            </th>
            <th
              className="mb-2"
              style={{ width: '20%', minWidth: '20%' }}
              onClick={handleSortBy.bind(this, 'user.email')}
            >
              {i18n.t('campaign_created_by')}
              <SortIcon sortBy={sortBy} field="user.email" />
            </th>
            <th className="mb-4" style={{ textAlign: 'center', width: '15%', minWidth: '15%' }}>
              {i18n.t('campaign_actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {campaigns &&
            campaigns.map((campaign, i) => (
              <CampaignRow
                key={i}
                campaign={campaign}
                user={user}
                poiType={poiType}
                exportCampaignFields={exportCampaignFields}
              />
            ))}
        </tbody>
      </table>
      <Paginator totalItems={totalItems} currentPage={currentPage} setPage={setPage} />
    </div>
  );
}

export default function CampaignManager(props) {
  let { poiType } = props;
  if (!poiType) throw new Error('POI Type is mandatory');

  poiType = poiType.toLowerCase();

  const {
    match: {
      params: { page = 1 }
    },
    history,
    user
  } = props;

  const [filter, setFilter] = useState('');

  const [sortBy, setSortBy] = useState({ createdAt: -1 });

  const [exportCampaignFields, setExportCampaignFields] = useState(null);

  const module = buildModuleKey(poiType);

  const campaignsResponse = useAPIv2DataLoader(
    'planner',
    `campaigns`,
    { page, poiType, filter, sortBy },

    { cache: false, responseHolder: `response` }
  );

  if (campaignsResponse.error) {
    console.log('error', campaignsResponse.error);
  }

  const setPage = page => {
    history.push(`/planner/${page}?type=${poiType}`);
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const handleSortBy = field => {
    const currentSortBy = sortBy || {};

    const _sortBy = currentSortBy[field]
      ? {
          [field]: currentSortBy[field] * -1
        }
      : {
          [field]: 1
        };

    setSortBy(_sortBy);
  };

  // LOAD Marker Details Config
  useEffect(() => {
    let isMounted = true;

    callSyncAPIv2DataLoader(
      'appConfig',
      `${module}/exportCampaignFields`,
      {},
      { cache: true, responseHolder: `response` },
      res => {
        if (isMounted) {
          res.hasValue('response') &&
            setExportCampaignFields(res.data.value.response[module].exportCampaignFields);
        }
      }
    );

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setExportCampaignFields]);

  const { campaigns, count } = campaignsResponse.hasValue('response')
    ? campaignsResponse.data.value.response
    : {};

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('planner_menu')}</h1>
          <p className="mb-0">{i18n.t('planner_manager')}</p>
        </header>
      </div>
      <div className="view-content view-components">
        <Card className="mb-4">
          <CardBody className="table-responsive">
            <Loading loaded={campaignsResponse.data.state !== 'LOADING'} />
            <ViewContent
              totalItems={count}
              currentPage={page}
              setPage={setPage}
              campaigns={campaigns}
              user={user}
              filter={filter}
              handleFilterChange={handleFilterChange}
              sortBy={sortBy}
              handleSortBy={handleSortBy}
              poiType={poiType}
              exportCampaignFields={exportCampaignFields}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
