import { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap';
import API2 from '../../api2/index';
import CheckoutForm from './CheckoutForm';
import LoadingModal from '../../components/utils/LoadingModal';
import { useDispatch } from 'redux-react-hook';
import { authSetOrganization, logout } from '../../redux/ducks/auth';
import { callSyncAPIv2DataLoader } from '../../hooks/api';

const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function Checkout(props) {
  const [trialPeriodDays, setTrialPeriodDays] = useState(0);

  useEffect(() => {
    let isMounted = true;

    callSyncAPIv2DataLoader(
      'appConfig',
      'billing/trialPeriod',
      {},
      { cache: true, responseHolder: `response` },
      res => {
        if (isMounted) {
          res.hasValue('response') &&
            setTrialPeriodDays(res.data.value.response?.billing?.trialPeriod?.days);
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, []);

  const { organization, withNav, history } = props;

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('edit_organization_title')}</h1>
          <p className="mb-0">{i18n.t('create_organization')}</p>
        </header>
      </div>
      <ViewContent
        organization={organization}
        withNav={withNav}
        history={history}
        trialPeriodDays={trialPeriodDays}
      />
    </div>
  );
}

function ViewContent({ organization, withNav, history, trialPeriodDays }) {
  const dispatch = useDispatch();
  const logoutDispath = () => dispatch(logout());

  const [options, setOptions] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    API2.get('organizations', `${organization.id}/subscription`, {}, (err, res) => {
      if (err) {
        if (err.code === 403) {
          setError(i18n.t('organization_not_allowed', { organization: organization.name }));
        } else {
          setError(
            i18n.t('unknown_error_please_contact_support', { msg: JSON.stringify(err.message) })
          );
        }
      } else {
        const organization = res.organization;
        const subscription = res.subscription;

        // if organization is ACTIVE then no need to create a payment form, set the org to state and redirect
        if (organization.status === 'ACTIVE') {
          dispatch(authSetOrganization(organization));
          history.push('/dashboard');
          return;
        }

        if (!subscription.stripeSubscription.clientSecret) {
          setError(i18n.t('organization_payment_not_valid', { organization: organization.name }));
        }

        let newOptions = options || {};
        newOptions.clientSecret = subscription.stripeSubscription.clientSecret;
        setOptions(newOptions);
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading && <LoadingModal isOpen={true} text={i18n.t('organization_loading')} />}
      <div className="view-content view-components">
        <Card className="mb-4">
          <CardBody className="table-responsive">
            <h6 className="mb-4 text-uppercase">{i18n.t('organization_info')}</h6>
            <Card className="align-items-center">
              {error && <Alert color="danger">{error}</Alert>}
              <CardBody className="mt-3">
                <Row>
                  <Col sm={5} className="pr-5">
                    <span
                      className="w-100"
                      dangerouslySetInnerHTML={{
                        __html: i18n.t('organization_signup_text', { trialPeriodDays })
                      }}
                    ></span>
                  </Col>
                  <Col sm={7} className="pr-5 pl-5">
                    {options?.clientSecret && (
                      <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm setLoading={setLoading} />
                      </Elements>
                    )}
                    {!withNav && (
                      <Button
                        color=""
                        onClick={logoutDispath}
                        className="btn-block border-0 shadow-none btn-sm mt-3"
                      >
                        {i18n.t('logout')}
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
