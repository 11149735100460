export default function InputRadio({ isSelected, multiSelected, disabled }) {
  return (
    <div
      className={
        'relative w-4.5 h-4.5 outline outline-1 rounded-full transition-all hover:outline-blue-500 duration-200 z-10 ' +
        (isSelected
          ? 'bg-blue-600 outline-blue-500 border-4 border-white'
          : multiSelected
          ? 'bg-blue-200/50 outline-blue-200 border-4 border-white'
          : 'bg-white outline-slate-500') +
        ' ' +
        (disabled ? 'cursor-default' : 'cursor-pointer')
      }
    />
  );
}

/*outline: 1px solid theme("colors.blue.700");
background-color: theme("colors.blue.700");
border: 4px solid white; */
