import L from 'leaflet';

const createCircleIcon = color => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="10" height="10" fill="${color}"><circle cx="10" cy="10" r="10"/></svg>`;
  return new L.DivIcon({
    className: 'custom-svg-icon',
    html: svg,
    iconSize: [12, 12],
    iconAnchor: [8, 8]
  });
};

const createSquareIcon = color => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="14" height="14" fill="${color}"><rect width="14" height="14" /></svg>`;
  return new L.DivIcon({
    className: 'custom-svg-icon',
    html: svg,
    iconSize: [12, 12],
    iconAnchor: [8, 8]
  });
};

const createTriangleIcon = color => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="12" height="12" fill="${color}"><polygon points="16,0 32,32 0,32"/></svg>`;
  return new L.DivIcon({
    className: 'custom-svg-icon',
    html: svg,
    iconSize: [12, 12],
    iconAnchor: [8, 12]
  });
};

const createStarIcon = color => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="7" height="7" fill="${color}"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c18.9-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"/></svg>`;
  return new L.DivIcon({
    className: 'custom-svg-icon',
    html: svg,
    iconSize: [12, 12],
    iconAnchor: [8, 8]
  });
};

const createDiamondIcon = color => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="7" height="7" fill="${color}"><polygon points="16,0 32,16 16,32 0,16"/></svg>`;
  return new L.DivIcon({
    className: 'custom-svg-icon',
    html: svg,
    iconSize: [12, 12],
    iconAnchor: [8, 8]
  });
};

const createCrossIcon = color => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="10" height="10" fill="${color}"><path d="M27.3 4.7c-.6-.6-1.5-.6-2.1 0L16 14 6.8 4.7c-.6-.6-1.5-.6-2.1 0s-.6 1.5 0 2.1L14 16l-9.3 9.3c-.6.6-.6 1.5 0 2.1s1.5.6 2.1 0L16 18l9.3 9.3c.6.6 1.5.6 2.1 0s.6-1.5 0-2.1L18 16l9.3-9.3c.6-.6.6-1.5 0-2.1z"/></svg>`;
  return new L.DivIcon({
    className: 'custom-svg-icon',
    html: svg,
    iconSize: [12, 12],
    iconAnchor: [8, 8]
  });
};

const createCheckmarkIcon = color => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="12" height="12" fill="${color}"><path d="M12 24L4 16l3-3 5 5L24 4l3 3z"/></svg>`;
  return new L.DivIcon({
    className: 'custom-svg-icon',
    html: svg,
    iconSize: [12, 12],
    iconAnchor: [8, 8]
  });
};

const createHeartIcon = color => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12" height="12" fill="${color}"><path d="M462.3 62.7c-54.5-46.4-136-38.7-186.6 13.5-50.6-52.3-132.1-60-186.6-13.5-62.2 53.6-66.1 149.8-9.9 207.9L218 480l186.3-209.4c56.3-58.1 52.3-154.3-9.9-207.9z"/></svg>`;
  return new L.DivIcon({
    className: 'custom-svg-icon',
    html: svg,
    iconSize: [12, 12],
    iconAnchor: [8, 8]
  });
};

const createHexagonIcon = color => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="8" height="8" fill="${color}"><polygon points="16,0 32,8 32,24 16,32 0,24 0,8"/></svg>`;
  return new L.DivIcon({
    className: 'custom-svg-icon',
    html: svg,
    iconSize: [12, 12],
    iconAnchor: [8, 8]
  });
};

const createOctagonIcon = color => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="8" height="8" fill="${color}"><polygon points="8,0 24,0 32,8 32,24 24,32 8,32 0,24 0,8"/></svg>`;
  return new L.DivIcon({
    className: 'custom-svg-icon',
    html: svg,
    iconSize: [12, 12],
    iconAnchor: [8, 8]
  });
};

const poiIconCreators = [
  createCircleIcon,
  createSquareIcon,
  createTriangleIcon,
  createStarIcon,
  createDiamondIcon,
  createCrossIcon,
  createHexagonIcon,
  createOctagonIcon,
  createCheckmarkIcon,
  createHeartIcon
];
export default poiIconCreators;
